import * as React from 'react';

export default ({
  stroke,
  width,
  height,
}: {
  stroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3 5.99609L13 6"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.99609L13 10"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 13V3" stroke={stroke || '#0A132B'} strokeWidth="2" strokeLinecap="round" />
    <path d="M10 13V3" stroke={stroke || '#0A132B'} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
