import type { Task } from 'redux-saga';
import type { Effects, ReduxSageEffectReverse, ReduxState } from './types';
import type { FetchEffectPayload, SaveAllAction, SaveCurrentAction } from './flows';
import Flow from '@/services/flow';
import { handleUserError } from '@/utils/handleError';
import { assertIsArray } from '@/utils/typeChecks';
import { sgcall } from '@/utils/reduxSaga';

const ns = 'app.models.flows.fetch';

const fetchLocalOrRemote: ReduxSageEffectReverse = function* fetchLocalOrRemote(
  { put },
  { workspaceId, remote }: FetchEffectPayload & { remote: boolean },
) {
  try {
    const flows = yield* sgcall(() => Flow.getFlowsByWorkspace(workspaceId, remote));
    flows.sort((a, b) => a.name.localeCompare(b.name));
    yield put<SaveAllAction>({ type: 'saveAll', payload: { all: flows } });
  } catch (e) {
    handleUserError(e, `${ns}.fetchLocalOrRemote`);
  }
};

let spawnedFetchOnceTask: Task;

export const fetchOnce: ReduxSageEffectReverse = function* fetchOnce(
  effects: Effects,
  {
    payload: { invokedFetchEffectPayload },
  }: { payload: { invokedFetchEffectPayload: FetchEffectPayload } },
) {
  const { call, put, select, spawn, cancel } = effects;
  if (spawnedFetchOnceTask?.isRunning()) yield cancel(spawnedFetchOnceTask);

  yield put<SaveAllAction>({ type: 'saveAll', payload: { all: null } });
  yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: null } });
  yield call(fetchLocalOrRemote, effects, { ...invokedFetchEffectPayload, remote: false });

  const flows = yield select((s: ReduxState) => s.flows.all);
  assertIsArray(flows, 'Flow', 'BEEM230712134518');
  const flowNeedsRemote = flows.find((el) => !el.lastRunState);

  if (flowNeedsRemote) {
    spawnedFetchOnceTask = (yield spawn(fetchLocalOrRemote, effects, {
      ...invokedFetchEffectPayload,
      remote: true,
    })) as Task;
  }
};
