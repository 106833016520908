import * as React from 'react';

export default () => (
  <svg width="40" height="40">
    <defs>
      <path
        d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
        id="a"
      />
      <path d="M1.058 6.186h13.853v3.175a6.926 6.926 0 1 1-13.853 0V6.186z" id="b" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#FFF" xlinkHref="#a" />
        <use fillOpacity=".2" fill="#0067F4" xlinkHref="#a" />
      </g>
      <g strokeLinejoin="round" transform="rotate(45 1.429 29.778)">
        <use fill="#FFF" xlinkHref="#b" />
        <use stroke="#0067F4" fillOpacity=".2" fill="#0067F4" xlinkHref="#b" />
      </g>
      <path
        stroke="#0067F4"
        strokeLinecap="round"
        d="M25.686 20.67l4.08-4.082M19.808 14.793l4.081-4.082M11.523 28.956l4.08-4.082"
      />
    </g>
  </svg>
);
