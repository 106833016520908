import * as React from 'react';

export default () => (
  <svg width="55" height="22">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <rect stroke="#B5BFD2" fill="#FFF" width="20" height="20" rx="10" />
      <rect fill="#B5BFD2" x="4" y="4" width="12" height="12" rx="6" />
      <text fontFamily="OpenSans-Bold, Open Sans" fontSize="14" fontWeight="bold" fill="#B5BFD2">
        <tspan x="27" y="15">
          OFF
        </tspan>
      </text>
    </g>
  </svg>
);
