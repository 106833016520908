import * as React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.00508L8.66667 18.9929H15.3333L17 7.00508H7Z"
      stroke="#F40045"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M5 7.00508H19" stroke="#F40045" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M9 7.00508V5.00711H15V7.00508"
      stroke="#F40045"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
