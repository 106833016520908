import type * as A from '@/api';
import type { TriggerDetails } from '@/services/triggerDetails';
import { Trigger } from '@/api';
import TriggerDetailsService from '@/services/triggerDetails';

export type DatasetEditStateProps = {
  timestamp: number;
  sqlQuery: string;
  trigger: A.Trigger;
  triggerDetails: TriggerDetails;
};

export default class DatasetEditState {
  readonly initial: DatasetEditStateProps; // values saved in dynamodb according to the version loaded

  readonly current: DatasetEditStateProps; // values currently in the editor page (could be same as initial or have been modified)

  readonly saved: DatasetEditStateProps | null; // latest values saved in dynamodb; null when the dataset was never saved

  readonly deployed: DatasetEditStateProps | null; // latest values deployed in customer's account; null when the dataset was never deployed

  constructor({
    initial,
    current,
    saved,
    deployed,
  }: {
    initial: DatasetEditStateProps;
    current: DatasetEditStateProps;
    saved: DatasetEditStateProps | null;
    deployed: DatasetEditStateProps | null;
  }) {
    this.initial = initial;
    this.current = current;
    this.saved = saved;
    this.deployed = deployed;

    this.hasUndeployedChanges = this.hasUndeployedChanges.bind(this);
  }

  hasUndeployedChanges(): boolean {
    return this.saved?.timestamp !== this.deployed?.timestamp;
  }

  static getDefaultState(): DatasetEditStateProps {
    return {
      timestamp: 0,
      sqlQuery: '',
      trigger: Trigger.MANUAL,
      triggerDetails: TriggerDetailsService.setDefaults({}),
      // @ts-expect-error
      description: '',
      enableSearchByAI: false,
    };
  }

  static areSimilarStates(
    s1: DatasetEditStateProps | null,
    s2: DatasetEditStateProps | null,
  ): boolean {
    if (s1 === null && s2 === null) return true;
    if (s1 === null || s2 === null) return false;
    const isCodeSimilar = s1.sqlQuery === s2.sqlQuery;
    const isTriggerSimilar = s1.trigger === s2.trigger;
    const isTriggerDetailsSimilar = s1.triggerDetails.cron === s2.triggerDetails.cron;
    return isCodeSimilar && isTriggerSimilar && isTriggerDetailsSimilar;
  }
}
