export default {
  "datasetAllTests": {
    "download_modal_dialog": {
      "title": "Download {name} on your desktop",
      "description": "Select the download file format for the table.",
      "filtered_button": "Download Filtered",
      "full_button": "Download All",
      "downloading": {
        "title": "Download file",
        "description": "Downloading the file can take a moment. It will be ready in your browser."
      }
    }
  }
};
