import * as React from 'react';

export default ({
  stroke,
  width,
  height,
}: {
  stroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect x="7" y="3" width="3" height="11" rx="1" stroke={stroke || '#0A132B'} strokeWidth="2" />
    <path
      d="M2 6C2 5.44772 2.44772 5 3 5H7V12H3C2.44772 12 2 11.5523 2 11V6Z"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
    />
    <path
      d="M10 5H13C13.5523 5 14 5.44772 14 6V11C14 11.5523 13.5523 12 13 12H10V5Z"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
    />
  </svg>
);
