import * as React from 'react';

export default () => {
  return (
    <svg width="40" height="40">
      <defs>
        <path d="M6 8a4 4 0 100-8 4 4 0 000 8z" id="modal-share-a" />
        <path
          d="M2.5 10h7a2.5 2.5 0 012.5 2.5V14a1 1 0 01-1 1H1a1 1 0 01-1-1v-1.5A2.5 2.5 0 012.5 10z"
          id="modal-share-b"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          fill="#0067F4"
          opacity=".1"
        />
        <g stroke="#0067F4" strokeLinecap="round" strokeLinejoin="round">
          <path d="M24.917 17.688h6M27.917 14.688v6" />
        </g>
        <g transform="translate(12.917 11.688)">
          <use fill="#FFF" href="#modal-share-a" />
          <path
            stroke="#0067F4"
            d="M6 .5c.966 0 1.841.392 2.475 1.025A3.489 3.489 0 019.5 4c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 016 7.5a3.489 3.489 0 01-2.475-1.025A3.489 3.489 0 012.5 4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 016 .5z"
            strokeLinejoin="square"
            fillOpacity=".2"
            fill="#0067F4"
          />
        </g>
        <g strokeLinejoin="round" transform="translate(12.917 11.688)">
          <use fill="#FFF" href="#modal-share-b" />
          <path
            stroke="#0067F4"
            d="M9.5 9.5a2.99 2.99 0 012.121.879A2.99 2.99 0 0112.5 12.5h0V14c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0H1c-.414 0-.79-.168-1.06-.44A1.495 1.495 0 01-.5 14h0v-1.5c0-.828.336-1.578.879-2.121A2.99 2.99 0 012.5 9.5h0z"
            fillOpacity=".2"
            fill="#0067F4"
          />
        </g>
      </g>
    </svg>
  );
};
