export interface ITableReference {
  table: string;
  schema: string;
}

export abstract class GenericQuery {
  private query: string;
  // private columns: string[] = [];
  private isParsed: boolean = false;
  private requiredKeywords: string[] = ['from', 'join'];

  public tables: ITableReference[] = [];
  public errors: string[] = [];
  public tokens: string[] = [];
  public sanitizedQuery: string;

  constructor(query: string) {
    this.query = query;
    this.sanitizedQuery = this.sanitize();
    this.requiredKeywordsCheck();
  }

  private sanitize(): string {
    // find inline comments and delete them
    let tmpQuery = this.query.replace(/--.*/g, '');

    // find block comments and delete them
    tmpQuery = tmpQuery.replace(/\/\*[\s\S]*?\*\//g, '');

    // remove new lines, tabs and carriage returns
    tmpQuery = tmpQuery.replace(/[\n\r\t]/g, ' ');

    return tmpQuery;
  }

  // check if at least one of the required keywords is present in the query
  private requiredKeywordsCheck(): void {
    const queryContainsRequiredKeyword = this.requiredKeywords.some((str) =>
      this.sanitizedQuery.toLowerCase().includes(` ${str} `),
    );

    if (!queryContainsRequiredKeyword) {
      this.errors.push(
        `Query must contain at least one of the following keywords: ${this.requiredKeywords.join(
          ', ',
        )}`,
      );
    }
  }

  private hasErrors(): boolean {
    return this.errors.length > 0;
  }

  public parse(force: boolean = false): void {
    if (this.isParsed && !force) {
      this.errors.push("Query already parsed. To force parsing, use 'force: true' option.");
      return;
    }

    if (!this.hasErrors()) {
      this.tables = this.parseTables();
      this.isParsed = true;
    }
  }

  protected parseTableReference(nextToken: string, nxtNextToken: string): ITableReference {
    let nextNextToken = nxtNextToken;
    const tableReference = {
      schema: '',
      table: '',
    };

    const tokenSplit = nextToken.split('.');

    if (tokenSplit && tokenSplit.length === 2) {
      tableReference.schema = tokenSplit[0].replace(/"/g, '');
      tableReference.table = tokenSplit[1].replace(/"/g, '');
    } else if (nextNextToken && nextNextToken.includes('.')) {
      nextNextToken = nextNextToken.replace(/\./g, '');
      tableReference.schema = nextToken.replace(/"/g, '');
      tableReference.table = nextNextToken.replace(/"/g, '');
    } else {
      this.errors.push(
        `Warning: Could not parse table reference from token '${nextToken}', could be a CTE.`,
      );
    }

    // support hyphen to underscore migration of schema
    tableReference.schema = tableReference.schema.replace(/-/g, '_');

    return tableReference;
  }

  protected abstract parseTables(): ITableReference[];
}
