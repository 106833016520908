import type { InvokeResult, ReduxSagaModel } from './types';
import type { Filter, Sorting } from '@/models/objects/datasetViewState';
import type { SupportedDownloadRecordTypes, SupportedDownloadFileTypes } from '@/services/download';
import type { BeemError } from '@/utils/handleError';
import Download from '@/services/download';
import { sgcall } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';
import { logger } from '@/utils/logger';
import { assertIsDefined } from '@/utils/typeChecks';

type DownloadResult = { id: string; url?: string; error?: BeemError };

export type State = {
  invokeResult: {
    resultByObjectId: Record<string, InvokeResult<string>>;
  };
};

const initialState: State = {
  invokeResult: { resultByObjectId: {} },
};

type SignedDownloadUrlAction = {
  type: 'signedDownloadUrl';
  payload: DownloadResult;
};

type InvokeErrorAction = {
  type: 'invokeError';
  payload: DownloadResult;
};

const DownloadModel: ReduxSagaModel<
  State,
  {
    signedDownloadUrl: SignedDownloadUrlAction;
    invokeError: InvokeErrorAction;
  }
> = {
  namespace: 'downloads',
  state: initialState,
  effects: {
    *invoke(
      {
        payload,
      }: {
        payload: {
          timestamp: number;
          type: SupportedDownloadRecordTypes;
          id: string;
          name: string;
          cloudResourceId: string;
          fileType: SupportedDownloadFileTypes;
          filters: Filter[];
          sortings: Sorting[];
        };
      },
      { put },
    ) {
      try {
        const download = yield* sgcall(() =>
          Download.invokeDownload(
            payload.type,
            payload.id,
            payload.name,
            payload.cloudResourceId,
            payload.fileType,
            payload.filters,
            payload.sortings,
          ),
        );
        logger.debug({ label: 'app.models.downloads.invokeDownload.download', message: download });

        yield put<SignedDownloadUrlAction>({
          type: 'signedDownloadUrl',
          payload: {
            id: `${payload.id}_${payload.timestamp}`,
            url: download.signedDownloadUrl,
          },
        });
      } catch (e) {
        yield put<InvokeErrorAction>({
          type: 'invokeError',
          payload: {
            id: `${payload.id}_${payload.timestamp}`,
            error: handleUserError(e, 'app.models.downloads.invokeDownload.error'),
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { invokeResult: { resultByObjectId: {} } };
    },
    signedDownloadUrl(state, { payload: { id, url } }) {
      assertIsDefined(url, 'BEEM220920134055');
      const { resultByObjectId } = state.invokeResult;
      resultByObjectId[id] = { success: true, data: url };
      return { ...state, invokeResult: { resultByObjectId } };
    },
    invokeError(state, { payload: { id, error } }) {
      assertIsDefined(error, 'BEEM220920134056');
      const { resultByObjectId } = state.invokeResult;
      resultByObjectId[id] = { success: false, error };
      return { ...state, invokeResult: { resultByObjectId } };
    },
  },
};

export default DownloadModel;
