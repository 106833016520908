import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M8 6V8" stroke="#FFA800" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M1 11L6 2H10L15 11L13 14H3L1 11Z"
      stroke="#FFA800"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <circle cx="8" cy="11" r="1" fill="#FFA800" />
  </svg>
);
