import type { ReduxSagaModel } from './types';
import { logger } from '@/utils/logger';

export type State = {
  isMarketPlaceUiOpen: boolean;
  isSettingsOpen: boolean;
};

interface SaveUpdateAction {
  type: 'saveUpdate';
  // eslint-disable-next-line
  payload: { [record: string]: boolean };
}

const initialState: State = { isMarketPlaceUiOpen: false, isSettingsOpen: false };

const OverlayModel: ReduxSagaModel<State, { saveUpdate: SaveUpdateAction }> = {
  namespace: 'overlay',
  state: initialState,
  effects: {
    *update({ payload }: { payload: { overlayType: string; status: boolean } }, { put }) {
      logger.debug({ label: 'app.models.overlay.update', message: payload });
      const { overlayType, status } = payload;

      yield put<SaveUpdateAction>({
        type: 'saveUpdate',
        payload: { [overlayType]: status },
      });
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveUpdate(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default OverlayModel;
