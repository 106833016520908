import React from 'react';

function Icon({ state }: { state: 'active' | 'hover' | 'static' }) {
  let fill = '#0067F4';
  if (state === 'hover') fill = '#0A132B';
  else if (state === 'static') fill = '#6C7280';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6.262 6.535C6.032 6.768 6 6.924 6 7c0 .076.032.232.262.465.234.238.623.497 1.182.737C8.56 8.68 10.17 9 12 9c1.83 0 3.44-.32 4.556-.798.56-.24.948-.5 1.182-.737.23-.233.262-.389.262-.465 0-.076-.032-.232-.262-.465-.234-.238-.623-.497-1.182-.737C15.44 5.32 13.83 5 12 5c-1.83 0-3.44.32-4.556.798-.56.24-.948.5-1.182.737zm.394-2.575C8.075 3.352 9.965 3 12 3s3.925.352 5.344.96c.707.303 1.344.69 1.818 1.17C19.64 5.614 20 6.248 20 7s-.36 1.386-.838 1.87c-.229.231-.494.441-.788.63.153.099.298.202.434.311.65.523 1.192 1.26 1.192 2.189 0 .752-.36 1.386-.838 1.87a4.62 4.62 0 01-.788.63c.154.1.3.204.436.313.65.523 1.19 1.26 1.19 2.187 0 .752-.36 1.386-.838 1.87-.474.48-1.11.867-1.818 1.17-1.419.608-3.309.96-5.344.96s-3.925-.352-5.344-.96c-.707-.303-1.344-.69-1.818-1.17C4.36 18.385 4 17.752 4 17c0-.932.546-1.671 1.2-2.195.133-.106.275-.208.426-.305a4.617 4.617 0 01-.788-.63C4.36 13.386 4 12.752 4 12c0-.93.543-1.668 1.195-2.19.134-.109.278-.212.43-.31a4.613 4.613 0 01-.787-.63C4.36 8.386 4 7.752 4 7s.36-1.386.838-1.87c.474-.48 1.11-.867 1.818-1.17zm1.487 11.58c-.757.24-1.326.533-1.693.827-.4.319-.45.542-.45.633 0 .076.032.232.262.465.234.238.623.497 1.182.737C8.56 18.68 10.17 19 12 19c1.83 0 3.44-.32 4.556-.798.56-.24.948-.5 1.182-.737.23-.233.262-.389.262-.465 0-.09-.05-.312-.444-.629-.367-.295-.938-.59-1.7-.831-1.148.297-2.469.46-3.856.46s-2.708-.163-3.857-.46zM12 11c1.387 0 2.708-.163 3.857-.46.76.241 1.33.535 1.698.83.395.318.445.54.445.63 0 .076-.032.232-.262.465-.234.238-.623.498-1.182.737C15.44 13.68 13.83 14 12 14c-1.83 0-3.44-.32-4.556-.798-.56-.24-.948-.5-1.182-.737C6.032 12.232 6 12.076 6 12c0-.09.05-.313.447-.631.367-.294.937-.588 1.696-.83 1.15.298 2.47.461 3.857.461z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
