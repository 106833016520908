import * as React from 'react';

export default ({
  stroke,
  width,
  height,
}: {
  stroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3 7H13M5 4V3M11 4V3M12 13H4C3.44772 13 3 12.5523 3 12V5C3 4.44772 3.44772 4 4 4H12C12.5523 4 13 4.44772 13 5V12C13 12.5523 12.5523 13 12 13Z"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
