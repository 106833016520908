import * as React from 'react';

export default ({
  stroke,
  width,
  height,
}: {
  stroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6 12H5.8541C4.83011 12 4 11.1699 4 10.1459V10.1459C4 9.44362 3.60322 8.80161 2.97508 8.48754L2 8"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 12H10.1459C11.1699 12 12 11.1699 12 10.1459V10.1459C12 9.44362 12.3968 8.80161 13.0249 8.48754L14 8"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 4H5.8541C4.83011 4 4 4.83011 4 5.8541V5.8541C4 6.55638 3.60322 7.19839 2.97508 7.51246L2 8"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4H10.1459C11.1699 4 12 4.83011 12 5.8541V5.8541C12 6.55638 12.3968 7.19839 13.0249 7.51246L14 8"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
