import * as React from 'react';

export default () => (
  <svg width="24" height="18" fill="none">
    <path
      d="M12.133 13.265l3.34 3.088 6.68-6.177M8.793 16.353H3a2 2 0 0 1-2-2V3.94a2 2 0 0 1 2-2h11.587a2 2 0 0 1 2 2v5.206"
      stroke="#0067F4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 7.088h15.587M6.567 1.941v14.412"
      stroke="#0067F4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
