export default {
  "view_integration": {
    "button": {
      "default": "Edit",
      "primary": "Done"
    },
    "update": "Update settings",
    "delete": "Delete integration",
    "connection": "Connection",
    "details": "Integration details",
    "integration": "Integration:",
    "added_by": "Added by:",
    "categories": "Categories:",
    "data_source": "Data source:",
    "data_source_supported": "Supported",
    "data_source_not_supported": "Not supported",
    "flow_actions": "Flow actions:",
    "integrations": {
      "active_campaign": {
        "title": "Connection to Active Campaign",
        "sub_title": "Authenticate and configure your Active Campaign integration.",
        "description": "Log in with your username and password to your Active Campaign account, and go to the Developer Settings page to access the API url and API Key.",
        "email": "API Url",
        "password": "API Key"
      },
      "emyx": {
        "title": "Connection to EMYX",
        "sub_title": "Authenticate and configure your EMYX integration.",
        "description": "Log in with your username and password to your EMYX app, and access your Client ID and Client Secret Key in the Profile section.",
        "email": "Client ID",
        "password": "Secret Key"
      }
    }
  }
};
