import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0z" />
        <g stroke={props.color || '#B5BFD2'} strokeWidth="2">
          <path strokeLinejoin="round" d="M6 6v14h12V6z" />
          <path strokeLinecap="round" d="M10 10v6M14 10v6" />
        </g>
        <g stroke={props.color || '#B5BFD2'} strokeLinecap="round" strokeWidth="2">
          <path d="M4 6h16" />
          <path strokeLinejoin="round" d="M9 6V4h6v2" />
        </g>
      </g>
    </svg>
  );
};
