import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="State=Static">
      <path
        id="Path 5"
        d="M19 4L3 9L10 13M19 4L14 21L10 13M19 4L10 13"
        stroke={props.color || '#6C7280'}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
