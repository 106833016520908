import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M10.842 4.125a1.125 1.125 0 0 1 2.25 0v5.74l4.913-2.836a1.286 1.286 0 1 1 1.285 2.227L14.538 12l4.752 2.744a1.286 1.286 0 1 1-1.285 2.227l-4.913-2.837v5.741a1.125 1.125 0 0 1-2.25 0v-5.74L5.929 16.97a1.286 1.286 0 0 1-1.286-2.227L9.395 12 4.643 9.256A1.286 1.286 0 1 1 5.93 7.03l4.913 2.837V4.125z"
        fill="#fff"
      />
      <path
        d="M10.842 4.125a1.125 1.125 0 0 1 2.25 0v5.74l4.913-2.836a1.286 1.286 0 1 1 1.285 2.227L14.538 12l4.752 2.744a1.286 1.286 0 1 1-1.285 2.227l-4.913-2.837v5.741a1.125 1.125 0 0 1-2.25 0v-5.74L5.929 16.97a1.286 1.286 0 0 1-1.286-2.227L9.395 12 4.643 9.256A1.286 1.286 0 1 1 5.93 7.03l4.913 2.837V4.125z"
        fill="#B5BFD2"
        fillOpacity=".202"
      />
      <path
        d="M5.929 16.97a1.286 1.286 0 0 1-1.286-2.226L9.395 12 4.643 9.256A1.286 1.286 0 0 1 5.93 7.03l4.913 2.837V4.125a1.125 1.125 0 0 1 2.25 0v5.74l4.913-2.836a1.286 1.286 0 1 1 1.285 2.227L14.538 12l4.752 2.744a1.286 1.286 0 1 1-1.285 2.227l-4.913-2.837v5.741a1.125 1.125 0 0 1-2.25 0v-5.74L5.929 16.97zm2.913.628l-1.913 1.105a3.286 3.286 0 0 1-3.286-5.691L5.395 12l-1.752-1.012a3.286 3.286 0 0 1 3.286-5.69l1.913 1.104V4.125a3.125 3.125 0 0 1 6.25 0v2.277l1.913-1.105a3.286 3.286 0 0 1 3.285 5.691L18.538 12l1.752 1.012a3.286 3.286 0 0 1-3.285 5.69l-1.913-1.104v2.277a3.125 3.125 0 1 1-6.25 0v-2.277z"
        fill="#B5BFD2"
      />
    </g>
  </svg>
);
