export default {
  "datasetTest": {
    "title": "Data Validation",
    "subtitle_1": "The data validation step occurs once your dataset run is completed. Create tests to block the use of dataset results that do not meet your test criteria.",
    "subtitle_2": "You will be notified if your dataset tests produce results and your dataset results were blocked from being updated.",
    "create_button": "Create Dataset Test",
    "create_modal_dialog": {
      "title": "Create a new dataset test",
      "folder_name_input": "Dataset test name",
      "submit_button": "Create dataset test"
    },
    "rename_modal_dialog": {
      "title": "Rename {name}",
      "name_input": "Dataset test new name",
      "submit_button": "Update"
    },
    "delete_modal_dialog": {
      "title": "Delete dataset test {name}?",
      "description": "Deleting a dataset test is permanent and cannot be undone.",
      "submit_button": "Delete dataset test"
    },
    "view": {
      "last_updated_moment_format": "MMMM D, YYYY h:mm A",
      "last_updated_tag": "LATEST",
      "download": "Download now",
      "edit": "Edit dataset test",
      "run": {
        "requested": "Dataset test run request has started.",
        "requested_d": "Please wait for the run to complete.",
        "request_failed": "Dataset test run request has failed.",
        "success": "Dataset test refresh succeeded.",
        "success_d": "Click to view result",
        "failure": "Dataset test refresh failed.",
        "failure_d": "Click to view error"
      }
    },
    "download_menu_item": "Download",
    "download_modal_dialog": {
      "title": "Download {name} on your desktop",
      "description": "Select the download file format for the table.",
      "filtered_button": "Download Filtered",
      "full_button": "Download All",
      "downloading": {
        "title": "Download file",
        "description": "Downloading the file can take a moment. It will be ready in your browser."
      }
    }
  }
};
