import flatten from 'flat';
import flowList from './fr-FR/flowList';
import upcomingFlows from './fr-FR/upcomingFlows';
import flowContentView from './fr-FR/flowContentView';

export default flatten({
  ...flowList,
  ...upcomingFlows,
  ...flowContentView,
});
