import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M1.167 5.804A5.479 5.479 0 015.8 1.148C9.297.63 14.648 0 20 0c5.334 0 10.667.626 14.165 1.143 2.434.36 4.306 2.263 4.654 4.699C39.343 9.507 40 15.082 40 20c0 4.918-.657 10.493-1.181 14.158-.348 2.436-2.22 4.339-4.654 4.699C30.667 39.374 25.334 40 20 40c-5.352 0-10.703-.63-14.2-1.148a5.479 5.479 0 01-4.632-4.656C.646 30.616 0 25.146 0 20 0 14.853.646 9.384 1.167 5.804z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#2EAE0F"
        fillOpacity="0.1"
        fillRule="evenodd"
        d="M1.167 5.804A5.479 5.479 0 015.8 1.148C9.297.63 14.648 0 20 0c5.334 0 10.667.626 14.165 1.143 2.434.36 4.306 2.263 4.654 4.699C39.343 9.507 40 15.082 40 20c0 4.918-.657 10.493-1.181 14.158-.348 2.436-2.22 4.339-4.654 4.699C30.667 39.374 25.334 40 20 40c-5.352 0-10.703-.63-14.2-1.148a5.479 5.479 0 01-4.632-4.656C.646 30.616 0 25.146 0 20 0 14.853.646 9.384 1.167 5.804z"
        clipRule="evenodd"
      ></path>
      <circle cx="20" cy="20" r="7" stroke="#1CC23A" strokeWidth="2"></circle>
      <path
        stroke="#1CC23A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23 18l-4 4-2-2"
      ></path>
    </svg>
  );
}

export default Icon;
