import * as React from 'react';

export default () => (
  <svg width="24" height="24">
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.267 2.438c0-3.003 11.017-3.634 11.54.414v5.68c0 1.91-1.648 3.037-3.04 3.037l-4.2.001c-4.18.017-5.361.355-5.092 6.236H3.622c-4.658 0-4.997-11.404 0-11.6h8.456v-.812H6.266zm2.505-.568c-.508.012-1.074.395-1.074 1.064 0 .668.509 1.03 1.018 1.017.509-.013 1.069-.375 1.069-1.068 0-.694-.505-1.025-1.013-1.013z"
        fill="#347AB4"
      />
      <path
        d="M17.741 21.433c0 3.003-11.025 3.634-11.547-.414v-5.68c0-2.234 1.449-3.038 3.041-3.038h3.506c4.29-.006 6.042-.207 5.793-6.236h1.854c4.627 0 5.001 11.404 0 11.6h-8.462v.812h5.816zm-2.506.568c.508-.012 1.074-.395 1.074-1.064 0-.668-.509-1.03-1.018-1.017-.51.013-1.07.375-1.07 1.068 0 .694.506 1.025 1.014 1.013z"
        fill="#FFCA1D"
      />
    </g>
  </svg>
);
