import * as React from 'react';

export default () => (
  <svg width="16" height="16" fill="none">
    <g stroke="#F02929" strokeWidth="2">
      <path clipRule="evenodd" d="M3 3l1.667 12h6.666L13 3H3z" strokeLinejoin="round" />
      <path d="M1 3h14" strokeLinecap="round" />
      <path d="M5 3V1h6v2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
