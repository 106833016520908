import flatten from 'flat';
import integration from './en-US/integration';
import viewIntegration from './en-US/viewIntegration';
import addIntegration from './en-US/addIntegration';
import connection from './en-US/connection';

export default flatten({
  ...integration,
  ...viewIntegration,
  ...addIntegration,
  ...connection,
});
