import * as React from 'react';

export default (props) => (
  <svg width="16" height="20">
    <g
      stroke={props.color || '#0A132B'}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      opacity={props.color ? '1' : '.6'}
    >
      <path d="M14 9a.997.997 0 0 1 1 1h0v8a.997.997 0 0 1-1 1h0H2a.997.997 0 0 1-1-1h0v-8a.997.997 0 0 1 1-1h0z" />
      <path d="M4 8V5a4 4 0 1 1 8 0v3h0" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
