import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 25}
      height={props.svgHeight || 25}
      viewBox={props.svgViewBox || '-1 0 25 25'}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.669.636A55.102 55.102 0 0112 0a55.1 55.1 0 018.316.634 3.6 3.6 0 013.019 3.032c.443 3 .665 5.778.665 8.334s-.222 5.334-.665 8.334a3.6 3.6 0 01-3.02 3.032A55.1 55.1 0 0112 24c-2.777 0-5.554-.212-8.331-.636A3.6 3.6 0 01.652 20.34C.217 17.451 0 14.671 0 12c0-2.672.217-5.452.652-8.341A3.6 3.6 0 013.67.636z"
          fillOpacity=".2"
          fill="#FFC600"
        />
        <g transform="translate(5.25 4.75)" stroke="#FFDB00">
          <path d="M5.172.5a1.5 1.5 0 011.06.44h0l.626.625a1.5 1.5 0 001.046.439h0l3.61.035A1.495 1.495 0 0113 3.539h0V8.5c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0H2c-.414 0-.79-.168-1.06-.44A1.495 1.495 0 01.5 8.5h0V2c0-.414.168-.79.44-1.06C1.21.667 1.585.5 2 .5h0z" />
          <path strokeLinecap="round" d="M.75 14.25h4M8.75 14.25h4" />
          <path d="M6.75 9.75v2.5" />
          <rect x="5" y="12.5" width="3.5" height="3.5" rx="1" />
        </g>
      </g>
    </svg>
  );
};
