import * as React from 'react';

export default () => {
  return (
    <svg width="252" height="180">
      <defs>
        <rect id="xlsx-on-a" x="0" y="0" width="252" height="180" rx="2" />
        <path
          d="M4 0h26.719a4 4 0 012.973 1.324L44.973 13.86A4 4 0 0146 16.535V60a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4z"
          id="xlsx-on-b"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="#FFF" href="#xlsx-on-a" />
          <rect
            stroke="#0067F4"
            strokeLinejoin="square"
            fillOpacity=".05"
            fill="#0067F4"
            x=".5"
            y=".5"
            width="251"
            height="179"
            rx="2"
          />
        </g>
        <g transform="translate(12 12)">
          <circle stroke="#0050BC" fill="#0067F4" cx="8" cy="8" r="7.5" />
          <circle stroke="#F0F2F5" fill="#FFF" cx="8" cy="8" r="2.5" />
        </g>
        <g fill="#0A132B">
          <text
            fontFamily="OpenSans-SemiBold, Open Sans"
            fontSize="16"
            fontWeight="500"
            transform="translate(63 112)"
          >
            <tspan x="43.5" y="17">
              .XLSX
            </tspan>
          </text>
          <text
            opacity=".6"
            fontFamily="OpenSans-Regular, Open Sans"
            fontSize="12"
            transform="translate(63 112)"
          >
            <tspan x=".5" y="33">
              Microsoft Excel format
            </tspan>
          </text>
        </g>
        <g transform="translate(104 32)">
          <use fill="#FFF" href="#xlsx-on-b" />
          <path
            stroke="#169A2D"
            d="M30.719.5a3.5 3.5 0 012.601 1.159h0l11.282 12.535a3.5 3.5 0 01.898 2.34h0V60c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0142 63.5h0H4a3.489 3.489 0 01-2.475-1.025A3.489 3.489 0 01.5 60h0V4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 014 .5h0z"
            strokeLinejoin="square"
            fillOpacity=".1"
            fill="#1CC23A"
          />
        </g>
        <g stroke="#169A2D">
          <path fillOpacity=".4" fill="#1CC23A" d="M115.5 59.5h6v20h-6zM121.5 59.5h18v20h-18z" />
          <path d="M115.5 66.5h6v6h-6zM121.5 66.5h18v6h-18z" />
        </g>
      </g>
    </svg>
  );
};
