import * as React from 'react';

export default () => (
  <svg width="37" height="41">
    <g
      // style="mix-blend-mode:screen"
      stroke="#B5BFD2"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M32.091 5.067a2.99 2.99 0 0 1 2.121.878 2.99 2.99 0 0 1 .88 2.122h0v28a2.99 2.99 0 0 1-.88 2.12 2.99 2.99 0 0 1-2.12.88h0-28a2.99 2.99 0 0 1-2.122-.88 2.99 2.99 0 0 1-.879-2.12h0v-28c0-.829.336-1.579.879-2.122a2.99 2.99 0 0 1 2.121-.878h0z"
        fillOpacity=".197"
        fill="#B5BFD2"
      />
      <path strokeLinecap="round" d="M9 1v8M27 1v8" />
      <path strokeLinecap="square" d="M1.163 13h33.9" />
    </g>
  </svg>
);
