export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createOrganizationUser = /* GraphQL */ `
  mutation CreateOrganizationUser(
    $input: CreateOrganizationUserInput!
    $condition: ModelOrganizationUserConditionInput
  ) {
    createOrganizationUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateOrganizationUser = /* GraphQL */ `
  mutation UpdateOrganizationUser(
    $input: UpdateOrganizationUserInput!
    $condition: ModelOrganizationUserConditionInput
  ) {
    updateOrganizationUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteOrganizationUser = /* GraphQL */ `
  mutation DeleteOrganizationUser(
    $input: DeleteOrganizationUserInput!
    $condition: ModelOrganizationUserConditionInput
  ) {
    deleteOrganizationUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createOrganizationFivetran = /* GraphQL */ `
  mutation CreateOrganizationFivetran(
    $input: CreateOrganizationFivetranInput!
    $condition: ModelOrganizationFivetranConditionInput
  ) {
    createOrganizationFivetran(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    createWorkspace(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace(
    $input: UpdateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    updateWorkspace(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createWorkspaceUser = /* GraphQL */ `
  mutation CreateWorkspaceUser(
    $input: CreateWorkspaceUserInput!
    $condition: ModelWorkspaceUserConditionInput
  ) {
    createWorkspaceUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteWorkspaceUser = /* GraphQL */ `
  mutation DeleteWorkspaceUser(
    $input: DeleteWorkspaceUserInput!
    $condition: ModelWorkspaceUserConditionInput
  ) {
    deleteWorkspaceUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createSource = /* GraphQL */ `
  mutation CreateSource($input: CreateSourceInput!, $condition: ModelSourceConditionInput) {
    createSource(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateSource = /* GraphQL */ `
  mutation UpdateSource($input: UpdateSourceInput!, $condition: ModelSourceConditionInput) {
    updateSource(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteSource = /* GraphQL */ `
  mutation DeleteSource($input: DeleteSourceInput!, $condition: ModelSourceConditionInput) {
    deleteSource(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createSourceWorkspaceShare = /* GraphQL */ `
  mutation CreateSourceWorkspaceShare(
    $input: CreateSourceWorkspaceShareInput!
    $condition: ModelSourceWorkspaceShareConditionInput
  ) {
    createSourceWorkspaceShare(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDataObject = /* GraphQL */ `
  mutation CreateDataObject(
    $input: CreateDataObjectInput!
    $condition: ModelDataObjectConditionInput
  ) {
    createDataObject(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDataObject = /* GraphQL */ `
  mutation UpdateDataObject(
    $input: UpdateDataObjectInput!
    $condition: ModelDataObjectConditionInput
  ) {
    updateDataObject(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDataObject = /* GraphQL */ `
  mutation DeleteDataObject(
    $input: DeleteDataObjectInput!
    $condition: ModelDataObjectConditionInput
  ) {
    deleteDataObject(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDataset = /* GraphQL */ `
  mutation CreateDataset($input: CreateDatasetInput!, $condition: ModelDatasetConditionInput) {
    createDataset(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDataset = /* GraphQL */ `
  mutation UpdateDataset($input: UpdateDatasetInput!, $condition: ModelDatasetConditionInput) {
    updateDataset(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDataset = /* GraphQL */ `
  mutation DeleteDataset($input: DeleteDatasetInput!, $condition: ModelDatasetConditionInput) {
    deleteDataset(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDatasetTest = /* GraphQL */ `
  mutation CreateDatasetTest(
    $input: CreateDatasetTestInput!
    $condition: ModelDatasetTestConditionInput
  ) {
    createDatasetTest(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDatasetTest = /* GraphQL */ `
  mutation UpdateDatasetTest(
    $input: UpdateDatasetTestInput!
    $condition: ModelDatasetTestConditionInput
  ) {
    updateDatasetTest(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDatasetTest = /* GraphQL */ `
  mutation DeleteDatasetTest(
    $input: DeleteDatasetTestInput!
    $condition: ModelDatasetTestConditionInput
  ) {
    deleteDatasetTest(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDatasetShareFromWorkspaceWithWorkspaceLink = /* GraphQL */ `
  mutation CreateDatasetShareFromWorkspaceWithWorkspaceLink(
    $input: CreateDatasetShareFromWorkspaceWithWorkspaceLinkInput!
    $condition: ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput
  ) {
    createDatasetShareFromWorkspaceWithWorkspaceLink(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDatasetShareFromWorkspaceWithWorkspaceLink = /* GraphQL */ `
  mutation DeleteDatasetShareFromWorkspaceWithWorkspaceLink(
    $input: DeleteDatasetShareFromWorkspaceWithWorkspaceLinkInput!
    $condition: ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput
  ) {
    deleteDatasetShareFromWorkspaceWithWorkspaceLink(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDatasetWorkspaceShare = /* GraphQL */ `
  mutation CreateDatasetWorkspaceShare(
    $input: CreateDatasetWorkspaceShareInput!
    $condition: ModelDatasetWorkspaceShareConditionInput
  ) {
    createDatasetWorkspaceShare(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDatasetWorkspaceShare = /* GraphQL */ `
  mutation DeleteDatasetWorkspaceShare(
    $input: DeleteDatasetWorkspaceShareInput!
    $condition: ModelDatasetWorkspaceShareConditionInput
  ) {
    deleteDatasetWorkspaceShare(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDatasetSqlQuery = /* GraphQL */ `
  mutation CreateDatasetSqlQuery(
    $input: CreateDatasetSqlQueryInput!
    $condition: ModelDatasetSqlQueryConditionInput
  ) {
    createDatasetSqlQuery(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDatasetSqlQuery = /* GraphQL */ `
  mutation UpdateDatasetSqlQuery(
    $input: UpdateDatasetSqlQueryInput!
    $condition: ModelDatasetSqlQueryConditionInput
  ) {
    updateDatasetSqlQuery(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDatasetSqlQuery = /* GraphQL */ `
  mutation DeleteDatasetSqlQuery(
    $input: DeleteDatasetSqlQueryInput!
    $condition: ModelDatasetSqlQueryConditionInput
  ) {
    deleteDatasetSqlQuery(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createFlow = /* GraphQL */ `
  mutation CreateFlow($input: CreateFlowInput!, $condition: ModelFlowConditionInput) {
    createFlow(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateFlow = /* GraphQL */ `
  mutation UpdateFlow($input: UpdateFlowInput!, $condition: ModelFlowConditionInput) {
    updateFlow(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteFlow = /* GraphQL */ `
  mutation DeleteFlow($input: DeleteFlowInput!, $condition: ModelFlowConditionInput) {
    deleteFlow(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateIntegration = /* GraphQL */ `
  mutation UpdateIntegration(
    $input: UpdateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    updateIntegration(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createConnection = /* GraphQL */ `
  mutation CreateConnection(
    $input: CreateConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    createConnection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateConnection = /* GraphQL */ `
  mutation UpdateConnection(
    $input: UpdateConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    updateConnection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteConnection = /* GraphQL */ `
  mutation DeleteConnection(
    $input: DeleteConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    deleteConnection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createFivetranConnection = /* GraphQL */ `
  mutation CreateFivetranConnection(
    $input: CreateFivetranConnectionInput!
    $condition: ModelFivetranConnectionConditionInput
  ) {
    createFivetranConnection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateFivetranConnection = /* GraphQL */ `
  mutation UpdateFivetranConnection(
    $input: UpdateFivetranConnectionInput!
    $condition: ModelFivetranConnectionConditionInput
  ) {
    updateFivetranConnection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteFivetranConnection = /* GraphQL */ `
  mutation DeleteFivetranConnection(
    $input: DeleteFivetranConnectionInput!
    $condition: ModelFivetranConnectionConditionInput
  ) {
    deleteFivetranConnection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createCredentials = /* GraphQL */ `
  mutation CreateCredentials(
    $input: CreateCredentialsInput!
    $condition: ModelCredentialsConditionInput
  ) {
    createCredentials(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateCredentials = /* GraphQL */ `
  mutation UpdateCredentials(
    $input: UpdateCredentialsInput!
    $condition: ModelCredentialsConditionInput
  ) {
    updateCredentials(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteCredentials = /* GraphQL */ `
  mutation DeleteCredentials(
    $input: DeleteCredentialsInput!
    $condition: ModelCredentialsConditionInput
  ) {
    deleteCredentials(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder($input: CreateFolderInput!, $condition: ModelFolderConditionInput) {
    createFolder(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder($input: UpdateFolderInput!, $condition: ModelFolderConditionInput) {
    updateFolder(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder($input: DeleteFolderInput!, $condition: ModelFolderConditionInput) {
    deleteFolder(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createDownload = /* GraphQL */ `
  mutation CreateDownload($input: CreateDownloadInput!, $condition: ModelDownloadConditionInput) {
    createDownload(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDownload = /* GraphQL */ `
  mutation UpdateDownload($input: UpdateDownloadInput!, $condition: ModelDownloadConditionInput) {
    updateDownload(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDownload = /* GraphQL */ `
  mutation DeleteDownload($input: DeleteDownloadInput!, $condition: ModelDownloadConditionInput) {
    deleteDownload(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
    createRole(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
    updateRole(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($input: DeleteRoleInput!, $condition: ModelRoleConditionInput) {
    deleteRole(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      id
    }
  }
`;
export const beemAgentInvokeDownload = /* GraphQL */ `
  mutation BeemAgentInvokeDownload($input: InvokeDownloadInput!) {
    beemAgentInvokeDownload(input: $input) {
      id
      dataId
      dataTimeStamp
      requesterId
      requester {
        id
        email
        firstName
        lastName
      }
      requestTimeStamp
      signedDownloadUrl
    }
  }
`;
export const beemAgentDeployDatasetSqlQuery = /* GraphQL */ `
  mutation BeemAgentDeployDatasetSqlQuery($input: DeployDatasetSqlQueryInput!) {
    beemAgentDeployDatasetSqlQuery(input: $input) {
      id
    }
  }
`;
export const fivetranCreateGroup = /* GraphQL */ `
  mutation FivetranCreateGroup($input: FivetranCreateGroupInput!) {
    fivetranCreateGroup(input: $input) {
      id
      groupId
    }
  }
`;
export const modifyFlow = /* GraphQL */ `
  mutation ModifyFlow($input: ModifyFlowInput) {
    modifyFlow(input: $input) {
      placeholder
    }
  }
`;
export const updateIDatasetUser = /* GraphQL */ `
  mutation UpdateIDatasetUser($input: UpdateIDatasetUserInput!) {
    updateIDatasetUser(input: $input) {
      id
    }
  }
`;
export const createIDatasetUser = /* GraphQL */ `
  mutation CreateIDatasetUser($input: CreateIDatasetUserInput!) {
    createIDatasetUser(input: $input) {
      id
    }
  }
`;
