import flatten from 'flat';
import dataset from './fr-FR/dataset';
import hub from './fr-FR/hub';
import folder from './fr-FR/folder';
import header from './fr-FR/header';
import datasetTest from './fr-FR/datasetTest';
import datasetAllTests from './fr-FR/datasetAllTests';

export default flatten({
  ...dataset,
  ...hub,
  ...folder,
  ...header,
  ...datasetTest,
  ...datasetAllTests,
});
