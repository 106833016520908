import * as React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.1675 5.80403C1.51977 3.38732 3.38385 1.50648 5.7997 1.1484C9.29676 0.630076 14.6484 0 20 0C25.3335 0 30.667 0.625815 34.1647 1.14313C36.5988 1.50314 38.4706 3.40573 38.8189 5.84151C39.343 9.50671 40 15.0818 40 20C40 24.9182 39.343 30.4933 38.8189 34.1585C38.4706 36.5943 36.5988 38.4969 34.1647 38.8569C30.667 39.3742 25.3335 40 20 40C14.6484 40 9.29676 39.3699 5.7997 38.8516C3.38384 38.4935 1.51977 36.6127 1.1675 34.196C0.64569 30.6161 0 25.1466 0 20C0 14.8534 0.645691 9.38386 1.1675 5.80403Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.1675 5.80403C1.51977 3.38732 3.38385 1.50648 5.7997 1.1484C9.29676 0.630076 14.6484 0 20 0C25.3335 0 30.667 0.625815 34.1647 1.14313C36.5988 1.50314 38.4706 3.40573 38.8189 5.84151C39.343 9.50671 40 15.0818 40 20C40 24.9182 39.343 30.4933 38.8189 34.1585C38.4706 36.5943 36.5988 38.4969 34.1647 38.8569C30.667 39.3742 25.3335 40 20 40C14.6484 40 9.29676 39.3699 5.7997 38.8516C3.38384 38.4935 1.51977 36.6127 1.1675 34.196C0.64569 30.6161 0 25.1466 0 20C0 14.8534 0.645691 9.38386 1.1675 5.80403Z"
      fill="#0067F4"
      fillOpacity="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.875 11C17.6659 11 15.875 12.7909 15.875 15C15.875 17.2091 17.6659 19 19.875 19C22.0841 19 23.875 17.2091 23.875 15C23.875 12.7909 22.0841 11 19.875 11ZM17.875 15C17.875 13.8954 18.7704 13 19.875 13C20.9796 13 21.875 13.8954 21.875 15C21.875 16.1046 20.9796 17 19.875 17C18.7704 17 17.875 16.1046 17.875 15ZM17 20C14.2386 20 12 22.2386 12 25V26C12 27.6569 13.3431 29 15 29H25C26.6569 29 28 27.6569 28 26V25C28 22.2386 25.7614 20 23 20H17ZM14 25C14 23.3431 15.3431 22 17 22H23C24.6569 22 26 23.3431 26 25V26C26 26.5523 25.5523 27 25 27H15C14.4477 27 14 26.5523 14 26V25Z"
      fill="#0067F4"
    />
  </svg>
);
