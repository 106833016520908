import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="State=Hover">
      <rect
        x="-0.00128174"
        y="-0.00128174"
        width="24.0026"
        height="24.0026"
        rx="2"
        fill="#B5BFD2"
        fillOpacity="0.4"
      />
      <path
        id="Icon Close"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70583 7.29161C8.3153 6.90109 7.68214 6.90109 7.29161 7.29161C6.90109 7.68214 6.90109 8.3153 7.29161 8.70583L10.5858 12L7.29161 15.2942C6.90109 15.6847 6.90109 16.3179 7.29161 16.7084C7.68214 17.0989 8.3153 17.0989 8.70583 16.7084L12 13.4142L15.2942 16.7084C15.6847 17.0989 16.3179 17.0989 16.7084 16.7084C17.0989 16.3179 17.0989 15.6847 16.7084 15.2942L13.4142 12L16.7084 8.70583C17.0989 8.3153 17.0989 7.68214 16.7084 7.29161C16.3179 6.90109 15.6847 6.90109 15.2942 7.29161L12 10.5858L8.70583 7.29161Z"
        fill="#040A1A"
      />
    </g>
  </svg>
);
