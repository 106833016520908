import type { FivetranConnection, FivetranConnectionResponse } from '@/models/connection';
import { graphql } from './graphql';
import { assertIsDefined } from './typeChecks';

const INTEGRATION_ICONS_BY_CONNECTION_ID = 'beem-integration-icons-by-connection-id';

export async function preloadAndCacheFivetranIconUrlsAndNativeIntegrationNames(
  connectionIds: string[],
  organizationId: string,
): Promise<void> {
  let reloadRequired = false;
  const cachedJson = localStorage.getItem(INTEGRATION_ICONS_BY_CONNECTION_ID);
  if (cachedJson) {
    const cachedIconsByConnectionId = JSON.parse(cachedJson);
    reloadRequired = connectionIds.some((el) => !cachedIconsByConnectionId[el]);
  } else {
    reloadRequired = true;
  }
  if (reloadRequired) {
    const iconsByConnectionId: Record<string, string> = {};

    const rs1 = await graphql('listConnectionsByOrganizationFivetran', { organizationId });
    const { items: items1 } = rs1;
    if (items1.length > 0) {
      const fivetranIconUrlsByServiceName: Record<string, string> = {};
      const { response: rstr } = await graphql('fivetranFetchConnections', { query: {} });
      const response = JSON.parse(rstr) as FivetranConnectionResponse<FivetranConnection>;
      response.data.items.forEach((el) => {
        fivetranIconUrlsByServiceName[el.id] = el.icon_url;
      });
      await Promise.all(
        items1.map(async ({ id, fivetranConnection }) => {
          assertIsDefined(fivetranConnection, 'BEEM231109160553');
          iconsByConnectionId[id] = fivetranIconUrlsByServiceName[fivetranConnection.serviceName];
        }),
      );
    }

    const rs2 = await graphql('listConnectionsByOrganizationNative', { organizationId });
    const { items: items2 } = rs2;
    if (items2.length > 0) {
      await Promise.all(
        items2.map(async ({ id, integration }) => {
          assertIsDefined(integration, 'BEEM231109160554');
          iconsByConnectionId[id] = integration.name;
        }),
      );
    }

    localStorage.setItem(INTEGRATION_ICONS_BY_CONNECTION_ID, JSON.stringify(iconsByConnectionId));
  }
}

export function getValueFromFivetranIconUrlsAndNativeIntegrationNamesCache(
  connectionId: string,
): string {
  const cachedJson = localStorage.getItem(INTEGRATION_ICONS_BY_CONNECTION_ID);
  if (!cachedJson) throw new Error('BEEM231108210757');
  const cachedIconsByConnectionId = JSON.parse(cachedJson);
  return cachedIconsByConnectionId[connectionId] ?? '';
}
