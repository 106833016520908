import * as React from 'react';

export default () => (
  <svg width="16" height="16" fill="none">
    <circle cx="8" cy="8" r="7" stroke="#1CC23A" strokeWidth="2" />
    <path
      d="M11 6l-4 4-2-2"
      stroke="#1CC23A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
