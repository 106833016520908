import * as React from 'react';

export default () => (
  <svg width="222" height="222" fill="none">
    <path
      d="M219 111c0 59.647-48.353 108-108 108M3 111C3 51.353 51.353 3 111 3"
      stroke="#0067F4"
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
);
