import React from 'react';

function Icon({ state }: { state: 'active' | 'hover' | 'static' }) {
  let fill = '#0067F4';
  if (state === 'hover') fill = '#0A132B';
  else if (state === 'static') fill = '#6C7280';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g>
        <g stroke={fill} strokeLinecap="round" strokeWidth="2">
          <path strokeLinejoin="round" d="M12 15l3 3 6-6"></path>
          <g>
            <path
              strokeLinejoin="round"
              d="M10 18H6a2 2 0 01-2-2V8a2 2 0 012-2h9a2 2 0 012 2v3"
            ></path>
            <path d="M4 10h13"></path>
            <path d="M8 6v12"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
