import flatten from 'flat';
import app from './en-US/app';
import date from './en-US/date';
import dpu from './en-US/dpu';
import menu from './en-US/menu';
import navBar from './en-US/navBar';
import tableContentView from './en-US/tableContentView';
import trigger from './en-US/trigger';
import workspace from './en-US/workspace';

export default flatten({
  ...app,
  ...date,
  ...dpu,
  ...menu,
  ...navBar,
  ...tableContentView,
  ...trigger,
  ...workspace,
});
