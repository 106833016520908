import * as React from 'react';

export default ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '32'}
    height={height || '8'}
    viewBox="0 0 32 8"
    fill="none"
  >
    <path
      d="M1 7L16 1L31 7"
      stroke={color || '#B5BFD2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
