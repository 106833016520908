import * as React from 'react';

export default () => (
  <svg width="17" height="20">
    <g stroke="#0067F4" fill="none" fillRule="evenodd">
      <path
        d="M11.974.5c.43 0 .84.184 1.124.507h0l2.426 2.744a1.5 1.5 0 0 1 .376.994h0V18c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0H2c-.414 0-.79-.168-1.06-.44A1.495 1.495 0 0 1 .5 18h0V2c0-.414.168-.79.44-1.06C1.21.667 1.585.5 2 .5h0z"
        strokeLinejoin="round"
      />
      <g strokeLinecap="round">
        <path strokeLinejoin="round" d="M11.2 9.28l1.8 1.87-1.8 1.73M5.2 9.28l-1.8 1.87 1.8 1.73" />
        <path d="M8.8 9.28l-1.2 3.6" />
      </g>
    </g>
  </svg>
);
