import * as React from 'react';

export default () => {
  return (
    <svg width="40" height="40">
      <defs>
        <path
          d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06Z"
          id="modal-lock-a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="#FFF" href="#modal-lock-a" />
          <use fillOpacity=".2" fill="#0067F4" href="#modal-lock-a" />
        </g>
        <g stroke="#0067F4">
          <path d="M26 17.515a1.495 1.495 0 0 1 1.5 1.5v8c0 .414-.168.789-.44 1.06-.27.272-.646.44-1.06.44H14c-.414 0-.79-.168-1.06-.44a1.495 1.495 0 0 1-.44-1.06v-8c0-.415.168-.79.44-1.061.27-.272.646-.44 1.06-.44Z" />
          <path d="M16 17.015v-3a4 4 0 1 1 8 0v3h0" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
    </svg>
  );
};
