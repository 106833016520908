import { Auth } from '@/aws-config';
import { exchangeAuthorizationCodeForTokens, authorizeByTokens } from '@/utils/awsCognito';
import { logger } from '@/utils/logger';

function getAuthorityFromAppPermissions(cognitoUser) {
  const cognitoGroups =
    cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:groups'] ?? [];
  if (cognitoGroups.includes('BeemAdministrators') || cognitoGroups.includes('OrgAdmin'))
    return 'admin';
  return 'user';
}

export async function AmplifyLogin(params) {
  logger.debug(params, { label: 'amplify' });

  try {
    const user = await Auth.signIn(params.email.toLowerCase(), params.password);
    // The user directly signs in
    logger.debug(user, { label: 'got user' });

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return {
        status: 'new_password_required',
        currentAuthority: 'guest',
        user,
      };
    }

    return {
      status: 'ok',
      currentAuthority: getAuthorityFromAppPermissions(user),
      user,
    };
  } catch (err) {
    logger.error(err, { label: 'error' });

    if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
    } else if (err.code === 'UserNotFoundException') {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    } else {
      logger.error(err, { label: 'unknown' });
    }

    return {
      status: 'error',
      currentAuthority: 'guest',
      error: err,
    };
  }
}

export async function AmplifyLogout() {
  await Auth.signOut();
  return {
    status: 'logged_out',
    currentAuthority: 'guest',
    user: null,
  };
}

export async function AmplifyForgotPassword(params) {
  const username = params.username.toLowerCase();
  const { code, newPassword, isFirstLogin } = params;

  try {
    if (code && newPassword) {
      // Collect confirmation code and new password, then
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);

      logger.debug(data, { label: 'after code data' });

      return {
        status: 'change_success',
        currentAuthority: 'guest',
      };
    }

    const data = await Auth.forgotPassword(username, { isFirstLogin });

    logger.debug(data, { label: 'some data' });

    return {
      status: 'code_required',
      currentAuthority: 'guest',
      email: username,
    };
  } catch (err) {
    logger.error({ label: 'AmplifyForgotPassword - Auth.forgotPassword - err', message: err });
    return {
      status: 'error',
      currentAuthority: 'guest',
      error: err,
      email: username,
    };
  }
}

export async function AmplifyNewPassword(params) {
  const { user, password } = params;

  if (!user) {
    return {
      status: 'error',
      currentAuthority: 'guest',
      error: {
        name: 'Missing token',
        message: 'Authentication token not found, please try login again.',
      },
    };
  }

  try {
    const authUser = await Auth.completeNewPassword(user, password);
    return {
      status: 'ok',
      currentAuthority: getAuthorityFromAppPermissions(authUser),
      user: authUser,
    };
  } catch (err) {
    return {
      status: 'error',
      currentAuthority: 'guest',
      user,
      error: err,
    };
  }
}

export async function AmplifyFetchAuthSession() {
  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
  return currentUser;
}

export async function CognitoUseAuthCode(code) {
  try {
    const tokens = await exchangeAuthorizationCodeForTokens(code);
    const user = await authorizeByTokens(tokens);

    return {
      status: 'ok',
      currentAuthority: getAuthorityFromAppPermissions(user),
      user,
    };
  } catch (e) {
    logger.error(err, { label: 'error' });

    return {
      status: 'error',
      currentAuthority: 'guest',
      error: err,
    };
  }
}
