import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0z" />
        <g
          stroke={props.color || '#B5BFD2'}
          strokeLinecap="round"
          strokeWidth="2"
          style={{ transition: props.withTransition ? 'stroke 0.3s ease' : 'none' }}
        >
          <path d="M12 5v8" />
          <path strokeLinejoin="round" d="M15 10l-3 3-3-3" />
        </g>
        <path
          stroke={props.color || '#B5BFD2'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 13l-2 5h16l-2-5"
          style={{ transition: props.withTransition ? 'stroke 0.3s ease' : 'none' }}
        />
      </g>
    </svg>
  );
};
