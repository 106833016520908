import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#F0F2F5" width="40" height="40" rx="4" />
      <g stroke="#B5BFD2" strokeWidth="2">
        <path
          d="M18.727 12.788l8.485 8.485-1.697 1.697a6 6 0 11-8.485-8.485l1.697-1.697h0z"
          strokeLinejoin="round"
        />
        <path
          strokeLinecap="round"
          d="M25.515 19.576l3.395-3.394M20.424 14.485l3.394-3.395M13.636 26.364l3.394-3.394"
        />
      </g>
    </g>
  </svg>
);
