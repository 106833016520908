import * as React from 'react';

export default () => (
  <svg width="32" height="32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.82 5.441A5.4 5.4 0 0 1 5.44.804C8.217.416 12.109 0 16 0c3.874 0 7.748.413 10.523.799 2.437.338 4.313 2.243 4.645 4.681C31.563 8.385 32 12.411 32 16c0 3.589-.437 7.615-.832 10.52-.332 2.438-2.208 4.343-4.645 4.681C23.748 31.587 19.874 32 16 32c-3.891 0-7.783-.416-10.56-.804A5.4 5.4 0 0 1 .82 26.56C.43 23.717 0 19.75 0 16c0-3.751.429-7.717.82-10.559z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.82 5.441A5.4 5.4 0 0 1 5.44.804C8.217.416 12.109 0 16 0c3.874 0 7.748.413 10.523.799 2.437.338 4.313 2.243 4.645 4.681C31.563 8.385 32 12.411 32 16c0 3.589-.437 7.615-.832 10.52-.332 2.438-2.208 4.343-4.645 4.681C23.748 31.587 19.874 32 16 32c-3.891 0-7.783-.416-10.56-.804A5.4 5.4 0 0 1 .82 26.56C.43 23.717 0 19.75 0 16c0-3.751.429-7.717.82-10.559z"
      fill="#6C7280"
      fillOpacity=".1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.728 7.707a1 1 0 0 1 0 1.414l-2.121 2.122 2.828 2.828 2.121-2.121a1 1 0 0 1 1.415 1.414l-2.122 2.121.707.707a1 1 0 0 1 0 1.415l-1.414 1.414a6.002 6.002 0 0 1-7.72.648l-2.18 2.18a1 1 0 0 1-1.413-1.414l2.18-2.18a6.002 6.002 0 0 1 .648-7.72l1.414-1.414a1 1 0 0 1 1.414 0l.707.707 2.122-2.12a1 1 0 0 1 1.414 0zm-5.657 9.9l.002.001a4 4 0 0 0 5.655-.001l.707-.707-.704-.705-.003-.003-.003-.003-4.947-4.946-.707.707a4 4 0 0 0 0 5.657z"
      fill="#6C7280"
    />
  </svg>
);
