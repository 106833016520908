export default {
  "workspace": {
    "rename_modal_dialog": {
      "title": "Rename {type} {name}",
      "name_input": "{type} new name",
      "submit_button": "Update"
    },
    "create_modal_dialog": {
      "title": "Create a new {type}.",
      "folder_name_input": "Workspace name",
      "submit_button": "Create workspace"
    }
  }
};
