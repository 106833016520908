import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path
        d="M17.887 22c.533 0 1.065-.207 1.639-.641.675-.476 1.27-.87 1.27-.87l.204-.144-.205-.145c-.082-.062-8.99-6.31-9.911-6.91-.43-.31-.922-.371-1.31-.185-.37.186-.574.579-.574 1.055v2.13l.082.042c.061.041 6.184 4.344 7.372 5.15.491.353.962.518 1.433.518z"
        fill="#356AE6"
        fillRule="nonzero"
      />
      <path
        d="M30.814 18.052c-.56-.405-20.672-14.264-21.534-14.86L9 3v3.406c0 1.086.582 1.49 1.293 2.002 0 0 15.434 10.623 17.352 11.922-1.918 1.32-16.446 11.262-17.373 11.837-1.1.724-1.207 1.192-1.207 2.171V38s21.274-15.03 21.727-15.371c.97-.724 1.186-1.575 1.207-2.193v-.383c.022-.767-.388-1.448-1.185-2.001z"
        fill="#356AE6"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
