import type * as A from '@/api';
import { logger } from '@/utils/logger';
import { graphql } from '@/utils/graphql';

type IntegrationConstructorInput = A.GetIntegrationQuery['getIntegration'];

export default class Integration {
  readonly id: string;

  readonly name: string;

  readonly category: A.IntegrationCategory;

  readonly type: A.IntegrationType;

  readonly description?: string;

  readonly dataSourceSupport: boolean;

  readonly flowActions?: string[];

  readonly createdAt: Date;

  constructor(input: IntegrationConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.category = input.category;
    this.type = input.type;
    this.description = input.description;
    this.dataSourceSupport = input.dataSourceSupport;
    this.flowActions = input.flowActions;
    this.createdAt = new Date(input.createdAt);
  }

  static async getAllIntegrations(): Promise<Integration[]> {
    logger.info({ label: 'app.services.integrations.getAllIntegrations' });
    const data = await graphql('listIntegrations', {});
    return data.items.map((el) => new Integration(el));
  }

  static async getIntegrationById(id: string): Promise<Integration> {
    logger.info(id, { label: 'app.services.integration.getIntegrationById' });
    const data = await graphql('getIntegration', { id });
    return new Integration(data);
  }
}
