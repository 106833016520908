import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24" viewBox="17 17 24 24">
      <defs>
        <filter
          x="-12.8%"
          y="-11%"
          width="125.6%"
          height="121.9%"
          filterUnits="objectBoundingBox"
          id="actions-edit-a"
        >
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#actions-edit-a)" transform="translate(5 9)" fill="none" fillRule="evenodd">
        <path d="M12 8h24v24H12z" />
        <path
          stroke={props.color || '#B5BFD2'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19 16.5l-4 4 4 4M29 16.5l4 4-4 4"
        />
        <path
          stroke={props.color || '#B5BFD2'}
          strokeWidth="2"
          strokeLinecap="round"
          d="M22 27.5l4-14"
        />
      </g>
    </svg>
  );
};
