import * as React from 'react';

export default (props) => (
  <svg width={props.width || '24'} height={props.height || '24'} fill="none" viewBox="0 0 24 24 ">
    <g stroke={props.color || '#8347F2'} strokeWidth="2">
      <path
        d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6z"
        fill="#8347F2"
        fillOpacity=".2"
      />
      <path d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v3H3V6zM3 9h5v11H5a2 2 0 0 1-2-2V9z" />
    </g>
  </svg>
);
