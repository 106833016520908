import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 8}
      height={props.svgHeight || 24}
      viewBox={props.svgViewBox || '0 0 8 24'}
    >
      <g
        id="dropdown-icon-bottom"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={props.svgMove ? `translate(0 ${props.svgMove})` : undefined}
      >
        <path d="M7 14l-3 3-3-3" />
      </g>
      <g
        id="dropdown-icon-top"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={props.svgMove ? `translate(0 -${props.svgMove})` : undefined}
      >
        <path d="M7 10l-3-3-3 3" />
      </g>
    </svg>
  );
};
