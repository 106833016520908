import * as React from 'react';

export default () => (
  <svg width="24" height="24">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" stroke="#0068f4" strokeWidth="2">
      <path d="M9 18l-5-5" />
      <path strokeLinejoin="round" d="M8 13H4v4" />
      <path d="M15 18l5-5" />
      <path strokeLinejoin="round" d="M16 13h4v4" />
      <g>
        <path d="M12 18V6" />
        <path strokeLinejoin="round" d="M9 9l3-3 3 3" />
      </g>
    </g>
  </svg>
);
