import React, { useState } from 'react';
import CloseOutline from '../Icons/CloseOutline';
import CloseOutlineHover from '../Icons/CloseOutlineHover';

const CustomCloseIcon = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ cursor: 'pointer' }}
    >
      {isHovered ? <CloseOutlineHover /> : <CloseOutline />}
    </div>
  );
};

export default CustomCloseIcon;
