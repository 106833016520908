import * as React from 'react';

export default ({ color }) => (
  <svg width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.875 3a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm-2 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM9 12a5 5 0 0 0-5 5v1a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-1a5 5 0 0 0-5-5H9zm-3 5a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-1z"
      fill={color || '#0A132B'}
      opacity={color ? '1' : '.6'}
    />
  </svg>
);
