import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M4 4L12 12" stroke="#F02929" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 4L4 12" stroke="#F02929" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
