import * as React from 'react';

export default () => (
  <svg width="16" height="16" fill="none">
    <path
      d="M13 5l-6 6-3-3"
      stroke="#07AB24"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
