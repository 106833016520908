import * as React from 'react';

export default () => (
  <svg width="222" height="222" fill="none">
    <g clipPath="url(#clip0_760_1792)">
      <rect x="3" y="3" width="216" height="216" rx="108" fill="#fff" />
      <rect x="79" y="79" width="64" height="64" rx="6" fill="#0067F4" />
      <g filter="url(#filter0_d_760_1792)">
        <rect x="95" y="95" width="32" height="32" rx="3" fill="#F5F9FF" />
      </g>
      <g fill="#DADEE6" fillOpacity=".6">
        <path d="M131 42h-2a2 2 0 0 1 .586-1.414L131 42zm22-22h2a2 2 0 0 1-.586 1.414L153 20zm-2-35a2 2 0 1 1 4 0h-4zm-22 94V42h4v37h-4zm.586-38.414l22-22 2.828 2.828-22 22-2.828-2.828zM151 20v-35h4v35h-4zM121 40h-2v-.828l.586-.586L121 40zm23.5-23.5h2v.828l-.586.586L144.5 16.5zm-2-41.5a2 2 0 1 1 4 0h-4zM119 79V40h4v39h-4zm.586-40.414l23.5-23.5 2.828 2.828-23.5 23.5-2.828-2.828zM142.5 16.5V-25h4v41.5h-4zM109-19a2 2 0 1 1 4 0h-4zm0 98v-98h4v98h-4zM101 11.5l1.414-1.414A2 2 0 0 1 103 11.5h-2zm-16-16l-1.414 1.414A2 2 0 0 1 83-4.5h2zM83-32a2 2 0 0 1 4 0h-4zM99 79V11.5h4V79h-4zm.586-66.086l-16-16 2.828-2.828 16 16-2.828 2.828zM83-4.5V-32h4v27.5h-4zM91 51l1.414-1.414A2 2 0 0 1 93 51h-2zM72 32l-1.414 1.414A2 2 0 0 1 70 32h2zm-2-59a2 2 0 0 1 4 0h-4zM89 79V51h4v28h-4zm.586-26.586l-19-19 2.828-2.828 19 19-2.828 2.828zM70 32v-59h4v59h-4z" />
      </g>
      <g fill="#003072">
        <path d="M129 74a2 2 0 1 1 4 0h-4zm0 5v-5h4v5h-4zM119 74a2 2 0 1 1 4 0h-4zm0 5v-5h4v5h-4zM109 74a2 2 0 1 1 4 0h-4zm0 5v-5h4v5h-4zM99 74a2 2 0 0 1 4 0h-4zm0 5v-5h4v5h-4zM89 74a2 2 0 0 1 4 0h-4zm0 5v-5h4v5h-4z" />
      </g>
      <g fill="#DADEE6" fillOpacity=".6">
        <path d="M180 91v2a2 2 0 0 0 1.414-.586L180 91zm13-13v-2a2 2 0 0 0-1.414.586L193 78zm41 2a2 2 0 1 0 0-4v4zm-91 13h37v-4h-37v4zm38.414-.586l13-13-2.828-2.828-13 13 2.828 2.828zM193 80h41v-4h-41v4zM193 103a2 2 0 1 0 0-4v4zm-50 0h50v-4h-50v4zM213 111v2a2 2 0 0 0 1.414-.586L213 111zm14.5-14.5v-2a2 2 0 0 0-1.414.586L227.5 96.5zm32 2a2 2 0 1 0 0-4v4zM143 113h70v-4h-70v4zm71.414-.586l14.5-14.5-2.828-2.828-14.5 14.5 2.828 2.828zM227.5 98.5h32v-4h-32v4zM178.5 121l1.414-1.414A2 2 0 0 0 178.5 119v2zm16 16l-1.414 1.414a2 2 0 0 0 1.414.586v-2zm67.5 2a2 2 0 1 0 0-4v4zm-119-16h35.5v-4H143v4zm34.086-.586l16 16 2.828-2.828-16-16-2.828 2.828zM194.5 139H262v-4h-67.5v4zM171 131l1.414-1.414A2 2 0 0 0 171 129v2zm19 19l-1.414 1.414A2 2 0 0 0 190 152v-2zm59 2a2 2 0 1 0 0-4v4zm-106-19h28v-4h-28v4zm26.586-.586l19 19 2.828-2.828-19-19-2.828 2.828zM190 152h59v-4h-59v4z" />
      </g>
      <g fill="#003072">
        <path d="M148 93a2 2 0 1 0 0-4v4zm-5 0h5v-4h-5v4zM148 103a2 2 0 1 0 0-4v4zm-5 0h5v-4h-5v4zM148 113a2 2 0 1 0 0-4v4zm-5 0h5v-4h-5v4zM148 123a2 2 0 1 0 0-4v4zm-5 0h5v-4h-5v4zM148 133a2 2 0 1 0 0-4v4zm-5 0h5v-4h-5v4z" />
      </g>
      <g>
        <g fill="#DADEE6" fillOpacity=".6">
          <path d="M42 131v-2a2 2 0 0 0-1.414.586L42 131zm-13 13v2a2 2 0 0 0 1.414-.586L29 144zm-59.5-2a2 2 0 1 0 0 4v-4zM79 129H42v4h37v-4zm-38.414.586l-13 13 2.828 2.828 13-13-2.828-2.828zM29 142h-59.5v4H29v-4zM-5.5 121v-2h-.828l-.586.586L-5.5 121zm-11 11v2h.828l.586-.586L-16.5 132zm-30-2a2 2 0 1 0 0 4v-4zM79 119H-5.5v4H79v-4zm-85.914.586l-11 11 2.828 2.828 11-11-2.828-2.828zM-16.5 130h-30v4h30v-4zM-24.5 109a2 2 0 1 0 0 4v-4zM79 109H-24.5v4H79v-4zM43.5 101l-1.414 1.414A2 2 0 0 0 43.5 103v-2zm-16-16l1.414-1.414A2 2 0 0 0 27.5 83v2zM-40 83a2 2 0 0 0 0 4v-4zM79 99H43.5v4H79v-4zm-34.086.586l-16-16-2.828 2.828 16 16 2.828-2.828zM27.5 83H-40v4h67.5v-4zM51 91l-1.414 1.414A2 2 0 0 0 51 93v-2zM32 72l1.414-1.414A2 2 0 0 0 32 70v2zm-59-2a2 2 0 0 0 0 4v-4zM79 89H51v4h28v-4zm-26.586.586l-19-19-2.828 2.828 19 19 2.828-2.828zM32 70h-59v4h59v-4z" />
        </g>
        <g fill="#003072">
          <path d="M74 129a2 2 0 1 0 0 4v-4zm5 0h-5v4h5v-4zM74 119a2 2 0 1 0 0 4v-4zm5 0h-5v4h5v-4zM74 109a2 2 0 1 0 0 4v-4zm5 0h-5v4h5v-4zM74 99a2 2 0 0 0 0 4v-4zm5 0h-5v4h5v-4zM74 89a2 2 0 0 0 0 4v-4zm5 0h-5v4h5v-4z" />
        </g>
      </g>
      <g>
        <g fill="#DADEE6" fillOpacity=".6">
          <path d="M91 180h2a2 2 0 0 1-.586 1.414L91 180zm-22 22h-2a2 2 0 0 1 .586-1.414L69 202zm2 35a2 2 0 1 1-4 0h4zm22-94v37h-4v-37h4zm-.586 38.414l-22 22-2.828-2.828 22-22 2.828 2.828zM71 202v35h-4v-35h4zM101 182h2v.828l-.586.586L101 182zm-23.5 23.5h-2v-.828l.586-.586L77.5 205.5zm2 41.5a2 2 0 1 1-4 0h4zM103 143v39h-4v-39h4zm-.586 40.414l-23.5 23.5-2.828-2.828 23.5-23.5 2.828 2.828zM79.5 205.5V247h-4v-41.5h4zM113 241a2 2 0 1 1-4 0h4zm0-98v98h-4v-98h4zM121 186.5l-1.414 1.414A2 2 0 0 1 119 186.5h2zm16 16l1.414-1.414A2 2 0 0 1 139 202.5h-2zm2 27.5a2 2 0 1 1-4 0h4zm-16-87v43.5h-4V143h4zm-.586 42.086l16 16-2.828 2.828-16-16 2.828-2.828zM139 202.5V230h-4v-27.5h4zM131 171l-1.414 1.414A2 2 0 0 1 129 171h2zm19 19l1.414-1.414A2 2 0 0 1 152 190h-2zm2 59a2 2 0 1 1-4 0h4zm-19-106v28h-4v-28h4zm-.586 26.586l19 19-2.828 2.828-19-19 2.828-2.828zM152 190v59h-4v-59h4z" />
        </g>
        <g fill="#003072">
          <path d="M93 148a2 2 0 1 1-4 0h4zm0-5v5h-4v-5h4zM103 148a2 2 0 1 1-4 0h4zm0-5v5h-4v-5h4zM113 148a2 2 0 1 1-4 0h4zm0-5v5h-4v-5h4zM123 148a2 2 0 1 1-4 0h4zm0-5v5h-4v-5h4zM133 148a2 2 0 1 1-4 0h4zm0-5v5h-4v-5h4z" />
        </g>
      </g>
    </g>
    <rect x="3" y="3" width="216" height="216" rx="108" stroke="#DADEE6" strokeWidth="6" />
    <defs>
      <clipPath id="clip0_760_1792">
        <rect x="3" y="3" width="216" height="216" rx="108" fill="#fff" />
      </clipPath>
      <filter
        id="filter0_d_760_1792"
        x="93"
        y="95"
        width="36"
        height="37"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_760_1792" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_760_1792" result="shape" />
      </filter>
    </defs>
  </svg>
);
