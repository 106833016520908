import * as React from 'react';

export default (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="State=Static">
      <path
        id="Vector 24"
        d="M5 17V8L12 5L19 8V17L12 20L5 17Z"
        stroke={props.color || '#6C7280'}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        id="Vector 25"
        d="M12 11V20M12 11L5 8M12 11L19 8"
        stroke={props.color || '#6C7280'}
        strokeWidth="2"
      />
    </g>
  </svg>
);
