import * as React from 'react';

export default ({ backgroundColor }) => (
  <svg width="48" height="48">
    <g fill="none" fillRule="evenodd">
      <circle fill={backgroundColor || '#F0F2F5'} cx="24" cy="24" r="24" />
      <g stroke="#0A132B" strokeLinecap="round" strokeWidth="2">
        <path d="M18 24h13" />
        <path strokeLinejoin="round" d="M25 17l-7 7 7 7" />
      </g>
    </g>
  </svg>
);
