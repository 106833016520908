import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 14 14"
    fill="none"
  >
    <circle cx="7" cy="7" r="7" fill="#0067F4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 4.73579C7.58333 4.91193 7.33333 5 7 5C6.33333 5 6 4.66555 6 3.99666C6 3.33222 6.33333 3 7 3C7.66667 3 8 3.33222 8 3.99666C8 4.31327 7.91667 4.55964 7.75 4.73579ZM8 6V11H6V6H8Z"
      fill="#FAFBFC"
    />
  </svg>
);
