/* eslint-disable no-console */
import { createLogger, format, transports } from 'winston';
import { LEVEL, MESSAGE } from 'triple-beam';

const colors = require('colors/safe');

const { combine, printf } = format;

const outputFormat = printf(({ level, message, label, timestamp }) => {
  let messageJson;
  if (typeof message === 'object') {
    try {
      messageJson = JSON.stringify(message);
    } catch (e) {
      messageJson = null;
    }
  }

  let color;
  if (level === 'debug') color = colors.green;
  else if (level === 'info') color = colors.yellow;
  else if (level === 'error') color = colors.red;
  else color = colors.magenta;

  return `${colors.grey(`${timestamp} [${label}] ${level}:`)} ${color(messageJson || message)}`;
});

const transportInstance = new transports.Console({});
if (process.env.NODE_ENV !== 'test') {
  transportInstance.log = (info: any, callback: () => void | undefined) => {
    setImmediate(() => transportInstance.emit('logged', info[MESSAGE]));

    if (info[LEVEL] === 'error') {
      console.error(info[MESSAGE]);

      if (callback) {
        callback();
      }
      return;
    }

    console.log(info[MESSAGE]);

    if (callback) {
      callback();
    }
  };
}

export const logger = createLogger({
  level: process.env.NODE_ENV === 'production' ? 'info' : 'debug',
  format: combine(format.timestamp(), outputFormat),
  transports: [transportInstance],
});
