import type { SqlQueryResults } from '@/services/types';
import type { InvokeResult, ReduxSagaModel, ReduxState } from './types';
import type DatasetViewState from './objects/datasetViewState';
import type { DatasetEditStateProps } from './objects/datasetEditState';
import DatasetTest from '@/services/datasetTest';
import { generateEffectsForGetContentCompatibleType } from './common/getContent';
import DatasetTestEditState from './objects/datasetEditState';
import { handleUserError } from '@/utils/handleError';
import { assertIsDatasetTest, assertIsDefined } from '@/utils/typeChecks';
import { sgcall, sgselect } from '@/utils/reduxSaga';

const ns = 'app.models.datasetTests';
const REDUX_NAMESPACE = 'datasetTests';

export interface State {
  all: DatasetTest[] | null;
  current: DatasetTest | null;
  rowCount: number | null;
  records: SqlQueryResults | null;
  viewState: DatasetViewState | null;
  editState: DatasetTestEditState | null;
  invokeUpdateResult: InvokeResult<null> | null;
  invokeCreateResult: InvokeResult<string> | null;
  invokeRenameResult: InvokeResult<null> | null;
  invokeRefreshResult: InvokeResult<null> | null;
  invokePreviewResult: InvokeResult<SqlQueryResults> | null;
  invokeSaveLocallyResult: InvokeResult<null> | null;
  invokeDeployRemotelyResult: InvokeResult<{ unchanged: boolean }> | null;
  invokeDeleteResult: InvokeResult<null> | null;
}

const initialState = {
  all: null,
  current: null,
  rowCount: null,
  records: null,
  viewState: null,
  editState: null,
  invokeUpdateResult: null,
  invokeCreateResult: null,
  invokeRenameResult: null,
  invokeRefreshResult: null,
  invokePreviewResult: null,
  invokeSaveLocallyResult: null,
  invokeDeployRemotelyResult: null,
  invokeDeleteResult: null,
};

interface FetchAction {
  type: 'fetchAction';
  payload: Pick<State, 'all'>;
}

interface SaveRowCountAction {
  type: 'saveRowCount';
  payload: Pick<State, 'rowCount'>;
}

interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveRecordsAction {
  type: 'saveRecords';
  payload: Pick<State, 'records'>;
}

interface SaveViewStateAction {
  type: 'saveViewState';
  payload: Pick<State, 'viewState'>;
}

interface SaveEditStateAction {
  type: 'saveEditState';
  payload: Pick<State, 'editState'>;
}

interface SaveInvokeUpdateResultAction {
  type: 'saveInvokeUpdateResult';
  payload: Pick<State, 'invokeUpdateResult'>;
}

interface SaveInvokeCreateResultAction {
  type: 'saveInvokeCreateResult';
  payload: Pick<State, 'invokeCreateResult'>;
}

interface SaveInvokeRenameResultAction {
  type: 'saveInvokeRenameResult';
  payload: Pick<State, 'invokeRenameResult'>;
}

interface SaveInvokeRefreshResultAction {
  type: 'saveInvokeRefreshResult';
  payload: Pick<State, 'invokeRefreshResult'>;
}

interface SaveInvokePreviewResultAction {
  type: 'saveInvokePreviewResult';
  payload: Pick<State, 'invokePreviewResult'>;
}

interface SaveInvokeSaveLocallyResultAction {
  type: 'saveInvokeSaveLocallyResult';
  payload: Pick<State, 'invokeSaveLocallyResult'>;
}

interface SaveInvokeDeployRemotelyResultAction {
  type: 'saveInvokeDeployRemotelyResult';
  payload: Pick<State, 'invokeDeployRemotelyResult'>;
}

interface SaveInvokeDeleteResultAction {
  type: 'saveInvokeDeleteResult';
  payload: { invokeDeleteResult: InvokeResult<null>; all?: DatasetTest[] };
}

const DatasetTest2Model: ReduxSagaModel<
  State,
  {
    fetchAction: FetchAction;
    saveCurrent: SaveCurrentAction;
    saveRowCount: SaveRowCountAction;
    saveRecords: SaveRecordsAction;
    saveViewState: SaveViewStateAction;
    saveEditState: SaveEditStateAction;
    saveInvokeUpdateResult: SaveInvokeUpdateResultAction;
    saveInvokeCreateResult: SaveInvokeCreateResultAction;
    saveInvokeRenameResult: SaveInvokeRenameResultAction;
    saveInvokeRefreshResult: SaveInvokeRefreshResultAction;
    saveInvokePreviewResult: SaveInvokePreviewResultAction;
    saveInvokeSaveLocallyResult: SaveInvokeSaveLocallyResultAction;
    saveInvokeDeployRemotelyResult: SaveInvokeDeployRemotelyResultAction;
    saveInvokeDeleteResult: SaveInvokeDeleteResultAction;
  }
> = {
  namespace: REDUX_NAMESPACE,
  state: initialState,
  effects: {
    *fetch({ payload }: { payload: { datasetId: string } }, { put }) {
      const { datasetId } = payload;
      const datasetTests = yield* sgcall(() => DatasetTest.getDatasetTestsByDataset(datasetId));
      yield put<FetchAction>({ type: 'fetchAction', payload: { all: datasetTests } });
    },
    *get(
      { payload }: { payload: { id: string; version?: number; timestamp?: number } },
      { call, put },
    ) {
      try {
        yield put<SaveViewStateAction>({ type: 'saveViewState', payload: { viewState: null } });
        const datasetTest = yield call(DatasetTest.getDatasetTestById, payload.id, payload.version);
        assertIsDatasetTest(datasetTest, 'BEEM220717145512');
        datasetTest.timestamp = payload.timestamp;
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: datasetTest } });
      } catch (e) {
        handleUserError(e, `${ns}.get.error`);
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: null } });
      }
    },
    ...generateEffectsForGetContentCompatibleType<DatasetTest>(REDUX_NAMESPACE),
    *initEditState({ payload }: { payload: { version: number } }, { put, select }) {
      const datasetTest = yield select((s: ReduxState) => s.datasetTests.current);
      assertIsDatasetTest(datasetTest, 'BEEM220717191005');

      const sqlQueryEtcChanges = yield* sgcall(datasetTest.getSqlQueryEtcChanges);

      let initial: DatasetEditStateProps;
      if (datasetTest.isDraft) {
        initial = DatasetTestEditState.getDefaultState();
      } else {
        const temp2 = sqlQueryEtcChanges.find((el, i) =>
          payload.version ? el.timestamp === payload.version : i === 0,
        );
        assertIsDefined(temp2, 'BEEM220919111827');
        initial = temp2;
      }

      const current: DatasetEditStateProps = { ...initial };

      const saved: DatasetEditStateProps | null =
        sqlQueryEtcChanges.length > 0 ? { ...sqlQueryEtcChanges[0] } : null;

      const deployedSqlQueryEtc = sqlQueryEtcChanges.find((el) => el.deployed);
      const deployed: DatasetEditStateProps | null = deployedSqlQueryEtc
        ? { ...deployedSqlQueryEtc }
        : null;

      yield put<SaveEditStateAction>({
        type: 'saveEditState',
        payload: { editState: new DatasetTestEditState({ initial, current, saved, deployed }) },
      });
    },
    *setEditStateCurrentValue({ payload }: { payload: Partial<DatasetEditStateProps> }, { put }) {
      const editState = yield* sgselect((s) => s.datasetTests.editState, 'BEEM220920094517');
      yield put<SaveEditStateAction>({
        type: 'saveEditState',
        payload: {
          editState: new DatasetTestEditState({
            ...editState,
            current: { ...editState.current, ...payload },
          }),
        },
      });
    },
    *invokeCreate(
      { payload }: { payload: { name: string; datasetId: string; workspaceId: string } },
      { call, put },
    ) {
      try {
        const newDatasetTestId = yield call(DatasetTest.create, {
          name: payload.name,
          datasetId: payload.datasetId,
          workspaceId: payload.workspaceId,
        });
        yield put({
          type: 'saveInvokeCreateResult',
          payload: { invokeCreateResult: { success: true, data: newDatasetTestId } },
        });
      } catch (e) {
        yield put({
          type: 'saveInvokeCreateResult',
          payload: {
            invokeCreateResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeCreate.error`),
            },
          },
        });
      }
    },
    *invokeRename({ payload }: { payload: { record: DatasetTest; name: string } }, { call, put }) {
      try {
        yield call(payload.record.update, { name: payload.name });
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: { invokeRenameResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: {
            invokeRenameResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeRename.error`),
            },
          },
        });
      }
    },
    *invokeRefresh({ payload }: { payload: { cloudResourceId: string } }, { call, put }) {
      try {
        yield call(DatasetTest.refresh, payload.cloudResourceId);
        yield put<SaveInvokeRefreshResultAction>({
          type: 'saveInvokeRefreshResult',
          payload: { invokeRefreshResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeRefreshResultAction>({
          type: 'saveInvokeRefreshResult',
          payload: {
            invokeRefreshResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeRefresh.error`),
            },
          },
        });
      }
    },
    *invokePreview({ payload }: { payload: { workspaceId: string; code: string } }, { put }) {
      try {
        const rs = yield* sgcall(() => DatasetTest.testSqlQuery(payload.workspaceId, payload.code));
        const data = rs.map((el, i) => ({ _beem_row_number: i + 1, ...el })) as SqlQueryResults;
        yield put<SaveInvokePreviewResultAction>({
          type: 'saveInvokePreviewResult',
          payload: { invokePreviewResult: { success: true, data } },
        });
      } catch (e) {
        yield put<SaveInvokePreviewResultAction>({
          type: 'saveInvokePreviewResult',
          payload: {
            invokePreviewResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokePreview.error`),
            },
          },
        });
      }
    },
    *invokeSaveLocally(_, { call, put, select }) {
      try {
        const datasetTest = yield select((s: ReduxState) => s.datasetTests.current);
        assertIsDatasetTest(datasetTest, 'BEEM220723124313');

        const editState = yield* sgselect((s) => s.datasetTests.editState, 'BEEM220920094518');
        const { sqlQuery, trigger, triggerDetails } = editState.current;

        yield call(datasetTest.saveLocally, { sqlQuery, trigger, triggerDetails });

        const sqlQueryEtcChanges = yield* sgcall(() => datasetTest.getSqlQueryEtcChanges(1));
        const { timestamp } = sqlQueryEtcChanges[0];

        yield put<SaveEditStateAction>({
          type: 'saveEditState',
          payload: {
            editState: new DatasetTestEditState({
              initial: editState.initial,
              current: { timestamp, sqlQuery, trigger, triggerDetails },
              saved: { timestamp, sqlQuery, trigger, triggerDetails },
              deployed: editState.deployed,
            }),
          },
        });

        yield put<SaveInvokeSaveLocallyResultAction>({
          type: 'saveInvokeSaveLocallyResult',
          payload: { invokeSaveLocallyResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeSaveLocallyResultAction>({
          type: 'saveInvokeSaveLocallyResult',
          payload: {
            invokeSaveLocallyResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeSaveLocally.error`),
            },
          },
        });
      }
    },
    *invokeDeployRemotely(_, { call, put, select }) {
      try {
        const datasetTest = yield select((s: ReduxState) => s.datasetTests.current);
        assertIsDatasetTest(datasetTest, 'BEEM220723124313');

        const editState = yield* sgselect((s) => s.datasetTests.editState, 'BEEM220920094519');
        const { sqlQuery, trigger, triggerDetails } = editState.current;
        const oldCode = editState.deployed ? editState.deployed.sqlQuery : null;

        yield call(datasetTest.deploy, { sqlQuery, trigger, triggerDetails });

        const sqlQueryEtcChanges = yield* sgcall(() => datasetTest.getSqlQueryEtcChanges(1));
        const { timestamp } = sqlQueryEtcChanges[0];

        yield put<SaveEditStateAction>({
          type: 'saveEditState',
          payload: {
            editState: new DatasetTestEditState({
              initial: editState.initial,
              current: { timestamp, sqlQuery, trigger, triggerDetails },
              saved: { timestamp, sqlQuery, trigger, triggerDetails },
              deployed: { timestamp, sqlQuery, trigger, triggerDetails },
            }),
          },
        });

        yield put<SaveInvokeDeployRemotelyResultAction>({
          type: 'saveInvokeDeployRemotelyResult',
          payload: {
            invokeDeployRemotelyResult: {
              success: true,
              data: { unchanged: sqlQuery === oldCode },
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeDeployRemotelyResultAction>({
          type: 'saveInvokeDeployRemotelyResult',
          payload: {
            invokeDeployRemotelyResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeDeployRemotely.error`),
            },
          },
        });
      }
    },
    *invokeDelete(
      {
        payload,
      }: {
        payload: {
          id: string;
        };
      },
      { call, put },
    ) {
      const { id } = payload;

      try {
        yield call(DatasetTest.delete, { id });

        const allDatasetTests = yield* sgselect((s) => s.datasetTests.all, 'BEEM268309270157');

        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: {
            invokeDeleteResult: { data: null, success: true },
            all: allDatasetTests.filter((dt) => dt.id !== id),
          },
        });
      } catch (e) {
        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: {
            invokeDeleteResult: {
              error: handleUserError(e, `${ns}.invokeDeleteResult.error`),
              success: false,
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    fetchAction(state, { payload }) {
      return { ...state, ...payload };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveRowCount(state, { payload }) {
      return { ...state, ...payload };
    },
    saveRecords(state, { payload }) {
      return { ...state, ...payload };
    },
    saveViewState(state, { payload }) {
      return { ...state, ...payload };
    },
    saveEditState(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeUpdateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeCreateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRenameResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRefreshResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokePreviewResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeSaveLocallyResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeployRemotelyResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeleteResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default DatasetTest2Model;
