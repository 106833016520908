import * as React from 'react';

export default ({ color }) => (
  <svg width="8" height="5" fill="none">
    <path
      d="M7 4L4 1 1 4"
      stroke={color || '#B5BFD2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
