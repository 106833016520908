import * as React from 'react';

export default (props) => {
  return (
    <svg width="32" height="32">
      <g fill="none" fillRule="evenodd">
        <rect fill={props.color[0]} width="32" height="32" rx="2" />
        <path
          fill={props.color[1]}
          fillRule="nonzero"
          d="M16.645 20.688v-3.965h3.896v-1.739h-3.896V11h-1.758v3.984H11v1.739h3.887v3.965z"
        />
      </g>
    </svg>
  );
};
