import type {
  ReduxState,
  InvokeResult,
  ReduxSagaModel,
  ReduxSagaEffect,
  Breadcrumb,
} from './types';
import Folder from '@/services/folder';
import { logger } from '@/utils/logger';
import { handleUserError } from '@/utils/handleError';
import { assertIsArray, assertIsDefined, assertIsFolder } from '@/utils/typeChecks';

const ns = 'app.models.folders';

export interface State {
  all: Folder[] | null;
  current: Folder | null;
  breadcrumbs: Breadcrumb[] | null;
  invokeCreateResult: InvokeResult<string> | null;
  invokeRenameResult: InvokeResult<string> | null;
  invokeMoveResult: InvokeResult<null> | null;
  invokeDeleteResult: InvokeResult<null> | null;
}

const initialState = {
  all: null,
  current: null,
  breadcrumbs: null,
  invokeCreateResult: null,
  invokeRenameResult: null,
  invokeMoveResult: null,
  invokeDeleteResult: null,
};

interface SaveAllAction {
  type: 'saveAll';
  payload: Pick<State, 'all'>;
}

interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveBreadcrumbsAction {
  type: 'saveBreadcrumbs';
  payload: Pick<State, 'breadcrumbs'>;
}

interface SaveInvokeCreateResultAction {
  type: 'saveInvokeCreateResult';
  payload: Pick<State, 'invokeCreateResult'>;
}

interface SaveInvokeRenameResultAction {
  type: 'saveInvokeRenameResult';
  payload: Pick<State, 'invokeRenameResult'>;
}

interface SaveInvokeMoveResultAction {
  type: 'saveInvokeMoveResult';
  payload: Pick<State, 'invokeMoveResult'>;
}

interface SaveInvokeDeleteResultAction {
  type: 'saveInvokeDeleteResult';
  payload: Pick<State, 'invokeDeleteResult'>;
}

const getBreadcrumbs: ReduxSagaEffect = function* getBreadcrumbs(
  payload: { workspaceId: string; folderId: string },
  { call, put },
) {
  let { folderId } = payload;
  const breadcrumbs: Breadcrumb[] = [];
  do {
    const folder = yield call(Folder.getFolderById, folderId);
    assertIsFolder(folder, 'BEEM220712160840');
    breadcrumbs.unshift({ id: folder.id, name: folder.name });
    folderId = folder.parentId;
  } while (folderId !== payload.workspaceId);

  yield put<SaveBreadcrumbsAction>({
    type: 'saveBreadcrumbs',
    payload: { breadcrumbs },
  });
};

const FolderModel: ReduxSagaModel<
  State,
  {
    saveAll: SaveAllAction;
    saveCurrent: SaveCurrentAction;
    saveBreadcrumbs: SaveBreadcrumbsAction;
    saveInvokeCreateResult: SaveInvokeCreateResultAction;
    saveInvokeRenameResult: SaveInvokeRenameResultAction;
    saveInvokeMoveResult: SaveInvokeMoveResultAction;
    saveInvokeDeleteResult: SaveInvokeDeleteResultAction;
  }
> = {
  namespace: 'folders',
  state: initialState,
  effects: {
    *fetch({ payload }: { payload: { workspaceId: string; folderId: string } }, effects) {
      const { call, put, spawn } = effects;

      yield put({ type: 'resetAll' });

      const folders = yield call(Folder.getFoldersByParentId, payload.folderId);
      assertIsArray(folders, 'Folder', 'BEEM211105120641');
      folders.sort((a, b) => a.name.localeCompare(b.name));

      logger.debug({ label: 'app.models.folders.fetch.folders', message: folders });

      yield put<SaveAllAction>({ type: 'saveAll', payload: { all: folders } });

      yield spawn(getBreadcrumbs, payload, effects);
    },
    *recheck({ payload }: { payload: { filterFn: (el: Folder) => boolean } }, { select, put }) {
      const all = yield select((s: ReduxState) => s.folders.all);
      assertIsArray(all, 'Folder', 'BEEM220716214157');
      const newAll = all.filter(payload.filterFn);
      yield put<SaveAllAction>({ type: 'saveAll', payload: { all: newAll } });
    },
    *select({ payload }: { payload: { folder: Folder } }, { put }) {
      yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: payload.folder } });
    },
    *invokeCreate({ payload }: { payload: { name: string; inFolderId: string } }, { call, put }) {
      try {
        yield call(Folder.create, { name: payload.name, parentId: payload.inFolderId });
        yield put<SaveInvokeCreateResultAction>({
          type: 'saveInvokeCreateResult',
          payload: { invokeCreateResult: { success: true, data: payload.inFolderId } },
        });
      } catch (e) {
        yield put<SaveInvokeCreateResultAction>({
          type: 'saveInvokeCreateResult',
          payload: {
            invokeCreateResult: {
              success: false,
              error: handleUserError(e, 'app.models.folders.invokeCreate.error'),
            },
          },
        });
      }
    },
    *invokeRename({ payload }: { payload: { record: Folder; name: string } }, { call, put }) {
      try {
        assertIsDefined(payload.record.parent, 'BEEM210930053213');
        yield call(payload.record.update, { name: payload.name });
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: { invokeRenameResult: { success: true, data: payload.record.parent.id } },
        });
      } catch (e) {
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: {
            invokeRenameResult: {
              success: false,
              error: handleUserError(e, 'app.models.folders.invokeRename.error'),
            },
          },
        });
      }
    },
    *invokeMove({ payload }: { payload: { record: Folder; toFolderId: string } }, { call, put }) {
      try {
        yield call(payload.record.update, { parentId: payload.toFolderId });
        yield put<SaveInvokeMoveResultAction>({
          type: 'saveInvokeMoveResult',
          payload: { invokeMoveResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeMoveResultAction>({
          type: 'saveInvokeMoveResult',
          payload: {
            invokeMoveResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeMove.error`),
            },
          },
        });
      }
    },
    *invokeDelete({ payload }: { payload: { id: string } }, { call, put }) {
      try {
        yield call(Folder.delete, payload.id);
        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: { invokeDeleteResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: {
            invokeDeleteResult: {
              success: false,
              error: handleUserError(e, 'app.models.folders.invokeDelete.error'),
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveAll(state, { payload }) {
      return { ...state, ...payload };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveBreadcrumbs(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeCreateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRenameResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeMoveResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeleteResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default FolderModel;
