import * as React from 'react';

export default ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '56'}
    height={height || '56'}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3335 0.83342C1.67664 0.83342 0.333496 2.17657 0.333496 3.83342V16.6662C0.333496 17.5566 0.728965 18.4009 1.41294 18.9709L25.9992 39.4594V55.1647C25.9992 56.8215 27.3423 58.1647 28.9992 58.1647C30.656 58.1647 31.9992 56.8215 31.9992 55.1647V39.4594L56.5853 18.9709C57.2693 18.4009 57.6648 17.5566 57.6648 16.6662V3.83342C57.6648 2.17657 56.3216 0.83342 54.6648 0.83342H3.3335ZM6.3335 15.2611V6.83342H51.6648V15.2611L28.9991 34.1491L6.3335 15.2611Z"
      fill="#0067F4"
    />
  </svg>
);
