import type * as A from '@/api';
import type { InvokeResult, ReduxSagaModel } from './types';
import type DatasetLite from '@/services/datasetLite';
import type ConnectionLite from '@/services/connectionLite';
import Dataset from '@/services/dataset';
import Flow from '@/services/flow'; // , { FILE_PATH }
import Connection from '@/services/connection';
import { assertIsArray, assertIsDefined, assertIsFlow, assertIsString } from '@/utils/typeChecks';
import { logger } from '@/utils/logger';
import { handleUserError } from '@/utils/handleError';
import type { TriggerDetails } from '@/services/triggerDetails';
import { sgselect } from '@/utils/reduxSaga';
import { spawnTaskToWatchForLastAction } from './common/utils';
import { fetchOnce } from './flows.fetch';

export type FetchEffectPayload = {
  workspaceId: string;
};

interface InitialEditState {
  enabled: boolean;
  trigger: A.Trigger;
  triggerDetails: TriggerDetails;
  datasets: DatasetLite[];
  selectedDataset: DatasetLite;
  connections: ConnectionLite[];
  selectedConnection: ConnectionLite;
  // parentCommitId: string;
  // filePath: string;
  // fileContent: string;
}

export interface EditState extends InitialEditState {
  initialState: InitialEditState;
}

function isStateChanged(state: EditState, fields: string[]): boolean {
  return !!fields.find((f) => state[f] !== state.initialState[f]);
}

export interface State {
  all: Flow[] | null;
  current: Flow | null;
  invokedFetchEffectPayload: FetchEffectPayload | null;
  editState: EditState | null;
  invokeUpdateResult: InvokeResult<null> | null;
}

const initialState = {
  all: null,
  current: null,
  invokedFetchEffectPayload: null,
  editState: null,
  invokeUpdateResult: null,
};

export interface SaveAllAction {
  type: 'saveAll';
  payload: Pick<State, 'all'>;
}

export interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveInvokedFetchEffectPayloadAction {
  type: 'saveInvokedFetchEffectPayload';
  payload: Pick<State, 'invokedFetchEffectPayload'>;
}

interface SaveEditStateAction {
  type: 'saveEditState';
  payload: Pick<State, 'editState'>;
}

interface SavePartialEditStateAction {
  type: 'savePartialEditState';
  payload: { partialEditState: Partial<EditState> };
}

interface SaveInvokeUpdateResultAction {
  type: 'saveInvokeUpdateResult';
  payload: Pick<State, 'invokeUpdateResult'>;
}

const FlowModel: ReduxSagaModel<
  State,
  {
    saveAll: SaveAllAction;
    saveCurrent: SaveCurrentAction;
    saveInvokedFetchEffectPayload: SaveInvokedFetchEffectPayloadAction;
    saveEditState: SaveEditStateAction;
    savePartialEditState: SavePartialEditStateAction;
    saveInvokeUpdateResult: SaveInvokeUpdateResultAction;
  }
> = {
  namespace: 'flows',
  state: initialState,
  effects: {
    *fetch(_, effects) {
      const { put, select } = effects;

      const workspaceId = yield select((s: any) => s.organization.currentWorkspace);
      assertIsString(workspaceId, 'BEEM211105120625');

      yield* spawnTaskToWatchForLastAction(
        'flows/saveInvokedFetchEffectPayload',
        fetchOnce,
        effects,
      );
      yield put<SaveInvokedFetchEffectPayloadAction>({
        type: 'saveInvokedFetchEffectPayload',
        payload: {
          invokedFetchEffectPayload: { workspaceId },
        },
      });
    },
    *get({ payload }: { payload: { id: string } }, { call, put }) {
      yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: null } });
      yield put<SaveEditStateAction>({ type: 'saveEditState', payload: { editState: null } });

      const flow = yield call(Flow.getFlowById, payload.id);
      assertIsFlow(flow, 'BEEM220712160833');
      logger.debug({ label: 'app.models.flows.get.flow', message: flow });

      yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: flow } });
    },
    *initEditState(_, { put, select }) {
      const flow = yield select((s: any) => s.flows.current);
      assertIsFlow(flow, 'BEEM220712160834');

      const selectedConnection = {
        id: flow.destinationId,
        integrationName: flow.destinationIntegrationName,
      };

      const selectedDataset = {
        id: flow.datasetId,
        name: flow.datasetName,
      };

      const initialEditState: InitialEditState = {
        enabled: flow.enabled,
        trigger: flow.trigger,
        triggerDetails: flow.triggerDetails,
        datasets: [],
        selectedDataset,
        connections: [],
        selectedConnection,
        // parentCommitId: '',
        // filePath: FILE_PATH,
        // fileContent: '',
      };
      yield put<SaveEditStateAction>({
        type: 'saveEditState',
        payload: {
          editState: {
            initialState: initialEditState,
            ...initialEditState,
          },
        },
      });
    },
    *loadOptionsForEditState(_, { call, put, select }) {
      const editState = yield* sgselect((s) => s.flows.editState, 'Beem220920115152');

      // don't reload if unnecessary
      if (editState.datasets.length) return;

      const flow = yield select((s: any) => s.flows.current);
      assertIsFlow(flow, 'BEEM220712160835');
      const datasets = yield call(Dataset.getDatasetsIdAndNameOwnByWorkspace, flow.workspaceId);
      assertIsArray(datasets, 'DatasetLite', 'BEEM211105095934');
      datasets.sort((a, b) => a.name.localeCompare(b.name));

      const currentOrganizationId = yield select((s: any) => s.organization.currentOrganization);
      assertIsString(currentOrganizationId, 'BEEM211105095935');
      const connections = yield call(
        Connection.getConnectionsIdAndNameByOrganization,
        currentOrganizationId,
      );
      assertIsArray(connections, 'ConnectionLite', 'BEEM211105095936');

      // const { commitId, fileContent } = (yield call(flow.getCode)) as A.GetFlowCodeResponse;
      // assertIsDefined(commitId, 'BEEM211105095932');
      // assertIsDefined(fileContent, 'BEEM211105095933');
      // const c = { parentCommitId: commitId, fileContent };
      // const newInitialState = { ...editState.initialState, ...c };
      const newInitialState = { ...editState.initialState };

      yield put<SavePartialEditStateAction>({
        type: 'savePartialEditState',
        payload: {
          // partialEditState: { initialState: newInitialState, datasets, connections, ...c },
          partialEditState: { initialState: newInitialState, datasets, connections },
        },
      });
    },
    *resetEditState(_, { put }) {
      const editState = yield* sgselect((s) => s.flows.editState, 'BEEM220920114941');
      const initialEditState = editState.initialState;
      yield put<SaveEditStateAction>({
        type: 'saveEditState',
        payload: {
          editState: {
            initialState: initialEditState,
            ...initialEditState,
          },
        },
      });
    },
    *invokeUpdate(_, { call, put }) {
      try {
        // const userId = yield* sgselect((s) => s.user.currentUser.id);
        const flow = yield* sgselect((s) => s.flows.current, 'BEEM220712160836');
        const editState = yield* sgselect((s) => s.flows.editState, 'BEEM220712160837');

        const {
          selectedDataset,
          // selectedConnection,
          enabled,
          trigger,
          triggerDetails,
          // parentCommitId,
          // filePath,
          // fileContent,
        } = editState;
        // const commitMessage = `Change(s) made by user ${userId} from BEEM App`;

        yield call(flow.update, {
          ...(isStateChanged(editState, ['selectedDataset'])
            ? { datasetId: selectedDataset.id }
            : {}),
          // ...(isStateChanged(editState, ['selectedConnection'])
          //   ? { destinationId: selectedConnection.id }
          //   : {}),
          ...(isStateChanged(editState, ['enabled', 'trigger', 'triggerDetails'])
            ? { enabled, trigger, triggerDetails }
            : {}),
          // ...(isStateChanged(editState, ['fileContent'])
          //   ? { parentCommitId, filePath, fileContent, commitMessage }
          //   : {}),
        });

        const newInitialState = { ...editState, initialState: undefined };
        yield put<SavePartialEditStateAction>({
          type: 'savePartialEditState',
          payload: { partialEditState: { initialState: newInitialState } },
        });

        yield put<SaveInvokeUpdateResultAction>({
          type: 'saveInvokeUpdateResult',
          payload: { invokeUpdateResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeUpdateResultAction>({
          type: 'saveInvokeUpdateResult',
          payload: {
            invokeUpdateResult: {
              success: false,
              error: handleUserError(e, 'app.models.flows.invokeUpdate.error'),
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveAll(state, { payload }) {
      return { ...state, ...payload };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokedFetchEffectPayload(state, { payload }) {
      return { ...state, ...payload };
    },
    saveEditState(state, { payload }) {
      return { ...state, ...payload };
    },
    savePartialEditState(state, { payload }) {
      assertIsDefined(state.editState, 'BEEM211105120650');
      return { ...state, editState: { ...state.editState, ...payload.partialEditState } };
    },
    saveInvokeUpdateResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default FlowModel;
