import * as React from 'react';

export default () => (
  <svg width="28" height="35">
    <path
      d="M2.135 1h23.73a1 1 0 0 1 .768 1.64L18 13h0v15.5a1 1 0 0 1-.4.8l-6 4.5A1 1 0 0 1 10 33V13h0L1.367 2.64A1 1 0 0 1 2.135 1z"
      fill="#B5BFD2"
      stroke="#B5BFD2"
      strokeWidth="2"
      fillRule="evenodd"
      fillOpacity=".197"
      strokeLinejoin="round"
    />
  </svg>
);
