export function* resetAllModelStates(put) {
  // must reset these first, in order to logout properly
  yield put({ type: 'login/resetAll' });
  yield put({ type: 'user/resetAll' });
  yield put({ type: 'user2/resetAll' });
  yield put({ type: 'organization/resetAll' });
  yield put({ type: 'workspaces/resetAll' });

  // then these can be in any order
  yield put({ type: 'connections/resetAll' });
  yield put({ type: 'credentials/resetAll' });
  yield put({ type: 'dataObjects/resetAll' });
  yield put({ type: 'dataset/resetAll' });
  yield put({ type: 'datasetTests/resetAll' });
  yield put({ type: 'downloads/resetAll' });
  yield put({ type: 'events/resetAll' });
  yield put({ type: 'flows/resetAll' });
  yield put({ type: 'folders/resetAll' });
  yield put({ type: 'hub/resetAll' });
  yield put({ type: 'notifications/resetAll' });
  yield put({ type: 'overlay/resetAll' });
  yield put({ type: 'sources/resetAll' });
}

const GlobalModel = {
  namespace: 'global',
  state: {},
  effects: {
    *resetAllModelStates(_, { put }) {
      yield* resetAllModelStates(put);
    },
  },
  reducers: {},
  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};

export default GlobalModel;
