import type { ITableReference } from './genericQuery';
import { GenericQuery } from './genericQuery';

export class RedshiftQuery extends GenericQuery {
  protected parseTables(): ITableReference[] {
    const tables: ITableReference[] = [];
    // eslint-disable-next-line no-useless-escape
    const regex = /(?:([^\s]+)\.)?(?:"([^"]+)"|([^\s\.]+))/g;
    const result = this.sanitizedQuery.match(regex);
    this.tokens = result || [];

    this.tokens.forEach((token, index) => {
      if (token.toLowerCase() === 'from' || token.toLowerCase() === 'join') {
        let nextToken = '';
        let nextNextToken = '';
        try {
          nextToken = this.tokens[index + 1] ?? '';
          nextNextToken = this.tokens[index + 2] ?? '';
        } catch (error) {
          /* do not fail if there is no next token */
        }
        if (nextToken && !nextToken.startsWith('(')) {
          const tableReference = this.parseTableReference(nextToken, nextNextToken);

          if (tableReference && tableReference.table !== '') {
            // if object does not exist in array, add it
            if (
              !tables.some(
                (t) => t.table === tableReference.table && t.schema === tableReference.schema,
              )
            ) {
              tables.push(tableReference);
            }
          }
        }
      }
    });

    return tables;
  }
}
