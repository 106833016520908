import type { ReduxState, ReduxSagaModel, InvokeResult } from './types';
import Organization from '@/services/organization';
import { logger } from '@/utils/logger';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';
import moment from 'moment';
import { redirectToURLFromLocalStorage } from '@/utils/utils';
import { getCurrentOrganizationTimezone } from '@/utils/namingConvention';
import {
  assertIsArray,
  assertIsDefined,
  assertIsNumber,
  assertIsOrganization,
  assertIsString,
} from '@/utils/typeChecks';

const ns = 'app.models.organization';

const MRU_ORG_KEY = 'beem-recently-used-organization';
const MRU_WS_KEY = 'beem-recently-used-workspace';
const MRU_ORG_TZ_KEY = 'beem-recently-used-org-timezone';
const MRU_WS_BY_ORG = 'beem-last-used-workspace-';

export interface State {
  currentOrganization: string | null;
  currentWorkspace: string | null;
  currentOrganizationTimezone: string | null;
  current: Organization | null;
  invokeSetWarehouseRetentionResult: InvokeResult<null> | null;
  invokeCreateFivetranGroupIdIfNotExistResult: InvokeResult<string> | null;
  invokeRemoveUserAccessResult: InvokeResult<null> | null;
  dpuUsage: number | null;
  dpuAllowanceAndCycle: {
    allowance: number;
    billingCycleDate: number;
  } | null;
}

function getInitialState(): State {
  return {
    currentOrganization: localStorage.getItem(MRU_ORG_KEY),
    currentWorkspace: localStorage.getItem(MRU_WS_KEY),
    currentOrganizationTimezone: getCurrentOrganizationTimezone(),
    current: null,
    invokeSetWarehouseRetentionResult: null,
    invokeCreateFivetranGroupIdIfNotExistResult: null,
    invokeRemoveUserAccessResult: null,
    dpuUsage: null,
    dpuAllowanceAndCycle: null,
  };
}

interface SaveCurrentOrganizationAndWorkspaceAction {
  type: 'saveCurrentOrganizationAndWorkspace';
  payload: Pick<
    State,
    'currentOrganization' | 'currentWorkspace' | 'currentOrganizationTimezone' | 'current'
  >;
}

interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveDpuUsage {
  type: 'saveDpuUsage';
  payload: Pick<State, 'dpuUsage'>;
}
interface SaveDpuAllowanceAndCycle {
  type: 'saveDpuAllowanceAndCycle';
  payload: Pick<State, 'dpuAllowanceAndCycle'>;
}

interface SaveInvokeSetWarehouseRetentionResultAction {
  type: 'saveInvokeSetWarehouseRetentionResult';
  payload: Pick<State, 'invokeSetWarehouseRetentionResult'>;
}

interface SaveInvokeCreateFivetranGroupIdIfNotExistResultAction {
  type: 'saveInvokeCreateFivetranGroupIdIfNotExistResult';
  payload: Pick<State, 'invokeCreateFivetranGroupIdIfNotExistResult'>;
}

interface SaveInvokeRemoveUserAccessResultAction {
  type: 'saveInvokeRemoveUserAccessResult';
  payload: Pick<State, 'invokeRemoveUserAccessResult'>;
}

const OrganizationModel: ReduxSagaModel<
  State,
  {
    saveCurrentOrganizationAndWorkspace: SaveCurrentOrganizationAndWorkspaceAction;
    saveCurrent: SaveCurrentAction;
    saveInvokeSetWarehouseRetentionResult: SaveInvokeSetWarehouseRetentionResultAction;
    saveInvokeCreateFivetranGroupIdIfNotExistResult: SaveInvokeCreateFivetranGroupIdIfNotExistResultAction;
    saveInvokeRemoveUserAccessResult: SaveInvokeRemoveUserAccessResultAction;
    saveDpuUsage: SaveDpuUsage;
    saveDpuAllowanceAndCycle: SaveDpuAllowanceAndCycle;
  }
> = {
  namespace: 'organization',
  state: getInitialState(),
  effects: {
    *use(
      { payload }: { payload: { organizationId: string; workspaceId: string } },
      { put, select },
    ) {
      logger.debug({ label: 'app.models.organization.use', message: payload });

      const { organizationId } = payload;
      let { workspaceId } = payload;
      if (!workspaceId) {
        const workspaces = yield select((s: any) => s.user.workspaces);
        assertIsArray(workspaces, 'Workspace', 'BEEM211105120231');
        const cachedWorkspaceId = localStorage.getItem(MRU_WS_BY_ORG + organizationId);
        const found1 = cachedWorkspaceId
          ? workspaces.find((w) => w.id === cachedWorkspaceId)
          : undefined;
        if (found1) {
          workspaceId = found1.id;
        } else {
          const found2 = workspaces.find((w) => w.organization.id === organizationId);
          assertIsDefined(found2, 'BEEM211105120230');
          workspaceId = found2.id;
        }
      }

      const organization = yield* sgcall(() => Organization.getOrganizationById(organizationId));
      const currentOrganizationTimezone = organization.timezone;

      localStorage.setItem(MRU_ORG_KEY, organizationId);
      localStorage.setItem(MRU_WS_KEY, workspaceId);
      localStorage.setItem(MRU_ORG_TZ_KEY, currentOrganizationTimezone);
      localStorage.setItem(MRU_WS_BY_ORG + organizationId, workspaceId);

      yield put<SaveCurrentOrganizationAndWorkspaceAction>({
        type: 'saveCurrentOrganizationAndWorkspace',
        payload: {
          currentOrganization: organizationId,
          currentWorkspace: workspaceId,
          current: organization,
          currentOrganizationTimezone,
        },
      });

      redirectToURLFromLocalStorage();
    },

    *clear(_, { put }) {
      localStorage.removeItem(MRU_ORG_KEY);
      localStorage.removeItem(MRU_WS_KEY);
      localStorage.removeItem(MRU_ORG_TZ_KEY);

      yield put<SaveCurrentOrganizationAndWorkspaceAction>({
        type: 'saveCurrentOrganizationAndWorkspace',
        payload: {
          currentOrganization: null,
          currentWorkspace: null,
          currentOrganizationTimezone: null,
          current: null,
        },
      });
    },

    *get({ payload }: { payload?: { id: string } }, { call, put, select }) {
      try {
        let organizationId: string;
        if (payload) organizationId = payload.id;
        else {
          const temp = yield select<ReduxState>((s) => s.organization.currentOrganization);
          assertIsString(temp, 'BEEM211105120628');
          organizationId = temp;
        }

        const organization = yield call(Organization.getOrganizationById, organizationId);
        assertIsOrganization(organization, 'BEEM220712161320');
        logger.debug({ label: 'app.models.organization.get.organization', message: organization });
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: organization } });
      } catch (e) {
        logger.error({ label: 'app.models.datasets.get.error', message: e });
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: null } });
      }
    },

    *setWarehouseRetention(
      { payload: { daysToRetainData } }: { payload: { daysToRetainData: number } },
      { call, put, select },
    ) {
      try {
        const organization = yield select((s: any) => s.organization.current);
        assertIsOrganization(organization, 'BEEM220712161321');
        yield call(organization.setWarehouseRetention, daysToRetainData);
        yield put<SaveInvokeSetWarehouseRetentionResultAction>({
          type: 'saveInvokeSetWarehouseRetentionResult',
          payload: { invokeSetWarehouseRetentionResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeSetWarehouseRetentionResultAction>({
          type: 'saveInvokeSetWarehouseRetentionResult',
          payload: {
            invokeSetWarehouseRetentionResult: {
              success: false,
              error: handleUserError(e, `${ns}.setWarehouseRetention.error`),
            },
          },
        });
      }
    },

    *createFivetranGroupIdIfNotExist(_, { put, select }) {
      try {
        const organizationId = yield select<ReduxState>((s) => s.organization.currentOrganization);
        assertIsString(organizationId, 'BEEM230912135627');
        const organization = yield* sgcall(() => Organization.getOrganizationById(organizationId));
        const fivetranGroupId = yield* sgcall(organization.createFivetranGroupIdIfNotExist);
        yield put<SaveInvokeCreateFivetranGroupIdIfNotExistResultAction>({
          type: 'saveInvokeCreateFivetranGroupIdIfNotExistResult',
          payload: {
            invokeCreateFivetranGroupIdIfNotExistResult: { success: true, data: fivetranGroupId },
          },
        });
      } catch (e) {
        yield put<SaveInvokeCreateFivetranGroupIdIfNotExistResultAction>({
          type: 'saveInvokeCreateFivetranGroupIdIfNotExistResult',
          payload: {
            invokeCreateFivetranGroupIdIfNotExistResult: {
              success: false,
              error: handleUserError(e, `${ns}.createFivetranGroupIdIfNotExist.error`),
            },
          },
        });
      }
    },

    *removeUserAccess({ payload: { userId } }: { payload: { userId: string } }, { put }) {
      try {
        const current = yield* sgselect((s) => s.organization.current, 'BEEM268307275109');
        yield* sgcall(() => current.removeUserFromAllWorkspaces(userId));
        yield* sgcall(() => current.removeUser(userId));
        yield put<SaveInvokeRemoveUserAccessResultAction>({
          type: 'saveInvokeRemoveUserAccessResult',
          payload: {
            invokeRemoveUserAccessResult: { success: true, data: null },
          },
        });
      } catch (e) {
        yield put<SaveInvokeRemoveUserAccessResultAction>({
          type: 'saveInvokeRemoveUserAccessResult',
          payload: {
            invokeRemoveUserAccessResult: {
              success: false,
              error: handleUserError(e, `${ns}.removeUserAccess.error`),
            },
          },
        });
      }
    },

    *getDpuUsage(_, { select, put }) {
      try {
        const organizationId = yield select<ReduxState>((s) => s.organization.currentOrganization);
        assertIsString(organizationId, 'BEEM230912135627');
        const dpuAllowanceAndCycle = yield* sgcall(() =>
          Organization.getDpuAllowanceAndBilling(organizationId),
        );
        const dpuUsage = yield* sgcall(() =>
          Organization.getAccumulatedDpuUsageByOrganizationId(
            organizationId,
            moment()
              .date(dpuAllowanceAndCycle.billingCycleDate ?? 1)
              .toDate(),
          ),
        );
        logger.debug({
          label: 'app.models.organization.getDpuUsage',
          message: dpuUsage,
        });
        assertIsNumber(dpuUsage, 'BEEM230912135627');
        yield put<SaveDpuUsage>({ type: 'saveDpuUsage', payload: { dpuUsage } });
      } catch (e) {
        logger.debug({
          label: 'app.models.organization.getDpuUsage.error',
          message: 'there is an error with dpu usage',
        });
        yield put<SaveDpuUsage>({ type: 'saveDpuUsage', payload: { dpuUsage: null } });
      }
    },

    *getDpuAllowanceAndCycle(_, { select, put }) {
      try {
        const organizationId = yield select<ReduxState>((s) => s.organization.currentOrganization);
        assertIsString(organizationId, 'BEEM202405071216');
        const dpuAllowanceAndCycle = yield* sgcall(() =>
          Organization.getDpuAllowanceAndBilling(organizationId),
        );
        logger.debug({
          label: 'app.models.organization.getDpuUsage',
          message: dpuAllowanceAndCycle,
        });
        yield put<SaveDpuAllowanceAndCycle>({
          type: 'saveDpuAllowanceAndCycle',
          payload: { dpuAllowanceAndCycle },
        });
      } catch (e) {
        logger.debug({
          label: 'app.models.organization.getDpuUsage.error',
          message: 'there is an error with dpuAllowanceAndCycle',
        });
        yield put<SaveDpuAllowanceAndCycle>({
          type: 'saveDpuAllowanceAndCycle',
          payload: { dpuAllowanceAndCycle: null },
        });
      }
    },
  },

  reducers: {
    resetAll() {
      return getInitialState();
    },
    saveCurrentOrganizationAndWorkspace(state, { payload }) {
      return { ...state, ...payload };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeSetWarehouseRetentionResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeCreateFivetranGroupIdIfNotExistResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRemoveUserAccessResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveDpuUsage(state, { payload }) {
      return { ...state, ...payload };
    },
    saveDpuAllowanceAndCycle(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default OrganizationModel;
