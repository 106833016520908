import * as React from 'react';

export default () => (
  <svg width="8" height="16">
    <g
      stroke="#3386f6"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 12l-3 3-3-3M7 4L4 1 1 4" />
    </g>
  </svg>
);
