import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <g stroke="#0067F4" strokeWidth="2">
      <path d="M3 4h7.23a1 1 0 0 1 .722.308l1.03 1.076A2 2 0 0 0 13.428 6H21a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1z" />
      <path d="M22 10v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h20z" fill="#0067F4" fillOpacity=".2" />
    </g>
  </svg>
);
