import type * as A from '@/api';
import type { InvokeResult, ReduxSagaModel } from './types';
import Organization from '@/services/organization';
import Connection from '@/services/connection';
import Credentials from '@/services/credentials';
import Source from '@/services/source';
import { graphql } from '@/utils/graphql';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { logger } from '@/utils/logger';
import { handleUserError } from '@/utils/handleError';
import { addBackendSuffix, makeNameGlueCompatible } from '@/utils/namingConvention';
import { assertIsConnection, assertIsDefined, assertIsString } from '@/utils/typeChecks';
import { preloadAndCacheFivetranIconUrlsAndNativeIntegrationNames } from '@/utils/integrationIcons';
import { showNotification } from '@/utils/showNotification';

const ns = 'app.models.connections';

type InvokeCreateInput = {
  integrationId: string;
  organizationId: string;
  credentials: Parameters<typeof Credentials.create>[0];
};

export type FivetranConnectionResponse<T> = {
  data: { items: T[] };
};

export type FivetranConnection = {
  description: string;
  icon_url: string;
  icons: [string, string];
  id: string;
  link_to_docs: string;
  link_to_erd: string;
  name: string;
  service_status: string;
  type: string;
};

type FivetranConnectCardResponse = {
  data: FivetranConnectCard;
  message: string;
};

type FivetranConnectCard = {
  connect_card: {
    uri: string;
    token: string;
  };
  connect_card_config: {
    redirect_uri: string;
  };
  connected_by: string;
  created_at: string;
  data_delay_sensitivity: string;
  data_delay_threshold: number;
  failed_at: string | null;
  group_id: string;
  id: string;
  pause_after_trial: boolean;
  paused: boolean;
  schedule_type: string;
  schema: string;
  service: string;
  service_version: number;
  status: {
    is_historical_sync: true;
    schema_status: string;
    setup_state: string;
    sync_state: string;
    update_state: string;
    tasks: [];
    warnings: [];
  };
  succeeded_at: string | null;
  sync_frequency: number;
};

export interface State {
  all: Connection[] | null;
  current: Connection | null;
  invokeCreateResult: InvokeResult<null> | null;
  invokeUpdateResult: InvokeResult<null> | null;
  invokeRenameResult: InvokeResult<null> | null;
  invokeDeleteResult: InvokeResult<null> | null;
  fivetranConnections: FivetranConnection[] | null;
  fivetranConnectCardUrl: InvokeResult<string> | null;
  invokeRecreateFivetranConnectCardResult: InvokeResult<string> | null;
  invokeDeployFivetranSourceIngestionsResult: InvokeResult<null> | null;
  invokeDeleteIncompleteFivetranSourceAndConnectionResult: InvokeResult<null> | null;
}

const initialState = {
  all: null,
  current: null,
  invokeCreateResult: null,
  invokeUpdateResult: null,
  invokeRenameResult: null,
  invokeDeleteResult: null,
  fivetranConnections: null,
  fivetranConnectCardUrl: null,
  invokeRecreateFivetranConnectCardResult: null,
  invokeDeployFivetranSourceIngestionsResult: null,
  invokeDeleteIncompleteFivetranSourceAndConnectionResult: null,
};

interface SaveAllAction {
  type: 'saveAll';
  payload: Pick<State, 'all'>;
}

interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveInvokeCreateResultAction {
  type: 'saveInvokeCreateResult';
  payload: Pick<State, 'invokeCreateResult'>;
}

interface SaveInvokeUpdateResultAction {
  type: 'saveInvokeUpdateResult';
  payload: Pick<State, 'invokeUpdateResult'>;
}

interface SaveInvokeRenameResultAction {
  type: 'saveInvokeRenameResult';
  payload: Pick<State, 'invokeRenameResult'>;
}

interface SaveInvokeDeleteResultAction {
  type: 'saveInvokeDeleteResult';
  payload: Pick<State, 'invokeDeleteResult'>;
}
interface SaveFetchFivetranConnectionsAction {
  type: 'saveFetchFivetranConnections';
  payload: Pick<State, 'fivetranConnections'>;
}

interface SaveFetchFivetranConnectCardAction {
  type: 'saveFetchFivetranConnectCard';
  payload: Pick<State, 'fivetranConnectCardUrl'>;
}

interface SaveInvokeRecreateFivetranConnectCardResultAction {
  type: 'saveInvokeRecreateFivetranConnectCardResult';
  payload: Pick<State, 'invokeRecreateFivetranConnectCardResult'>;
}

interface SaveInvokeDeployFivetranSourceIngestionsResultAction {
  type: 'saveInvokeDeployFivetranSourceIngestionsResult';
  payload: Pick<State, 'invokeDeployFivetranSourceIngestionsResult'>;
}

interface SaveInvokeDeleteIncompleteFivetranSourceAndConnectionResultAction {
  type: 'saveInvokeDeleteIncompleteFivetranSourceAndConnectionResult';
  payload: Pick<State, 'invokeDeleteIncompleteFivetranSourceAndConnectionResult'>;
}

const ConnectionModel: ReduxSagaModel<
  State,
  {
    saveAll: SaveAllAction;
    saveCurrent: SaveCurrentAction;
    saveInvokeCreateResult: SaveInvokeCreateResultAction;
    saveInvokeUpdateResult: SaveInvokeUpdateResultAction;
    saveInvokeRenameResult: SaveInvokeRenameResultAction;
    saveInvokeDeleteResult: SaveInvokeDeleteResultAction;
    saveFetchFivetranConnections: SaveFetchFivetranConnectionsAction;
    saveFetchFivetranConnectCard: SaveFetchFivetranConnectCardAction;
    saveInvokeRecreateFivetranConnectCardResult: SaveInvokeRecreateFivetranConnectCardResultAction;
    saveInvokeDeployFivetranSourceIngestionsResult: SaveInvokeDeployFivetranSourceIngestionsResultAction;
    saveInvokeDeleteIncompleteFivetranSourceAndConnectionResult: SaveInvokeDeleteIncompleteFivetranSourceAndConnectionResultAction;
  }
> = {
  namespace: 'connections',
  state: initialState,
  effects: {
    *fetch(
      { payload }: { payload: { organizationId: string; workspaceId: string } },
      { call, put },
    ) {
      try {
        const nativeConnections = yield* sgcall(() =>
          Connection.getNativeConnectionsByWorkspace(payload.workspaceId),
        );
        nativeConnections.sort((a, b) => a.integration.name.localeCompare(b.integration.name));
        logger.info({ label: `${ns}.fetch.nativeConnections`, message: nativeConnections });

        const fivetranConnections: Connection[] = (yield* sgcall(() =>
          Connection.getFivetranConnectionsByWorkspace(payload.workspaceId),
        )) as unknown as Connection[];

        const connectionIds: string[] = [
          ...nativeConnections.map((c) => c.id),
          ...fivetranConnections.map((c) => c.id),
        ];

        yield call(
          preloadAndCacheFivetranIconUrlsAndNativeIntegrationNames,
          connectionIds,
          payload.organizationId,
        );
        yield put<SaveAllAction>({
          type: 'saveAll',
          payload: { all: [...nativeConnections, ...fivetranConnections] },
        });
      } catch (e) {
        logger.error({ label: `${ns}.fetch.error`, message: e });
        yield put<SaveAllAction>({ type: 'saveAll', payload: { all: null } });
      }
    },
    *get({ payload }: { payload: { id: string } }, { call, put }) {
      try {
        const connection = yield call(Connection.getConnectionById, payload.id);
        assertIsConnection(connection, 'BEEM220712160558');
        logger.info({ label: `${ns}.get.connection`, message: connection });

        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: connection } });
      } catch (e) {
        logger.error({ label: `${ns}.get.error`, message: e });
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: null } });
      }
    },
    *validateCredentialsAndCreateConnection(
      {
        payload,
      }: {
        payload: { query: A.ValidateIntegrationCredentialsQueryInput; input: InvokeCreateInput };
      },
      { call, put },
    ) {
      const status = yield call(Credentials.validateIntegrationCredentials, payload.query);
      assertIsString(status, 'BEEM211105120622');
      if (status === '200') {
        yield put({ type: 'invokeCreate', payload: payload.input });
      } else {
        yield put({ type: 'invokeCreate', payload: { valid: false } });
      }
    },
    // *invokeCreate({ payload }: { payload: InvokeCreateInput }, { call, put }) {
    //   try {
    //     if (!payload) throw new Error('Cannot create empty credentials');

    //     const credentialsId = yield call(Credentials.create, payload.credentials);
    //     assertIsString(credentialsId, 'BEEM211105120623');
    //     logger.info({ label: `${ns}.invokeCreate.credentialsId`, message: credentialsId });

    //     const { integrationId, organizationId } = payload;
    //     const connectionId = yield call(Connection.create, {
    //       organizationId,
    //       integrationId,
    //       credentialsId,
    //     });
    //     assertIsString(connectionId, 'BEEM211105120624');
    //     logger.info({ label: `${ns}.invokeCreate.connectionId`, message: connectionId });

    //     yield put<SaveInvokeCreateResultAction>({
    //       type: 'saveInvokeCreateResult',
    //       payload: { invokeCreateResult: { success: true, data: null } },
    //     });
    //   } catch (e) {
    //     yield put<SaveInvokeCreateResultAction>({
    //       type: 'saveInvokeCreateResult',
    //       payload: {
    //         invokeCreateResult: {
    //           success: false,
    //           error: handleUserError(e, `${ns}.invokeCreate.error`),
    //         },
    //       },
    //     });
    //   }
    // },
    *invokeUpdate(
      { payload }: { payload: Parameters<typeof Connection.update>[0] },
      { call, put },
    ) {
      try {
        yield call(Connection.update, payload);
        yield put<SaveInvokeUpdateResultAction>({
          type: 'saveInvokeUpdateResult',
          payload: { invokeUpdateResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeUpdateResultAction>({
          type: 'saveInvokeUpdateResult',
          payload: {
            invokeUpdateResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeUpdate.error`),
            },
          },
        });
      }
    },
    *invokeRename({ payload }: { payload: { record: Connection; name: string } }, { call, put }) {
      try {
        const { record, name }: { record: Connection; name: string } = payload;

        yield call(Connection.update, { id: record.id, name });
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: { invokeRenameResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: {
            invokeRenameResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeRename.error`),
            },
          },
        });
      }
    },
    *invokeDelete({ payload }: { payload: { id: string } }, { call, put }) {
      try {
        const sourceId = yield* sgcall(() => Source.getSourceIdByConnectionId(payload.id));
        const source = yield* sgcall(() => Source.getSourceById(sourceId));
        const isCompleted = yield* sgcall(() => source.isFivetranConnectCardCompleted());

        if (isCompleted) {
          yield call(Connection.softDeleteFivetranConnection, payload.id);
        } else {
          yield call(Source.deleteIncompleteFivetranSourceAndConnection, sourceId);
        }
        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: { invokeDeleteResult: { success: true, data: null } },
        });
      } catch (e) {
        yield put<SaveInvokeDeleteResultAction>({
          type: 'saveInvokeDeleteResult',
          payload: {
            invokeDeleteResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeDelete.error`),
            },
          },
        });
      }
    },
    *fetchFivetranConnections(_, { put }) {
      try {
        const fivetranConnections = yield* sgcall(async () => {
          const { response: rstr } = await graphql('fivetranFetchConnections', { query: {} });
          const response = JSON.parse(rstr) as FivetranConnectionResponse<FivetranConnection>;
          return response.data.items;
        });
        yield put<SaveFetchFivetranConnectionsAction>({
          type: 'saveFetchFivetranConnections',
          payload: { fivetranConnections },
        });
      } catch (e) {
        logger.error({ label: `${ns}.fetchFivetranConnections.error`, message: e });
      }
    },
    *fetchFivetranConnectCard({ payload: { fivetranInput, sourceInput } }, { put }) {
      try {
        const organizationId = yield* sgselect(
          (s) => s.organization.currentOrganization,
          'BEEM231109130907',
        );
        const fivetranConnectCardUrl = yield* sgcall(async () => {
          const fivetranConnectionName = fivetranInput.schema;
          const { workspaceId, name, fivetranIntegrationName } = sourceInput;

          const organization = await Organization.getOrganizationById(organizationId);
          let glueDatabaseName = addBackendSuffix(makeNameGlueCompatible(name));
          let cloudResourceId = '';
          let done = false;
          while (!done) {
            cloudResourceId = `${organization.cloudResourceId}.${glueDatabaseName}`;
            // eslint-disable-next-line no-await-in-loop
            done = !(await Source.checkGlueDatabaseNameUsed(cloudResourceId));
            if (!done) {
              const suffix = Date.now().toString(36);
              glueDatabaseName = addBackendSuffix(makeNameGlueCompatible(name) + suffix);
            }
          }

          const { response: rstr } = await graphql('fivetranCreateConnectCard', {
            query: { ...fivetranInput },
          });
          const { data } = JSON.parse(rstr) as FivetranConnectCardResponse;
          const fivetranConnectionId = data.id;
          const fivetranServiceName = data.service;

          // create dynamodb Connection & FivetranConnection
          await graphql('createFivetranConnection', {
            input: {
              id: fivetranConnectionId,
              name: fivetranConnectionName,
              serviceName: fivetranServiceName,
              glueDatabaseName,
              integrationName: fivetranIntegrationName,
            },
          });

          const connectionId = await Connection.create({
            name,
            organizationId,
            workspaceId,
            fivetranConnectionId,
          });

          // create dynamodb Source
          await graphql('createSource', {
            input: {
              name,
              description: fivetranConnectionId,
              connectionId,
              workspaceId,
              cloudResourceId,
            },
          });

          return data.connect_card.uri;
        });

        yield put<SaveFetchFivetranConnectCardAction>({
          type: 'saveFetchFivetranConnectCard',
          payload: { fivetranConnectCardUrl: { success: true, data: fivetranConnectCardUrl } },
        });
      } catch (e) {
        yield put<SaveFetchFivetranConnectCardAction>({
          type: 'saveFetchFivetranConnectCard',
          payload: {
            fivetranConnectCardUrl: {
              success: false,
              error: handleUserError(e, `${ns}.invokeUpdate.error`),
            },
          },
        });
        showNotification({
          type: 'error',
          description: handleUserError(e, `${ns}.fetchFivetranConnectCard.error`).formattedString,
        });
        logger.error({ label: `${ns}.fetchFivetranConnectCard.error`, message: e });
      }
    },
    *invokeRecreateFivetranConnectCard(
      { payload: { source } }: { payload: { source: Source } },
      { put },
    ) {
      try {
        const fivetranConnectCardUrl = yield* sgcall(async () => {
          const fivetranConnectionId = await source.getFivetranConnectionId();
          assertIsDefined(fivetranConnectionId, 'BEEM240221152403');
          const { response: rstr } = await graphql('fivetranRecreateConnectCard', {
            query: {
              connectorId: fivetranConnectionId,
              redirectUrl: `${window.location.origin}/catalog`,
            },
          });
          const { data } = JSON.parse(rstr) as FivetranConnectCardResponse;
          return data.connect_card.uri;
        });

        yield put<SaveInvokeRecreateFivetranConnectCardResultAction>({
          type: 'saveInvokeRecreateFivetranConnectCardResult',
          payload: {
            invokeRecreateFivetranConnectCardResult: {
              success: true,
              data: fivetranConnectCardUrl,
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeRecreateFivetranConnectCardResultAction>({
          type: 'saveInvokeRecreateFivetranConnectCardResult',
          payload: {
            invokeRecreateFivetranConnectCardResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeRecreateFivetranConnectCard.error`),
            },
          },
        });
      }
    },
    *deployFivetranSourceIngestions(
      {
        payload: { fivetranConnectionId },
      }: {
        payload: { fivetranConnectionId: string };
      },
      { put },
    ) {
      try {
        yield* sgcall(() => Connection.deployFivetranSourceIngestions(fivetranConnectionId));
        yield put<SaveInvokeDeployFivetranSourceIngestionsResultAction>({
          type: 'saveInvokeDeployFivetranSourceIngestionsResult',
          payload: {
            invokeDeployFivetranSourceIngestionsResult: {
              success: true,
              data: null,
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeDeployFivetranSourceIngestionsResultAction>({
          type: 'saveInvokeDeployFivetranSourceIngestionsResult',
          payload: {
            invokeDeployFivetranSourceIngestionsResult: {
              success: false,
              error: handleUserError(e, `${ns}.deployFivetranSourceIngestions.error`),
            },
          },
        });
      }
    },
    *invokeDeleteIncompleteFivetranSourceAndConnection(
      { payload: { sourceId } }: { payload: { sourceId: string } },
      { put },
    ) {
      try {
        yield* sgcall(() => Source.deleteIncompleteFivetranSourceAndConnection(sourceId));
        yield put<SaveInvokeDeleteIncompleteFivetranSourceAndConnectionResultAction>({
          type: 'saveInvokeDeleteIncompleteFivetranSourceAndConnectionResult',
          payload: {
            invokeDeleteIncompleteFivetranSourceAndConnectionResult: { success: true, data: null },
          },
        });
      } catch (e) {
        yield put<SaveInvokeDeleteIncompleteFivetranSourceAndConnectionResultAction>({
          type: 'saveInvokeDeleteIncompleteFivetranSourceAndConnectionResult',
          payload: {
            invokeDeleteIncompleteFivetranSourceAndConnectionResult: {
              success: false,
              error: handleUserError(
                e,
                `${ns}.invokeDeleteIncompleteFivetranSourceAndConnection.error`,
              ),
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveAll(state, { payload }) {
      return { ...state, ...payload };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeCreateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeUpdateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRenameResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeleteResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveFetchFivetranConnections(state, { payload }) {
      return { ...state, ...payload };
    },
    saveFetchFivetranConnectCard(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRecreateFivetranConnectCardResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeployFivetranSourceIngestionsResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeDeleteIncompleteFivetranSourceAndConnectionResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default ConnectionModel;
