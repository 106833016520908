export type Filter = {
  column: string;
  operator: string;
  values: unknown[];
  active: boolean;
};

export type Sorting = {
  column: string;
  asc: boolean;
};

export default class DatasetViewState {
  readonly filters: Filter[];

  readonly sortings: Sorting[];

  constructor({
    filters,
    sortings,
    disable = false,
  }: {
    filters: Filter[];
    sortings: Sorting[];
    disable?: boolean;
  }) {
    this.filters = filters;
    this.sortings = sortings;

    if (disable) {
      this.filters = this.filters.map((el) => ({ ...el, active: false }));
    }

    this.isColumnFiltered = this.isColumnFiltered.bind(this);
    this.isColumnSorted = this.isColumnSorted.bind(this);
  }

  isColumnFiltered(column: string): boolean {
    return this.filters.some((el) => el.column === column && el.active);
  }

  isColumnSorted(column: string): boolean {
    return this.sortings.some((el) => el.column === column);
  }
}
