import * as Sentry from '@sentry/react';
import { logger } from '@/utils/logger';

export interface BeemError {
  formattedString: string;
  originalError: any;
}

export function handleError(e: any, label?: string): BeemError {
  let text: string;
  if (e instanceof Error) {
    text = e.message;
  } else if (
    e.data &&
    typeof e.data === 'object' &&
    Array.isArray(e.errors) &&
    e.errors.length === 1
  ) {
    // graphql error with 1 error
    text = e.errors[0].message;
  } else {
    // fallback
    text = JSON.stringify(e);
  }
  if (label) logger.error(text, { label });
  return {
    formattedString: text,
    originalError: e,
  };
}

export function handleUserError(e: any, label: string): BeemError {
  return handleError(e, label);
}

export function handleSystemError(e: any, label: string): BeemError {
  Sentry.captureException(e);
  return handleError(e, label);
}
