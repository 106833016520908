import type * as A from '@/api';
import type { PartialBy, Column, PipelineResult, SqlQueryEtc } from './types';
import { v4 as uuid } from 'uuid';
import { Trigger, PipelineResultStatus, ModelSortDirection } from '@/api';
import { Auth } from '@/aws-config';
import { deleteAllSqlQueriesByDataset } from './common';
import Workspace from './workspace';
import Dataset from './dataset';
import TriggerDetailsService from './triggerDetails';
import * as DatasetCommon from './dataset.common';
import { graphql } from '@/utils/graphql';
import { addBackendSuffix } from '@/utils/namingConvention';
import { logger } from '@/utils/logger';
import { assertIsDefined, assertIsNumber, assertIsString } from '@/utils/typeChecks';

const ns = 'app.services.datasettest';

type ConstructorInput = A.GetDatasetTestQuery['getDatasetTest'] & {
  version?: number;
  beemAgentInfo?: string;
}; // version can be undefined when we want to get the latest version, without knowing that version number

type CreateInput = Omit<
  PartialBy<ConstructorInput, 'id' | 'ownerId' | 'cloudResourceId'>,
  'isDraft'
>;

type UpdateInput = Partial<Omit<ConstructorInput, 'isDraft'>>;

type SaveLocallyInput = { sqlQuery: string };

export default class DatasetTest {
  timestamp?: number;

  readonly id: string;

  readonly datasetId: string;

  readonly ownerId: string;

  readonly version: number;

  readonly isLatest: boolean;

  readonly name: string;

  #isDraft: boolean;
  get isDraft(): boolean {
    return this.#isDraft;
  }
  private set isDraft(i: boolean) {
    this.#isDraft = i;
  }

  readonly workspaceId: string;

  readonly cloudResourceId: string;

  #stats?: {
    lastUpdated: string;
    rowCount: number;
    columns: Column[];
  };

  get lastUpdated(): string | undefined {
    return this.#stats?.lastUpdated;
  }

  get rowCount(): number | undefined {
    return this.#stats?.rowCount;
  }

  get columns() {
    return this.#stats?.columns;
  }

  private constructor(input: ConstructorInput) {
    this.id = input.id;
    this.datasetId = input.datasetId;
    this.ownerId = input.ownerId;
    this.name = input.name;
    this.#isDraft = input.isDraft;
    this.workspaceId = input.workspaceId;
    this.cloudResourceId = input.cloudResourceId;

    if (this.isDraft) {
      this.version = 0;
      this.isLatest = false;
    } else {
      const { cloudResourceInfo, beemAgentInfo } = input;
      assertIsString(cloudResourceInfo, 'BEEM211105120351');
      const cloudResourceInfoObj = JSON.parse(cloudResourceInfo);
      assertIsDefined(cloudResourceInfoObj, 'BEEM211105120352');
      const latestVersion = cloudResourceInfoObj.version;
      assertIsNumber(latestVersion, 'BEEM211105120353');
      this.version = input.version ?? latestVersion;
      this.isLatest = this.version === latestVersion;
      if (this.isLatest) {
        assertIsDefined(cloudResourceInfo, 'BEEM211105120353');
        this.#stats = JSON.parse(cloudResourceInfo);
      } else {
        assertIsDefined(beemAgentInfo, 'BEEM211105120354');
        this.#stats = JSON.parse(beemAgentInfo);
      }
    }

    this.getContentStart = this.getContentStart.bind(this);
    this.getSqlQueryEtcChanges = this.getSqlQueryEtcChanges.bind(this);
    this.getSavedCode = this.getSavedCode.bind(this);
    this.getDeployedCode = this.getDeployedCode.bind(this);
    this.getPipelineResults = this.getPipelineResults.bind(this);
    this.getCurrentPipelineResult = this.getCurrentPipelineResult.bind(this);
    this.listenToPipelineResult = this.listenToPipelineResult.bind(this);
    this.update = this.update.bind(this);
    this.saveLocally = this.saveLocally.bind(this);
    this.deploy = this.deploy.bind(this);
  }

  getContentStart = DatasetCommon.getContentStart;

  async getSqlQueryEtcChanges(limit?: number): Promise<SqlQueryEtc[]> {
    const data = await graphql('sqlQueriesByDataset', {
      datasetId: this.id,
      sortDirection: ModelSortDirection.DESC,
      limit,
    });

    // vinh408 should not be undefined
    const items = data.items.map((el) => ({
      ...el,
      trigger: el.trigger ?? Trigger.MANUAL,
      triggerDetails: el.triggerDetails ?? '{}',
      deployed: el.deployed ?? true,
      updatedAt: el.updatedAt ?? el.createdAt,
    }));

    return items.map((el) => {
      return {
        id: el.id,
        timestamp: Math.floor(Date.parse(el.updatedAt) / 1000),
        sqlQuery: el.sqlQuery,
        trigger: el.trigger,
        triggerDetails: TriggerDetailsService.setDefaults(JSON.parse(el.triggerDetails)),
        deployed: el.deployed,
      };
    });
  }

  async getSavedCode(): Promise<string> {
    const items = await this.getSqlQueryEtcChanges(1);
    if (items.length === 0) return '';
    return items[0].sqlQuery;
  }

  async getDeployedCode(): Promise<string> {
    const items = await this.getSqlQueryEtcChanges(2);
    if (items.length === 0) return '';
    const found = items.find((el) => el.deployed);
    return found ? found.sqlQuery : '';
  }

  async getPipelineResults(timestamp?: number): Promise<PipelineResult[]> {
    if (!this.cloudResourceId) return [];
    const data = await graphql('pipelineResultsByRecordCloudResourceId', {
      recordCloudResourceId: this.cloudResourceId,
      timestamp: timestamp ? { eq: timestamp } : undefined,
      sortDirection: ModelSortDirection.DESC,
    });
    return data.items;
  }

  async getCurrentPipelineResult(): Promise<PipelineResult> {
    const rs = await this.getPipelineResults(this.version);
    if (rs.length !== 1) {
      throw new Error(`BEEM230903022100.2 ${this.id} ${this.version} ${rs.length}`);
    }
    return rs[0];
  }

  listenToPipelineResult = DatasetCommon.listenToPipelineResult;

  // update is only for the app db, for non-remote values
  async update(input: UpdateInput) {
    logger.info({ label: 'app.services.dataset.update.input', message: input });

    await graphql('updateDatasetTest', { input: { ...input, id: this.id } });
    Object.entries(input).forEach(([k, v]) => {
      this[k] = v;
    });
  }

  async saveLocally(input: SaveLocallyInput) {
    logger.info({ label: `${ns}.saveLocally.input`, message: input });
    const { sqlQuery } = input;

    const gqlInput = {
      datasetId: this.id,
      workspaceId: this.workspaceId,
      sqlQuery,
      trigger: Trigger.MANUAL,
      triggerDetails: '{}',
      deployed: false,
    };

    const temp1 = await this.getSqlQueryEtcChanges(1);

    if (temp1.length > 0 && temp1[0].deployed === false) {
      await graphql('updateDatasetSqlQuery', { input: { ...gqlInput, id: temp1[0].id } });
    } else {
      await graphql('createDatasetSqlQuery', { input: gqlInput });
    }
  }

  async deploy(input: SaveLocallyInput) {
    logger.info({ label: `${ns}.deploy.input`, message: input });
    const { sqlQuery } = input;

    let dbSchemaName: string | undefined;
    if (this.isDraft) {
      const dataset = await Dataset.getDatasetById(this.datasetId);
      dbSchemaName = dataset.dbSchemaName;
    }

    // depending on whether save was clicked before deploy, we create or update the sql query etc record
    const gqlInput = {
      datasetId: this.id,
      datasetCloudResourceId: this.cloudResourceId,
      workspaceId: this.workspaceId,
      sqlQuery,
      trigger: Trigger.MANUAL,
      triggerDetails: '{}',
      deployed: true,
      dbSchemaName,
    };
    const temp1 = await this.getSqlQueryEtcChanges(1);
    const id = temp1.length > 0 && temp1[0].deployed === false ? temp1[0].id : undefined;
    await graphql('beemAgentDeployDatasetSqlQuery', { input: { id, ...gqlInput } });

    // change draft status if necessary
    if (this.isDraft) {
      // then update the app db
      const now = Date.now();
      const cloudResourceInfo = JSON.stringify({
        version: Math.floor(now / 1000),
        status: PipelineResultStatus.SUCCESS,
        lastUpdated: new Date(now).toISOString(),
        rowCount: 0,
        columns: [],
      });
      await graphql('updateDatasetTest', {
        input: { id: this.id, isDraft: false, cloudResourceInfo },
      });
      this.isDraft = false;
    }
  }

  // version can be undefined when we want to get the latest version, without knowing that version number
  static async getDatasetTestById(id: string, version?: number): Promise<DatasetTest> {
    logger.info(id, { label: 'app.services.dataset.getDatasetTestById.id' });
    const data = await graphql('getDatasetTest', { id });
    let beemAgentInfo: string | undefined;
    if (version) {
      ({ info: beemAgentInfo } = await graphql('beemAgentGetDatasetInfo', {
        query: { cloudResourceId: data.cloudResourceId, version: String(version) },
      }));
    }
    return new DatasetTest({ ...data, version, beemAgentInfo });
  }

  static async getDatasetTestsByDataset(datasetId: string) {
    logger.info({ label: `${ns}.getDatasetTestsByDataset.datasetId`, message: datasetId });
    const data = await graphql('listDatasetTestsByDataset', { datasetId });
    return data.items.map((el) => new DatasetTest(el));
  }

  static testSqlQuery = DatasetCommon.testSqlQuery;

  static async create(input: CreateInput): Promise<string> {
    logger.info({ label: 'app.services.dataset.create.input', message: input });
    const id = uuid();
    const { workspaceId, datasetId } = input;
    let { ownerId, cloudResourceId } = input;

    if (!cloudResourceId) {
      const workspace = await Workspace.getWorkspaceById(workspaceId);
      cloudResourceId = addBackendSuffix(`${workspace.organization.cloudResourceId}.${id}`);
    }

    if (!ownerId) ownerId = (await Auth.currentAuthenticatedUser()).getUsername();
    assertIsDefined(ownerId, 'BEEM211105120245');

    const { id: testDatasetId } = await graphql('createDatasetTest', {
      input: {
        ...input,
        id,
        ownerId,
        isDraft: true,
        cloudResourceId,
      },
    });

    const dataset = await Dataset.getDatasetById(datasetId);
    await dataset.modifyTests();

    return testDatasetId;
  }

  static async refresh(cloudResourceId: string): Promise<void> {
    logger.info({
      label: 'app.services.dataset.refresh.cloudResourceId',
      message: cloudResourceId,
    });
    await graphql('refreshDataset', { query: { cloudResourceId } });
  }

  static async delete({ id }: { id: string }) {
    logger.info({ label: 'app.services.datasetTest.delete.id', message: id });

    const { datasetId } = await DatasetTest.getDatasetTestById(id);

    await graphql('deleteDatasetTest', { input: { id } });

    await deleteAllSqlQueriesByDataset(id);

    const dataset = await Dataset.getDatasetById(datasetId);
    await dataset.modifyTests();
  }
}
