import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <defs>
      <path
        d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
        id="1o"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#FFF" href="#1o" />
        <use fillOpacity=".1" fill="#32D02F" href="#1o" />
      </g>
      <g stroke="#32D02F" strokeLinecap="round">
        <path strokeLinejoin="round" d="M15.5 16.5l4-4 4 4M12.5 22.682V28.5h15v-5.818" />
        <path d="M19.5 12.5v10.909" />
      </g>
    </g>
  </svg>
);
