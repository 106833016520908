import * as React from 'react';

export default ({ color }) => (
  <svg width="24" height="24" fill="none">
    <path
      d="M12 15l3 3 6-6"
      stroke={color || '#B5BFD2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v3"
      stroke={color || '#B5BFD2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 10h13M8 6v12" stroke={color || '#B5BFD2'} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
