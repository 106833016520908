import * as React from 'react';

export default ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M12 7V17" stroke={color || '#0067F4'} strokeWidth="2" strokeLinecap="round" />
    <path d="M7 12L17 12" stroke={color || '#0067F4'} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
