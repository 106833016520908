import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.728 3.707a1 1 0 0 1 0 1.414l-2.121 2.122 2.828 2.828 2.121-2.121a1 1 0 1 1 1.415 1.414l-2.122 2.121.707.707a1 1 0 0 1 0 1.415l-1.414 1.414a6.002 6.002 0 0 1-7.72.648l-2.18 2.18a1 1 0 0 1-1.413-1.414l2.18-2.18a6.002 6.002 0 0 1 .648-7.72l1.414-1.414a1 1 0 0 1 1.414 0l.708.707 2.12-2.12a1 1 0 0 1 1.415 0zm-5.657 9.9l.002.001a4 4 0 0 0 5.655-.001l.707-.707-.704-.705-.003-.003-.003-.003-4.947-4.946-.707.707a4 4 0 0 0 0 5.657z"
      fill="#0067F4"
    />
  </svg>
);
