import * as React from 'react';

export default ({ fill, stroke }) => (
  <svg width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5h3.25c.966 0 1.75.784 1.75 1.75v10.5A1.75 1.75 0 0 1 17.25 19H14m-4 0H6.75A1.75 1.75 0 0 1 5 17.25V6.75C5 5.784 5.784 5 6.75 5H10"
      fill={fill || '#fff'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 6a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75H10v2H6.75A2.75 2.75 0 0 1 4 17.25V6.75A2.75 2.75 0 0 1 6.75 4H10v2H6.75zm10.5 0H14V4h3.25A2.75 2.75 0 0 1 20 6.75v10.5A2.75 2.75 0 0 1 17.25 20H14v-2h3.25a.75.75 0 0 0 .75-.75V6.75a.75.75 0 0 0-.75-.75z"
      fill={stroke || '#B5BFD2'}
    />
    <path
      d="M9 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V3z"
      fill={fill || '#fff'}
    />
    <path
      d="M9 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V3z"
      fill={stroke || '#B5BFD2'}
      fillOpacity=".4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4v16h2V4h-2zm-1-2a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-4z"
      fill={stroke || '#B5BFD2'}
    />
  </svg>
);
