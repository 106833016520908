export default class DatasetLite {
  readonly id: string;

  readonly name: string;

  constructor(input: { id: string; name: string }) {
    this.id = input.id;
    this.name = input.name;
  }
}
