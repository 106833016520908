import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 12}
      height={props.svgHeight || 16}
      viewBox={props.svgViewBox || '0 0 12 16'}
      style={{ verticalAlign: 'bottom' }}
    >
      <path
        d="M2.573 2.101l7.257 5.08a1 1 0 010 1.638L2.573 13.9A1 1 0 011 13.079V2.921a1 1 0 011.573-.82z"
        stroke="#0067F4"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};
