export default {
  "trigger": {
    "selectTriggerType": "Trigger type",
    "name": {
      "MANUAL": "Manual Trigger",
      "SCHEDULED": "Scheduled Trigger",
      "CRON": "Cron Expression"
    },

    "recurrence": "Recurrence",
    "occurrences": {
      "daily": "Every day",
      "weekly": "Every week",
      "monthly": "Every month"
    },
    "weeklyChoices": {
      "?": "Select Day",
      "sun": "Sunday",
      "mon": "Monday",
      "tue": "Tuesday",
      "wed": "Wednesday",
      "thu": "Thursday",
      "fri": "Friday",
      "sat": "Saturday"
    },
    "monthlyChoices": {
      "1": "1st",
      "2": "2nd",
      "3": "3rd",
      "4": "4th",
      "5": "5th",
      "6": "6th",
      "7": "7th",
      "8": "8th",
      "9": "9th",
      "10": "10th",
      "11": "11th",
      "12": "12th",
      "13": "13th",
      "14": "14th",
      "15": "15th",
      "16": "16th",
      "17": "17th",
      "18": "18th",
      "19": "19th",
      "20": "20th",
      "21": "21st",
      "22": "22nd",
      "23": "23rd",
      "24": "24th",
      "25": "25th",
      "26": "26th",
      "27": "27th",
      "28": "28th",
      "29": "29th",
      "30": "30th",
      "31": "31st"
    },

    "cronExpressionInput": "Expression",
    "cronExpressionTooltip": "Cron Expression uses {timeZoneDesc} time zone.",
    "cronExpressionDocsLink": "Cron Expression Docs",
    "cronExpressionSummary": "Summary",

    "summary": {
      "invalid": "This Cron Expression is invalid. Please enter a valid value.",
      "describable": {
        "dataset": "will be updated automatically {description}",
        "flow": "will be triggered automatically {description}"
      },
      "fallback": {
        "dataset": "will be updated automatically based on the custom cron expression above",
        "flow": "will be triggered automatically based on the custom cron expression above"
      },
      "next_run": {
        "dataset": "The next update will be on {dateTime}",
        "flow": "The next run will be on {dateTime}"
      },
      "next_run_moment_format": "dddd DD MMM, YYYY [@] hh:mm A"
    }
  }
};
