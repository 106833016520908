import type { Task } from 'redux-saga';
import type { Effects, ReduxSageEffectReverse } from '../types';

const spawnEffects: Record<string, Task | null> = {};

export function* spawnTaskToWatchForLastAction(
  actionType: string,
  handler: ReduxSageEffectReverse,
  effects: Effects,
) {
  if (!spawnEffects[actionType]) {
    const watchLast = function* watchLast() {
      yield effects.takeLatest(actionType, handler, effects);
    };
    spawnEffects[actionType] = (yield effects.spawn(watchLast)) as Task;
  }
}
