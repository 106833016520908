import { THEME } from '@/theme';
import * as React from 'react';

const defaultSize = 24;

export default ({
  height = defaultSize,
  width = defaultSize,
  color = THEME.colors.defaultGray,
}: {
  height?: number;
  width?: number;
  color?: string;
}) => (
  <svg
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${defaultSize} ${defaultSize}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 17.5V7.1l.5-.4c.5-.3 1.4-.7 3-.7s2.5.4 3 .7l.5.4v10.4a14.6 14.6 0 0 0-7 0Zm8-12.2.4-.3c.9-.5 2.2-1 4.1-1 2 0 3.2.5 4.1 1a4.5 4.5 0 0 1 1.3 1.2l-.9.5.9-.4.1.5V19a1 1 0 0 1-1.6.8l-.6-.3a11 11 0 0 0-3.3-.4 11 11 0 0 0-3.8.6 1 1 0 0 1-1.3 0c-.2 0-.4 0-.6-.2a11 11 0 0 0-3.3-.4 11 11 0 0 0-3.8.6A1 1 0 0 1 2 19V6.7l.1-.4.9.5a65 65 0 0 1-.9-.5 1.7 1.7 0 0 1 .1-.2l.3-.3.9-.8c.9-.5 2.2-1 4.1-1A7.5 7.5 0 0 1 12 5.3Zm1 12.2a14.6 14.6 0 0 1 7 0V7.1l-.5-.4c-.5-.3-1.4-.7-3-.7s-2.5.4-3 .7l-.5.4v10.4Z"
      fill={color}
    />
  </svg>
);
