import flatten from 'flat';
import flowList from './en-US/flowList';
import upcomingFlows from './en-US/upcomingFlows';
import flowContentView from './en-US/flowContentView';

export default flatten({
  ...flowList,
  ...upcomingFlows,
  ...flowContentView,
});
