import * as React from 'react';

export default () => {
  return (
    <svg width="40" height="40">
      <defs>
        <path d="M0 0v12a2 2 0 002 2h8a2 2 0 002-2V0H0z" id="delete-a" />
        <path id="delete-b" d="M5.429 2.5v-2h6v2" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          fill="#F24949"
          opacity=".1"
        />
        <g transform="translate(14 14)">
          <use fill="#FFF" href="#delete-a" />
          <path
            stroke="#F02929"
            d="M11.5.5V12c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0-8c-.414 0-.79-.168-1.06-.44A1.495 1.495 0 01.5 12h0V.5h11z"
            strokeLinejoin="square"
            fillOpacity=".2"
            fill="#F02929"
          />
        </g>
        <path stroke="#F02929" strokeLinecap="round" d="M18 18v6M22 18v6" />
        <g strokeLinecap="round">
          <g strokeLinejoin="round" transform="translate(11.571 12)">
            <use fill="#FFF" href="#delete-b" />
            <use stroke="#F02929" fillOpacity=".2" fill="#F02929" href="#delete-b" />
          </g>
          <path stroke="#F02929" d="M12 14.5h16" />
        </g>
      </g>
    </svg>
  );
};
