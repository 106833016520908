import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <defs>
      <path
        d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
        id="qb"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#FFF" href="#qb" />
        <use fillOpacity=".1" fill="#32D02F" href="#qb" />
      </g>
      <g transform="translate(11 9)" stroke="#32D02F">
        <path
          d="M7.172.5H2C1.586.5 1.21.668.94.94.667 1.21.5 1.585.5 2v10c0 .414.168.79.44 1.06.27.272.646.44 1.06.44h14c.414 0 .79-.168 1.06-.44.272-.27.44-.646.44-1.06V4.058a1.495 1.495 0 00-1.486-1.5l-5.61-.054a1.5 1.5 0 01-1.046-.44L8.232.94A1.5 1.5 0 007.172.5z"
          fillOpacity=".201"
          fill="#32D02F"
        />
        <path d="M9 13v4" />
        <rect fillOpacity=".2" fill="#32D02F" x="6.5" y="16.5" width="5" height="5" rx="1" />
        <path strokeLinecap="round" d="M1 19h5.442M12 19h5" />
      </g>
    </g>
  </svg>
);
