import type { Query, Mutation, Subscription, Variables, Result } from '@/api';
import type { GraphQLResult } from '@aws-amplify/api-graphql';
import type Observable from 'zen-observable-ts';
import type { ZenObservable } from 'zen-observable-ts';
import * as tc from '@/utils/typeChecks';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import * as subscriptions from '@/graphql/subscriptions';
import { logger } from './logger';
import Organization from '@/services/organization';
import Workspace from '@/services/workspace';
import { getReduxState } from './reduxSaga';
import { addBackendSuffix } from './namingConvention';

type Context = { orgId: string; wsSeq: string };

const context = { currentOrganization: '', currentWorkspace: '', orgId: '', wsSeq: '' };

export async function getContext(
  currentOrganization: string,
  currentWorkspace: string,
): Promise<Context> {
  if (currentOrganization !== context.currentOrganization) {
    const org = await Organization.getOrganizationById(currentOrganization);
    context.currentOrganization = currentOrganization;
    context.orgId = org.cloudResourceId;
  }
  if (currentWorkspace !== context.currentWorkspace) {
    const ws = await Workspace.getWorkspaceById(currentWorkspace);
    context.currentWorkspace = currentWorkspace;
    context.wsSeq = addBackendSuffix(String(ws.seq));
  }
  return { orgId: context.orgId, wsSeq: context.wsSeq };
}

export async function graphql<T extends Query | Mutation>(
  command: T,
  variables: Variables<T>,
  rnt = false, // temporary "return-next-token" to not follow the nextToken below
): Promise<Result<T>> {
  const query = queries[command as string] ?? mutations[command as string];
  try {
    if (command.startsWith('beemAgent')) {
      // @ts-expect-error
      const temp = variables.query ?? variables.input;
      if (temp) {
        const { currentOrganization, currentWorkspace } = getReduxState().organization;
        tc.assertIsString(currentOrganization, 'BEEM240128174601');
        tc.assertIsString(currentWorkspace, 'BEEM240128174602');
        const ctx = await getContext(currentOrganization, currentWorkspace);
        temp.orgId = ctx.orgId;
        temp.wsSeq = ctx.wsSeq;
      }
    }
    const rs = (await API.graphql(graphqlOperation(query, variables))) as GraphQLResult<
      Record<string, Result<T>>
    >;
    tc.assertIsDefined(rs.data, 'BEEM211105120248');
    const pos = command.indexOf('Only');
    // return rs.data[pos > 0 ? command.substring(0, pos) : command];

    // this code is kind of temporary because we will want to implement pagination
    // instead of just plainly following the nextToken and grab all the records,
    // which potentially can result in a lot of requests for 1000+ records
    // that's why I didn't spend time to polish the tsc errors
    const rs1 = rs.data[pos > 0 ? command.substring(0, pos) : command];
    // @ts-expect-error
    if (!rnt && rs1?.nextToken) {
      // @ts-expect-error
      if (variables.limit && rs1.items.length >= variables.limit) return rs1;
      // @ts-expect-error
      const { items, nextToken } = rs1;
      // @ts-expect-error
      const { items: moreItems, nextToken: moreNextToken } = await graphql(command, {
        ...variables,
        nextToken,
      });
      // @ts-expect-error
      return { items: items.concat(moreItems), nextToken: moreNextToken };
    }
    return rs1;
  } catch (e) {
    logger.debug({ label: 'graphql error', message: e });
    throw e;
  }
}

export function subscribe<T extends Subscription>(
  name: T,
  variables: Variables<T>,
  handler: (result: Result<T>, done: () => void) => void,
): ZenObservable.Subscription {
  const rs = API.graphql(graphqlOperation(subscriptions[name], variables)) as Observable<
    Record<string, unknown>
  >;
  const subscription = rs.subscribe({
    next: (event: { value: { data: T } }) => {
      tc.assertIsDefined(event.value.data, 'BEEM211105120249');
      handler(event.value.data[name as string], () => {
        subscription.unsubscribe();
      });
    },
  });
  return subscription;
}

const findMatchingClosingBracket = (text: string, openBracketIndex: number): number => {
  const nextClosingBracketIndex = text.indexOf('}', openBracketIndex);
  const nextOpenBracketIndex = text.indexOf('{', openBracketIndex + 1);
  if (nextOpenBracketIndex > openBracketIndex && nextOpenBracketIndex < nextClosingBracketIndex)
    return text.indexOf('}', findMatchingClosingBracket(text, nextOpenBracketIndex) + 1);
  return nextClosingBracketIndex;
};

export const removeSubObjectFromQuery = (query: string, subObjectName: string): string => {
  const start = query.indexOf(`${subObjectName} {`);
  const openBracPos = query.indexOf('{', start);
  const closeBracPos = findMatchingClosingBracket(query, openBracPos);
  return query.substring(0, start) + query.substring(closeBracPos + 1);
};
