import flatten from 'flat';
import integration from './fr-FR/integration';
import viewIntegration from './fr-FR/viewIntegration';
import addIntegration from './fr-FR/addIntegration';
import connection from './fr-FR/connection';

export default flatten({
  ...integration,
  ...viewIntegration,
  ...addIntegration,
  ...connection,
});
