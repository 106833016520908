import * as React from 'react';

export default () => (
  <svg width="40" height="40">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
          id="a"
          fill="#FFF"
        />
        <path
          d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
          id="a"
          fillOpacity=".2"
          fill="#F02929"
        />
      </g>
      <g transform="translate(11 11)">
        <path id="b" d="M12.79 0L18 5.21v7.58L12.79 18H5.21L0 12.79V5.21L5.21 0z" fill="#FFF" />
        <path
          id="b"
          d="M12.79 0L18 5.21v7.58L12.79 18H5.21L0 12.79V5.21L5.21 0z"
          stroke="#F02929"
          fillOpacity=".2"
          fill="#F02929"
        />
      </g>
      <path
        stroke="#F02929"
        strokeLinecap="round"
        d="M23.316 16.684l-6.632 6.632M16.684 16.684l6.632 6.632"
      />
    </g>
  </svg>
);
