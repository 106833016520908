export default {
  "flowContentView": {
    "edit": "Edit Flow",
    "cancel": "Cancel",
    "save": "Save changes",
    "status": "Status",
    "chooseTrigger": "Choose trigger",
    "createdAtMomentFormat": "[Created] MMM DD, YYYY [at] hh:mm A",
    "nextRunMomentFormat": "[Next run] MMM DD YYYY [at] hh:mm A",
    "nextRunNullMessage": "Next run not set yet",
    "result": "Latest Results",
    "lastUpdatedMomentFormat": "MMM DD, YYYY [at] hh:mm A",
    "lastRunMomentFormat": "[Last run] MMM DD, YYYY [at] hh:mm A",
    "lastRunNullMessage": "This flow has not run yet",
    "integration": "Update list",
    "customizeAction": "Customize Action",
    "customCode": "Custom Code",
    "customCodeDescription": "This custom script is executed on the event trigger {trigger}.",
    "open": "Open",
    "modalEditor": {
      "title": "Custom Code Editor",
      "discard": "Discard",
      "save": "Save"
    }
  }
};
