import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox={viewBox} style={style}>
    <g>
      <path
        fill="#089949"
        d="M18.184 24.914c-.305 0-.618-.062-.914-.191l-6.34-2.828a2.25 2.25 0 01-1.14-2.97l2.827-6.34a2.25 2.25 0 012.969-1.14l6.34 2.825a2.252 2.252 0 011.14 2.968l-2.824 6.34a2.248 2.248 0 01-2.058 1.336zm-.387-1.383a.956.956 0 001.258-.476l2.824-6.34a.955.955 0 00-.48-1.258l-6.344-2.824a.952.952 0 00-1.254.48l-2.824 6.34a.953.953 0 00.476 1.254zm0 0"
      ></path>
      <path
        fill="#F9B21D"
        d="M38.07 24.918h-6.945a2.253 2.253 0 01-2.25-2.25v-6.941a2.256 2.256 0 012.25-2.25h6.945a2.256 2.256 0 012.25 2.25v6.941a2.253 2.253 0 01-2.25 2.25zm-6.945-10.14c-.52 0-.95.425-.95.949v6.941c0 .523.43.95.95.95h6.945c.52 0 .95-.427.95-.95v-6.941c0-.524-.43-.95-.95-.95zm0 0"
      ></path>
      <path
        fill="#E42527"
        d="M12.074 17l-.937 2.09-.035.062.363 2.254a.95.95 0 01-.781 1.09l-6.856 1.11a.96.96 0 01-.71-.169.928.928 0 01-.38-.617l-1.11-6.855a.936.936 0 01.785-1.09l6.853-1.11a.954.954 0 011.09.79l.37 2.265.965-2.168-.05-.304a2.257 2.257 0 00-2.579-1.864l-6.855 1.11a2.22 2.22 0 00-1.46.898 2.235 2.235 0 00-.4 1.68l1.11 6.851c.098.594.418 1.114.902 1.47.387.28.844.421 1.313.421.117 0 .242-.008.363-.027l6.856-1.11a2.257 2.257 0 001.863-2.578zm0 0"
      ></path>
      <path
        fill="#226DB4"
        d="M20.293 20.273l1.008-2.253-.285-2.098a.947.947 0 01.183-.703.95.95 0 01.633-.364l6.879-.933a.957.957 0 01.703.184l.086.078c.309-.32.707-.551 1.156-.653a2.221 2.221 0 00-.457-.46 2.254 2.254 0 00-1.664-.442l-6.887.937a2.203 2.203 0 00-1.484.868 2.249 2.249 0 00-.441 1.664zm0 0M32 21.434l-.906-6.657a.948.948 0 00-.914.946v1.953l.535 3.93c.035.25-.031.5-.188.703a.918.918 0 01-.629.363l-6.878.937a.936.936 0 01-.7-.187.946.946 0 01-.367-.629l-.316-2.336-1.004 2.254.035.254a2.22 2.22 0 00.867 1.488c.395.3.867.457 1.36.457.101 0 .207-.008.308-.02l6.871-.925a2.218 2.218 0 001.485-.867c.363-.48.52-1.07.441-1.664zm0 0"
      ></path>
      <path d="M12.59 28.21l.984-1.448h-.808a.078.078 0 01-.079-.078v-.196c0-.043.036-.078.079-.078h1.328c.043 0 .078.035.078.078v.075a.104.104 0 01-.012.046l-.965 1.45h.867c.043 0 .079.035.079.078v.195a.078.078 0 01-.079.078H12.66a.08.08 0 01-.082-.078v-.07a.066.066 0 01.012-.051zm0 0M16.727 27.395c0-.594.437-1.02 1.03-1.02.614 0 1.032.418 1.032 1.023 0 .618-.426 1.04-1.039 1.04-.617 0-1.023-.426-1.023-1.043zm1.59.007c0-.359-.176-.668-.567-.668-.395 0-.547.32-.547.684 0 .348.188.664.567.664.394-.004.546-.34.546-.68zm0 0M21.605 26.406h.293c.043 0 .079.035.079.078v.739h.832v-.739c0-.043.039-.078.082-.078h.293c.043 0 .078.035.078.078v1.844a.078.078 0 01-.078.078h-.29c-.042 0-.081-.035-.081-.078v-.746h-.833v.746a.078.078 0 01-.078.078h-.293a.078.078 0 01-.078-.078v-1.844a.073.073 0 01.074-.078zm0 0M26 27.395c0-.594.438-1.02 1.031-1.02.614 0 1.028.418 1.028 1.023 0 .618-.422 1.04-1.036 1.04-.62 0-1.023-.426-1.023-1.043zm1.586.007c0-.359-.176-.668-.566-.668-.399 0-.547.32-.547.684 0 .348.183.664.566.664.39-.004.547-.34.547-.68zm0 0"></path>
    </g>
  </svg>
);
