import type * as A from '@/api';
import type { InvokeResult, ReduxSagaModel } from './types';
import Credentials from '@/services/credentials';
import { sgcall } from '@/utils/reduxSaga';
import { logger } from '@/utils/logger';
import { handleUserError } from '@/utils/handleError';

const ns = 'app.models.credentials';

export type State = {
  invokeCreateResult: InvokeResult<{ createdId: string }> | null;
  invokeUpdateResult: InvokeResult<null> | null;
};

const initialState: State = {
  invokeCreateResult: null,
  invokeUpdateResult: null,
};

interface InvokeCreateResultAction {
  type: 'invokeCreateResult';
  payload: Pick<State, 'invokeCreateResult'>;
}

interface InvokeUpdateResultAction {
  type: 'invokeUpdateResult';
  payload: Pick<State, 'invokeUpdateResult'>;
}

const CredentialsModel: ReduxSagaModel<
  State,
  {
    invokeCreateResult: InvokeCreateResultAction;
    invokeUpdateResult: InvokeUpdateResultAction;
  }
> = {
  namespace: 'credentials',
  state: initialState,
  effects: {
    *invokeCreate({ payload }: { payload: Parameters<typeof Credentials.create>[0] }, { put }) {
      try {
        const createdId = yield* sgcall(() => Credentials.create(payload));
        logger.info({ label: `${ns}.invokeCreate`, message: createdId });

        yield put<InvokeCreateResultAction>({
          type: 'invokeCreateResult',
          payload: { invokeCreateResult: { success: true, data: { createdId } } },
        });
      } catch (e) {
        yield put<InvokeCreateResultAction>({
          type: 'invokeCreateResult',
          payload: {
            invokeCreateResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeCreate.error`),
            },
          },
        });
      }
    },
    *invokeUpdate(
      {
        payload,
      }: {
        payload: {
          input: Parameters<typeof Credentials.update>[0];
          query: A.ValidateIntegrationCredentialsQueryInput;
        };
      },
      { put },
    ) {
      try {
        const status = yield* sgcall(() =>
          Credentials.validateIntegrationCredentials(payload.query),
        );

        if (status === '200') {
          yield* sgcall(() => Credentials.update(payload.input));

          yield put<InvokeUpdateResultAction>({
            type: 'invokeUpdateResult',
            payload: { invokeUpdateResult: { success: true, data: null } },
          });
        } else {
          throw new Error('You must provide valid credentials');
        }
      } catch (e) {
        yield put<InvokeUpdateResultAction>({
          type: 'invokeUpdateResult',
          payload: {
            invokeUpdateResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeUpdate.error`),
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    invokeCreateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    invokeUpdateResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default CredentialsModel;
