import * as React from 'react';

export default (props) => (
  <svg width="16" height="18">
    <g
      transform="translate(1 1)"
      stroke={props.color || '#0A132B'}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      opacity={props.color ? '1' : '.6'}
    >
      <rect x="3.875" width="6" height="6" rx="3" />
      <path d="M3.5 9h7a3.5 3.5 0 0 1 3.5 3.5V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-1.5A3.5 3.5 0 0 1 3.5 9z" />
    </g>
  </svg>
);
