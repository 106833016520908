export default {
  "hub": {
    "title": "Warehouse",
    "foldersSubtitle": "Folders",
    "datasetsSubtitle": "Datasets",
    "folder_select_input_placeholder": "Please select",
    "shared_datasets": "Shared Datasets",
    "shared_by": "Shared by",
    "shared_all": "Shared Datasets"
  }
};
