import * as React from 'react';

export default () => (
  <svg width="40" height="40" fill="none">
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M1.167 5.804A5.479 5.479 0 0 1 5.8 1.148C9.297.63 14.648 0 20 0c5.334 0 10.667.626 14.165 1.143 2.434.36 4.306 2.263 4.654 4.699C39.343 9.507 40 15.082 40 20c0 4.918-.657 10.493-1.181 14.158-.348 2.436-2.22 4.339-4.654 4.699C30.667 39.374 25.334 40 20 40c-5.352 0-10.703-.63-14.2-1.148a5.479 5.479 0 0 1-4.632-4.656C.646 30.616 0 25.146 0 20 0 14.853.646 9.384 1.167 5.804z"
        fill="#fff"
      />
      <path
        d="M1.167 5.804A5.479 5.479 0 0 1 5.8 1.148C9.297.63 14.648 0 20 0c5.334 0 10.667.626 14.165 1.143 2.434.36 4.306 2.263 4.654 4.699C39.343 9.507 40 15.082 40 20c0 4.918-.657 10.493-1.181 14.158-.348 2.436-2.22 4.339-4.654 4.699C30.667 39.374 25.334 40 20 40c-5.352 0-10.703-.63-14.2-1.148a5.479 5.479 0 0 1-4.632-4.656C.646 30.616 0 25.146 0 20 0 14.853.646 9.384 1.167 5.804z"
        fill="#0067F4"
        fillOpacity=".2"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.728 11.707a1 1 0 0 1 0 1.414l-2.121 2.122 2.828 2.828 2.121-2.121a1 1 0 0 1 1.415 1.414l-2.122 2.121.707.707a1 1 0 0 1 0 1.415l-1.414 1.414a6.002 6.002 0 0 1-7.72.648l-2.18 2.18a1 1 0 0 1-1.414-1.414l2.18-2.18a6.002 6.002 0 0 1 .649-7.72l1.414-1.414a1 1 0 0 1 1.414 0l.707.707 2.122-2.12a1 1 0 0 1 1.414 0zm-5.657 9.9l.002.001a4 4 0 0 0 5.655-.001l.707-.707-.704-.704-.003-.004-.003-.003-4.947-4.946-.707.707a4 4 0 0 0 0 5.657z"
      fill="#0067F4"
    />
  </svg>
);
