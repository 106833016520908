export default {
  "tableContentView": {
    "sorting_popover": {
      "apply_button": "Apply Sorting",
      "clear_button": "Clear"
    },
    "filter_popover": {
      "apply_button": "Apply Filter",
      "clear_all_filters": "Clear All Filters",
      "new_filter": "New filter",
      "empty": "Empty"
    },
    "no_results": {
      "nothing": "Nothing to see here...",
      "current_filters": "Your current filters produced no results.",
      "update": "Try updating or removing your filters."
    }
  }
};
