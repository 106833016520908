import type { InvokeResult, ReduxSagaModel } from './types';
import Workspace from '@/services/workspace';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';
import { logger } from '@/utils/logger';
import { assertIsString, assertIsUser, assertIsWorkspace } from '@/utils/typeChecks';
import User from '@/services/user';

const ns = 'app.models.workspaces';

export interface State {
  current: Workspace | null;
  invokeCreateResult: InvokeResult<string> | null;
  invokeRenameResult: InvokeResult<null> | null;
  invokeAddMemberResult: InvokeResult<null> | null;
  invokeRemoveMemberResult: InvokeResult<null> | null;
}

const initialState = {
  current: null,
  invokeCreateResult: null,
  invokeRenameResult: null,
  invokeAddMemberResult: null,
  invokeRemoveMemberResult: null,
};

interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveInvokeCreateResultAction {
  type: 'saveInvokeCreateResult';
  payload: Pick<State, 'invokeCreateResult'>;
}

interface SaveInvokeRenameResultAction {
  type: 'saveInvokeRenameResult';
  payload: Pick<State, 'invokeRenameResult'>;
}

interface SaveInvokeAddMemberResultAction {
  type: 'saveInvokeAddMemberResult';
  payload: Pick<State, 'invokeAddMemberResult'>;
}

interface SaveInvokeRemoveMemberResultAction {
  type: 'saveInvokeRemoveMemberResult';
  payload: Pick<State, 'invokeRemoveMemberResult'>;
}

const WorkspaceModel: ReduxSagaModel<
  State,
  {
    saveCurrent: SaveCurrentAction;
    saveInvokeCreateResult: SaveInvokeCreateResultAction;
    saveInvokeRenameResult: SaveInvokeRenameResultAction;
    saveInvokeAddMemberResult: SaveInvokeAddMemberResultAction;
    saveInvokeRemoveMemberResult: SaveInvokeRemoveMemberResultAction;
  }
> = {
  namespace: 'workspaces',
  state: initialState,
  effects: {
    *get({ payload }: { payload?: { id: string } }, { call, put, select }) {
      try {
        let workspaceId: string;
        if (payload) workspaceId = payload.id;
        else {
          const temp = yield select((s: any) => s.organization.currentWorkspace);
          assertIsString(temp, 'BEEM211105120630');
          workspaceId = temp;
        }

        const workspace = yield call(Workspace.getWorkspaceById, workspaceId);
        assertIsWorkspace(workspace, 'BEEM220712161100');
        logger.debug({ label: 'app.models.workspace.get.workspace', message: workspace });

        yield put<SaveCurrentAction>({
          type: 'saveCurrent',
          payload: { current: workspace },
        });
      } catch (e) {
        handleUserError(e, `${ns}.get.error`);
        yield put<SaveCurrentAction>({
          type: 'saveCurrent',
          payload: { current: null },
        });
      }
    },
    *invokeCreate({ payload }: { payload: { name: string } }, { put, select }) {
      try {
        const organizationId = yield select((s: any) => s.organization.currentOrganization);
        assertIsString(organizationId, 'BEEM211105120631');
        const currentUser = yield select((s: any) => s.user.currentUser);
        assertIsUser(currentUser, 'BEEM220712142308');
        const workspaceId = yield* sgcall(() =>
          Workspace.create(
            { input: { name: payload.name, organizationId, seq: 0 } },
            { userIds: [currentUser.id] },
          ),
        );
        yield put<SaveInvokeCreateResultAction>({
          type: 'saveInvokeCreateResult',
          payload: {
            invokeCreateResult: {
              success: true,
              data: workspaceId,
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeCreateResultAction>({
          type: 'saveInvokeCreateResult',
          payload: {
            invokeCreateResult: {
              success: false,
              error: handleUserError(e, 'app.models.workspaces.invokeCreate.error'),
            },
          },
        });
      }
    },
    *invokeRename({ payload }: { payload: { record: Workspace; name: string } }, { call, put }) {
      try {
        yield call(payload.record.update, { name: payload.name });
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: {
            invokeRenameResult: {
              success: true,
              data: null,
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeRenameResultAction>({
          type: 'saveInvokeRenameResult',
          payload: {
            invokeRenameResult: {
              success: false,
              error: handleUserError(e, 'app.models.workspaces.invokeRename.error'),
            },
          },
        });
      }
    },
    *invokeAddMember(
      { payload }: { payload: { memberIds: string[]; emailList: string[] } },
      { put },
    ) {
      try {
        const workspace = yield* sgselect((s) => s.workspaces.current, 'BEEM220712161101');
        if (payload?.memberIds?.length) {
          yield* sgcall(() => workspace.addMembers(payload.memberIds));
        }
        if (payload?.emailList?.length) {
          const createUsersPromises = payload.emailList.map((email: string) =>
            User.createIfNotExist(email, workspace),
          );

          yield* sgcall(() => Promise.all(createUsersPromises));
        }
        yield put<SaveInvokeAddMemberResultAction>({
          type: 'saveInvokeAddMemberResult',
          payload: {
            invokeAddMemberResult: {
              success: true,
              data: null,
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeAddMemberResultAction>({
          type: 'saveInvokeAddMemberResult',
          payload: {
            invokeAddMemberResult: {
              success: false,
              error: handleUserError(e, 'app.models.workspaces.invokeAddMember.error'),
            },
          },
        });
      }
    },
    *invokeRemoveMember({ payload }: { payload: { id: string } }, { call, put, select }) {
      try {
        const workspace = yield select((s: any) => s.workspaces.current);
        assertIsWorkspace(workspace, 'BEEM220712161102');
        yield call(workspace.removeMembers, [payload.id]);
        yield put<SaveInvokeRemoveMemberResultAction>({
          type: 'saveInvokeRemoveMemberResult',
          payload: {
            invokeRemoveMemberResult: {
              success: true,
              data: null,
            },
          },
        });
      } catch (e) {
        yield put<SaveInvokeRemoveMemberResultAction>({
          type: 'saveInvokeRemoveMemberResult',
          payload: {
            invokeRemoveMemberResult: {
              success: false,
              error: handleUserError(e, 'app.models.workspaces.invokeRemoveMember.error'),
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeCreateResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRenameResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeAddMemberResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeRemoveMemberResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default WorkspaceModel;
