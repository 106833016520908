export default {
  "dpu": {
    "Usage": "Usage",
    "dpuUsage": "{dpuAmount} DPUs",
    "dpuLimit": "on {limit}",
    "dpuReset": "Resets in {daysLeft} days",
    "dpuNextBilling": "Resets next billing cycle",
    "dpuError": "Unable to calculate usage.",
    "dpuSupport": "Please contact our support team"
  }
};
