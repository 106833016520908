import Connection from '@/services/connection';
import ConnectionLite from '@/services/connectionLite';
import DataObject from '@/services/dataObject';
import Dataset from '@/services/dataset';
import DatasetLite from '@/services/datasetLite';
import Flow from '@/services/flow';
import Folder from '@/services/folder';
import Organization from '@/services/organization';
import Source from '@/services/source';
import User from '@/services/user';
import Workspace from '@/services/workspace';
import DatasetTest from '@/services/datasetTest';

export function assertIsDefined<T>(val: T, label: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) throw new Error(`assertIsDefined ${val} [${label}]`);
}

export function assertIsString(val: unknown, label: string): asserts val is string {
  if (typeof val !== 'string') throw new Error(`assertIsString ${val} [${label}]`);
}

export function assertIsNumber(val: unknown, label: string): asserts val is number {
  if (typeof val !== 'number') throw new Error(`assertIsNumber ${val} [${label}]`);
}

export function assertIsConnection(val: unknown, label: string): asserts val is Connection {
  if (!(val instanceof Connection)) throw new Error(`assertIsConnection ${val} [${label}]`);
}

export function assertIsConnectionLite(val: unknown, label: string): asserts val is ConnectionLite {
  if (!(val instanceof ConnectionLite)) throw new Error(`assertIsConnectionLite ${val} [${label}]`);
}

export function assertIsDataObject(val: unknown, label: string): asserts val is DataObject {
  if (!(val instanceof DataObject)) throw new Error(`assertIsDataObject ${val} [${label}]`);
}

export function assertIsDataset(val: unknown, label: string): asserts val is Dataset {
  if (!(val instanceof Dataset)) throw new Error(`assertIsDataset ${val} [${label}]`);
}

export function assertIsDatasetLite(val: unknown, label: string): asserts val is DatasetLite {
  if (!(val instanceof DatasetLite)) throw new Error(`assertIsDatasetLite ${val} [${label}]`);
}

export function assertIsFlow(val: unknown, label: string): asserts val is Flow {
  if (!(val instanceof Flow)) throw new Error(`assertIsFlow ${val} [${label}]`);
}

export function assertIsFolder(val: unknown, label: string): asserts val is Folder {
  if (!(val instanceof Folder)) throw new Error(`assertIsFolder ${val} [${label}]`);
}

export function assertIsOrganization(val: unknown, label: string): asserts val is Organization {
  if (!(val instanceof Organization)) throw new Error(`assertIsOrganization ${val} [${label}]`);
}

export function assertIsSource(val: unknown, label: string): asserts val is Source {
  if (!(val instanceof Source)) throw new Error(`assertIsSource ${val} [${label}]`);
}

export function assertIsUser(val: unknown, label: string): asserts val is User {
  if (!(val instanceof User)) throw new Error(`assertIsUser ${val} [${label}]`);
}

export function assertIsWorkspace(val: unknown, label: string): asserts val is Workspace {
  if (!(val instanceof Workspace)) throw new Error(`assertIsWorkspace ${val} [${label}]`);
}

export function assertIsDatasetTest(val: unknown, label: string): asserts val is DatasetTest {
  if (!(val instanceof DatasetTest)) throw new Error(`assertIsWorkspace ${val} [${label}]`);
}

type A =
  | 'string'
  | 'Connection'
  | 'ConnectionLite'
  | 'DataObject'
  | 'Dataset'
  | 'DatasetLite'
  | 'Flow'
  | 'Folder'
  | 'Organization'
  | 'Source'
  | 'User'
  | 'Workspace'
  | 'DatasetTest';

type B<T extends A> = T extends 'string'
  ? string
  : T extends 'Connection'
  ? Connection
  : T extends 'ConnectionLite'
  ? ConnectionLite
  : T extends 'DataObject'
  ? DataObject
  : T extends 'Dataset'
  ? Dataset
  : T extends 'DatasetLite'
  ? DatasetLite
  : T extends 'Flow'
  ? Flow
  : T extends 'Folder'
  ? Folder
  : T extends 'Organization'
  ? Organization
  : T extends 'Source'
  ? Source
  : T extends 'User'
  ? User
  : T extends 'Workspace'
  ? Workspace
  : T extends 'DatasetTest'
  ? DatasetTest
  : never;

const mapTypeToAssert: Record<A, (val: unknown, label: string) => void> = {
  string: assertIsString,
  Connection: assertIsConnection,
  ConnectionLite: assertIsConnectionLite,
  DataObject: assertIsDataObject,
  Dataset: assertIsDataset,
  DatasetLite: assertIsDatasetLite,
  Flow: assertIsFlow,
  Folder: assertIsFolder,
  Organization: assertIsOrganization,
  Source: assertIsSource,
  User: assertIsUser,
  Workspace: assertIsWorkspace,
  DatasetTest: assertIsDatasetTest,
};

export function assertIsArray<T extends A>(
  val: unknown,
  type: T,
  label: string,
): asserts val is B<T>[] {
  if (!Array.isArray(val)) throw new Error(`assertIsArray ${val} [${label}]`);
  val.map((el) => mapTypeToAssert[type](el, label));
}
