import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 17}
      height={props.svgHeight || 17}
      viewBox={props.svgViewBox || '0 0 17 17'}
    >
      <g transform="translate(1 1)" stroke="#B5BFD2" strokeWidth="2" fill="none" fillRule="evenodd">
        <circle cx="6" cy="6" r="6" />
        <path strokeLinecap="round" d="M10.5 10.5L15 15" />
      </g>
    </svg>
  );
};
