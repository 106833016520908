import type * as A from '@/api';
import { logger } from '@/utils/logger';
import { graphql } from '@/utils/graphql';
import { pluck, difference } from 'underscore';
import Organization from './organization';
import WorkspaceLite from './workspaceLite';
import Source from './source';
import User from './user';
import Folder from './folder';
import { assertIsDefined } from '@/utils/typeChecks';
import { addBackendSuffix } from '@/utils/namingConvention';

type WorkspaceConstructorInput = A.GetWorkspaceQuery['getWorkspace'];

type WorkspaceUpdateInput = Partial<WorkspaceConstructorInput>;

export default class Workspace {
  readonly id: string;

  readonly name: string;

  readonly seq: number;

  readonly organization: Organization;

  #isUsersLoaded: boolean;

  #users: User[];

  get users(): User[] {
    if (this.#isUsersLoaded) return this.#users;
    throw new Error('Unexpected - Organization - get users() - !#isUsersLoaded');
  }

  private constructor(input: WorkspaceConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.seq = input.seq;
    this.organization = new Organization(input.organization);
    if (input.users?.items) {
      this.#isUsersLoaded = true;
      this.#users = input.users?.items.map((el) => new User(el.user));
    } else {
      this.#isUsersLoaded = false;
      this.#users = [];
    }

    this.getSources = this.getSources.bind(this);
    this.update = this.update.bind(this);
    this.addMembers = this.addMembers.bind(this);
    this.removeMembers = this.removeMembers.bind(this);
  }

  async getSources(): Promise<Source[]> {
    return Source.getSourcesOwnByWorkspace(this.id);
  }

  async update(input: WorkspaceUpdateInput): Promise<void> {
    logger.info({ label: 'app.services.workspace.update.input', message: input });
    await graphql('updateWorkspace', { input: { id: this.id, ...input } });
    Object.entries(input).forEach(([k, v]) => {
      this[k] = v;
    });
  }

  private async refreshWarehousePermissionForMember(userId: string): Promise<void> {
    const user = await User.getUserById(userId);
    const { hasWarehouseCredentials } = await user.hasWarehouseCredentials(this.organization);
    if (!hasWarehouseCredentials) return;
    const password = await user.createOrUpdateWarehouseCredentials(this.organization);
    if (password !== '') throw new Error('BEEM220711104055');
  }

  async addMembers(memberIds: string[]): Promise<void> {
    logger.info({ label: 'app.services.workspace.addMembers.memberIds', message: memberIds });
    const workspaceId = this.id;
    const currentUserIds = pluck(this.users, 'id');
    const userIds = difference(memberIds, currentUserIds);
    await Promise.all(
      userIds.map(async (userId): Promise<void> => {
        await graphql('createWorkspaceUser', {
          input: { id: `${workspaceId}#${userId}`, userId, workspaceId },
        });
        await this.refreshWarehousePermissionForMember(userId);
      }),
    );
  }

  async removeMembers(memberIds: string[]): Promise<void> {
    logger.info({ label: 'app.services.workspace.removeMembers.memberIds', message: memberIds });
    const workspaceId = this.id;
    await Promise.all(
      memberIds.map(async (userId): Promise<void> => {
        const data = await graphql('listWorkspaceUsersByUser', {
          userId,
          workspaceId: { eq: workspaceId },
        });
        if (data.items.length !== 1)
          throw new Error('Unexpected - workspace - removeMembers - data.items.length !== 1');
        await graphql('deleteWorkspaceUser', { input: { id: data.items[0].id } });
        await this.refreshWarehousePermissionForMember(userId);
      }),
    );
  }

  static async getWorkspaceById(id: string): Promise<Workspace> {
    logger.info(id, { label: 'app.services.workspace.getWorkspaceById.id' });
    const data = await graphql('getWorkspace', { id });
    return new Workspace(data);
  }

  static async getWorkspacesByUser(userId: string): Promise<Workspace[]> {
    logger.info(userId, { label: 'app.services.workspace.getWorkspacesByUser.userId' });
    const data = await graphql('listWorkspaceUsersByUser', { userId });
    return data.items.map((el) => new Workspace(el.workspace));
  }

  static async getWorkspacesByOrganization(organizationId: string) {
    logger.info({
      label: 'app.services.workspace.getWorkspacesByOrganization.organizationId',
      message: organizationId,
    });
    const data = await graphql('listWorkspacesByOrganization', { organizationId });
    return data.items.map((el) => new Workspace(el));
  }

  static async getWorkspacesWhichSharedDatasetWithThisWorkspace(
    thisWorkspaceId: string,
  ): Promise<WorkspaceLite[]> {
    const data = await graphql('listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId', {
      withWorkspaceId: thisWorkspaceId,
    });
    return data.items.map((el) => new WorkspaceLite(el.fromWorkspace));
  }

  static async create(
    variables: A.CreateWorkspaceMutationVariables,
    { userIds }: { userIds: string[] },
    _unitTestOnly_DoNotCreateRootFolder = false,
  ): Promise<string> {
    logger.info({ label: 'app.services.workspace.create.variables', message: variables });
    logger.info({ label: 'app.services.workspace.create.userIds', message: userIds });

    // IMPORTANT: must check workspace limit on organization
    const organizationId = variables.input?.organizationId;
    assertIsDefined(organizationId, 'BEEM211105120247');
    const organization = await Organization.getOrganizationById(organizationId);
    const workspaces = await Workspace.getWorkspacesByOrganization(organizationId);
    if (organization.workspaceLimit <= workspaces.length)
      throw new Error(
        `WORKSPACE_LIMIT_REACHED - You've reached the workspace limit for this organization. Please contact BEEM to increase the limit.`,
      );
    const workspaceSeq = workspaces.length + 1;

    // now can create the workspace
    const temp = { ...variables };
    if (temp.input) temp.input.seq = workspaceSeq;
    const data = await graphql('createWorkspace', temp);
    const workspaceId = data.id;

    if (!_unitTestOnly_DoNotCreateRootFolder)
      Folder.create({ name: 'Warehouse', parentId: workspaceId });

    // call beem agent to create the redshift group, user, schema, etc..
    await graphql('configureNewWorkspaceInTheWarehouse', {
      query: {
        orgId: organization.cloudResourceId,
        workspaceSeq: addBackendSuffix(String(workspaceSeq)),
      },
    });

    // add the initial users to this workspace
    const ws = await Workspace.getWorkspaceById(workspaceId);
    await ws.addMembers(userIds);

    return workspaceId;
  }
}
