import * as React from 'react';

export default (props) => (
  <svg width="40" height="40">
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
        fill={props.backgroundColor || '#FFC600'}
        opacity=".1"
      />
      <g transform="rotate(180 14.86 12.5)">
        <path d="M7.923 15.19l-3.564-3.728a1.562 1.562 0 0 1 1.535-2.587l2.324.625V2a1.5 1.5 0 1 1 3 0v3.5l1.605.159A2.857 2.857 0 0 1 15.218 7.5l1.103.368a2.775 2.775 0 0 1 1.897 2.632v.343a4 4 0 0 1-1.171 2.829l-1.536 1.535a1 1 0 0 1-.707.293H8.646a1 1 0 0 1-.723-.31z" />
        <path d="M10.808 3.73v-2a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v2h7z" id="b" />
        <path
          d="M7.923 15.19l-3.564-3.728a1.562 1.562 0 0 1 1.535-2.587l2.324.625V2a1.5 1.5 0 1 1 3 0v3.5l1.605.159A2.857 2.857 0 0 1 15.218 7.5l1.103.368a2.775 2.775 0 0 1 1.897 2.632v.343a4 4 0 0 1-1.171 2.829l-1.536 1.535a1 1 0 0 1-.707.293H8.646a1 1 0 0 1-.723-.31z"
          fill="#FFF"
        />
        <path
          d="M7.923 15.19l-3.564-3.728a1.562 1.562 0 0 1 1.535-2.587l2.324.625V2a1.5 1.5 0 1 1 3 0v3.5l1.605.159A2.857 2.857 0 0 1 15.218 7.5l1.103.368a2.775 2.775 0 0 1 1.897 2.632v.343a4 4 0 0 1-1.171 2.829l-1.536 1.535a1 1 0 0 1-.707.293H8.646a1 1 0 0 1-.723-.31z"
          stroke="#FFC600"
          fillOpacity=".2"
          fill="#FFC600"
        />
      </g>
      <g strokeLinecap="round">
        <g transform="translate(12.692 26.77)">
          <path fill="#FFF" d="M10.808 3.73v-2a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v2h7z" />
          <path
            stroke="#FFC600"
            fillOpacity=".2"
            fill="#FFC600"
            d="M10.808 3.73v-2a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v2h7z"
          />
        </g>
        <path stroke="#FFC600" d="M13.5 30.5h13" />
      </g>
    </g>
  </svg>
);
