import * as React from 'react';

export default () => (
  <svg width="24" height="22">
    <defs>
      <rect id="a" x="0" y="0" width="24" height="22" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#FFF" xlinkHref="#a" />
        <rect
          stroke="#0067F4"
          strokeLinejoin="square"
          fillOpacity=".201"
          fill="#0067F4"
          x=".5"
          y=".5"
          width="23"
          height="21"
          rx="2"
        />
      </g>
      <path
        d="M22 .5c.414 0 .79.168 1.06.44.272.27.44.646.44 1.06h0v1.5H.5V2c0-.414.168-.79.44-1.06C1.21.667 1.585.5 2 .5h0z"
        stroke="#0067F4"
      />
      <g stroke="#0067F4" strokeLinecap="round">
        <path strokeLinejoin="round" d="M5 8l4 4-4 4" />
        <path d="M10 16h5.833" />
      </g>
    </g>
  </svg>
);
