import * as A from '@/api';
import { graphql } from '@/utils/graphql';
import { logger } from '@/utils/logger';

const ns = 'app.services.event';

type ConstructorInput = A.ListEventsByWorkspaceQuery['listEventsByWorkspace']['items'][0];

type StatusFilter = 'START' | 'SUCCESS' | 'WARNING' | 'FAIL' | 'CANCEL';

export default class Event {
  readonly id: string;

  readonly message: string;

  readonly level: A.EventLevel;

  readonly actionType: A.EventActionType;

  readonly actionSourceId: string;

  readonly actionSourceName: string;

  readonly actionSourceType: A.EventObjectType;

  readonly actionSourceWorkspaceId: string;

  readonly actionData: string; // AWSJSON

  readonly actionRecordedAt: Date;

  private constructor(input: ConstructorInput) {
    this.id = input.id;
    this.message = input.message;
    this.level = input.level;
    this.actionType = input.actionType;
    this.actionSourceId = input.actionSourceId;
    this.actionSourceName = input.actionSourceName;
    this.actionSourceType = input.actionSourceType;
    this.actionSourceWorkspaceId = input.actionSourceWorkspaceId;
    this.actionData = input.actionData;
    this.actionRecordedAt = new Date(input.actionRecordedAt);
  }

  static async getEventsByWorkspaceId(
    workspaceId: string,
    limit: number,
    filters?: {
      objectType?: A.EventObjectType;
      status?: StatusFilter[];
      level?: A.EventLevel;
      dateRange?: { from: Date; to: Date };
      name?: string;
    },
  ): Promise<Event[]> {
    logger.info(workspaceId, { label: `${ns}.getEventsByWorkspaceId.workspaceId` });

    const buildOrFilter = (statuses: StatusFilter[]) => {
      const orFilters: { actionType: { eq: A.EventActionType } }[] = [];

      statuses.forEach((status) => {
        switch (status) {
          case 'START':
            orFilters.push(
              { actionType: { eq: A.EventActionType.CATALOG_SOURCE_SCHEDULED_START } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_SCHEDULED_START } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_MANUAL_START } },
            );
            break;
          case 'SUCCESS':
            orFilters.push(
              { actionType: { eq: A.EventActionType.CATALOG_SOURCE_SCHEDULED_SUCCESS } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_SCHEDULED_SUCCESS } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_MANUAL_SUCCESS } },
            );
            break;
          case 'WARNING':
            orFilters.push(
              { actionType: { eq: A.EventActionType.HUB_DATASET_MANUAL_TEST_NONBLOCK } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_SCHEDULED_TEST_NONBLOCK } },
            );
            break;
          case 'FAIL':
            orFilters.push(
              { actionType: { eq: A.EventActionType.CATALOG_SOURCE_SCHEDULED_FAIL } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_SCHEDULED_FAIL } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_MANUAL_FAIL } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_SCHEDULED_TEST_BLOCKING } },
              { actionType: { eq: A.EventActionType.HUB_DATASET_MANUAL_TEST_BLOCKING } },
            );
            break;
          default:
            break;
        }
      });

      return orFilters;
    };
    const or = filters?.status ? buildOrFilter(filters.status) : [];

    const queryFilter = {
      or: or.length > 0 ? or : undefined,
      actionSourceName: filters?.name ? { contains: filters.name } : undefined,
      actionSourceType: filters?.objectType ? { eq: filters?.objectType } : undefined,
      level: filters?.level ? { eq: filters?.level } : undefined,
    };
    const hasQueryFilter = Object.values(queryFilter).findIndex((el) => !!el) >= 0;

    let items: ConstructorInput[] = [];
    let nextToken: string | undefined;
    do {
      // eslint-disable-next-line no-await-in-loop
      const data = await graphql(
        'listEventsByWorkspace',
        {
          actionSourceWorkspaceId: workspaceId,
          actionRecordedAt: filters?.dateRange
            ? {
                between: [
                  filters?.dateRange.from.toISOString(),
                  filters?.dateRange.to.toISOString(),
                ],
              }
            : undefined,
          sortDirection: A.ModelSortDirection.DESC,
          filter: hasQueryFilter ? queryFilter : undefined,
          nextToken,
        },
        true,
      );
      const newItems = data.items;
      if (newItems.length) items = items.concat(newItems);
      nextToken = data.nextToken;
    } while (nextToken && items.length < limit);

    return items.slice(0, limit).map((el) => new Event(el));
  }
}
