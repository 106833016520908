import * as React from 'react';

export default () => {
  return (
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0z" />
        <g stroke="#B5BFD2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M16 10h6M19 7v6" />
        </g>
        <rect stroke="#B5BFD2" strokeWidth="2" x="5.875" y="4" width="6" height="6" rx="3" />
        <path
          d="M6 13h6a3 3 0 013 3v1a2 2 0 01-2 2H5a2 2 0 01-2-2v-1a3 3 0 013-3z"
          stroke="#B5BFD2"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
