import * as React from 'react';

export default () => {
  return (
    <svg width="252" height="180">
      <defs>
        <rect id="csv-off-a" x="0" y="0" width="252" height="180" rx="2" />
        <path
          d="M4 0h26.719a4 4 0 012.973 1.324L44.973 13.86A4 4 0 0146 16.535V60a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4z"
          id="csv-off-b"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FFF" href="#csv-off-a" />
        <rect
          stroke="#0067F4"
          strokeLinejoin="square"
          fillOpacity=".05"
          fill="#0067F4"
          x=".5"
          y=".5"
          width="251"
          height="179"
          rx="2"
        />
        <rect stroke="#DADEE6" fill="#FFF" x=".5" y=".5" width="251" height="179" rx="2" />
        <rect
          x=".5"
          y=".5"
          width="15"
          height="15"
          rx="7.5"
          transform="translate(12 12)"
          fill="#FFF"
          stroke="#B5BFD2"
        />
        <g fill="#0A132B">
          <text
            fontFamily="OpenSans-SemiBold, Open Sans"
            fontSize="16"
            fontWeight="500"
            transform="translate(54 112)"
          >
            <tspan x="55.5" y="17">
              .CSV
            </tspan>
          </text>
          <text
            opacity=".6"
            fontFamily="OpenSans-Regular, Open Sans"
            fontSize="12"
            transform="translate(54 112)"
          >
            <tspan x="0" y="33">
              Comma-seperated values
            </tspan>
          </text>
        </g>
        <g>
          <g transform="translate(103 32)">
            <use fill="#FFF" href="#csv-off-b" />
            <path
              stroke="#B5BFD2"
              d="M30.719.5a3.5 3.5 0 012.601 1.159h0l11.282 12.535a3.5 3.5 0 01.898 2.34h0V60c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0142 63.5h0H4a3.489 3.489 0 01-2.475-1.025A3.489 3.489 0 01.5 60h0V4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 014 .5h0z"
              strokeLinejoin="square"
              fillOpacity=".2"
              fill="#F0F2F5"
            />
          </g>
          <path
            d="M125.47 55.5c1.534 0 2.829.545 3.826 1.442 1.101.992 1.84 2.406 2.155 3.98.621 3.107.218 6.361-.901 9.161-1.075 2.685-2.808 4.958-4.945 6.262h0l-1.356-1.807c1.181-.807 2.28-1.84 3.047-3.06.741-1.18 1.175-2.533 1.09-4.03a6.503 6.503 0 00-.08-.718 5.986 5.986 0 01-2.835.711 5.952 5.952 0 01-4.222-1.749 5.952 5.952 0 01-1.749-4.221c0-1.65.668-3.142 1.749-4.222a5.952 5.952 0 014.222-1.749z"
            stroke="#B5BFD2"
            fillOpacity=".4"
            fill="#B5BFD2"
          />
        </g>
      </g>
    </svg>
  );
};
