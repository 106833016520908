import * as React from 'react';

export default ({ opacity }) => (
  <svg width="24" height="24" fill="none">
    <g opacity={opacity || '0.4'} stroke="#0A132B" strokeWidth="2" strokeLinecap="round">
      <path d="M6 12h13" />
      <path d="M13 5l-7 7 7 7" strokeLinejoin="round" />
    </g>
  </svg>
);
