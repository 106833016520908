export default {
  "add_integration": {
    "title": "Select Integration",
    "sub_title": "Choose which data integration you want to connect to start syncing",
    "sort_by": "Sort by",
    "sort_by_types": "Alphabetical",
    "filter_by_category": "Filter by category",
    "cancel": "Cancel",
    "back": "Back to selection",
    "test": "Test connection",
    "errorMessage": "An error occured, please contact the support team.",
    "connector_details": {
      "title": "Connect to {connector}",
      "subtitle": "Integration Details",
      "connect_subtitle": "Authenticate and configure your {connector} integration.",
      "category": "Category:",
      "learn_more": "Learn More",
      "disclaimer_title": "Third-Party Source Connector",
      "disclaimer":
        "BEEM offers to connect to {connector} via its trusted partner Fivetran.<br>For more information check out our&nbsp;<a href='https://www.beemdata.com/legals/app-privacy'>privacy policy.</a>&nbsp;",
      "button": "Connect using Fivetran",
      "input": "Connection Display Name",
      "input_max_length_error": "Maximum 100 characters",
      "advancedConfiguration": "Advanced Configuration Required",
      "advancedConfigurationBody":
        "Our {connector} source integration requires our team to complete a special configuration before launching the initial sync. Once you have completed this connection setup, our team will be notified and activate it within 24 hours."
    },
    "credentials": {
      "success": {
        "title": "Connection Successful!",
        "sub_title": "You can continue your configuration by selecting the data you want to sync.",
        "next": "Next"
      },
      "failure": {
        "title": "The configuration has failed.",
        "sub_title": "The credentials are invalid. Go back and verify the information is correct.",
        "edit": "Edit credentials"
      }
    },
    "categories": {
      "all_categories": "All categories",
      "accounting": "Accounting",
      "analytics": "Analytics",
      "business": "Business",
      "customer_support": "Customer support",
      "crm": "CRM",
      "database": "Database",
      "email_newsletter": "Email & Newsletter",
      "forms_surveys": "Forms & Surveys",
      "project_management": "Project Management",
      "marketing_automation": "Marketing & Automation",
      "task_management": "Task Management"
    },
    "integrations": {
      "active_campaign": {
        "title": "Active Campaign",
        "sub_title": "Authenticate and configure your Active Campaign integration.",
        "connection": "Connection",
        "connection_description": "Log in with your username and password to your Active Campaign account, and go to the Developer Settings page to access the API url and API Key.",
        "email": "API Url",
        "password": "API Key"
      },
      "emyx": {
        "title": "Connect to EMYX",
        "sub_title": "Authenticate and configure your EMYX integration.",
        "connection": "Connection",
        "connection_description": "Log in with your username and password to your EMYX app, and access to your Client ID and Client Secret Key in the Profile section.",
        "email": "Client ID",
        "password": "Secret Key"
      }
    }
  }
};
