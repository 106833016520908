import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 40}
      height={props.svgHeight || 40}
      viewBox={props.svgViewBox || '0 0 40 40'}
    >
      <defs>
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          id="icon-status-indicator-error-a"
        />
        <path
          id="icon-status-indicator-error-b"
          d="M12.79 0L18 5.21v7.58L12.79 18H5.21L0 12.79V5.21L5.21 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="#FFF" href="#icon-status-indicator-error-a" />
          <use fillOpacity=".2" fill="#F02929" href="#icon-status-indicator-error-a" />
        </g>
        <g transform="translate(11 11)">
          <use fill="#FFF" href="#icon-status-indicator-error-b" />
          <use
            stroke="#F02929"
            fillOpacity=".2"
            fill="#F02929"
            href="#icon-status-indicator-error-b"
          />
        </g>
        <path
          stroke="#F02929"
          strokeLinecap="round"
          d="M23.316 16.684l-6.632 6.632M16.684 16.684l6.632 6.632"
        />
      </g>
    </svg>
  );
};
