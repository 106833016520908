import type * as A from '@/api';
import { Auth } from '@/aws-config';
import { logger } from '@/utils/logger';
import { graphql } from '@/utils/graphql';
import Workspace from './workspace';
import User from './user';
import { handleUserError } from '@/utils/handleError';
import { assertIsDefined } from '@/utils/typeChecks';

type OrganizationConstructorInput = A.GetOrganizationQuery['getOrganization'];

const ns = 'app.services.organization';

export default class Organization {
  readonly id: string;

  readonly name: string;

  readonly color: string;

  readonly workspaceLimit: number;

  #warehouseRetention: number;
  get warehouseRetention(): number {
    return this.#warehouseRetention;
  }
  private set warehouseRetention(i: number) {
    this.#warehouseRetention = i;
  }

  readonly timezone: string;

  readonly cloudResourceId: string;

  readonly workspaces: Workspace[];

  #isUsersLoaded: boolean;

  #users: User[];

  get users(): User[] {
    if (this.#isUsersLoaded) return this.#users;
    throw new Error('Unexpected - Organization - get users() - !#isUsersLoaded');
  }

  set users(newUsers: User[]) {
    this.#users = newUsers;
  }

  constructor(input: OrganizationConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.color = input.color;
    this.workspaceLimit = input.workspaceLimit ?? 1;
    this.#warehouseRetention = input.warehouseRetention ?? 90;
    this.timezone = input.timezone;
    this.cloudResourceId = input.cloudResourceId;
    // @ts-ignore
    this.workspaces = input.workspaces?.items;
    if (input.users?.items) {
      this.#isUsersLoaded = true;
      this.#users = input.users?.items.map((el) => new User(el.user));
    } else {
      this.#isUsersLoaded = false;
      this.#users = [];
    }

    this.getWarehouseStorageInfo = this.getWarehouseStorageInfo.bind(this);
    this.setWarehouseRetention = this.setWarehouseRetention.bind(this);
    this.createFivetranGroupIdIfNotExist = this.createFivetranGroupIdIfNotExist.bind(this);
    this.removeUserFromAllWorkspaces = this.removeUserFromAllWorkspaces.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }

  async getWarehouseStorageInfo(): Promise<A.GetWarehouseStorageInfoOutput> {
    return await graphql('getWarehouseStorageInfo', {
      query: { orgId: this.cloudResourceId },
    });
  }

  async getWarehouseConnectionInfo(): Promise<A.GetWarehouseConnectionInfoOutput> {
    return await graphql('getWarehouseConnectionInfo', {
      query: { orgId: this.cloudResourceId },
    });
  }

  async setWarehouseRetention(daysToRetainData: number): Promise<void> {
    await graphql('updateOrganizationSettings', {
      query: { orgId: this.cloudResourceId, daysToRetainData },
    });
    await graphql('updateOrganization', {
      input: { id: this.id, warehouseRetention: daysToRetainData },
    });
    this.warehouseRetention = daysToRetainData;
  }

  async createFivetranGroupIdIfNotExist(): Promise<string> {
    const rs = await graphql('getOrganizationFivetran', { id: this.id });
    if (rs) return rs.groupId;
    const rs2 = await graphql('fivetranCreateGroup', {
      input: { id: this.id, orgId: this.cloudResourceId },
    });
    return rs2.groupId;
  }

  async removeUserFromAllWorkspaces(userId: string): Promise<void> {
    logger.info({ label: `${ns}.removeUserFromAllWorkspaces.userId`, message: userId });
    const temp = await Workspace.getWorkspacesByUser(userId);
    const workspaces = temp.filter((el) => el.organization.id === this.id);
    if (workspaces.length === 0) return;
    const lastWorkspace = workspaces.pop();
    assertIsDefined(lastWorkspace, 'BEEM240217084103');
    await Promise.all(
      workspaces.map(async (workspace) => {
        await workspace.removeMembers([userId]);
      }),
    );
    await lastWorkspace.removeMembers([userId]);
  }

  async removeUser(userId: string): Promise<void> {
    logger.info({ label: `${ns}.removeUser.userId`, message: userId });
    await graphql('beemAgentAdminLogoutUser', { query: { userId } });
    // vinhf migrate ID of OrganizationUser to be ${orgId}.${userId}
    const { items } = await graphql('listOrganizationUsersByUser', {
      userId,
      organizationId: { eq: this.id },
    });
    if (items.length !== 1) throw new Error('BEEM240217093041');
    const { id: organizationUserId } = items[0];
    await graphql('deleteOrganizationUser', { input: { id: organizationUserId } });
  }

  static async getDpuAllowanceAndBilling(id: string) {
    const { allowance, billingCycleDate } = await graphql('getOrganizationChargebee', { id });
    return { allowance, billingCycleDate };
  }

  static async getAccumulatedDpuUsageByOrganizationId(
    organizationId: string,
    start: Date,
  ): Promise<number> {
    const { items } = await graphql('listOrganizationDPUsByOrganization', {
      organizationId,
      date: { gt: new Date(start.getTime() - 24 * 60 * 60 * 1000).toISOString() },
    });
    return items.reduce((acc, { dpu }) => acc + dpu, 0);
  }

  static async getOrganizationById(id: string): Promise<Organization> {
    logger.info({ label: 'app.services.organization.getOrganizationById.id', message: id });
    const data = await graphql('getOrganization', { id });
    return new Organization(data);
  }

  static async getWorkspacesOfCurrentUser(): Promise<Workspace[]> {
    try {
      const user = await Auth.currentUserInfo();
      logger.debug(user, { label: 'app.services.organization.getWorkspacesOfCurrentUser.user' });
      if (!user) return [];
      return Workspace.getWorkspacesByUser(user.username);
    } catch (err) {
      // vinhf should display error correctly, such as graphql error
      // perhaps should use a global catch and display via antd message
      if (err !== 'No current user') {
        handleUserError(err, 'app.services.organization.getWorkspacesOfCurrentUser.error');
      }
      logger.debug({
        label: 'app.services.organization.getWorkspacesOfCurrentUser.error',
        message: err,
      });
      return [];
    }
  }
}
