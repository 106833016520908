import * as React from 'react';

export default () => (
  <svg width="16" height="4">
    <g fill="#0A132B" fillRule="evenodd" opacity=".6">
      <circle cx="2" cy="2" r="2" />
      <circle cx="8" cy="2" r="2" />
      <circle cx="14" cy="2" r="2" />
    </g>
  </svg>
);
