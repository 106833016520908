export default {
  "workspace": {
    "member_list": {
      "title": "{name} members",
      "name": "Members",
      "add_button": "Add member",
      "menu": {
        "remove": "Remove"
      }
    },
    "add_member_modal_dialog": {
      "title": "Invite people to workspace",
      "filter_input": "Filter organization members",
      "email_input": "Invite by email address",
      "submit_button": "Add"
    }
  },
  "workspaceMember": {
    "delete_modal_dialog": {
      "title": "Remove member {name} ?",
      "description": "Are you sure you want to remove this member?",
      "submit_button": "Remove member"
    }
  }
};
