import * as React from 'react';

export default () => (
  <svg width="40" height="40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.167 5.804A5.479 5.479 0 0 1 5.8 1.148C9.297.63 14.648 0 20 0c5.334 0 10.667.626 14.165 1.143 2.434.36 4.306 2.263 4.654 4.699C39.343 9.507 40 15.082 40 20c0 4.918-.657 10.493-1.181 14.158-.348 2.436-2.22 4.339-4.654 4.699C30.667 39.374 25.334 40 20 40c-5.352 0-10.703-.63-14.2-1.148a5.479 5.479 0 0 1-4.632-4.656C.646 30.616 0 25.146 0 20 0 14.853.646 9.384 1.167 5.804z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.167 5.804A5.479 5.479 0 0 1 5.8 1.148C9.297.63 14.648 0 20 0c5.334 0 10.667.626 14.165 1.143 2.434.36 4.306 2.263 4.654 4.699C39.343 9.507 40 15.082 40 20c0 4.918-.657 10.493-1.181 14.158-.348 2.436-2.22 4.339-4.654 4.699C30.667 39.374 25.334 40 20 40c-5.352 0-10.703-.63-14.2-1.148a5.479 5.479 0 0 1-4.632-4.656C.646 30.616 0 25.146 0 20 0 14.853.646 9.384 1.167 5.804z"
      fill="#32D02F"
      fillOpacity=".1"
    />
    <rect
      x="11"
      y="12"
      width="18"
      height="16"
      rx="2"
      fill="#1CC23A"
      fillOpacity=".2"
      stroke="#1CC23A"
      strokeWidth="2"
    />
    <path d="M20 12v16M11 20h18" stroke="#1CC23A" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
