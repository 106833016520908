import * as React from 'react';

export default () => (
  <svg width="40" height="40">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
          id="a"
          fillOpacity=".2"
          fill="#0067F4"
        />
      </g>
      <g fill="#0067F4" fillOpacity=".2" stroke="#0067F4">
        <g transform="translate(8 10)">
          <path d="M4.5 11.5h7a4 4 0 0 1 4 4v3a1 1 0 0 1-1 1h-13a1 1 0 0 1-1-1v-3a4 4 0 0 1 4-4z" />
          <rect x="4.5" y=".5" width="8" height="8" rx="3.654" />
        </g>
        <g transform="translate(22 15)">
          <path d="M1 8.5h5c2 0 3.5 1.5 3.5 3.5v1.5a1 1 0 0 1-1 1H0h0" />
          <rect x="1.5" y=".5" width="5" height="5" rx="2.5" />
        </g>
      </g>
    </g>
  </svg>
);
