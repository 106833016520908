import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <path
      d="M15.807 4.175a6.305 6.305 0 0 0-1.469 1.723L1.741 27.623a6.377 6.377 0 0 0 2.311 8.69 6.377 6.377 0 0 0 8.69-2.311L23.568 15.33c-2.39-3.43-5.874-8.432-7.762-11.155z"
      fill="#FEBD00"
    />
    <path
      d="M13.6 30.813c0 1.755-.622 3.254-1.863 4.495-1.241 1.242-2.74 1.862-4.496 1.862-1.755 0-3.254-.62-4.495-1.862-1.242-1.241-1.862-2.74-1.862-4.495 0-1.756.62-3.255 1.862-4.496 1.241-1.242 2.74-1.862 4.495-1.862 1.756 0 3.255.62 4.496 1.862 1.241 1.241 1.862 2.74 1.862 4.495 0 .074-.001.148-.004.222"
      fill="#25A84A"
    />
    <path
      d="M37.891 27.658a6.358 6.358 0 1 1-11.012 6.357L14.322 12.267a6.358 6.358 0 1 1 11.012-6.358l12.557 21.749z"
      fill="#2E8ADD"
    />
  </svg>
);
