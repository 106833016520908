import * as React from 'react';

export default ({ backgroundColor }) => (
  <svg width="48" height="48">
    <g fill="none" fillRule="evenodd">
      <circle fill={backgroundColor || '#F0F2F5'} cx="24" cy="24" r="24" />
      <g stroke="#0A132B" strokeLinecap="round" strokeWidth="2">
        <path d="M17 17l14 14M31 17L17 31" />
      </g>
    </g>
  </svg>
);
