import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00195 5C5.44967 5 5.00195 5.44772 5.00195 6V9C5.00195 9.29678 5.13378 9.57823 5.36177 9.76822L11.002 14.4684V18C11.002 18.5523 11.4497 19 12.002 19C12.5542 19 13.002 18.5523 13.002 18V14.4684L18.6421 9.76822C18.8701 9.57823 19.002 9.29678 19.002 9V6C19.002 5.44772 18.5542 5 18.002 5H6.00195ZM7.00195 8.53163V7H17.002V8.53163L12.002 12.6983L7.00195 8.53163Z"
        fill={props.color || '#6C7280'}
        style={{ transition: props.withTransition ? 'fill 0.3s ease' : 'none' }}
      />
    </svg>
  );
};
