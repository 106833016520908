import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M26.668 26.788c.23-1.91.16-2.19 1.583-1.88l.362.031c1.094.05 2.525-.176 3.366-.567 1.81-.84 2.884-2.242 1.099-1.873-4.072.84-4.352-.54-4.352-.54 4.3-6.378 6.097-14.476 4.545-16.458-4.23-5.406-11.555-2.85-11.677-2.783l-.04.007a14.506 14.506 0 0 0-2.715-.283c-1.843-.03-3.24.483-4.301 1.288 0 0-13.066-5.383-12.458 6.77.13 2.584 3.705 19.56 7.97 14.433 1.56-1.875 3.066-3.46 3.066-3.46.748.497 1.644.75 2.583.659l.073-.062c-.023.233-.013.46.03.73-1.1 1.228-.777 1.444-2.973 1.896-2.223.458-.917 1.273-.065 1.486 1.034.259 3.424.625 5.04-1.637l-.064.258c.431.345.402 2.479.463 4.003.061 1.525.163 2.948.474 3.786.31.84.678 3 3.565 2.38 2.413-.516 4.258-1.26 4.426-8.184"
        stroke="#000"
        strokeWidth="3.027"
        fill="#000"
        fillRule="nonzero"
      />
      <path
        d="M33.078 22.498c-4.072.84-4.352-.538-4.352-.538 4.3-6.38 6.097-14.477 4.546-16.46C29.04.096 21.716 2.653 21.594 2.719l-.04.007a14.52 14.52 0 0 0-2.716-.283c-1.842-.03-3.24.483-4.3 1.287 0 0-13.066-5.382-12.459 6.77.13 2.585 3.706 19.561 7.971 14.434a78.78 78.78 0 0 1 3.066-3.46c.748.496 1.644.75 2.583.659l.073-.062c-.023.233-.013.46.029.73-1.099 1.228-.776 1.443-2.973 1.896-2.223.458-.917 1.273-.064 1.486 1.033.259 3.424.625 5.04-1.637l-.064.258c.43.345.733 2.243.682 3.964-.05 1.721-.085 2.903.255 3.825.339.923.677 3 3.565 2.38 2.413-.516 3.663-1.856 3.837-4.092.123-1.589.403-1.354.42-2.775l.225-.672c.258-2.154.04-2.85 1.527-2.526l.361.032c1.095.05 2.527-.176 3.367-.567 1.81-.84 2.883-2.242 1.099-1.874Z"
        fill="#336791"
        fillRule="nonzero"
      />
      <path
        d="M17.979 23.733c-.112 4.008.028 8.045.42 9.025.393.981 1.233 2.89 4.12 2.27 2.413-.517 3.291-1.518 3.672-3.727.28-1.626.822-6.14.89-7.065M14.518 3.64S1.443-1.704 2.05 10.448c.13 2.585 3.706 19.562 7.972 14.434a70.805 70.805 0 0 1 2.968-3.346m8.59-18.872c-.453.142 7.273-2.824 11.663 2.786 1.551 1.982-.246 10.08-4.545 16.46"
        stroke="#FFF"
        strokeWidth="1.009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.698 21.91s.28 1.378 4.352.538c1.784-.369.71 1.034-1.1 1.874-1.484.69-4.814.866-4.868-.086-.14-2.457 1.752-1.711 1.616-2.327-.124-.554-.97-1.098-1.53-2.455-.488-1.185-6.702-10.269 1.723-8.92.31-.063-2.197-8.014-10.082-8.143-7.883-.13-7.624 9.695-7.624 9.695"
        stroke="#FFF"
        strokeWidth="1.009"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M15.772 22.75c-1.099 1.227-.776 1.443-2.973 1.895-2.222.458-.916 1.274-.064 1.487 1.033.258 3.425.625 5.04-1.638.492-.688-.003-1.787-.679-2.068-.326-.135-.763-.304-1.324.324h0Z"
        stroke="#FFF"
        strokeWidth="1.009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7 22.728c-.11-.722.237-1.58.61-2.585.56-1.508 1.853-3.016.82-7.799-.772-3.564-5.942-.742-5.945-.258-.004.483.233 2.45-.087 4.739-.418 2.988 1.902 5.515 4.572 5.257"
        stroke="#FFF"
        strokeWidth="1.009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.47 12.013c-.023.165.302.606.726.665.424.059.787-.286.81-.45.023-.165-.302-.347-.727-.406-.424-.06-.787.027-.809.191ZM27.375 11.677c.023.165-.302.605-.727.664-.424.06-.786-.285-.81-.45-.022-.165.303-.347.727-.406.424-.059.787.027.81.192Z"
        stroke="#FFF"
        strokeWidth=".5"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M28.891 10.534c.07 1.295-.278 2.177-.322 3.555-.065 2.004.955 4.296-.582 6.592"
        stroke="#FFF"
        strokeWidth="1.009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
