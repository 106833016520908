import flatten from 'flat';
import app from './fr-FR/app';
import date from './fr-FR/date';
import dpu from './fr-FR/dpu';
import menu from './fr-FR/menu';
import navBar from './fr-FR/navBar';
import tableContentView from './fr-FR/tableContentView';
import trigger from './fr-FR/trigger';
import workspace from './fr-FR/workspace';

export default flatten({
  ...app,
  ...date,
  ...dpu,
  ...menu,
  ...navBar,
  ...tableContentView,
  ...trigger,
  ...workspace,
});
