import Integration from '@/services/integration';
import { logger } from '@/utils/logger';

function* fetch(action, { call, put }) {
  try {
    const integrations = yield call(Integration.getAllIntegrations);
    logger.info(integrations, { label: 'app.models.integrations.fetch.integrations' });

    yield put({
      type: 'all',
      payload: integrations,
    });
  } catch (e) {
    logger.error(e, { label: 'app.models.integrations.fetch.error' });
  }
}

const IntegrationModel = {
  namespace: 'integrations',
  state: {
    all: null,
    current: null,
  },
  effects: {
    *fetch({ payload }, { call, put, all, spawn }) {
      yield all([
        put({
          type: 'all',
          payload: null,
        }),
      ]);
      yield call(fetch, { payload: { ...payload } }, { call, put });
      yield spawn(fetch, { payload: { ...payload } }, { call, put });
    },
    *get({ payload }, { call, put }) {
      try {
        const integration = yield call(Integration.getIntegrationById, payload.id);
        logger.info(integration, { label: 'app.models.integrations.fetch.current' });

        yield put({
          type: 'current',
          payload: integration,
        });
      } catch (e) {
        logger.error(e, { label: 'app.models.integration.fetch.error' });
        yield put({
          type: 'current',
          payload: null,
        });
      }
    },
  },
  reducers: {
    all(state, { payload }) {
      return { ...state, all: payload };
    },
    current(state, { payload }) {
      return { ...state, current: payload };
    },
  },
};

export default IntegrationModel;
