import * as React from 'react';

export default () => {
  return (
    <svg width="40" height="40">
      <defs>
        <path
          d="M2 0h4.172a2 2 0 011.414.586l1.121 1.121A1 1 0 009.414 2H15a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
          id="move-a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          fill="#0067F4"
          opacity=".1"
        />
        <g transform="translate(12 13)">
          <use fill="#FFF" href="#move-a" />
          <use stroke="#0067F4" fillOpacity=".2" fill="#0067F4" href="#move-a" />
        </g>
        <g stroke="#0067F4" strokeLinecap="round">
          <path d="M17 21h7" />
          <path strokeLinejoin="round" d="M21 18l3 3-3 3" />
        </g>
      </g>
    </svg>
  );
};
