import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24" viewBox="-2 -2 24 24">
      <g stroke={props.color || '#B5BFD2'} strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M3.165 11.834l9.706-9.78a2.882 2.882 0 114.076 4.075l-9.78 9.706a1 1 0 01-.39.239l-3.88 1.294a1 1 0 01-1.265-1.265l1.294-3.88a1 1 0 01.239-.39zM12 4l3 3" />
      </g>
    </svg>
  );
};
