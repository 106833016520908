let route;

export function onRouteChange({ location /* , routes, action */ }) {
  // HACK: Fix to prevent sending duplicate events on refresh
  // Reported in issue: https://github.com/umijs/umi/issues/4752
  if (route === location.pathname) return;

  route = location.pathname;
  window.Intercom('update');
}
