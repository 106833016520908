import * as React from 'react';

export default () => (
  <svg width="26" height="26">
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.403922 0 0 0 0 0.956863 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="b">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.403922 0 0 0 0 0.956863 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g filter="url(#a)" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g transform="scale(-1 1) rotate(60 -5.83 -8.553)">
        <g stroke="#0067f4" strokeWidth="2">
          <path d="M3.5 10.478V8.144a7 7 0 1 1 7 7" />
          <path strokeLinejoin="round" d="M5.833 9.144l-2.333 2-2.333-2" />
        </g>
      </g>
    </g>
  </svg>
);
