import type { ReduxState, ReduxSagaModel, InvokeResult } from './types';
import { assertIsOrganization, assertIsString, assertIsUser } from '@/utils/typeChecks';
import { handleUserError } from '@/utils/handleError';

const ns = 'app.models.user2';

export interface State {
  invokeCreateWarehousePasswordResult: InvokeResult<string> | null;
  invokeResetWarehousePasswordResult: InvokeResult<string> | null;
}

function getInitialState(): State {
  return {
    invokeCreateWarehousePasswordResult: null,
    invokeResetWarehousePasswordResult: null,
  };
}

interface SaveInvokeCreateWarehousePasswordResult {
  type: 'saveInvokeCreateWarehousePasswordResult';
  payload: Pick<State, 'invokeCreateWarehousePasswordResult'>;
}

interface SaveInvokeResetWarehousePasswordResult {
  type: 'saveInvokeResetWarehousePasswordResult';
  payload: Pick<State, 'invokeResetWarehousePasswordResult'>;
}

const User2Model: ReduxSagaModel<
  State,
  {
    saveInvokeCreateWarehousePasswordResult: SaveInvokeCreateWarehousePasswordResult;
    saveInvokeResetWarehousePasswordResult: SaveInvokeResetWarehousePasswordResult;
  }
> = {
  namespace: 'user2',
  state: getInitialState(),
  effects: {
    *createWarehousePassword(_, { call, put, select }) {
      try {
        const currentUser = yield select((s: ReduxState) => s.user.currentUser);
        assertIsUser(currentUser, 'BEEM220712142309');
        const currentOrganization = yield select((s: ReduxState) => s.organization.current);
        assertIsOrganization(currentOrganization, 'BEEM220712161322');
        const password = yield call(
          currentUser.createOrUpdateWarehouseCredentials,
          currentOrganization,
        );
        assertIsString(password, 'BEEM220710102724');
        yield put<SaveInvokeCreateWarehousePasswordResult>({
          type: 'saveInvokeCreateWarehousePasswordResult',
          payload: { invokeCreateWarehousePasswordResult: { success: true, data: password } },
        });
      } catch (e) {
        yield put<SaveInvokeCreateWarehousePasswordResult>({
          type: 'saveInvokeCreateWarehousePasswordResult',
          payload: {
            invokeCreateWarehousePasswordResult: {
              success: false,
              error: handleUserError(e, `${ns}.createWarehousePassword.error`),
            },
          },
        });
      }
    },
    *resetWarehousePassword(_, { call, put, select }) {
      try {
        const currentUser = yield select((s: ReduxState) => s.user.currentUser);
        assertIsUser(currentUser, 'BEEM220712142310');
        const currentOrganization = yield select((s: ReduxState) => s.organization.current);
        assertIsOrganization(currentOrganization, 'BEEM220712161323');
        const password = yield call(currentUser.resetWarehouseCredentials, currentOrganization);
        assertIsString(password, 'BEEM220710102725');
        yield put<SaveInvokeResetWarehousePasswordResult>({
          type: 'saveInvokeResetWarehousePasswordResult',
          payload: { invokeResetWarehousePasswordResult: { success: true, data: password } },
        });
      } catch (e) {
        yield put<SaveInvokeResetWarehousePasswordResult>({
          type: 'saveInvokeResetWarehousePasswordResult',
          payload: {
            invokeResetWarehousePasswordResult: {
              success: false,
              error: handleUserError(e, `${ns}.resetWarehousePassword.error`),
            },
          },
        });
      }
    },
  },
  reducers: {
    resetAll() {
      return getInitialState();
    },
    saveInvokeCreateWarehousePasswordResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeResetWarehousePasswordResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default User2Model;
