import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M13.572 1a1 1 0 0 1 .723.31l2.481 2.595c1.398 1.463-.011 3.839-1.965 3.313L14 7v7.5a1.5 1.5 0 0 1-3 0V11l-1.605-.159A2.857 2.857 0 0 1 7 9l-.949-.316A3 3 0 0 1 4 5.838v-.181a4 4 0 0 1 1.172-2.829l1.535-1.535A1 1 0 0 1 7.414 1h6.159z"
        fill="#fff"
      />
      <path
        d="M13.572 1a1 1 0 0 1 .723.31l2.481 2.595c1.398 1.463-.011 3.839-1.965 3.313L14 7v7.5a1.5 1.5 0 0 1-3 0V11l-1.605-.159A2.857 2.857 0 0 1 7 9l-.949-.316A3 3 0 0 1 4 5.838v-.181a4 4 0 0 1 1.172-2.829l1.535-1.535A1 1 0 0 1 7.414 1h6.159z"
        fill="#B5BFD2"
        fillOpacity=".2"
      />
      <path
        d="M12.5 15a.5.5 0 0 0 .5-.5V5.695l2.07.557c.978.263 1.682-.925.983-1.656L13.573 2H7.413L5.88 3.536A3 3 0 0 0 5 5.656v.182a2 2 0 0 0 1.368 1.897l1.401.467.167.447a1.857 1.857 0 0 0 1.557 1.197l2.507.248V14.5a.5.5 0 0 0 .5.5zm2.5-.5a2.5 2.5 0 0 1-5 0v-2.594l-.703-.07A3.858 3.858 0 0 1 6.27 9.812l-.536-.179A4 4 0 0 1 3 5.838v-.181A5 5 0 0 1 4.464 2.12L6 .586A2 2 0 0 1 7.414 0h6.159a2 2 0 0 1 1.445.618L17.5 3.214c1.988 2.08.19 5.392-2.499 5.058V14.5z"
        fill="#B5BFD2"
      />
      <g>
        <path d="M16 23v-3a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v3h7z" fill="#fff" />
        <path d="M16 23v-3a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v3h7z" fill="#B5BFD2" fillOpacity=".2" />
        <path d="M8 20a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2v4H8v-4zm7 0h-5v2h5v-2z" fill="#B5BFD2" />
        <path d="M5 23a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1z" fill="#B5BFD2" />
      </g>
    </g>
  </svg>
);
