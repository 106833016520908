export default {
  "header": {
    "notification": {
      "tooltip": "Subscribe to notifications",
      "tooltip_active": "Unsubscribe to notifications"
    },
    "share": {
      "modal_title": "Share access to",
      "modal_subtitle": "Share with",
      "button": "Done",
      "tooltip": "Shared with {workspaces}"
    },
    "trigger": {
      "CRON": { "title": "Updated {readable} ({offset})", "subtitle": " Next run {next}", "tooltip": "Cron expression: {cron}" },
      "SCHEDULED": { "title": "Updated {readable} ({offset})", "subtitle": " Next run {next}", "tooltip": "Scheduled trigger" },
      "MANUAL": { "title": "This dataset does not update automatically", "tooltip": "Manual trigger" }
    }
  }
};
