import type { CallEffect, SelectEffect } from 'redux-saga/effects';
import type { ReduxState } from '@/models/types';
import { getDvaApp } from 'umi';
import { call, select } from 'redux-saga/effects'; // note that umi seems to add some additional logic to the "put" effect, so don't use it directly from 'redux-saga' lib
import { assertIsDefined } from './typeChecks';

export function* sgcall<T extends () => Promise<unknown>>(
  method: T,
): Generator<CallEffect, Awaited<ReturnType<T>>, Awaited<ReturnType<T>>> {
  const temp = yield call(method);
  return temp;
}

export function* sgselect<T extends (s: ReduxState) => unknown, L extends string | undefined>(
  method: T,
  label?: L,
): Generator<
  SelectEffect,
  L extends string ? NonNullable<ReturnType<T>> : ReturnType<T>,
  ReturnType<T>
> {
  const temp = yield select<ReduxState>(method);
  if (label) assertIsDefined(temp, label);
  return temp as L extends string ? NonNullable<ReturnType<T>> : ReturnType<T>; // this type cast is needed because TS is not smart enough yet
}

let jestState: unknown = null;
export function setJestState(p: unknown): void {
  jestState = p;
}

export function getReduxState(): ReduxState {
  if (jestState) {
    return {
      // @ts-expect-error
      organization: {
        // @ts-expect-error
        currentOrganization: jestState.organizations[0].id,
        // @ts-expect-error
        currentWorkspace: jestState.workspaces[0].id,
        // @ts-expect-error
        currentOrganizationTimezone: jestState.organizations[0].timezone,
      },
    };
  }
  // eslint-disable-next-line no-underscore-dangle
  return getDvaApp()._store.getState();
}
