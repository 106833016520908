import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24" viewBox="0 -1 24 24">
      <g stroke={props.color} strokeWidth="2" fill="none" fillRule="evenodd">
        <rect x="1" y="1" width="6" height="6" rx="2" />
        <rect x="17" y="8" width="6" height="6" rx="2" />
        <rect x="1" y="15" width="6" height="6" rx="2" />
        <path d="M7 4h3a2 2 0 012 2v5h4M7 18h3a2 2 0 002-2v-5h4" />
      </g>
    </svg>
  );
};
