import * as React from 'react';

export default ({ color }) => (
  <svg width="19" height="20">
    <g stroke={color || '#0A132B60'} strokeWidth="2" fill="none" fillRule="evenodd">
      <path
        d="M16.709 15H2.291a.871.871 0 0 1-.603-1.5l.12-.115A4.692 4.692 0 0 0 3.25 10h0l.137-3.146a6.12 6.12 0 0 1 12.226 0L15.75 10h0c0 1.278.521 2.5 1.443 3.385l.12.115a.871.871 0 0 1-.604 1.5z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.375 16c0 1.657 1.4 3 3.125 3 1.726 0 3.125-1.343 3.125-3" />
    </g>
  </svg>
);
