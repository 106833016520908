import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="State=Static">
      <path
        id="Icon Close"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2955 7.29289C15.686 6.90237 16.3191 6.90237 16.7097 7.29289C17.1002 7.68342 17.1002 8.31658 16.7097 8.70711L13.4155 12.0013L16.7097 15.2955C17.1002 15.686 17.1002 16.3191 16.7097 16.7097C16.3191 17.1002 15.686 17.1002 15.2955 16.7097L12.0013 13.4155L8.70711 16.7097C8.31658 17.1002 7.68342 17.1002 7.29289 16.7097C6.90237 16.3191 6.90237 15.686 7.29289 15.2955L10.5871 12.0013L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L12.0013 10.5871L15.2955 7.29289Z"
        fill={props.fill || '#6C7280'}
      />
    </g>
  </svg>
);
