import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" fill="none" viewBox={viewBox} style={style}>
    <path
      d="M15.247 2c9.82 0 17.783 8.04 17.783 17.957 0 9.916-7.963 17.956-17.783 17.956H6v-3.232h9.247c8.055 0 14.582-6.59 14.582-14.724S23.302 5.232 15.247 5.232H6V2h9.247z"
      fill="#3067DB"
    />
    <path
      d="M15.247 5.232c8.055 0 14.582 6.591 14.582 14.725s-6.527 14.724-14.582 14.724H6V31.45h9.247c6.287 0 11.38-5.144 11.38-11.492 0-6.348-5.097-11.492-11.38-11.492H6V5.232h9.247z"
      fill="#3982E8"
    />
  </svg>
);
