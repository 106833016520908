import * as React from 'react';

export default () => {
  return (
    <svg width="40" height="40">
      <defs>
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          id="member-content-a"
        />
        <path d="M8 10A5 5 0 108 0a5 5 0 000 10z" id="member-content-b" />
        <path
          d="M3.5 13h9a3.5 3.5 0 013.5 3.5V19a1 1 0 01-1 1H1a1 1 0 01-1-1v-2.5A3.5 3.5 0 013.5 13z"
          id="member-content-c"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="#FFF" href="#member-content-a" />
          <use fillOpacity=".2" fill="#0067F4" href="#member-content-a" />
        </g>
        <g transform="translate(12 10)">
          <use fill="#FFF" href="#member-content-b" />
          <path
            stroke="#0067F4"
            d="M8 .5c1.243 0 2.368.504 3.182 1.318A4.486 4.486 0 0112.5 5a4.486 4.486 0 01-1.318 3.182A4.486 4.486 0 018 9.5a4.486 4.486 0 01-3.182-1.318A4.486 4.486 0 013.5 5c0-1.243.504-2.368 1.318-3.182A4.486 4.486 0 018 .5z"
            strokeLinejoin="square"
            fillOpacity=".2"
            fill="#0067F4"
          />
        </g>
        <g strokeLinejoin="round" transform="translate(12 10)">
          <use fill="#FFF" href="#member-content-c" />
          <path
            stroke="#0067F4"
            d="M12.5 12.5c1.105 0 2.105.448 2.828 1.172A3.987 3.987 0 0116.5 16.5h0V19c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0H1c-.414 0-.79-.168-1.06-.44A1.495 1.495 0 01-.5 19h0v-2.5c0-1.105.448-2.105 1.172-2.828A3.987 3.987 0 013.5 12.5h0z"
            fillOpacity=".2"
            fill="#0067F4"
          />
        </g>
      </g>
    </svg>
  );
};
