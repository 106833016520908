import * as React from 'react';

export default ({ color }) => (
  <svg width="25" height="25" fill="none">
    <g stroke={color || '#fff'} strokeWidth="2">
      <path
        clipRule="evenodd"
        d="M11 4l10 10-2 2a7.071 7.071 0 0 1-10 0v0A7.071 7.071 0 0 1 9 6l2-2z"
        strokeLinejoin="round"
      />
      <path d="M19.5 11.5L23 8M13.5 5.5L17 2M5 20l3.5-3.5" strokeLinecap="round" />
    </g>
  </svg>
);
