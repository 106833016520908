import type * as A from '@/api';
import { Auth } from '@/aws-config';
import { logger } from '@/utils/logger';
import { graphql } from '@/utils/graphql';
import User from './user';
import Workspace from './workspace';
import Source from './source';
import Integration from './integration';
import ConnectionLite from './connectionLite';
import { addBackendSuffix } from '@/utils/namingConvention';
import { assertIsDefined } from '@/utils/typeChecks';

const ns = 'app.services.connection';

type ConnectionConstructorInput = A.GetConnectionOnlyNativeQuery['getConnection'];

type ConnectionCreateInput = {
  organizationId: string;
  workspaceId: string;
  name?: string;
  fivetranConnectionId?: string;
  integrationId?: string;
  credentialsId?: string;
};

type ConnectionUpdateInput = { id: string } & Partial<ConnectionCreateInput>;

// this is only for native connections, not fivetran ones
// we may refactor in the future when we have the needs to manage both types
export default class Connection {
  readonly id: string;

  readonly name: string;

  readonly organizationId: string;

  readonly workspaceId: string;

  readonly owner: User;

  readonly integration: Integration;

  readonly credentialsId: string;

  private constructor(input: ConnectionConstructorInput) {
    assertIsDefined(input.integration, 'BEEM231108093322');
    assertIsDefined(input.credentialsId, 'BEEM231108093323');
    this.id = input.id;
    this.name = input.name ?? '';
    this.organizationId = input.organizationId;
    this.workspaceId = input.workspaceId;
    this.owner = new User(input.owner);
    this.integration = new Integration(input.integration);
    this.credentialsId = input.credentialsId;
  }

  static async getConnectionById(id: string): Promise<Connection> {
    logger.info(id, { label: 'app.services.connection.getConnectionById.id' });
    const data = await graphql('getConnectionOnlyNative', { id });
    return new Connection(data);
  }

  // static async getNativeConnectionsByOrganization(organizationId: string): Promise<Connection[]> {
  //   logger.info(organizationId, {
  //     label: 'app.services.connection.getNativeConnectionsByOrganization.organizationId',
  //   });
  //   const data = await graphql('listConnectionsByOrganizationNative', { organizationId });
  //   return data.items.map((el) => new Connection(el));
  // }

  // static async getFivetranConnectionsByOrganization(organizationId: string) {
  //   logger.info(organizationId, {
  //     label: 'app.services.connection.getFivetranConnectionsByOrganization.organizationId',
  //   });
  //   const data = await graphql('listConnectionsByOrganizationFivetran', { organizationId });
  //   return data.items;
  // }

  static async getNativeConnectionsByWorkspace(workspaceId: string): Promise<Connection[]> {
    logger.info(workspaceId, {
      label: 'app.services.connection.getNativeConnectionsByWorkspace.workspaceId',
    });
    const data = await graphql('listConnectionsByWorkspaceNative', {
      workspaceId,
      filter: { isDeleted: { ne: true } },
    });
    return data.items.map((el) => new Connection(el));
  }

  static async getFivetranConnectionsByWorkspace(workspaceId: string) {
    logger.info(workspaceId, {
      label: 'app.services.connection.getFivetranConnectionsByWorkspace.workspaceId',
    });
    const data = await graphql('listConnectionsByWorkspaceFivetran', {
      workspaceId,
      filter: { isDeleted: { ne: true } },
    });
    return data.items;
  }

  static async getConnectionsIdAndNameByOrganization(
    organizationId: string,
  ): Promise<ConnectionLite[]> {
    logger.info(organizationId, {
      label: 'app.services.connection.getConnectionsIdAndNameByOrganization.organizationId',
    });
    const data = await graphql('listConnectionsByOrganizationNativeOnlyIdAndName', {
      organizationId,
      filter: { isDeleted: { ne: true } },
    });
    return data.items.map((el) => {
      assertIsDefined(el.integration, 'BEEM231108093324');
      return new ConnectionLite({ id: el.id, integrationName: el.integration.name });
    });
  }

  static async create(input: ConnectionCreateInput): Promise<string> {
    logger.info({ label: 'app.services.connection.create.input', message: input });
    const ownerId = (await Auth.currentAuthenticatedUser()).getUsername();
    const data = await graphql('createConnection', { input: { ...input, ownerId } });
    return data.id;
  }

  static async update(input: ConnectionUpdateInput): Promise<void> {
    logger.info({ label: 'app.services.connection.update.input', message: input });
    await graphql('updateConnection', { input });
  }

  static async softDeleteFivetranConnection(id: string): Promise<void> {
    const { fivetranConnection } = await graphql('getConnectionOnlyFivetran', { id });
    assertIsDefined(fivetranConnection, 'BEEM2403221148');

    const deletedConnector = await graphql('fivetranDeleteConnector', {
      query: { connectorId: fivetranConnection.id },
    });
    if (deletedConnector.code !== 'Success')
      throw new Error(
        `Could not delete Fivetran connection. Error message: ${deletedConnector.message}`,
      );
    await graphql('beemAgentFivetranUndeployConnector', {
      query: { glueDatabaseName: fivetranConnection.glueDatabaseName },
    });

    await graphql('updateFivetranConnection', {
      input: { id: fivetranConnection.id, isDeleted: true },
    });

    await graphql('updateConnection', { input: { id, isDeleted: true } });
  }

  static async deployFivetranSourceIngestions(fivetranConnectionId: string): Promise<void> {
    logger.info({
      label: `${ns}.deployFivetranSourceIngestions.fivetranConnectionId`,
      message: fivetranConnectionId,
    });
    const fivetranConnection = await graphql('getFivetranConnection', { id: fivetranConnectionId });
    if (fivetranConnection.isDeployed) throw new Error('BEEM240115161212');
    const sourceId = await Source.getSourceIdByFivetranConnectionId(fivetranConnectionId);
    const source = await Source.getSourceById(sourceId);
    const workspace = await Workspace.getWorkspaceById(source.workspaceId);
    const workspaceSeq = addBackendSuffix(String(workspace.seq));
    await graphql('beemAgentFivetranDeployConnector', {
      query: {
        fivetranServiceName: fivetranConnection.serviceName,
        fivetranConnectionId,
        fivetranConnectionName: fivetranConnection.name,
        glueDatabaseName: fivetranConnection.glueDatabaseName,
        workspaceSeq,
        dynamoDbSourceId: source.id,
        dynamoDbWorkspaceId: workspace.id,
      },
    });
    await graphql('updateFivetranConnection', {
      input: { id: fivetranConnectionId, isDeployed: true },
    });
  }
}
