import * as React from 'react';

export default () => (
  <svg width="24" height="17">
    <g fill="#DADEE6" stroke="#B5BFD2" fillRule="evenodd">
      <path d="M9.72.5a1.5 1.5 0 0 1 1.238.654h0l.94 1.376a1.5 1.5 0 0 0 1.239.654h7.555c.415 0 .79.168 1.061.44.271.271.44.646.44 1.06h0V15c0 .414-.169.79-.44 1.06-.271.272-.646.44-1.06.44h0-18c-.415 0-.79-.168-1.061-.44a1.495 1.495 0 0 1-.44-1.06h0V2c0-.414.168-.79.44-1.06.271-.272.646-.44 1.06-.44H9.72z" />
      <path d="M17.974 1.5a1.5 1.5 0 0 1 1.236.65h0l.939 1.366a1.5 1.5 0 0 0 1.236.65c.292 0 .517.047.667.152h0L22.192 15a1.495 1.495 0 0 1-1.5 1.5h0-18c-.414 0-.789-.168-1.06-.44a1.495 1.495 0 0 1-.44-1.06h0V4.167h9.974a1.5 1.5 0 0 0 1.236-.65h0l.94-1.367a1.5 1.5 0 0 1 1.236-.65h0z" />
    </g>
  </svg>
);
