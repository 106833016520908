import * as React from 'react';

export default () => (
  <svg width="40" height="40">
    <defs>
      <rect id="a" x="0" y="0" width="20" height="24" rx="1" />
      <path
        d="M8.368 7h3.264a1.13 1.13 0 0 1 1.099 1l.792 8c.055.552-.517 1-1.277 1H7.754c-.76 0-1.332-.448-1.277-1l.792-8c.055-.552.547-1 1.1-1z"
        id="b"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
        fill="#FFC600"
        opacity=".1"
      />
      <g transform="translate(10 8)">
        <use fill="#FFF" />
        <rect
          stroke="#FFC600"
          strokeLinejoin="square"
          fillOpacity=".196"
          fill="#FFC600"
          x=".5"
          y=".5"
          width="19"
          height="23"
          rx="1"
        />
        <rect fill="#FFC600" x="2.105" y="2" width="2.105" height="2" rx="1" />
        <rect fill="#FFC600" x="2.105" y="20" width="2.105" height="2" rx="1" />
        <rect fill="#FFC600" x="15.789" y="2" width="2.105" height="2" rx="1" />
        <rect fill="#FFC600" x="15.789" y="20" width="2.105" height="2" rx="1" />
        <g>
          <use fill="#FFF" />
          <path
            stroke="#FFC600"
            d="M11.632 7.5l1.393 8.55c-.299.385-.526.45-.78.45h0-4.49c-.254 0-.481-.065-.637-.187h0l.649-8.264 3.865-.549z"
            strokeLinejoin="square"
            fillOpacity=".196"
            fill="#FFC600"
          />
        </g>
      </g>
    </g>
  </svg>
);
