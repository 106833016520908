export const capitalize = (string: string | undefined) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const randomlyFill = (str: string, length: number) => {
  let rs = `${str}_`;
  while (rs.length < length) rs += Math.random().toString(36).substring(2, 15);
  return rs.substring(0, length);
};
