import * as React from 'react';

export default ({
  stroke,
  width,
  height,
}: {
  stroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11 5H5C3.34315 5 2 6.34315 2 8C2 9.65685 3.34315 11 5 11H11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5Z"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="11" cy="8" r="3" stroke={stroke || '#0A132B'} strokeWidth="2" />
  </svg>
);
