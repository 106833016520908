import * as React from 'react';

function SvgCatalogSidebar(props) {
  return (
    <svg {...props} width={24} height={24}>
      <g fill="none" fillRule="evenodd" stroke={props.color} strokeWidth={2}>
        <path d="M6.565 2.13a.997.997 0 00-1.362.38l-3.06 5.428c-.09.186-.143.356-.083.74.176.257.554.322.94.322h6c.462 0 .811-.104 1.02-1.052l-3.075-5.44a1 1 0 00-.38-.379z" />
        <rect x={2} y={14} width={8} height={8} rx={3} />
        <rect x={14} y={2} width={7} height={7} rx={3.5} />
        <path d="M19.46 14.51a1.994 1.994 0 00-1.415-.586c-.511 0-1.023.195-1.414.586l-2.121 2.12a1.996 1.996 0 000 2.829l2.121 2.121a1.996 1.996 0 002.829 0l2.121-2.121a1.997 1.997 0 000-2.829l-2.121-2.12z" />
      </g>
    </svg>
  );
}

export default SvgCatalogSidebar;
