import * as React from 'react';

export default () => (
  <svg width="40" height="40">
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          // values="0 0 0 0 0.000000 0 0 0 0 0.403922 0 0 0 0 0.956863 0 0 0 0.104240 0"
        />
      </filter>
      <filter id="c">
        <feColorMatrix
          in="SourceGraphic"
          // values="0 0 0 0 0.000000 0 0 0 0 0.403922 0 0 0 0 0.956863 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#a)">
        <path
          d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
          id="b"
          fill="#FFF"
        />
        <path
          d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
          id="b"
          fillOpacity=".2"
          fill="#0067F4"
        />
      </g>
      <g filter="url(#c)">
        <g transform="translate(11.5 9.5)" stroke="#1674f4">
          <path d="M9 2a9 9 0 1 0 6.387 2.659M9 6a5 5 0 1 0 4.002 2.002" strokeLinecap="round" />
          <circle cx="9" cy="11" r="1" />
          <path strokeLinecap="round" d="M9 11V0" />
        </g>
      </g>
    </g>
  </svg>
);
