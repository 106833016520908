/* eslint-disable no-await-in-loop */
import type { ReduxSagaModel } from './types';
import type { BedrockMessage } from '@/utils/beemAgentTypes';
import Dataset from '@/services/dataset';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { graphql, subscribe } from '@/utils/graphql';
import { logger } from '@/utils/logger';
import { assertIsDefined } from '@/utils/typeChecks';
import { handleSystemError } from '@/utils/handleError';

const ns = 'app.models.insight';

function isValidAssistantMessage(message: BedrockMessage): boolean {
  return (
    message.role === 'assistant' &&
    !message.content.some((item) => item.toolUse) &&
    !message.content.some((item) => item.toolResult)
  );
}

export type State = {
  queryLoading: boolean;
  chatHistory: BedrockMessage[];
  generatedDescription: string;
};

const initialState = {
  queryLoading: false,
  chatHistory: [],
  generatedDescription: '',
};

type QueryLoadingAction = {
  type: 'queryLoading';
  payload: Pick<State, 'queryLoading'>;
};

type SaveChatHistoryAction = {
  type: 'saveChatHistory';
  payload: Pick<State, 'chatHistory'>;
};

type SaveGeneratedDescriptionAction = {
  type: 'saveGeneratedDescription';
  payload: Pick<State, 'generatedDescription'>;
};

export type StartQueryEffectPayload = {
  prompt: string;
};

export type GenerateDescriptionEffectPayload = {
  context: string;
  description: string;
};

const InsightModel: ReduxSagaModel<
  State,
  {
    queryLoading: QueryLoadingAction;
    saveChatHistory: SaveChatHistoryAction;
    saveGeneratedDescription: SaveGeneratedDescriptionAction;
  }
> = {
  namespace: 'insight',
  state: initialState,
  effects: {
    *invokeMockQuery(_, { call, put }) {
      const responses = [
        'What specific assistance do you require?',
        'Could you please provide more details?',
        'How can I support you right now?',
        "I'm ready to assist. What's on your mind?",
        'Let me know how I can be of service to you right now.',
        "How can I support you today? Let's address your needs.",
        'Tell me more about your requirements so I can assist you better.',
        "I'm here to help. Please specify what kind of assistance you need so I can assist you effectively.",
        "Feel free to ask me anything! I'm here to provide you with the support you need.",
        'Let me know how I can be of service to you right now. Your satisfaction is my priority.',
      ];
      const randomIndex = Math.floor(Math.random() * responses.length);

      yield put<QueryLoadingAction>({
        type: 'queryLoading',
        payload: {
          queryLoading: true,
        },
      });

      const messages = yield* sgselect((s) => s.insight.chatHistory);
      const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

      yield call(delay, 1000);

      yield put<SaveChatHistoryAction>({
        type: 'saveChatHistory',
        payload: {
          chatHistory: [
            ...messages,
            {
              role: 'assistant',
              content: [
                {
                  text: responses[randomIndex],
                },
              ],
            },
          ],
        },
      });

      yield put<QueryLoadingAction>({
        type: 'queryLoading',
        payload: {
          queryLoading: false,
        },
      });
    },
    *invokeConverse({ payload: { prompt } }: { payload: StartQueryEffectPayload }, { put }) {
      yield put<QueryLoadingAction>({
        type: 'queryLoading',
        payload: {
          queryLoading: true,
        },
      });

      const currentUser = yield* sgselect((s) => s.user.currentUser);
      const currentWorkspace = yield* sgselect(
        (s) => s.organization.currentWorkspace,
        'BEEM240711183312',
      );
      const chatHistory = (yield* sgselect((s) => s.insight.chatHistory)).filter(
        (el) => el.role === 'user' || el.role === 'assistant',
      );

      try {
        const newMessages: BedrockMessage[] = yield* sgcall(async () => {
          // get list of datasets that are enabled for AI
          const ownedDatasets = await Dataset.getDatasetsOwnByWorkspace(
            currentWorkspace,
            undefined,
            { enableSearchByAI: { eq: true } },
          );
          let sharedDatasets = await Dataset.getDatasetsSharedWithWorkspace(currentWorkspace);
          sharedDatasets = sharedDatasets.filter((el) => el.enableSearchByAI);
          const datasetAttrs = ownedDatasets.concat(sharedDatasets).map((dataset) => {
            const id = dataset.dbViewsQualifiedName;
            assertIsDefined(id, 'BEEM240712085850');
            return { id, description: `${dataset.description}\n${dataset.createTableStatement}` };
          });
          if (datasetAttrs.length === 0)
            throw new Error('There is no dataset enabled for Search By AI. Please fix this.');

          // remove the messages after the last valid assistant message
          let found = false;
          while (!found) {
            const lastMessage = chatHistory.pop();
            if (!lastMessage) break;
            if (isValidAssistantMessage(lastMessage)) {
              chatHistory.push(lastMessage);
              found = true;
            }
          }

          // vinh1506 implement properly to use graphql subscription to obtain response asynchronously
          let messages: string | undefined;
          let metadata: string | undefined;
          let subscriptionResultContent: string = '';
          subscribe('onCreateInsightMessage', { userId: currentUser.id }, (result, done) => {
            subscriptionResultContent = result.content;
            done();
          });
          try {
            ({ messages, metadata } = await graphql('beemAgentInsightConverse', {
              query: {
                datasets: JSON.stringify(datasetAttrs),
                prompt,
                messages: JSON.stringify(chatHistory),
              },
            }));
          } catch (e1) {
            handleSystemError(e1, `${ns}.invokeConverse.graphql.catch`);
            let done = false;
            const start = Date.now();
            while (!done && Date.now() - start < 3 * 60 * 1000) {
              if (subscriptionResultContent) {
                const temp1 = JSON.parse(subscriptionResultContent);
                messages = JSON.stringify(temp1.messages);
                metadata = JSON.stringify(temp1.metadata);
                done = true;
              } else {
                await new Promise((resolve) => setTimeout(resolve, 10 * 1000));
              }
            }
            if (!done) throw new Error(`Waited too long for AI response!`);
          }
          logger.debug({ label: `${ns}.invokeConverse.messages`, message: messages });
          logger.debug({ label: `${ns}.invokeConverse.metadata`, message: metadata });
          assertIsDefined(messages, 'BEEM240822083901');
          assertIsDefined(metadata, 'BEEM240822083902');

          // process the results
          const rows: BedrockMessage[] = JSON.parse(messages);
          const lastRow = rows[rows.length - 1];
          if (!isValidAssistantMessage(lastRow))
            throw new Error(`Received invalid message: ${JSON.stringify(lastRow)}`);
          return rows;
        });

        yield put<SaveChatHistoryAction>({
          type: 'saveChatHistory',
          payload: {
            chatHistory: newMessages,
          },
        });
      } catch (e) {
        const errMsg = handleSystemError(e, `${ns}.invokeConverse`).formattedString;
        yield put<SaveChatHistoryAction>({
          type: 'saveChatHistory',
          payload: {
            chatHistory: [
              ...chatHistory,
              {
                role: 'error',
                content: [
                  {
                    text: `ERROR: ${errMsg}.\n\nPlease re-enter your question to try again: "${prompt}"`,
                  },
                ],
              },
            ],
          },
        });
      }

      yield put<QueryLoadingAction>({
        type: 'queryLoading',
        payload: {
          queryLoading: false,
        },
      });
    },
    *generateDescription(
      { payload: { context, description } }: { payload: GenerateDescriptionEffectPayload },
      { put },
    ) {
      yield put<QueryLoadingAction>({
        type: 'queryLoading',
        payload: {
          queryLoading: true,
        },
      });

      try {
        const newDescription: string = yield* sgcall(async () => {
          const descriptionGenerationPrompt = `Write a short description or improve the existing one of the following dataset based on the metadata \
            provided. Your response should only contain the description without any chain of thought. Provide the \
            columns details in your answer. Avoid repeating the dataset name in the description. Never attempt to \
            query the table using tools.`;

          const { messages, metadata } = await graphql('beemAgentInsightConverse', {
            query: {
              datasets: JSON.stringify([]),
              prompt: `${descriptionGenerationPrompt} The following context is available: ${context}. The existing description: ${description}`,
              messages: JSON.stringify([]),
            },
          });

          logger.debug({
            label: `${ns}.generateDescription.messages`,
            message: JSON.stringify(messages, null, 2),
          });
          logger.debug({
            label: `${ns}.generateDescription.metadata`,
            message: JSON.stringify(metadata, null, 2),
          });

          return JSON.parse(messages)[1]?.content[0]?.text;
        });

        yield put<SaveGeneratedDescriptionAction>({
          type: 'saveGeneratedDescription',
          payload: {
            generatedDescription: newDescription,
          },
        });
      } catch (e) {
        yield put<SaveGeneratedDescriptionAction>({
          type: 'saveGeneratedDescription',
          payload: {
            generatedDescription: '',
          },
        });
      }
      yield put<QueryLoadingAction>({
        type: 'queryLoading',
        payload: {
          queryLoading: false,
        },
      });
    },
    *resetDescription(_, { put }) {
      yield put<SaveGeneratedDescriptionAction>({
        type: 'saveGeneratedDescription',
        payload: {
          generatedDescription: '',
        },
      });
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    queryLoading(state, { payload }) {
      return { ...state, ...payload };
    },
    saveChatHistory(state, { payload }) {
      return { ...state, ...payload };
    },
    saveGeneratedDescription(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default InsightModel;
