import * as React from 'react';

export default () => {
  return (
    <svg width="24" height="24" viewBox="-2 -2 24 24">
      <path
        d="M11.338 1.55a1.995 1.995 0 00-2.676 0l-7 6.3A2 2 0 001 9.335V18c0 .552.224 1.052.586 1.414.362.362.862.586 1.414.586h4v-7h6v7h4c.552 0 1.052-.224 1.414-.586.362-.362.586-.862.586-1.414V9.336a2 2 0 00-.662-1.487l-7-6.3z"
        stroke="#FFF"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};
