import * as React from 'react';

export default () => (
  <svg width="24" height="24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        stroke="#0A132B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.5 6.5l5 5-5 5"
      />
    </g>
  </svg>
);
