export default {
  "flowList": {
    "title": "Flows",
    "state": "State",
    "state_label": {
      "live": "Live",
      "paused": "Paused",
      "null": " "
    },
    "type": "Type",
    "name": "Name",
    "dataset": "Dataset",
    "last_run": "Last run started at",
    "next_run": "Next run",
    "last_run_status": "Last run status",
    "trigger": "Trigger type",
    "last_run_moment_format": "MMM DD, YYYY [at] hh:mm A",
    "success": "Completed",
    "failure": "Failed",
    "empty": {
      "title": "You have no Flows yet!",
      "description": "Flows are workflows enabling you to sync back data to your other softwares using your datasets from your warehouse.",
      "contact": "Please contact our team to setup your first Flow."
    }
  }
};
