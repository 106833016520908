import * as React from 'react';

export default () => (
  <svg width="25" height="24">
    <defs>
      <path
        d="M12.223 10.156 22.7 15.623c.399.208.399.546 0 .754l-10.478 5.467c-.4.208-1.047.208-1.446 0L.3 16.377c-.399-.208-.399-.546 0-.754l10.478-5.467c.4-.208 1.047-.208 1.446 0Z"
        id="warehouse-storage-a"
      />
      <path
        d="M12.223 5.156 22.7 10.623c.399.208.399.546 0 .754l-10.478 5.467c-.4.208-1.047.208-1.446 0L.3 11.377c-.399-.208-.399-.546 0-.754l10.478-5.467c.4-.208 1.047-.208 1.446 0Z"
        id="warehouse-storage-b"
      />
      <path
        d="M12.223.156 22.7 5.623c.399.208.399.546 0 .754l-10.478 5.467c-.4.208-1.047.208-1.446 0L.3 6.377C-.1 6.169-.1 5.831.3 5.623L10.777.156c.4-.208 1.047-.208 1.446 0Z"
        id="warehouse-storage-c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <use fill="#FFF" href="#warehouse-storage-a" />
        <use stroke="#0067F4" fillOpacity=".192" fill="#0067F4" href="#warehouse-storage-a" />
      </g>
      <g transform="translate(1 1)">
        <use fill="#FFF" href="#warehouse-storage-b" />
        <use stroke="#0067F4" fillOpacity=".192" fill="#0067F4" href="#warehouse-storage-b" />
      </g>
      <g transform="translate(1 1)">
        <use fill="#FFF" href="#warehouse-storage-c" />
        <use stroke="#0067F4" fillOpacity=".192" fill="#0067F4" href="#warehouse-storage-c" />
      </g>
    </g>
  </svg>
);
