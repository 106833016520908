import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      x="6"
      y="6"
      width="12"
      height="12"
      rx="1"
      stroke={props.color || '#FFF'}
      strokeWidth="2"
    />
    <rect
      x="10"
      y="10"
      width="4"
      height="4"
      stroke={props.color || '#FFF'}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M19 12L21 12" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M19 8L21 8" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M19 16L21 16" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M3 12L5 12" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M3 8L5 8" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M3 16L5 16" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M12 5V3" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M8 5V3" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M16 5V3" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M12 21V19" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M8 21V19" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
    <path d="M16 21V19" stroke={props.color || '#FFF'} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
