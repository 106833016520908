export default {
  "folder": {
    "rename_menu_item": "Rename",
    "rename_modal_dialog": {
      "title": "Rename {type} {name}",
      "name_input": "{type} new name",
      "submit_button": "Update"
    },
    "move_menu_item": "Move",
    "move_modal_dialog": {
      "title": "Move {name}",
      "description": "Select the location where you want to move your {type}.",
      "submit_button": "Done"
    },
    "delete_menu_item": "Delete",
    "delete_modal_dialog": {
      "title": "Delete folder {name} ?",
      "description": "Are you sure you want to delete this folder?",
      "submit_button": "Delete folder",
      "full": {
        "title": "Sorry, you can't delete a folder full.",
        "description": "This folder can't be deleted with its content.",
        "description2": "Please move the content out of the folder before deleting it.",
        "submit_button": "Done"
      }
    },
    "create_menu_item": "+ New Folder",
    "create_modal_dialog": {
      "title": "Create a new {type}.",
      "folder_name_input": "Folder name",
      "folder_tree_select_input": "Choose {type} location",
      "submit_button": "Create folder"
    },
    "empty": "A new folder will be added here"
  }
};
