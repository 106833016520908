import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <path
      d="M32.35 7.912c-.139-.013-3.088-.232-3.088-.232s-2.05-2.044-2.274-2.27c-.226-.226-.665-.158-.836-.106l-1.146.354c-.685-1.98-1.892-3.798-4.017-3.798-.058 0-.119.003-.18.006-.604-.802-1.353-1.152-2-1.152-4.949 0-7.314 6.22-8.055 9.38a821.367 821.367 0 0 0-3.464 1.08c-1.076.339-1.109.372-1.248 1.389-.108.769-2.917 22.602-2.917 22.602l21.889 4.121 11.861-2.58S32.712 8.42 32.685 8.225a.371.371 0 0 0-.336-.312zM20.645 6.8l-.001.215-3.826 1.191c.737-2.856 2.117-4.237 3.325-4.758.304.766.502 1.868.502 3.352zm-1.974-4.751c.217 0 .43.072.637.215-1.587.75-3.287 2.64-4.005 6.414l-3.024.94c.841-2.879 2.838-7.57 6.393-7.57zm.854 16.82s-1.282-.687-2.851-.687c-2.304 0-2.42 1.452-2.42 1.817 0 1.997 5.18 2.763 5.18 7.44 0 3.68-2.322 6.048-5.454 6.048-3.758 0-5.68-2.35-5.68-2.35l1.005-3.34s1.975 1.705 3.644 1.705c1.088 0 1.53-.863 1.53-1.492 0-2.605-4.249-2.721-4.249-7.002 0-3.6 2.573-7.088 7.768-7.088 2 0 2.99.577 2.99.577l-1.463 4.372zM21.944 6.61l.001-.402c0-1.229-.17-2.22-.443-3.005 1.095.137 1.824 1.39 2.294 2.83-.55.173-1.175.367-1.852.577z"
      fill="#95C675"
    />
    <path
      d="M32.35 7.912c-.139-.013-3.088-.232-3.088-.232s-2.05-2.044-2.274-2.27c-.226-.226-.665-.158-.836-.106l-1.146.354c-.685-1.98-1.892-3.798-4.017-3.798-.058 0-.119.003-.18.006-.604-.802-1.353-1.152-2-1.152-4.949 0-6.17 6.22-6.912 9.38-1.923.597-3.4 3.887-3.574 3.942-1.074.338-1.107.372-1.247 1.389-.109.769-3.951 19.74-3.951 19.74l21.889 4.121 11.861-2.58S32.712 8.42 32.685 8.225a.371.371 0 0 0-.336-.312zM20.645 6.8l-.001.215-3.826 1.191c.737-2.856 2.117-4.237 3.325-4.758.304.766.502 1.868.502 3.352zm-1.974-4.751c.217 0 .43.072.637.215-1.587.75-3.287 2.64-4.005 6.414l-3.024.94c.841-2.879 2.838-7.57 6.393-7.57zm.854 16.82s-1.282-.687-2.851-.687c-2.304 0-2.42 1.452-2.42 1.817 0 1.997 5.18 2.763 5.18 7.44 0 3.68-2.322 6.048-5.454 6.048-3.758 0-5.68-2.35-5.68-2.35l1.005-3.34s1.975 1.705 3.644 1.705c1.088 0 1.53-.863 1.53-1.492 0-2.605-4.249-2.721-4.249-7.002 0-3.6 2.573-7.088 7.768-7.088 2 0 2.99.577 2.99.577l-1.463 4.372zM21.944 6.61l.001-.402c0-1.229-.17-2.22-.443-3.005 1.095.137 1.824 1.39 2.294 2.83-.55.173-1.175.367-1.852.577z"
      fill="#79B259"
    />
    <path
      d="m25.014 39.286 11.861-2.58S32.712 8.42 32.685 8.225a.37.37 0 0 0-.336-.312c-.138-.013-3.087-.232-3.087-.232s-2.05-2.044-2.274-2.27c-.12-.12-.3-.155-.47-.156-.45.762-.356 2.21-.356 2.21L24.767 39.24l.247.047z"
      fill="#55932C"
    />
    <path
      d="M20.99 1.86c-.06 0-.12.003-.181.007-.604-.802-1.353-1.153-2-1.153-4.929 0-7.296 6.171-8.047 9.342l1.53-.474c.846-2.88 2.84-7.533 6.38-7.533.217 0 .43.072.637.215-1.583.747-3.278 2.631-3.999 6.384l1.519-.47c.738-2.837 2.113-4.211 3.317-4.73.302.767.502 1.868.502 3.352 0 .068 0 .132-.002.196l1.299-.402.001-.385c0-1.229-.17-2.22-.443-3.005 1.091.137 1.82 1.384 2.289 2.818l1.211-.376c-.687-1.973-1.893-3.785-4.014-3.785z"
      fill="#4A7A2B"
    />
    <path
      d="M29.757 7.718c-.296-.024-.496-.038-.496-.038s-2.05-2.044-2.274-2.27c-.12-.12-.3-.155-.469-.156-.45.762-.357 2.21-.357 2.21L24.766 39.24l.248.047 6.311-1.373-1.568-30.195z"
      fill="#4C822A"
    />
  </svg>
);
