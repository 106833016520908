import * as React from 'react';

export default ({ color }) => (
  <svg width="24" height="24" fill="none">
    <path
      d="M10 7l5 5-5 5"
      stroke={color || '#6C7280'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
