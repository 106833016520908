import * as A from '@/api';
import { logger } from '@/utils/logger';
import { graphql } from '@/utils/graphql';
import { PartialBy } from './types';

type CredentialsConstructorInput = A.GetCredentialsQuery['getCredentials'];

type CredentialsCreateInput = PartialBy<CredentialsConstructorInput, 'id'>;

type CredentialsUpdateInput = Partial<CredentialsConstructorInput> & { id: string };

export default class Credentials {
  readonly id: string;

  readonly username: string;

  readonly password: string;

  private constructor(input: CredentialsConstructorInput) {
    this.id = input.id;
    this.username = input.username;
    this.password = input.password;
  }

  static async create(input: CredentialsCreateInput): Promise<string> {
    logger.info({ label: 'app.services.credentials.create.input', message: input });
    const data = await graphql('createCredentials', { input });
    return data.id;
  }

  static async update(input: CredentialsUpdateInput): Promise<void> {
    logger.info({ label: 'app.services.credentials.update.input', message: input });
    await graphql('updateCredentials', { input });
  }

  static async validateIntegrationCredentials(
    query: A.ValidateIntegrationCredentialsQueryInput,
  ): Promise<string> {
    logger.info({
      label: 'app.services.credentials.validateIntegrationCredentials.input',
      message: query,
    });

    const data = await graphql('validateIntegrationCredentials', { query });
    return data;
  }
}
