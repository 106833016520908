const connectorTypesWithHistoryFeature = [
  'adp_workforce_now',
  'adyen',
  'airtable',
  'amazon_ads',
  'apple_search_ads',
  'aurora_postgres',
  'big_commerce',
  'bigquery_db',
  'braze',
  'cosmos',
  'db2',
  'db2i_hva',
  'dynamodb',
  'eloqua',
  'facebook_ads',
  'facebook_pages',
  'financial_force',
  'financial_force_sandbox',
  'firebase',
  'front',
  'greenhouse',
  'heap',
  'helpscout',
  'heroku_postgres',
  'hubspot',
  'intercom',
  'iterable',
  'jira',
  'kustomer',
  'lever',
  'light_speed_retail',
  'linkedin_ads',
  'linkedin_company_pages',
  'marin',
  'marketo',
  'mavenlink',
  'medallia',
  'mixpanel',
  'mongo',
  'optimizely',
  'outbrain',
  'pendo',
  'pinterest_ads',
  'pipedrive',
  'postgres',
  'postgres_rds',
  'recharge',
  'reddit_ads',
  'redshift_db',
  'salesforce',
  'salesforce_marketing_cloud',
  'salesforce_sandbox',
  'snapchat_ads',
  'snowflake_db',
  'sql_server_hva',
  'sql_server_sap_ecc_hva',
  'square',
  'stripe',
  'stripe_test',
  'survey_monkey',
  'twilio',
  'twitter',
  'twitter_ads',
  'typeform',
  'uservoice',
  'workday',
  'yahoo_dsp',
  'yahoo_gemini',
  'zendesk',
  'zendesk_chat',
  'zendesk_sell',
  'zendesk_sunshine',
];

// provide the connector type. Example: hubspot
export async function getFivetranConnectorHistoryFlag(connectorType: string): Promise<boolean> {
  return connectorTypesWithHistoryFeature.includes(connectorType);
}

// import axios from 'axios';
// import cheerio from 'cheerio';
// import { logger } from './logger';
// import { graphql } from '@/utils/graphql';
// import type { FivetranConnection } from '@/models/connection';

// const ns = 'utils.fivetranGetHistoryFlag';

// async function getDocumentationLink(connectorType: string): Promise<string | undefined> {
//   try {
//     const { response: rstr } = await graphql('fivetranFetchConnections', {
//       query: { connectorType },
//     });
//     const response = JSON.parse(rstr).data as FivetranConnection;

//     if (!response.link_to_docs) {
//       logger.error({
//         label: `${ns}.getDocumentationLink`,
//         message: 'Documentation link not found',
//       });
//       return undefined;
//     }
//     return response.link_to_docs;
//   } catch (error) {
//     logger.error({
//       label: `${ns}.getDocumentationLink`,
//       message: `Error making request. ${error}`,
//     });
//     return undefined;
//   }
// }

// async function fetchHTML(url: string): Promise<string | undefined> {
//   try {
//     const response = await axios.get(url, {
//       responseType: 'text',
//     });
//     return response.data;
//   } catch (error) {
//     logger.error({ label: `${ns}.fetchHTML`, message: `Error fetching HTML. ${error}` });
//     return undefined;
//   }
// }

// async function findElementWithText(
//   html: string,
//   tagName: string,
//   searchText: string,
// ): Promise<cheerio.Element | undefined> {
//   try {
//     if (!html) return undefined;

//     const $ = cheerio.load(html);
//     const elements = $(tagName).toArray();

//     return elements.find((element) => $(element).text().trim() === searchText);
//   } catch (error) {
//     logger.error({
//       label: `${ns}.findElementWithText`,
//       message: `Error finding element. ${error}`,
//     });
//     return undefined;
//   }
// }

// provide the connector type. Example: hubspot
// export async function getFivetranConnectorHistoryFlag(
//   connectorType: string,
// ): Promise<boolean | undefined> {
//   const documentationLink = await getDocumentationLink(connectorType);
//   if (documentationLink) {
//     const html = await fetchHTML(documentationLink);
//     const element = await findElementWithText(html ?? '', 'a', 'History');

//     if (element) {
//       const tr = cheerio(element).closest('tr');
//       return tr.find('td:nth-child(2)').text().includes('check');
//     }

//     logger.error({ label: `${ns}.getFivetranConnectorHistoryFlag`, message: 'Element not found' });
//   }

//   return undefined;
// }

// const connectorTypes = [];
// const all: string[] = [];
// const errors: string[] = [];
// for (let i = 0; i < connectorTypes.length; i += 1) {
//   const connectorType = connectorTypes[i];
//   try {
//     // eslint-disable-next-line no-await-in-loop
//     const { history } = await graphql('fivetranGetConnectorTypeFeatures', {
//       query: { connectorType },
//     });
//     console.log(connectorType, history);
//     if (history) all.push(connectorType);
//   } catch (e) {
//     console.log(connectorType, 'error');
//     errors.push(connectorType);
//   }
//   console.log(`all ${i}`, all);
//   console.log(`err ${i}`, errors);
// }
