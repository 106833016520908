import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 40}
      height={props.svgHeight || 40}
      viewBox={props.svgViewBox || '0 0 40 40'}
    >
      <defs>
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          id="vf"
        />
        <path
          d="M9 21.5c5.003 0 8.5-1.998 8.5-4.5s-3.497-4.5-8.5-4.5S.5 14.498.5 17s3.497 4.5 8.5 4.5z"
          id="w1"
        />
        <path
          d="M9 15.5c5.003 0 8.5-1.998 8.5-4.5S14.003 6.5 9 6.5c-2.63 0-4.844.552-6.352 1.44C1.287 8.74.5 9.814.5 11c0 2.502 3.497 4.5 8.5 4.5z"
          id="cw"
        />
        <path
          d="M9 9.5c5.003 0 8.5-1.998 8.5-4.5S14.003.5 9 .5.5 2.498.5 5 3.997 9.5 9 9.5z"
          id="e8"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill={props.backgroundColor || '#FFF'} href="#vf" />
          <use fillOpacity=".1" fill={props.backgroundColor || '#8347F2'} href="#vf" />
        </g>
        <g transform="translate(11 9)">
          <use fill="#FFF" href="#w1" />
          <use stroke="#8347F2" fillOpacity=".2" fill="#8347F2" href="#w1" />
        </g>
        <g transform="translate(11 9)">
          <use fill="#FFF" href="#cw" />
          <use stroke="#8347F2" fillOpacity=".2" fill="#8347F2" href="#cw" />
        </g>
        <g transform="translate(11 9)">
          <use fill="#FFF" href="#e8" />
          <use stroke="#8347F2" fillOpacity=".2" fill="#8347F2" href="#e8" />
        </g>
      </g>
    </svg>
  );
};
