export default {
  "monitor": {
    "CATALOG_SOURCE_SCHEDULED_START": "Ingestion for source table {name} has started.",
    "CATALOG_SOURCE_SCHEDULED_SUCCESS":
      "Ingestion for source table {name} has finished&nbsp;<div style='color: #1cc23a; display: flex; alignItems: center;'>successfully<div><div style='color: #dadee6;'>.</div>",
    "CATALOG_SOURCE_SCHEDULED_FAIL": "Ingestion for source table {name} has&nbsp;<div style='color: #f34848; display: flex; alignItems: center;'>failed<div><div style='color: #dadee6;'>.</div>",
    "HUB_DATASET_SCHEDULED_START": "Dataset {name} has started.",
    "HUB_DATASET_SCHEDULED_SUCCESS": "Dataset {name} has run&nbsp;<div style='color: #1cc23a; display: flex; alignItems: center;'>successfully<div><div style='color: #dadee6;'>.</div>",
    "HUB_DATASET_SCHEDULED_TEST_BLOCKING":
      "Dataset {name} run&nbsp;<div style='color: #f34848; display: flex; alignItems: center;'>failed<div><div style='color: #dadee6;'>&nbsp;due to blocking test results.</div>",
    "HUB_DATASET_SCHEDULED_TEST_NONBLOCK":
      "Dataset {name} run&nbsp;<div style='color: #ffd300; display: flex; alignItems: center;'>completed<div><div style='color: #dadee6;'>&nbsp;with non-blocking test results.</div>",
    "HUB_DATASET_SCHEDULED_FAIL": "Dataset {name} run&nbsp;<div style='color: #f34848; display: flex; alignItems: center;'>failed<div><div style='color: #dadee6;'>.</div>",
    "HUB_DATASET_MANUAL_START": "Dataset {name} has started.",
    "HUB_DATASET_MANUAL_SUCCESS": "Dataset {name} has run&nbsp;<div style='color: #1cc23a; display: flex; alignItems: center;'>successfully<div><div style='color: #dadee6;'>.</div>",
    "HUB_DATASET_MANUAL_TEST_BLOCKING":
      "Dataset {name} run&nbsp;<div style='color: #f34848; display: flex; alignItems: center;'>failed<div><div style='color: #dadee6;'>&nbsp;due to blocking test results.</div>",
    "HUB_DATASET_MANUAL_TEST_NONBLOCK":
      "Dataset {name} run&nbsp;<div style='color: #ffd300; display: flex; alignItems: center;'>completed<div><div style='color: #dadee6;'>&nbsp;with non-blocking test results.</div>",
    "HUB_DATASET_MANUAL_FAIL": "Dataset {name} run&nbsp;<div style='color: #f34848; display: flex; alignItems: center;'>failed<div><div style='color: #dadee6;'>.</div>",
    "FLOW_SCHEDULED_START": "Flow {name} has started.",
    "FLOW_SCHEDULED_SUCCESS": "Flow {name} has run&nbsp;<div style='color: #1cc23a; display: flex; alignItems: center;'>successfully<div><div style='color: #dadee6;'>.</div>",
    "FLOW_SCHEDULED_FAIL": "Flow {name} run&nbsp;<div style='color: #f34848; display: flex; alignItems: center;'>failed<div><div style='color: #dadee6;'>.</div>",
    "FLOW_MANUAL_START": "Flow {name} has started.",
    "FLOW_MANUAL_SUCCESS": "Flow {name} has run&nbsp;<div style='color: #1cc23a; display: flex; alignItems: center;'>successfully<div><div style='color: #dadee6;'>.</div>",
    "FLOW_MANUAL_FAIL": "Flow {name} run&nbsp;<div style='color: #f34848; display: flex; alignItems: center;'>failed<div><div style='color: #dadee6;'>.</div>",
    "object_types": {
      "default": "All object types",
      "SOURCE": "Source",
      "DATA_OBJECT": "Source Table",
      "DATASET": "Dataset",
      "FLOW": "Flow"
    },
    "status": {
      "default": "All statuses",
      "START": "Started",
      "SUCCESS": "Success",
      "WARNING": "Warning",
      "FAIL": "Failed",
      "CANCEL": "Cancel"
    },
    "select_status": "Filter by status",
    "empty": "No events for the applied filters.",
    "clear": "Clear Filters",
    "refresh": "Refresh",
    "last_updated": "Last updated ",
    "load_more": "Load more",
    "latest_events": "Showing&nbsp;<div style='color: black;'>{numberOfEvents} most recent events</div>&nbsp;",
    "latest_events_date": "through {latestDate}.",
    "total_events": "Showing&nbsp;<div style='color: black;'>{numberOfEvents} most recent events</div>&nbsp;of {totalEvents} total events.",
    "go_to_button": "Go to object"
  }
};
