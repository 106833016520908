import type * as A from '@/api';
import Workspace from './workspace';
import Connection from './connection';
import DataObject from './dataObject';
import { graphql } from '@/utils/graphql';
import { logger } from '@/utils/logger';
import { assertIsDefined } from '@/utils/typeChecks';
import { getFivetranConnectorHistoryFlag } from '@/utils/fivetranHistory';

const ns = 'app.services.source';

type SourceConstructorInput = A.GetSourceQuery['getSource'];

export default class Source {
  readonly id: string;

  readonly name: string;

  readonly description?: string;

  readonly connectionId: string;

  readonly workspaceId: string;

  readonly cloudResourceId: string;

  private constructor(input: SourceConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.description = input.description;
    this.connectionId = input.connectionId;
    this.workspaceId = input.workspaceId;
    this.cloudResourceId = input.cloudResourceId;

    this.getDataObjects = this.getDataObjects.bind(this);
    this.getFivetranConnectionId = this.getFivetranConnectionId.bind(this);
    this.isFivetranConnectCardCompleted = this.isFivetranConnectCardCompleted.bind(this);
    this.isFivetranConnectionWithHistory = this.isFivetranConnectionWithHistory.bind(this);
  }

  async getSharedWithWorkspaces(): Promise<Workspace[]> {
    const sourceId = this.id;
    const data = await graphql('listSourceWorkspaceSharesBySource', { sourceId });
    return await Promise.all(
      data.items.map(async (el) => {
        return await Workspace.getWorkspaceById(el.workspaceId);
      }),
    );
  }

  async getDataObjects(): Promise<DataObject[]> {
    return DataObject.getDataObjectsBySource(this.id);
  }

  async getFivetranConnectionId(): Promise<string | null> {
    const connections = await Connection.getFivetranConnectionsByWorkspace(this.workspaceId);
    const connection = connections.find((el) => el.id === this.connectionId);
    if (!connection) return null;
    const fivetranConnectionId = connection.fivetranConnection?.id;
    assertIsDefined(fivetranConnectionId, 'BEEM240221134818');
    return fivetranConnectionId;
  }

  async isFivetranConnectCardCompleted(): Promise<boolean | null> {
    let isCompleted: boolean | null = null;
    const connections = await Connection.getFivetranConnectionsByWorkspace(this.workspaceId);
    const connection = connections.find((el) => el.id === this.connectionId);
    if (connection) isCompleted = !!connection.fivetranConnection?.isDeployed;
    return isCompleted;
  }

  async isFivetranConnectionWithHistory(): Promise<boolean | undefined> {
    let hasHistoryFlag;
    const connections = await Connection.getFivetranConnectionsByWorkspace(this.workspaceId);
    const connection = connections.find((el) => el.id === this.connectionId);
    if (connection && connection.fivetranConnection) {
      hasHistoryFlag = await getFivetranConnectorHistoryFlag(
        connection.fivetranConnection.serviceName,
      );
    }
    return hasHistoryFlag;
  }

  static async getSourceById(id: string): Promise<Source> {
    logger.info({ label: `${ns}.getSourceById.id`, message: id });
    const data = await graphql('getSource', { id });
    return new Source(data);
  }

  static async getSourcesOwnByWorkspace(workspaceId: string) {
    logger.info({ label: `${ns}.getSourcesOwnByWorkspace.workspaceId`, message: workspaceId });
    const data = await graphql('listSourcesByWorkspace', { workspaceId });
    return data.items.map((el) => new Source(el));
  }

  static async getSourcesSharedWithWorkspace(workspaceId: string) {
    logger.info({ label: `${ns}.getSourcesSharedWithWorkspace.workspaceId`, message: workspaceId });
    const data = await graphql('listSourceWorkspaceSharesByWorkspace', { workspaceId });
    return data.items.map((el) => new Source(el.source));
  }

  static async getSourcesByWorkspace(workspaceId: string) {
    const a = await Source.getSourcesOwnByWorkspace(workspaceId);
    const b = await Source.getSourcesSharedWithWorkspace(workspaceId);
    return a.concat(b);
  }

  static async getSourceIdByFivetranConnectionId(fivetranConnectionId: string): Promise<string> {
    const rs = await graphql('listConnectionsByFivetranConnection', { fivetranConnectionId });
    const connectionId = rs.items[0].id;
    return Source.getSourceIdByConnectionId(connectionId);
  }

  static async getSourceIdByConnectionId(connectionId: string): Promise<string> {
    const rs2 = await graphql('listSourcesByConnection', { connectionId });
    const sourceId = rs2.items[0].id;
    return sourceId;
  }

  static async checkGlueDatabaseNameUsed(cloudResourceId: string): Promise<boolean> {
    const data = await graphql('listSourcesByCloudResourceId', { cloudResourceId });
    return data.items.length > 0;
  }

  static async deleteIncompleteFivetranSourceAndConnection(id: string): Promise<void> {
    const source = await Source.getSourceById(id);
    const isCompleted = await source.isFivetranConnectCardCompleted();
    if (isCompleted !== false) throw new Error('BEEM240221134815');

    const dataObjects = await source.getDataObjects();
    if (dataObjects.length) throw new Error('BEEM240221134816');

    const fivetranConnectionId = await source.getFivetranConnectionId();
    assertIsDefined(fivetranConnectionId, 'BEEM240221151852');

    await graphql('deleteFivetranConnection', { input: { id: fivetranConnectionId } });
    await graphql('deleteConnection', { input: { id: source.connectionId } });
    await graphql('deleteSource', { input: { id } });
  }
}
