export const onCreatePipelineResult = /* GraphQL */ `
  subscription OnCreatePipelineResult {
    onCreatePipelineResult {
      id
      recordCloudResourceId
      timestamp
      status
      info
    }
  }
`;
export const onCreateInsightMessage = /* GraphQL */ `
  subscription OnCreateInsightMessage($userId: String) {
    onCreateInsightMessage(userId: $userId) {
      id
      workspaceUserId
      userId
      createdAt
      role
      content
    }
  }
`;
