import { backendId } from '@/aws-config';

// once the user is logged in, this will be defined
const MRU_ORG_TZ_KEY = 'beem-recently-used-org-timezone';
export function getCurrentOrganizationTimezone(): string {
  return localStorage?.getItem(MRU_ORG_TZ_KEY) || 'America/Montreal';
}

export const makeNameGlueCompatible = (name: string): string => {
  return name.toLowerCase().replace(/[^0-9a-z]/g, '_');
};

// COPIED TO `cypress/integration/common.ts`
// https://docs.aws.amazon.com/redshift/latest/dg/r_names.html#r_names-standard-identifiers
export function makeNameSqlCompatible(name: string): string {
  return name.toLowerCase().replace(/[\s"]/g, '_');
}

export const addBackendSuffix = (name: string): string => {
  if (backendId === 'wjvcybotv') return name;
  return `${name}@${backendId}`;
};

export const getUrlForViewDatasetTestData = (testUuid: string): string => {
  return `/hub/datasets/test/${testUuid}/view/latest`;
};

export const getCognitoUsernameFromAuth0UserId = (auth0UserId: string): string => {
  return `auth0_${auth0UserId}`;
};

// currentUser,
// currentOrganization,
//
// currentUser: user.currentUser,
// currentOrganization: organization.currentOrganization,
//
// let enabled;
// if (backendId !== 'wjvcybotv') {
//   enabled = true;
// } else if (!(currentUser?.email ?? '').includes('@beemhq.com')) {
//   enabled = false;
// } else if (
//   currentOrganization === /* INT */ '8ae0194d-8b90-49eb-8847-af9bdac5bf8b' ||
//   currentOrganization === /* EMD */ 'db1ebcda-1462-4965-813e-2fdf10990083' ||
//   currentOrganization === /* LGM */ '521f2c4a-a0d6-48de-8459-4e4d74663d93'
// ) {
//   enabled = true;
// } else {
//   enabled = false;
// }
