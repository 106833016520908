import * as React from 'react';

export default () => (
  <svg width="18" height="22">
    <g fill="#DADEE6" stroke="#B5BFD2" fillRule="evenodd">
      <path d="M9 21.5c5.003 0 8.5-1.998 8.5-4.5s-3.497-4.5-8.5-4.5S.5 14.498.5 17s3.497 4.5 8.5 4.5z" />
      <path d="M9 15.5c5.003 0 8.5-1.998 8.5-4.5S14.003 6.5 9 6.5c-2.63 0-4.844.552-6.352 1.44C1.287 8.74.5 9.814.5 11c0 2.502 3.497 4.5 8.5 4.5z" />
      <path d="M9 9.5c5.003 0 8.5-1.998 8.5-4.5S14.003.5 9 .5.5 2.498.5 5 3.997 9.5 9 9.5z" />
    </g>
  </svg>
);
