import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M6 4a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3H6z"
        fill="#fff"
      />
      <path
        d="M6 4a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3H6z"
        fill="#B5BFD2"
        fillOpacity=".197"
      />
      <path
        d="M5 7v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1zM3 7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7z"
        fill="#B5BFD2"
      />
      <path
        d="M8 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zM16 2a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1zM3 8h18v2H3V8z"
        fill="#B5BFD2"
      />
    </g>
  </svg>
);
