import type { ReduxSagaModel } from './types';
import { logger } from '@/utils/logger';

export type State = {
  isSubscribed: boolean | null;
};

interface FetchAction {
  type: 'fetchAction';
  payload: Pick<State, 'isSubscribed'>;
}

interface SaveInvokeUpdateResultAction {
  type: 'saveInvokeUpdateResult';
  payload: Pick<State, 'isSubscribed'>;
}

const initialState: State = { isSubscribed: null };

const NotificationsModel: ReduxSagaModel<
  State,
  { fetchAction: FetchAction; saveInvokeUpdateResult: SaveInvokeUpdateResultAction }
> = {
  namespace: 'notifications',
  state: initialState,
  effects: {
    *fetch({ payload }: { payload: { currentUser: string; currentDataset: string } }, { put }) {
      logger.debug({ label: 'app.models.notifications.fetch.isSubscribed', message: payload });

      // @todo: fetch if current user is subscribed to current dataset and set isSubscribed to proper value
      yield put<FetchAction>({
        type: 'fetchAction',
        payload: { isSubscribed: false },
      });
    },
    *invokeUpdate(
      {
        payload,
      }: { payload: { currentUserId: string; currentDatasetId: string; isSubscribed: boolean } },
      { put },
    ) {
      const { isSubscribed } = payload;
      logger.debug({ label: 'app.models.notifications.fetch.isSubscribed', message: payload });

      // @todo: Update subscription status to opposite of previous subscription value
      yield put<SaveInvokeUpdateResultAction>({
        type: 'saveInvokeUpdateResult',
        payload: { isSubscribed: !isSubscribed },
      });
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    fetchAction(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeUpdateResult(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default NotificationsModel;
