import flatten from 'flat';
import catalog from './fr-FR/catalog';
import source from './fr-FR/source';
import dataObject from './fr-FR/dataObject';

export default flatten({
  ...catalog,
  ...source,
  ...dataObject,
});
