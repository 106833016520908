import type { GenericQuery } from './parsers/genericQuery';
import { RedshiftQuery } from './parsers/redshiftQuery';

export interface IBeemSqlParserOptions {
  dbEngine: string;
}

export class BeemSqlParser {
  private options: IBeemSqlParserOptions;
  private parser: GenericQuery | undefined;

  constructor(options: IBeemSqlParserOptions = { dbEngine: 'redshift' }) {
    this.options = options;
  }

  public parse(query: string) {
    const databaseEngine = this.options.dbEngine.toLowerCase();
    switch (databaseEngine) {
      case 'redshift':
        this.parser = new RedshiftQuery(query);
        this.parser.parse();
        break;

      default:
        throw new Error(`Database engine ${this.options.dbEngine} not supported.`);
    }
  }

  public getSanitizedQuery() {
    return this.parser?.sanitizedQuery;
  }

  public getTokens() {
    return this.parser?.tokens;
  }

  public getTables() {
    return this.parser?.tables ?? [];
  }

  public getErrors() {
    return this.parser?.errors ?? [];
  }
}
