import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24" viewBox="-3 -1 24 24">
      <g
        fill={props.color || '#FFF'}
        stroke={props.color || '#FFF'}
        strokeWidth="2"
        fillRule="evenodd"
        fillOpacity=".2"
      >
        <path d="M9 9a4 4 0 100-8 4 4 0 000 8z" />
        <path d="M4 13h10a3 3 0 013 3v4h0H1v-4a3 3 0 013-3z" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
