import * as React from 'react';

export default ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5 9.5L8 12.5L11 9.5"
      stroke={color || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M8 12.5V3.5" stroke={color || '#0A132B'} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
