import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="State=Static">
        <g id="Group 68">
          <path
            id="Path 10"
            d="M11 15L8 18M8 18L5 15M8 18L8 6"
            stroke={props.color || '#6C7280'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ transition: props.withTransition ? 'stroke 0.3s ease' : 'none' }}
          />
          <path
            id="Path 10_2"
            d="M13 9L16 6M16 6L19 9M16 6V18"
            stroke={props.color || '#6C7280'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ transition: props.withTransition ? 'stroke 0.3s ease' : 'none' }}
          />
        </g>
      </g>
    </svg>
  );
};
