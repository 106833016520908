import * as React from 'react';

export default () => (
  <svg width="80" height="80" fill="none">
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M2.95 7.614a5.651 5.651 0 0 1 4.66-4.69C13.951 1.867 26.976 0 40 0c13 0 26.001 1.86 32.355 2.919a5.666 5.666 0 0 1 4.677 4.731C78.082 14.317 80 28.117 80 40c0 11.883-1.918 25.683-2.968 32.35a5.666 5.666 0 0 1-4.677 4.731C66.001 78.141 53.001 80 40 80c-13.024 0-26.049-1.866-32.39-2.925a5.651 5.651 0 0 1-4.66-4.689C1.895 65.89 0 52.471 0 40 0 27.53 1.896 14.11 2.95 7.614z"
        fill="#fff"
      />
      <path
        d="M2.95 7.614a5.651 5.651 0 0 1 4.66-4.69C13.951 1.867 26.976 0 40 0c13 0 26.001 1.86 32.355 2.919a5.666 5.666 0 0 1 4.677 4.731C78.082 14.317 80 28.117 80 40c0 11.883-1.918 25.683-2.968 32.35a5.666 5.666 0 0 1-4.677 4.731C66.001 78.141 53.001 80 40 80c-13.024 0-26.049-1.866-32.39-2.925a5.651 5.651 0 0 1-4.66-4.689C1.895 65.89 0 52.471 0 40 0 27.53 1.896 14.11 2.95 7.614z"
        fill="#DADEE6"
        fillOpacity=".4"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.92 24a16.07 16.07 0 0 0-10.617 4H25.797a2 2 0 1 0 0 4h8.205a15.823 15.823 0 0 0-2.16 8c0 2.916.788 5.648 2.16 8h-8.205a2 2 0 1 0 0 4h11.506c2.84 2.49 6.564 4 10.618 4C56.743 56 64 48.851 64 40c0-8.851-7.257-16-16.08-16zM35.843 40c0-6.613 5.437-12 12.079-12S60 33.387 60 40s-5.437 12-12.08 12c-6.641 0-12.078-5.387-12.078-12zm13.49-7.418a2 2 0 1 0-2.821 2.836L49.107 38h-7.22a2 2 0 1 0 0 4h7.22l-2.596 2.582a2 2 0 1 0 2.82 2.836l6.02-5.986c.372-.363.604-.87.604-1.432 0-.562-.232-1.07-.605-1.433l-6.019-5.985zM16 40a2 2 0 0 1 2-2h9.808a2 2 0 0 1 0 4H18a2 2 0 0 1-2-2z"
      fill="#6C7280"
    />
  </svg>
);
