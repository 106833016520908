export default {
  "menu": {
    "undefined": "__UNDEFINED_MENU_ITEM__",
    "Insights": "AI Insights",
    "Catalog": "Datalake",
    "Hub": "Warehouse",
    "Flows": "Flows",
    "Members": "Members",
    "Connections": "Connections",
    "Monitor": "Monitor",
    "Settings": "Settings",
    "home": "home",
    "account": {
      "settings": "Profile & Account",
      "logout": "Log out"
    },
    "Usage": "Usage",
    "dpuUsage": "{dpuAmount} DPUs",
    "dpuLimit": "on {limit}",
    "dpuReset": "Resets in {daysLeft} days",
    "dpuError": "Unable to calculate usage.",
    "dpuSupport": "Please contact our support team"
  }
};
