import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <circle cx="12" cy="13" r="7" stroke="#6C7280" strokeWidth="2" />
    <path
      d="M12 9v4h2"
      stroke="#6C7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7 3L3 7M17 3l4 4" stroke="#6C7280" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
