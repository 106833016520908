import * as React from 'react';

export default () => (
  <svg width="13" height="8">
    <path
      d="M11.5 1.5l-5 5-5-5"
      stroke="#0067F4"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
