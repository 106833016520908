import * as React from 'react';

export default ({ color }) => (
  <svg width="24" height="24" fill="none">
    <rect
      x="5"
      y="19"
      width="14"
      height="14"
      rx="1"
      transform="rotate(-90 5 19)"
      stroke={color || '#B5BFD2'}
      strokeWidth="2"
    />
    <path fill={color || '#B5BFD2'} d="M7 17v-4h10v4z" />
  </svg>
);
