import type { Task } from 'redux-saga';
import type { Effects, ReduxSageEffectReverse, ReduxState } from './types';
import type { FetchEffectPayload, SaveAllAction, SaveSelectedAction } from './hub';
import Dataset from '@/services/dataset';
import { getOrSetRecentFolderId } from '@/utils/hubFolder';
import { handleUserError } from '@/utils/handleError';
import { assertIsArray } from '@/utils/typeChecks';
import { sgcall } from '@/utils/reduxSaga';

const ns = 'app.models.hub.fetch';

const fetchLocalOrRemote: ReduxSageEffectReverse = function* fetchLocalOrRemote(
  { put },
  { workspaceId, folderId, remote }: FetchEffectPayload & { remote: boolean },
) {
  try {
    if (remote) throw new Error('BEEM230706120140');
    const folderId2 = yield* sgcall(() => getOrSetRecentFolderId(workspaceId, folderId));
    const datasets = yield* sgcall(() => Dataset.getDatasetsByFolder(folderId2));
    datasets.sort((a, b) => a.name.localeCompare(b.name));
    yield put<SaveAllAction>({ type: 'saveAll', payload: { all: datasets } });
  } catch (e) {
    handleUserError(e, `${ns}.fetchLocalOrRemote`);
  }
};

let spawnedFetchOnceTask: Task;

export const fetchOnce: ReduxSageEffectReverse = function* fetchOnce(
  effects: Effects,
  {
    payload: { invokedFetchEffectPayload },
  }: { payload: { invokedFetchEffectPayload: FetchEffectPayload } },
) {
  const { call, put, select, spawn, cancel } = effects;
  if (spawnedFetchOnceTask?.isRunning()) yield cancel(spawnedFetchOnceTask);

  yield put<SaveAllAction>({ type: 'saveAll', payload: { all: null } });
  yield put<SaveSelectedAction>({ type: 'saveSelected', payload: { selected: null } });
  yield call(fetchLocalOrRemote, effects, { ...invokedFetchEffectPayload, remote: false });

  const datasets = yield select((s: ReduxState) => s.hub.all);
  assertIsArray(datasets, 'Dataset', 'BEEM220716111342');
  const datasetNeedsRemote = datasets.find((el) => !el.isDraft && !el.lastUpdated);

  if (datasetNeedsRemote) {
    spawnedFetchOnceTask = (yield spawn(fetchLocalOrRemote, effects, {
      ...invokedFetchEffectPayload,
      remote: true,
    })) as Task;
  }
};
