import * as React from 'react';

export default () => (
  <svg width="11" height="62" xmlns="http://www.w3.org/2000/svg%22%3E">
    <g fill="none" fillRule="evenodd">
      <path stroke="#0067F4" strokeWidth="2" strokeLinecap="round" d="M5.5 9.5v51" />
      <path
        d="M10.5 5.5a5 5 0 1 0-10 0 5 5 0 0 0 10 0zm-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM10.207 55.793a1 1 0 0 1 .083 1.32l-.083.094-4 4a1 1 0 0 1-1.32.083l-.094-.083-4-4a1 1 0 0 1 1.32-1.497l.094.083L5.5 59.085l3.293-3.292a1 1 0 0 1 1.32-.083l.094.083z"
        fill="#0067F4"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
