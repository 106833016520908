import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <defs>
      <linearGradient id="icon-facebook-ads-a" x1="102.001" y1="204.001" x2="102.001" y2="0">
        <stop stopColor="#263B74" offset="0%" />
        <stop stopColor="#4D78BF" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M161.5 204h-119C19.1 204 0 184.9 0 161.5v-119C0 19.1 19.1 0 42.5 0h119C184.9 0 204 19.1 204 42.5v119c0 23.4-19.1 42.5-42.5 42.5z"
      fill="url(#icon-facebook-ads-a)"
      transform="rotate(.228) scale(.19608)"
    />
    <path
      d="M9.47 22.843s2.785 6.804 3.275 7.157c.49.353 2.059.392 2.706 0 .647-.392 1.176-.627.98-1.216-.196-.588-.862-3.647-.745-3.608.118.04.863.157 1.157.099.294-.06.412-.295.196-.824-.215-.53-.745-1.549-.745-1.549s4.745-.098 8.137 1.294c3.393 1.392 4.628 2.628 4.726 3.647.098 1.02.882.804 1.176.804.294 0 1.079.157 1.079-.745V9.255c0-.49-.118-.765-.588-.765h-1.02c-.275 0-.628.118-.628.628 0 0-.176 2.47-3.901 3.725-3.726 1.255-7.608 1.667-8.98 1.667-1.373 0-8.04-.216-8.04-.216l-1.98 2.294v4l1.647 2.236 1.549.02z"
      fill="#FFF"
    />
    <path
      d="M16.275 14.51c-1.412 0-8.02-.216-8.02-.216l-1.98 2.294v4l1.647 2.236 1.568.039s.02.02.02.059h6.765V14.51z"
      fill="#829EC6"
    />
  </svg>
);
