export default {
  "dataset": {
    "edit_menu_item": "Edit dataset",
    "view_menu_item": "View dataset",
    "last_updated": "Last updated",
    "rename_menu_item": "Rename",
    "delete_menu_item": "Delete",
    "last_updated_moment_format": "MMMM DD @ h:mm A",
    "rename_modal_dialog": {
      "title": "Rename {type} {name}",
      "name_input": "{type} new name",
      "warning_text": "Warning: Any queries referencing this dataset will now be broken. You'll need to update query references with its new name.",
      "submit_button": "Update"
    },
    "view": {
      "last_updated_moment_format": "MMMM D, YYYY h:mm A",
      "last_updated_tag": "LATEST",
      "download": "Download now",
      "edit": "Edit dataset",
      "run": {
        "requested": "Dataset run request has started.",
        "requested_d": "Please wait for the run to complete.",
        "request_failed": "Dataset run request has failed.",
        "success": "Dataset refresh succeeded.",
        "success_d": "Click to view data",
        "failure": "Dataset refresh failed.",
        "failure_d": "Click to view error",
        "run_now": "Run Now",
        "running": "Running ..."
      },
      "display": {
        "errored": {
          "title": "An error occurred."
        },
        "blocking_tests_failed": {
          "title": "Blocking Tests Failed.",
          "desc1": "New dataset results were blocked due to critical validation tests that failed.",
          "desc2": "Launch a new run after correcting the underlying issues causing these tests to fail.",
          "button": "View Test Results"
        },
        "all_tests_passed": {
          "title": "All Tests Passed Successfully.",
          "desc1": "All dataset tests have passed successfully without producing any results.",
          "button": "View Data"
        }
      },
      "tab": {
        "data": "Data",
        "test_results": "Test Results"
      },
      "footer": {
        "showing": "Showing",
        "rows": "###row_count### rows",
        "columns": "###column_count### columns",
        "blockers": "{v} blockers",
        "warnings": "{v} warnings",
        "zero": "No test results"
      }
    },
    "move_menu_item": "Move",
    "move_modal_dialog": {
      "title": "Move {name}",
      "description": "Select the location where you want to move your {type}.",
      "submit_button": "Done"
    },
    "edit": {
      "last_edited_moment_format": "MMMM D, YYYY h:mm A",
      "last_edited_tag": "LATEST",
      "next_run_moment_format": "dddd DD MMM, YYYY [@] hh:mm A",
      "cancel_button": "Cancel",
      "results_button": "Live Results",
      "save_button": "Save",
      "saved_button": "Saved",
      "deploy_button": "Deploy",
      "save_tooltip": "No unsaved changes",
      "deploy_tooltip": "No undeployed changes",
      "search_input_placeholder": "Search Source Tables and Datasets",
      "search_header_title": "References",
      "tree": {
        "title": {
          "catalog": "Datalake",
          "hub": "Warehouse",
          "shared_datasets": "Shared Datasets"
        },
        "empty": "No {type} found"
      },
      "sql_editor": {
        "title": "SQL Editor",
        "preview_button": "Preview",
        "limit": "Limit {count}"
      },
      "preview_table": {
        "info_rows": "Preview first {count} rows",
        "info_columns": "{count} columns",
        "no_rows": "0 row. No result found for your query.",
        "error": "It seems something went wrong with your query.",
        "data": "Data"
      },
      "update_options": {
        "header_title": "Automation",
        "title": "Update Options",
        "subtitle": "Select which method to use to update the content of this dataset."
      },
      "description": {
        "title": "Description",
        "field_label": "Dataset Description",
        "placeholder": "Add add a dataset description",
        "enable_search_by_ai": "Mark dataset as searchable by BEEM AI",
        "generate": "Generate a description",
        "improve": "Improve description",
        "unsavedChanges": "You have unsaved changes, are you sure you want to leave?"
      },
      "save": {
        "update": {
          "in_progress": "Dataset SQL query and/or Trigger update in progress.",
          "in_progress_d": "Please wait.",
          "done": "Dataset SQL query update done.",
          "done_d": "Please wait for dataset to be refreshed.",
          "unchanged": "Dataset SQL query update not needed because nothing was changed.",
          "unchanged_d": "Click to view data",
          "error": "Dataset SQL query update error."
        },
        "run": {
          "success": "Dataset refresh succeeded.",
          "success_d": "Click to view data",
          "failure": "Dataset refresh failed.",
          "failure_d": "Click to view error"
        }
      }
    },
    "create_menu_item": "Create dataset",
    "create_modal_dialog": {
      "title": "Create a new {type}.",
      "folder_name_input": "Dataset name",
      "folder_tree_select_input": "Choose {type} location",
      "submit_button": "Create dataset"
    },
    "download_menu_item": "Download",
    "download_modal_dialog": {
      "title": "Download {name} on your desktop",
      "description": "Select the download file format for the table.",
      "filtered_button": "Download Filtered",
      "full_button": "Download All",
      "downloading": {
        "title": "Download file",
        "description": "Downloading the file can take a moment. It will be ready in your browser."
      }
    },
    "share_menu_item": "Share",
    "version_menu": {
      "failed": "Failed",
      "errored": "Errored",
      "edited": "Edited",
      "deployed": "Deployed",
      "undeployed": "Undeployed Changes"
    },
    "download": {
      "last_updated_tag": "LATEST",
      "last_updated_moment_format": "MMMM D, YYYY h:mm A",
      "invoke": "Download latest version",
      "request_date_format": "MMMM D, YYYY h:mm:ss A",
      "data_date_format": "YYYY-MM-DD [@] HH:mm:ss"
    },
    "empty": "A new dataset will be added here",
    "delete_modal_dialog": {
      "title": "Delete all {name} data?",
      "description": "Deleting a dataset will also delete it in the warehouse. This dataset might have dependencies. This action is permanent and cannot be undone.",
      "additionalInfo": "This operation may take some time to complete. Warehouse storage and external tools might still take this object into account until it is fully deleted.",
      "submit_button": "Delete dataset",
      "confirm_message": 'Type in "Delete" in this box to proceed',
      "confirm_message_value": "Delete"
    },
    "actions": {
      "download": "Download",
      "sort": "Sort",
      "filter": "Filter"
    },
    "sort": {
      "set_sorting": "Set Sorting",
      "column_select": "Select a column",
      "sort_select": "Select to sort"
    },
    "filter": {
      "set_filter": "Set Filter",
      "column_select": "Select a column",
      "filter_select": "Select a filter",
      "and": "AND"
    }
  }
};
