import * as React from 'react';

export default () => (
  <svg width="55" height="22">
    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
      <rect stroke="#1CC23A" fill="#FFF" x="34" width="20" height="20" rx="10" />
      <rect fill="#1CC23A" x="38" y="4" width="12" height="12" rx="6" />
      <text fontFamily="OpenSans-Bold, Open Sans" fontSize="14" fontWeight="bold" fill="#1CC23A">
        <tspan x="0" y="15">
          ON
        </tspan>
      </text>
    </g>
  </svg>
);
