import * as React from 'react';

export default () => (
  <svg width="8" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.5l5 5-5 5"
      stroke="#B5BFD2"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
