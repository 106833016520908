import type { ReduxSagaModel, ReduxSagaEffect } from './types';
import type DataObject from '@/services/dataObject';
import Source from '@/services/source';
import { sgselect, sgcall } from '@/utils/reduxSaga';
import { preloadAndCacheFivetranIconUrlsAndNativeIntegrationNames } from '@/utils/integrationIcons';
import { logger } from '@/utils/logger';
import { assertIsDefined } from '@/utils/typeChecks';

const ns = 'app.models.sources';

export interface State {
  all: Source[] | null;
  filtered: Source[] | null;
  selected: {
    source: Source;
    dataObjects: DataObject[];
    isFivetranConnectCardCompleted: boolean | null;
    isFivetranConnectionWithHistory?: boolean;
  } | null;
}

const initialState = {
  all: null,
  filtered: null,
  selected: null,
};

interface SaveAllAction {
  type: 'saveAll';
  payload: Pick<State, 'all'>;
}

interface SaveFilteredAction {
  type: 'saveFiltered';
  payload: Pick<State, 'filtered'>;
}

interface SaveSelectedAction {
  type: 'saveSelected';
  payload: Pick<State, 'selected'>;
}

const preloadDetails: ReduxSagaEffect = function* preloadDetails(source: Source, effects) {
  const { put } = effects;

  const dataObjects = yield* sgcall(source.getDataObjects);
  dataObjects.sort((x, y) => x.name.localeCompare(y.name));
  logger.info({ label: `${ns}.preloadDetails.dataObjects`, message: dataObjects });

  const isFivetranConnectCardCompleted = yield* sgcall(source.isFivetranConnectCardCompleted);
  const isFivetranConnectionWithHistory = yield* sgcall(source.isFivetranConnectionWithHistory);

  yield put<SaveSelectedAction>({
    type: 'saveSelected',
    payload: {
      selected: {
        source,
        dataObjects,
        isFivetranConnectCardCompleted,
        isFivetranConnectionWithHistory,
      },
    },
  });
};

const SourceModel: ReduxSagaModel<
  State,
  {
    saveAll: SaveAllAction;
    saveFiltered: SaveFilteredAction;
    saveSelected: SaveSelectedAction;
  }
> = {
  namespace: 'sources',
  state: initialState,
  effects: {
    *fetch(
      {
        payload: { workspaceId, fivetranConnectionId },
      }: { payload: { workspaceId: string; fivetranConnectionId?: string } },
      effects,
    ) {
      const { all, put } = effects;
      yield all([
        put<SaveAllAction>({ type: 'saveAll', payload: { all: null } }),
        put<SaveFilteredAction>({ type: 'saveFiltered', payload: { filtered: null } }),
        put<SaveSelectedAction>({ type: 'saveSelected', payload: { selected: null } }),
      ]);

      const sources = yield* sgcall(() => Source.getSourcesByWorkspace(workspaceId));
      sources.sort((a, b) => a.name.localeCompare(b.name));
      logger.info({ label: `${ns}.fetch.sources`, message: sources });

      yield all([
        put<SaveAllAction>({ type: 'saveAll', payload: { all: sources } }),
        put<SaveFilteredAction>({ type: 'saveFiltered', payload: { filtered: sources } }),
      ]);

      if (sources.length > 0) {
        // preload icons (fivetran or native) if needed
        const connectionIds = sources.map((el) => el.connectionId);
        const currentOrganizationId = yield* sgselect((s) => s.organization.currentOrganization);
        assertIsDefined(currentOrganizationId, 'BEEM240219071741');
        yield* sgcall(() =>
          preloadAndCacheFivetranIconUrlsAndNativeIntegrationNames(
            connectionIds,
            currentOrganizationId,
          ),
        );

        // preselect the first source or the recently created fivetran source
        let selectedSource: Source = sources[0];
        if (fivetranConnectionId) {
          const fivetranSourceId = yield* sgcall(() =>
            Source.getSourceIdByFivetranConnectionId(fivetranConnectionId),
          );
          const found = sources.find((source) => source.id === fivetranSourceId);
          if (found) selectedSource = found;
        }
        yield effects.call(preloadDetails, selectedSource, effects);
      }
    },
    *select({ payload: { selected } }: { payload: { selected: Source } }, effects) {
      yield effects.call(preloadDetails, selected, effects);
    },
    *filter({ payload: { filter } }: { payload: { filter?: string } }, { put }) {
      const sources = yield* sgselect((s) => s.sources.all);
      assertIsDefined(sources, 'BEEM240219072515');
      const filtered = filter
        ? sources.filter((key) => key.name.toLowerCase().includes(filter.toLowerCase()))
        : sources;
      yield put<SaveFilteredAction>({ type: 'saveFiltered', payload: { filtered } });
    },
    *refresh({ payload: { id } }: { payload: { id: string } }, effects) {
      const source = yield* sgcall(() => Source.getSourceById(id));
      yield effects.call(preloadDetails, source, effects);
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveAll(state, { payload }) {
      return { ...state, ...payload };
    },
    saveFiltered(state, { payload }) {
      return { ...state, ...payload };
    },
    saveSelected(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default SourceModel;
