import * as React from 'react';

export default ({ color }) => (
  <svg width="20" height="18">
    <g stroke={color || '#a3a6af'} strokeWidth="2" fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path d="M3 10h6a3 3 0 0 1 3 3v2a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-2a3 3 0 0 1 3-3z" />
        <rect x="3" width="6" height="6" rx="3" />
      </g>
      <g transform="translate(11 4)">
        <path d="M1.333 8H6c1.105 0 2 1.12 2 2.5V12a1 1 0 0 1-1 1H1.333h0H0" />
        <rect x="2" width="4" height="4" rx="2" />
      </g>
    </g>
  </svg>
);
