export default {
  "organizationSettings": {
    "headerTitle": "Organization Settings",
    "headerDesc": "Change the configuration of your organization's settings.",
    "saveButton": "Save",
    "generalTab": {
      "header": "General",
      "title": "General",
      "desc": "This default settings will be applied to the entire organization account.",
      "warehouseRetention": {
        "title": "Warehouse Retention Policy",
        "storageInfoLabel": "Warehouse storage",
        "storageInfoMsg": "Used space {used}/{total} GB",
        "inputLabel": "Retention period",
        "inputDesc":
          "Set your retention period. Dataset versions exceeding this period of time, will be automatically deleted. The latest dataset version is always saved regardless of the retention setting.",
        "inputInfo": "Datasets version created before {date} will be deleted at midnight.",
        "inputUnit": "days"
      }
    },
    "credentialsTab": {
      "header": "Credentials",
      "title": "Connect to the {organizationName} Warehouse",
      "desc": "Use the following credentials to connect to this organization's data warehouse.",
      "instructions": "Your personal credentials provided allow you to access sources and datasets for this organization.",
      "serverAddressLabel": "Server Address",
      "portNumberLabel": "Port",
      "databaseNameLabel": "Database Name",
      "usernameLabel": "Username",
      "passwordLabel": "Password",
      "copyButton": "Copy",
      "generateButton": "Generate",
      "resetButton": "Reset",
      "generateModal": {
        "title": "Warehouse Password for {organizationName}",
        "instructions": "Your password has been generated. It won't be accessible on the BEEM app once you quit this modal dialog.",
        "checkboxTitle": "Check the following items to reveal your password:",
        "checkboxText1": "BEEM does not store my warehouse password.",
        "checkboxText2": "My password is unrecoverable and will need to be reset in case it is lost.",
        "checkboxText3": "My password should be stored securely through a password manager or in a file.",
        "passwordLabel": "New password",
        "revealButton": "Reveal",
        "copyButton": "Copy",
        "mainButton": "I've stored my password"
      },
      "resetModal": {
        "title": "Reset Warehouse Credential Password",
        "instructions": "Warning: Resetting your password will invalidate all your passwords used in external tools. You'll need to change your password in all external tools it's been used.",
        "resetText": "Are you sure you want to reset your password?",
        "resetButton": "Reset"
      }
    },
    "usersTab": {
      "header": "Users & Access",
      "title": "Manage access",
      "desc": "Manage user organization and workspace roles, memberships and ownership of accounts that have access to your organization.",
      "filter": "Filter members by name or username",
      "subtitles": {
        "user": "{numberOfUsers} Users",
        "membership": "Membership"
      },
      "workspaces": "{numberOfWorkspaces} Workspaces",
      "dropdown": {
        "delete": "Remove from organization"
      }
    }
  }
};
