import * as React from 'react';

export default ({
  stroke,
  height,
  width,
}: {
  stroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4 6V3.99902H12V6"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 12H10" stroke={stroke || '#0A132B'} strokeWidth="2" strokeLinecap="round" />
    <path d="M8 12.001V4.00098" stroke={stroke || '#0A132B'} strokeWidth="2" />
  </svg>
);
