import * as React from 'react';

export default ({ color }) => (
  <svg width="26" height="22">
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          // values="0 0 0 0 0.000000 0 0 0 0 0.403922 0 0 0 0 0.956863 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g transform="translate(-11 -9)" filter="url(#a)" fill="none" fillRule="evenodd">
      <g transform="translate(11.5 9.5)" stroke={color || '#979797'} strokeWidth="2">
        <path d="M9 2a9 9 0 1 0 6.387 2.659M9 6a5 5 0 1 0 4.002 2.002" strokeLinecap="round" />
        <circle cx="9" cy="11" r="1" />
        <path strokeLinecap="round" d="M9 11V0" />
      </g>
    </g>
  </svg>
);
