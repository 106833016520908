import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <g fillRule="evenodd" clipRule="evenodd" fill="#B5BFD2">
      <path d="M2 6a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6zm3-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H5z" />
      <path d="M2 6a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v4H2V6zm3-1a1 1 0 0 0-1 1v2h16V6a1 1 0 0 0-1-1H5z" />
      <path d="M2 8h7v13H5a3 3 0 0 1-3-3V8zm2 2v8a1 1 0 0 0 1 1h2v-9H4z" />
    </g>
  </svg>
);
