import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.0364 12.4738C16.3074 11.4968 17.6926 11.4969 17.9636 12.4738L18.586 14.7176C18.6799 15.0559 18.9441 15.3201 19.2824 15.414L21.5262 16.0364C22.5032 16.3074 22.5031 17.6926 21.5262 17.9636L19.2824 18.5861C18.9441 18.6799 18.6799 18.9441 18.5861 19.2824L17.9636 21.5262C17.6926 22.5032 16.3074 22.5031 16.0364 21.5262L15.414 19.2824C15.3201 18.9441 15.0559 18.6799 14.7176 18.586L12.4738 17.9636C11.4968 17.6926 11.4969 16.3074 12.4738 16.0364L14.7176 15.414C15.0559 15.3201 15.3201 15.0559 15.414 14.7176L16.0364 12.4738Z"
      fill={props.color || '#FFC600'}
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0313 17C16.4244 17.2438 16.7562 17.5756 17 17.9687C17.2438 17.5756 17.5756 17.2438 17.9687 17C17.5756 16.7562 17.2438 16.4244 17 16.0313C16.7562 16.4244 16.4244 16.7562 16.0313 17ZM17.9636 12.4738C17.6926 11.4969 16.3074 11.4968 16.0364 12.4738L15.414 14.7176C15.3201 15.0559 15.0559 15.3201 14.7176 15.414L12.4738 16.0364C11.4969 16.3074 11.4968 17.6926 12.4738 17.9636L14.7176 18.586C15.0559 18.6799 15.3201 18.9441 15.414 19.2824L16.0364 21.5262C16.3074 22.5031 17.6926 22.5032 17.9636 21.5262L18.5861 19.2824C18.6799 18.9441 18.9441 18.6799 19.2824 18.5861L21.5262 17.9636C22.5031 17.6926 22.5032 16.3074 21.5262 16.0364L19.2824 15.414C18.9441 15.3201 18.6799 15.0559 18.586 14.7176L17.9636 12.4738Z"
      fill={props.color || '#FFC600'}
    />
    <path
      d="M6.07277 2.94757C6.61477 0.993705 9.38523 0.993706 9.92723 2.94757L10.5206 5.08675C10.7083 5.76319 11.2368 6.29173 11.9132 6.47937L14.0524 7.07277C16.0063 7.61477 16.0063 10.3852 14.0524 10.9272L11.9132 11.5206C11.2368 11.7083 10.7083 12.2368 10.5206 12.9132L9.92722 15.0524C9.38523 17.0063 6.61477 17.0063 6.07278 15.0524L5.47937 12.9132C5.29173 12.2368 4.76319 11.7083 4.08675 11.5206L1.94756 10.9272C-0.00629575 10.3852 -0.00629564 7.61477 1.94757 7.07277L4.08675 6.47937C4.76319 6.29173 5.29173 5.76319 5.47937 5.08675L6.07277 2.94757Z"
      fill={props.color || '#FFC600'}
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62136 8.4066L2.48217 9L4.62136 9.5934C5.97423 9.96868 7.03132 11.0258 7.4066 12.3786L8 14.5178L8.5934 12.3786C8.96868 11.0258 10.0258 9.96868 11.3786 9.5934L13.5178 9L11.3786 8.4066C10.0258 8.03132 8.96868 6.97423 8.5934 5.62136L8 3.48217L7.4066 5.62136C7.03132 6.97423 5.97423 8.03132 4.62136 8.4066ZM9.92723 2.94757C9.38523 0.993705 6.61477 0.993705 6.07277 2.94757L5.47937 5.08675C5.29173 5.76319 4.76319 6.29173 4.08675 6.47937L1.94757 7.07277C-0.00629583 7.61477 -0.00629613 10.3852 1.94756 10.9272L4.08675 11.5206C4.76319 11.7083 5.29173 12.2368 5.47937 12.9132L6.07278 15.0524C6.61477 17.0063 9.38523 17.0063 9.92722 15.0524L10.5206 12.9132C10.7083 12.2368 11.2368 11.7083 11.9132 11.5206L14.0524 10.9272C16.0063 10.3852 16.0063 7.61477 14.0524 7.07277L11.9132 6.47937C11.2368 6.29173 10.7083 5.76319 10.5206 5.08675L9.92723 2.94757Z"
      fill={props.color || '#FFC600'}
    />
  </svg>
);
