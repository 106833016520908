import * as React from 'react';

export default (props) => (
  <svg width="40" height="40">
    <defs>
      <path
        d="M7.967 0c.621 0 1.125.504 1.125 1.125L9.09 6.866l4.914-2.837a1.286 1.286 0 111.285 2.227L10.538 9l4.752 2.744a1.286 1.286 0 11-1.285 2.227L9.09 11.133v5.742a1.125 1.125 0 01-2.25 0v-5.741l-4.912 2.837a1.286 1.286 0 11-1.286-2.227L5.395 9 .643 6.256A1.286 1.286 0 011.93 4.03L6.84 6.865v-5.74C6.842.504 7.346 0 7.968 0z"
        id="flow-view-flow-cron-a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
        fill={props.backgroundColor || '#FFC600'}
        opacity=".1"
      />
      <g transform="translate(12 11)">
        <use fill="#FFF" href="#flow-view-flow-cron-a" />
        <path
          stroke="#FFC600"
          d="M7.967-.5c.448 0 .855.182 1.149.476.294.294.476.7.476 1.15h0V6l4.163-2.404a1.78 1.78 0 011.355-.178 1.78 1.78 0 011.084.832c.246.427.297.914.178 1.355a1.78 1.78 0 01-.832 1.084h0L11.538 9l4.002 2.31c.427.247.714.644.832 1.085a1.78 1.78 0 01-.178 1.355 1.78 1.78 0 01-1.084.832 1.78 1.78 0 01-1.355-.178h0L9.59 11.999v4.876A1.62 1.62 0 017.967 18.5a1.62 1.62 0 01-1.148-.476 1.62 1.62 0 01-.476-1.149h0V12l-4.163 2.404a1.78 1.78 0 01-1.355.178 1.78 1.78 0 01-1.085-.832 1.78 1.78 0 01-.178-1.355 1.78 1.78 0 01.832-1.084h0L4.395 9 .393 6.69a1.78 1.78 0 01-.832-1.085A1.78 1.78 0 01-.26 4.25a1.78 1.78 0 011.085-.832 1.78 1.78 0 011.355.178h0L6.34 6V1.125c0-.449.183-.855.477-1.149A1.62 1.62 0 017.967-.5h0z"
          fillOpacity=".2"
          fill="#FFC600"
        />
      </g>
    </g>
  </svg>
);
