import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 40}
      height={props.svgHeight || 40}
      viewBox={props.svgViewBox || '0 0 40 40'}
    >
      <defs>
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          id="icon-status-indicator-warning-b"
        />
        <path id="icon-status-indicator-warning-c" d="M11.66 0l11.658 19.636H0z" />
        <filter id="icon-status-indicator-warning-a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 0.623529 0 0 0 0 0.262745 0 0 0 0.203398 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g filter="url(#icon-status-indicator-warning-a)">
          <use fill="#FFF" href="#icon-status-indicator-warning-b" />
          <use fillOpacity=".2" fill="#FFC600" href="#icon-status-indicator-warning-b" />
        </g>
        <g transform="translate(8 10)">
          <g strokeLinejoin="round">
            <use fill="#FFF" href="#icon-status-indicator-warning-c" />
            <path
              stroke="#FF9F43"
              strokeWidth="1.227"
              d="M11.66-1.202L24.395 20.25H-1.078L11.659-1.202z"
              fillOpacity=".202"
              fill="#FF9F43"
            />
          </g>
          <path
            stroke="#FF9F43"
            strokeWidth="1.227"
            strokeLinecap="round"
            d="M11.659 7.977v6.137"
          />
          <rect fill="#FF9F43" x="11.045" y="15.955" width="1.227" height="1.227" rx=".614" />
        </g>
      </g>
    </svg>
  );
};
