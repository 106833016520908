export default {
  "connection": {
    "delete_modal_dialog": {
      "title": "Delete connection?",
      "dependencies": "This connection has {dependencies} dependencies.",
      "description": "This action will permanently delete the connection, however the synchronized data will remain unaffected and won't be deleted.",
      "confirm_message": 'Type in "Delete" in this box to proceed',
      "confirm_message_value": "Delete",
      "submit_button": "Delete connection"
    }
  }
};
