import * as React from 'react';

export default ({ fill, stroke }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 11V3M5 3L2 6M5 3L8 6"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill || '#fff'}
    />
    <path
      d="M11 5V13M11 13L14 10M11 13L8 10"
      stroke={stroke || '#0A132B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill || '#fff'}
    />
  </svg>
);
