import * as React from 'react';

export default ({ viewBox, style }) => (
  <svg width="40" height="40" viewBox={viewBox} style={style}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <g opacity=".9" fillRule="nonzero">
        <path
          d="M8.052 24.873c-3.785 0-6.896-2.882-6.896-6.382 0-3.551 3.11-6.382 6.896-6.382l23.957.052c3.786 0 6.897 2.882 6.897 6.381 0 3.551-3.111 6.382-6.897 6.382l-23.957-.051z"
          fill="#0052A4"
        />
        <path
          d="M26.15 12.058c1.192-3.345-.57-7-3.89-8.183-3.37-1.184-7.052.566-8.244 3.911l-7.52 20.998c-1.192 3.345.571 7 3.942 8.183 3.37 1.184 7.052-.566 8.245-3.911l7.467-20.998z"
          fill="#8DC63F"
        />
        <path
          d="M14.016 12.058c-1.193-3.345.57-7 3.94-8.183 3.371-1.184 7.053.566 8.246 3.911l7.467 20.998c1.192 3.345-.57 7-3.89 8.183-3.37 1.184-7.052-.566-8.244-3.911l-7.52-20.998z"
          fill="#0089CB"
        />
      </g>
    </g>
  </svg>
);
