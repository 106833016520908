import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 30 30">
      <g stroke={props.color || '#FFF'} strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M13.648 6.268l10 10-2 2a7.071 7.071 0 11-10-10l2-2h0z" strokeLinejoin="round" />
        <path
          strokeLinecap="round"
          d="M22.148 13.769l3.5-3.5M16.148 7.768l3.5-3.5M7.648 22.268l3.5-3.5"
        />
      </g>
    </svg>
  );
};
