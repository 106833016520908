import * as React from 'react';

export default (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="State=Static">
      <path
        id="Icon Column"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 19V5H10V19H8ZM6 4C6 3.44772 6.44772 3 7 3H11C11.5523 3 12 3.44772 12 4V20C12 20.5523 11.5523 21 11 21H7C6.44772 21 6 20.5523 6 20V4ZM4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H5V17H4V7L5 7V5H4ZM13 7H20V17H13V19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H13V7Z"
        fill={props.color || '#6C7280'}
      />
    </g>
  </svg>
);
