import Folder from '@/services/folder';

const MRU_FOLDER_BY_WS = 'beem-last-used-folder-';
const ROOT_FOLDER_BY_WS = 'beem-root-hub-folder-';

/**
 * store most recently used folder id so that we can go back to that current location
 * if folder id is provided then just update local storage
 * if folder id is not provided then read from local storage
 * or search for root folder and return
 * @param {*} workspaceId
 * @param {*} pfolderId
 */
export async function getOrSetRecentFolderId(
  workspaceId: string,
  folderId: string,
): Promise<string> {
  let result: string;
  if (folderId) {
    result = folderId;
  } else {
    const folderIdLS = localStorage.getItem(MRU_FOLDER_BY_WS + workspaceId);
    if (folderIdLS) result = folderIdLS;
    else result = await getOrSetRootFolder(workspaceId);
  }
  localStorage.setItem(MRU_FOLDER_BY_WS + workspaceId, result);
  return result;
}

export async function getOrSetRootFolder(workspaceId: string): Promise<string> {
  const rootFolderIdLS = localStorage.getItem(ROOT_FOLDER_BY_WS + workspaceId);
  if (rootFolderIdLS) return rootFolderIdLS;

  const rootFolderId = (await Folder.getRootFolder(workspaceId)).id;
  localStorage.setItem(ROOT_FOLDER_BY_WS + workspaceId, rootFolderId);
  return rootFolderId;
}
