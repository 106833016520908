import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '24'}
    height={props.height || '24'}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.color || '#6C7280'}
      fillRule="evenodd"
      d="M7 13V7h6v2h-2a2 2 0 0 0-2 2v2H7Zm2 2H6.8A1.8 1.8 0 0 1 5 13.2V6.8A1.8 1.8 0 0 1 6.8 5h6.4A1.8 1.8 0 0 1 15 6.8V9h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2v-3Zm2-4h7v7h-7v-7Z"
      clipRule="evenodd"
    />
  </svg>
);
