import * as React from 'react';

export default () => (
  <svg width="24" height="24">
    <defs>
      <rect id="a" x="0" y="0" width="24" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#a" />
      <use fill="#F0F2F5" xlinkHref="#a" />
      <circle fill="#0067F4" cx="6" cy="12" r="2" />
      <circle fill="#0067F4" cx="12" cy="12" r="2" />
      <circle fill="#0067F4" cx="18" cy="12" r="2" />
    </g>
  </svg>
);
