import type { SqlQueryResults } from '@/services/types';
import type { ReduxSagaModel } from './types';
import type DatasetViewState from './objects/datasetViewState';
import DataObject from '@/services/dataObject';
import { generateEffectsForGetContentCompatibleType } from './common/getContent';
import { sgcall } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';

const ns = 'app.models.dataObjects';
const REDUX_NAMESPACE = 'dataObjects';

export type State = {
  current: DataObject | null;
  rowCount: number | null;
  records: SqlQueryResults | null;
  viewState: DatasetViewState | null;
};

const initialState = {
  current: null,
  rowCount: null,
  records: null,
  viewState: null,
};

interface SaveCurrentAction {
  type: 'saveCurrent';
  payload: Pick<State, 'current'>;
}

interface SaveRowCountAction {
  type: 'saveRowCount';
  payload: Pick<State, 'rowCount'>;
}

interface SaveRecordsAction {
  type: 'saveRecords';
  payload: Pick<State, 'records'>;
}

interface SaveViewStateAction {
  type: 'saveViewState';
  payload: Pick<State, 'viewState'>;
}

const DataObjectModel: ReduxSagaModel<
  State,
  {
    saveCurrent: SaveCurrentAction;
    saveRowCount: SaveRowCountAction;
    saveRecords: SaveRecordsAction;
    saveViewState: SaveViewStateAction;
  }
> = {
  namespace: REDUX_NAMESPACE,
  state: initialState,
  effects: {
    *get({ payload }: { payload: { id: string; timestamp?: number } }, { put }) {
      try {
        yield put<SaveViewStateAction>({ type: 'saveViewState', payload: { viewState: null } });
        const dataObject = yield* sgcall(() => DataObject.getDataObjectById(payload.id));
        dataObject.timestamp = payload.timestamp;
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: dataObject } });
      } catch (e) {
        handleUserError(e, `${ns}.get.error`);
        yield put<SaveCurrentAction>({ type: 'saveCurrent', payload: { current: null } });
      }
    },
    ...generateEffectsForGetContentCompatibleType<DataObject>(REDUX_NAMESPACE),
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveCurrent(state, { payload }) {
      return { ...state, ...payload };
    },
    saveRowCount(state, { payload }) {
      return { ...state, ...payload };
    },
    saveRecords(state, { payload }) {
      return { ...state, ...payload };
    },
    saveViewState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default DataObjectModel;
