export default {
  "dataObject": {
    "table_count": "{ count, plural, one {# Table} other {# Tables} }",
    "rows": "Rows",
    "created_date": "Created date",
    "sync_date": "Last Sync Ended",
    "tables_ingested": "Some tables are still being ingested for this source.",
    "tables_loading": "{count} Tables loading",
    "sync_runtime": "Last Sync Runtime",
    "date_format": "DD MMM YYYY [@] HH:mm",
    "download_menu_item": "Download",
    "download_modal_dialog": {
      "title": "Download {name} on your desktop",
      "description": "Select the download file format for the table.",
      "filtered_button": "Download Filtered",
      "full_button": "Download All",
      "downloading": {
        "title": "Download file",
        "description": "Downloading the file can take a moment. It will be ready in your browser."
      }
    },
    "view": {
      "last_updated_tag": "LAST SYNCED",
      "last_updated_moment_format": "MMMM D, YYYY h:mm A",
      "transformation": "View transformation",
      "download": "Download now"
    },
    "download": {
      "last_updated_tag": "Last Synced",
      "last_updated_moment_format": "MMMM D, YYYY h:mm A",
      "invoke": "Download latest version",
      "request_date_format": "MMMM D, YYYY h:mm:ss A",
      "data_date_format": "YYYY-MM-DD [@] HH:mm:ss"
    }
  }
};
