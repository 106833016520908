import * as React from 'react';

export default (props) => {
  return (
    <svg width="40" height="40">
      <defs>
        <path
          d="M6.115 1.06A91.837 91.837 0 0120 0c4.62 0 9.24.352 13.86 1.057a6 6 0 015.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 01-5.03 5.053A91.834 91.834 0 0120 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 01-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 016.115 1.06z"
          id="y5"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill={props.backgroundColor || '#FFF'} href="#y5" />
          <use fillOpacity=".1" fill={props.backgroundColor || '#32D02F'} href="#y5" />
        </g>
        <g transform="translate(9 9)">
          <rect stroke="#32D02F" x=".5" y=".5" width="21" height="21" rx="3" />
          <rect x="3" y="3" width="7" height="7" rx="1" fill="#FFF" />
          <rect
            stroke="#32D02F"
            strokeLinejoin="square"
            fillOpacity=".2"
            fill="#32D02F"
            x="3.5"
            y="3.5"
            width="6"
            height="6"
            rx="1"
          />
          <rect x="3" y="12" width="7" height="7" rx="1" fill="#FFF" />
          <rect
            stroke="#32D02F"
            strokeLinejoin="square"
            fillOpacity=".6"
            fill="#32D02F"
            x="3.5"
            y="12.5"
            width="6"
            height="6"
            rx="1"
          />
          <g>
            <rect x="12" y="3" width="7" height="7" rx="1" fill="#FFF" />
            <rect
              stroke="#32D02F"
              strokeLinejoin="square"
              fillOpacity=".4"
              fill="#32D02F"
              x="12.5"
              y="3.5"
              width="6"
              height="6"
              rx="1"
            />
          </g>
          <g>
            <rect x="12" y="12" width="7" height="7" rx="1" fill="#FFF" />
            <rect
              stroke="#32D02F"
              strokeLinejoin="square"
              fillOpacity=".2"
              fill="#32D02F"
              x="12.5"
              y="12.5"
              width="6"
              height="6"
              rx="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
