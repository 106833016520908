import * as React from 'react';

export default ({ fill }) => (
  <svg width="32" height="32" fill="none">
    <rect width="32" height="32" rx="2" fill={fill || '#0067F4'} />
    <path
      d="M16 10v12.133M9.933 16.067h12.133"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
