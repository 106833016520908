import type { ReduxSagaModel, InvokeResult } from './types';
import Dataset from '@/services/dataset';
import Workspace from '@/services/workspace';
import { sgcall, sgselect } from '@/utils/reduxSaga';
import { handleUserError } from '@/utils/handleError';
import { spawnTaskToWatchForLastAction } from './common/utils';
import { fetchOnce } from './hub.fetch';

const ns = 'app.models.hub';

export type FetchEffectPayload = {
  workspaceId: string;
  folderId: string;
};

export interface State {
  all: Dataset[] | null;
  selected: Dataset | null;
  invokedFetchEffectPayload: FetchEffectPayload | null;
  invokeFetchSharedDatasetsByWorkspaceResult: InvokeResult<Dataset[]> | null;
  invokeWorkspacesWithSharedDatasetsResult: InvokeResult<Workspace[]> | null;
  datasetsWithSharedWorkspaces: Record<string, Workspace[]> | null;
}

const initialState = {
  all: null,
  selected: null,
  invokedFetchEffectPayload: null,
  invokeFetchSharedDatasetsByWorkspaceResult: null,
  invokeWorkspacesWithSharedDatasetsResult: null,
  datasetsWithSharedWorkspaces: null,
};

export type SaveAllAction = {
  type: 'saveAll';
  payload: Pick<State, 'all'>;
};

export type SaveSelectedAction = {
  type: 'saveSelected';
  payload: Pick<State, 'selected'>;
};

interface SaveInvokedFetchEffectPayloadAction {
  type: 'saveInvokedFetchEffectPayload';
  payload: Pick<State, 'invokedFetchEffectPayload'>;
}

interface SaveInvokeFetchSharedDatasetsByWorkspaceAction {
  type: 'saveInvokeFetchSharedDatasetsByWorkspaceResult';
  payload: Pick<State, 'invokeFetchSharedDatasetsByWorkspaceResult'>;
}

interface SaveInvokeFetchWorkspacesWithSharedDatasetsAction {
  type: 'saveInvokeFetchWorkspacesWithSharedDatasetsResult';
  payload: Pick<State, 'invokeWorkspacesWithSharedDatasetsResult'>;
}

interface SaveInvokeFetchSharedWithWorkspaces {
  type: 'saveInvokeFetchSharedWithWorkspaces';
  payload: Record<string, Workspace[]>;
}

const HubModel: ReduxSagaModel<
  State,
  {
    saveAll: SaveAllAction;
    saveSelected: SaveSelectedAction;
    saveInvokedFetchEffectPayload: SaveInvokedFetchEffectPayloadAction;
    saveInvokeFetchSharedDatasetsByWorkspaceResult: SaveInvokeFetchSharedDatasetsByWorkspaceAction;
    saveInvokeFetchWorkspacesWithSharedDatasetsResult: SaveInvokeFetchWorkspacesWithSharedDatasetsAction;
    saveInvokeFetchSharedWithWorkspaces: SaveInvokeFetchSharedWithWorkspaces;
  }
> = {
  namespace: 'hub',
  state: initialState,
  effects: {
    *fetch({ payload }: { payload: FetchEffectPayload }, effects) {
      const { put } = effects;
      yield* spawnTaskToWatchForLastAction('hub/saveInvokedFetchEffectPayload', fetchOnce, effects);
      yield put<SaveInvokedFetchEffectPayloadAction>({
        type: 'saveInvokedFetchEffectPayload',
        payload: {
          invokedFetchEffectPayload: payload,
        },
      });
    },
    *recheck({ payload }: { payload: { filterFn: (el: Dataset) => boolean } }, { put }) {
      const all = yield* sgselect((s) => s.hub.all, 'BEEM220920094520');
      const newAll = all.filter(payload.filterFn);
      yield put<SaveAllAction>({ type: 'saveAll', payload: { all: newAll } });
    },
    *select({ payload }: { payload: { selected: Dataset } }, { put }) {
      yield put<SaveSelectedAction>({
        type: 'saveSelected',
        payload,
      });
    },
    *invokeFetchSharedDatasetsByWorkspace(
      { payload }: { payload: { currentWorkspace: string } },
      { put },
    ) {
      try {
        const { currentWorkspace } = payload;
        const sharedDatasetsByWorkspace = yield* sgcall(() =>
          Dataset.getDatasetsSharedWithWorkspace(currentWorkspace),
        );

        if (sharedDatasetsByWorkspace.length > 0) {
          sharedDatasetsByWorkspace.sort((a, b) => a.name.localeCompare(b.name));
          yield put<SaveInvokeFetchSharedDatasetsByWorkspaceAction>({
            type: 'saveInvokeFetchSharedDatasetsByWorkspaceResult',
            payload: {
              invokeFetchSharedDatasetsByWorkspaceResult: {
                success: true,
                data: sharedDatasetsByWorkspace,
              },
            },
          });
        }
      } catch (e) {
        yield put<SaveInvokeFetchSharedDatasetsByWorkspaceAction>({
          type: 'saveInvokeFetchSharedDatasetsByWorkspaceResult',
          payload: {
            invokeFetchSharedDatasetsByWorkspaceResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeFetchSharedDatasetsByWorkspace.error`),
            },
          },
        });
      }
    },
    *invokeFetchWorkspacesWithSharedDatasets(
      { payload }: { payload: { currentWorkspace: string } },
      { call, put },
    ) {
      try {
        const { currentWorkspace } = payload;
        const workspacesWithSharedDatasets = yield call(
          Workspace.getWorkspacesWhichSharedDatasetWithThisWorkspace,
          currentWorkspace,
        );

        if (
          Array.isArray(workspacesWithSharedDatasets) &&
          workspacesWithSharedDatasets.length > 0
        ) {
          yield put<SaveInvokeFetchWorkspacesWithSharedDatasetsAction>({
            type: 'saveInvokeFetchWorkspacesWithSharedDatasetsResult',
            payload: {
              invokeWorkspacesWithSharedDatasetsResult: {
                success: true,
                data: workspacesWithSharedDatasets,
              },
            },
          });
        }
      } catch (e) {
        yield put<SaveInvokeFetchWorkspacesWithSharedDatasetsAction>({
          type: 'saveInvokeFetchWorkspacesWithSharedDatasetsResult',
          payload: {
            invokeWorkspacesWithSharedDatasetsResult: {
              success: false,
              error: handleUserError(e, `${ns}.invokeFetchWorkspacesWithSharedDatasets.error`),
            },
          },
        });
      }
    },
    *invokeFetchSharedWithWorkspaces(
      { payload }: { payload: { dataset: Dataset; currentWorkspaceId: string } },
      { call, put },
    ) {
      const { dataset, currentWorkspaceId } = payload;

      const rawSharedWorkspaces = yield call(dataset.getSharedWithWorkspaces);

      if (Array.isArray(rawSharedWorkspaces) && rawSharedWorkspaces.length > 0) {
        const sharedWorkspaces = rawSharedWorkspaces.filter(
          (workspace) => workspace.id !== currentWorkspaceId,
        );

        yield put<SaveInvokeFetchSharedWithWorkspaces>({
          type: 'saveInvokeFetchSharedWithWorkspaces',
          payload: { [dataset.id]: sharedWorkspaces },
        });
      }
    },
    *invokeShareDatasetWithWorkspace(
      { payload }: { payload: { dataset: Dataset; workspaces: Workspace[] } },
      { call, put },
    ) {
      const { dataset, workspaces } = payload;

      // We call this operation first to display the changes in the ui immediately
      yield put<SaveInvokeFetchSharedWithWorkspaces>({
        type: 'saveInvokeFetchSharedWithWorkspaces',
        payload: { [dataset.id]: workspaces },
      });

      yield call(dataset.share, workspaces);
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    saveAll(state, { payload }) {
      return { ...state, ...payload };
    },
    saveSelected(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokedFetchEffectPayload(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeFetchSharedDatasetsByWorkspaceResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeFetchWorkspacesWithSharedDatasetsResult(state, { payload }) {
      return { ...state, ...payload };
    },
    saveInvokeFetchSharedWithWorkspaces(state, { payload }) {
      return {
        ...state,
        datasetsWithSharedWorkspaces: { ...state.datasetsWithSharedWorkspaces, ...payload },
      };
    },
  },
};

export default HubModel;
