import * as React from 'react';

export default () => (
  <svg width="24" height="24">
    <g transform="translate(4 4)" stroke="#59be66" strokeWidth="2" fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="8" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 8l2 2 4-4" />
    </g>
  </svg>
);
