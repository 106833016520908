import * as React from 'react';

export default (props) => (
  <svg width="22" height="22">
    <g
      stroke={props.color || '#0A132B'}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      opacity={props.color ? '1' : '.6'}
    >
      <path
        d="M9.778 4.828L16.85 11.9l-1.414 1.415a5 5 0 1 1-7.071-7.071l1.414-1.415h0z"
        strokeLinejoin="round"
      />
      <path
        strokeLinecap="round"
        d="M15.435 10.485l2.828-2.828M11.192 6.243l2.829-2.829M5.536 16.142l2.828-2.828"
      />
    </g>
  </svg>
);
