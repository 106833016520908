import * as React from 'react';

export default ({ color }) => (
  <svg width="20" height="20">
    <g stroke={color || '#0067F4'} strokeWidth="2" fill="none" fillRule="evenodd">
      <path
        d="M10 3a4.85 4.85 0 0 1 3.367 1.354 4.85 4.85 0 0 1 1.493 3.308h0l.14 3.36a5.828 5.828 0 0 0 1.707 4.1h0L3.406 15c.991-1.079 1.517-2.358 1.586-3.698h0l.148-3.64a4.85 4.85 0 0 1 1.493-3.308A4.85 4.85 0 0 1 10 3h0z"
        fill="#0067F4"
      />
      <path
        d="M5.001 1.07A7.997 7.997 0 0 0 1 8m18 0a7.997 7.997 0 0 0-4.015-6.939"
        strokeLinecap="round"
      />
      <path d="M11.732 18A2.008 2.008 0 0 1 10 19a1.994 1.994 0 0 1-1.732-1h0z" fill="#0067F4" />
    </g>
  </svg>
);
