import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '24'}
    height={props.height || '24'}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={props.color || '#6B707F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m7 8.5-4 4 4 4M17 8.5l4 4-4 4"
    />
    <path
      stroke={props.color || '#6B707F'}
      strokeLinecap="round"
      strokeWidth="2"
      d="m10 19.5 4-14"
    />
  </svg>
);
