import * as React from 'react';

export default ({ color }) => (
  <svg width="17" height="17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7a5 5 0 1 1 10 0A5 5 0 0 1 2 7zm5-7a7 7 0 1 0 4.192 12.606l4.1 4.101a1 1 0 0 0 1.415-1.414l-4.1-4.1A7 7 0 0 0 7 0z"
      fill={color || '#B5BFD2'}
    />
  </svg>
);
