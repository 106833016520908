import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

if (typeof COGNITO_CUSTOM_DOMAIN !== 'undefined' && COGNITO_CUSTOM_DOMAIN !== 'default') {
  // Force update Cognito Settings for custom domain
  // refs https://github.com/aws-amplify/amplify-cli/issues/1880
  awsconfig.oauth.domain = COGNITO_CUSTOM_DOMAIN;
}
Amplify.configure(awsconfig);

// Cognito Hosted UI configuration
// const oauth = {
//     domain: 'backend-services-userpool-dev.auth.us-east-1.amazoncognito.com',
//     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
//     redirectSignIn: 'http://localhost:3000/',
//     redirectSignOut: 'http://localhost:3000/',
//     responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
// }

// Auth.configure({ oauth })

const cognitoUserPoolId = awsconfig.aws_user_pools_id;

const cognitoUserPoolClientId = awsconfig.aws_user_pools_web_client_id;

const backendId = cognitoUserPoolId.substring(cognitoUserPoolId.lastIndexOf('_') + 1).toLowerCase();

const isLocalMock = awsconfig.aws_appsync_dangerously_connect_to_http_endpoint_for_testing === true;

export { Amplify, Auth, cognitoUserPoolId, cognitoUserPoolClientId, backendId, isLocalMock };
