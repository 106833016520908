import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24" viewBox="0 -4 24 24">
      <g stroke={props.color} strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M16 1a6.978 6.978 0 00-4.95 2.05A6.978 6.978 0 009 8c0 1.933.784 3.683 2.05 4.95A6.978 6.978 0 0016 15a6.978 6.978 0 004.95-2.05A6.978 6.978 0 0023 8a6.978 6.978 0 00-2.05-4.95A6.978 6.978 0 0016 1z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M16 5l3 3-3 3" />
        <path strokeLinecap="round" d="M11 3H4M5 8H1M18 8h-5M11 13H4" />
      </g>
    </svg>
  );
};
