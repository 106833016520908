import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <path
      clipRule="evenodd"
      d="M13.52 1a1 1 0 0 1 .716.302l2.538 2.603c1.43 1.466-.006 3.878-1.976 3.32L14 7v7.5a1.5 1.5 0 0 1-1.5 1.5v0a1.5 1.5 0 0 1-1.5-1.5V11H8.854a3 3 0 0 1-2.683-1.658L6 9l-1.029-.343A2.883 2.883 0 0 1 3 5.922v0c0-1.21.57-2.35 1.538-3.075L6.733 1.2a1 1 0 0 1 .6-.2h6.187z"
      stroke="#6C7280"
      strokeWidth="2"
    />
    <path
      clipRule="evenodd"
      d="M16 22v-2a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v2h7z"
      stroke="#6C7280"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M6 22h13" stroke="#6C7280" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
