import * as React from 'react';

export default () => (
  <svg width="40" height="40">
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
        fill="#0067F4"
        opacity=".1"
      />
      <g transform="translate(21 16)">
        <path fill="#FFF" d="M1.333 6H6c1.105 0 2 1.12 2 2.5V10a1 1 0 0 1-1 1H0" />
        <path
          stroke="#0067F4"
          fillOpacity=".2"
          fill="#0067F4"
          d="M1.333 6H6c1.105 0 2 1.12 2 2.5V10a1 1 0 0 1-1 1H0"
        />
      </g>
      <g transform="translate(21 16)">
        <rect fill="#FFF" x="2" y="0" width="4" height="4" rx="2" />
        <rect
          stroke="#0067F4"
          fillOpacity=".2"
          fill="#0067F4"
          x="2"
          y="0"
          width="4"
          height="4"
          rx="2"
        />
      </g>
      <g>
        <g transform="translate(11 13)">
          <path
            fill="#FFF"
            d="M3 8h6a3 3 0 0 1 3 3v2a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-2a3 3 0 0 1 3-3z"
          />
          <path
            stroke="#0067F4"
            fillOpacity=".2"
            fill="#0067F4"
            d="M3 8h6a3 3 0 0 1 3 3v2a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-2a3 3 0 0 1 3-3z"
          />
        </g>
        <g transform="translate(11 13)">
          <rect fill="#FFF" x="3" y="0" width="6" height="6" rx="3" />
          <rect
            stroke="#0067F4"
            fillOpacity=".2"
            fill="#0067F4"
            x="3"
            y="0"
            width="6"
            height="6"
            rx="3"
          />
        </g>
      </g>
    </g>
  </svg>
);
