import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <g stroke="#B5BFD2" strokeWidth="2">
      <rect x="3" y="4" width="18" height="16" rx="2" />
      <path d="M12 4v16M3 12h18" strokeLinecap="round" />
    </g>
  </svg>
);
