export default {
  "account": {
    "login": {
      "title": "Sign in to your account",
      "sub-title": "Continue to your organisation",
      "signin": "Sign In",
      "change_password": "Change Password",
      "forgot_password": "Forgot Password?",
      "activate_account": "Activate your account",
      "switch": "Switch to another account"
    },
    "terms": "By signing up and using the BEEM platform you agree to the {terms}, our {policy} and {acceptable}.",
    "email_label": "Email",
    "email_placeholder": "you@example.com",
    "email_not_valid": "Please enter a valid email!",
    "send_code": "Send me the link",
    "go_back": "Go back",
    "forgot_password": {
      "title": "Forgot your password?",
      "sub_title": "No worries! Just enter your email and we will send you a reset link.",
      "check_inbox": "Check your inbox!",
      "email_sent": "An email has been sent to {email} with your verification link.",
      "email_sent_expired": "The code is expired. A new email has been sent to {email} with your verification link."
    },
    "first_login": {
      "title": "Welcome to BEEM!",
      "sub_title": "Confirm your email address below and we'll send you a link to activate your BEEM account.",
      "check_inbox": "Check your inbox!",
      "email_sent": "An email has been sent to {email} with your verification link."
    },
    "reset_password": {
      "title": "Reset your password",
      "sub_title": "We're almost done! Enter your new password."
    },
    "code_label": "Code",
    "code_placeholder": "code",
    "code_error": "Please enter a reset code!",
    "password_label": "New Password",
    "password_placeholder": "password",
    "password_error": "Please enter a password!",
    "password_mismatch_error": "The passwords do not match!",
    "confirm_label": "Confirm Password",
    "new_password": {
      "title": "New password required",
      "sub-title": "You're almost done! Please enter a new password before continuing."
    },
    "settings": {
      "title": "Your profile settings",
      "sub_title": "Edit your profile and manage your preferences settings.",
      "details": {
        "tab": "Details",
        "title": "Your profile",
        "sub_title": "Set up your basic information",
        "first_name": "First name",
        "last_name": "Last name",
        "email": "Email",
        "password": "Password",
        "change_password": "Change password",
        "interface_language": "Interface Language"
      }
    },
    "activation": {
      "title": "Complete your account",
      "sub_title": "We just need a few more things from you before we get into the fun stuff. Please fill ou the following information.",
      "first_name": "First name",
      "last_name": "Last name",
      "password": "Password",
      "job_title": "Job title",
      "create_account": "Create account"
    }
  }
};
