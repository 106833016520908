import * as React from 'react';

export default (props) => (
  <svg width="21" height="19">
    <g fill="none" fillRule="evenodd" opacity={props.color ? '1' : '.6'}>
      <path
        stroke={props.color || '#0A132B'}
        strokeWidth="2"
        strokeLinejoin="round"
        d="M9 18V8h11v10z"
      />
      <path fill={props.color || '#0A132B'} d="M15 10h2v2h-2zM15 13h2v2h-2z" />
      <path stroke="#FFF" strokeWidth="2" fill="#FFF" strokeLinejoin="round" d="M1 18V1h12v17z" />
      <g>
        <path
          stroke={props.color || '#0A132B'}
          strokeWidth="2"
          fill="#FFF"
          strokeLinejoin="round"
          d="M1 18V1h12v17z"
        />
        <path
          fill={props.color || '#0A132B'}
          d="M4 4h2v2H4zM4 7h2v2H4zM4 10h2v2H4zM8 4h2v2H8zM8 7h2v2H8zM8 10h2v2H8zM6 14h2v3H6z"
        />
      </g>
    </g>
  </svg>
);
