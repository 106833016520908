export default class ConnectionLite {
  readonly id: string;

  readonly integrationName: string;

  constructor(input: { id: string; integrationName: string }) {
    this.id = input.id;
    this.integrationName = input.integrationName;
  }
}
