export default {
  "catalog": {
    "title": "Datalake",
    "sub": "{ count, plural, one {# Data Source} other {# Data Sources} }",
    "empty": "There's no source connected yet.",
    "empty2": "Start choosing your data integration to add sources in your Datalake.",
    "select": "Select Integration",
    "sync": {
      "title": "Grab a coffee, this might take a little bit.",
      "sub_title": "We are starting up the extraction engine for this source, this process can take several minutes.<br /> Navigating away or closing this tab will not interrupt this process"
    },
    "advancedConfig": {
      "title": "Advanced Configuration Request Sent",
      "specialConfig": "This type of connection requires a special configuration only our internal team can setup.",
      "teamNotified": "Our team has been notified and will enable this connection as soon as possible. ",
      "footer": "Please allow our team up to 24 hours to complete this configuration."
    },
    "fivetran_incomplete": {
      "title": "Connection Setup Incomplete",
      "subtitle": "You interrupted the connection process and some steps remain before launching the extraction.",
      "delete": "Delete Connection",
      "resume": "Resume Setup"
    }
  }
};
