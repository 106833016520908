import React from 'react';
import { notification } from 'antd';
import CustomCloseIcon from '@/components/Buttons/CloseIcon';
import { ErrorIcon, InfoCircleIcon, SuccessIcon, WarningIcon } from '@/components/Icons';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type NotificationPropType = {
  type: NotificationType;
  description: string;
  title?: string;
  onClick?: () => void;
};

const getIcon = (type: NotificationType) => {
  switch (type) {
    case 'success':
      return <SuccessIcon height="24" width="24" />;
    case 'error':
      return <ErrorIcon height="24" width="24" />;
    case 'warning':
      return <WarningIcon height="24" width="24" />;
    case 'info':
    default:
      return <InfoCircleIcon height="24" width="24" />;
  }
};

export const showNotification = ({ type, description, title, onClick }: NotificationPropType) => {
  const icon = getIcon(type);
  const key = `notification-${type}-${Date.now()}`;
  const capitalizedType = `${type.charAt(0).toUpperCase()}${type.slice(1).toLowerCase()}`;

  notification.open({
    message: title || capitalizedType,
    description,
    closeIcon: <CustomCloseIcon />,
    duration: null,
    icon,
    key,
    onClick,
  });
};
