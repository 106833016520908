import * as React from 'react';

export default (props) => (
  <svg width="40" height="40">
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.115 1.06A91.837 91.837 0 0 1 20 0c4.62 0 9.24.352 13.86 1.057a6 6 0 0 1 5.03 5.053c.74 5 1.11 9.63 1.11 13.89s-.37 8.89-1.11 13.89a6 6 0 0 1-5.03 5.053A91.834 91.834 0 0 1 20 40c-4.628 0-9.257-.353-13.885-1.06a6 6 0 0 1-5.028-5.038C.362 29.087 0 24.452 0 20c0-4.453.362-9.087 1.087-13.902A6 6 0 0 1 6.115 1.06z"
        fill={props.backgroundColor || '#FFC600'}
        opacity=".1"
      />
      <g transform="translate(10 11)">
        <path d="M10.5 17.5a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" fill="#FFF" />
        <path
          d="M10.5 17.5a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          stroke="#FFC600"
          fillOpacity=".2"
          fill="#FFC600"
        />
      </g>
      <path stroke="#FFC600" strokeLinecap="round" strokeLinejoin="round" d="M20.5 16.5v5h3" />
      <path stroke="#FFC600" strokeLinecap="round" d="M14.5 11.5l-4 5M26.5 11.5l4 5" />
    </g>
  </svg>
);
