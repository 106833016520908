import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <path
      fill="#FFC600"
      stroke="#E5B306"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.24 2.647c-1.838 1.34-4.632 2.07-7.066 1.971C3.556 4.593 3 5.063 3 5.68V14c0 4.207 6.119 6.666 8.55 7.786.285.131.615.131.9 0C14.882 20.666 21 18.207 21 14V5.68c0-.618-.556-1.087-1.174-1.062-2.434.1-5.228-.63-7.065-1.971a1.308 1.308 0 0 0-1.522 0Z"
    />
    <g filter="url(#a)">
      <path stroke="#fff" strokeLinejoin="round" strokeWidth={2} d="m8 11 3 3 5-5" />
    </g>
    <defs>
      <filter
        id="a"
        width={9.414}
        height={7.707}
        x={7.293}
        y={8.293}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="ds" />
        <feBlend in="SourceGraphic" in2="ds" result="shape" />
      </filter>
    </defs>
  </svg>
);
