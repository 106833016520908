import * as React from 'react';

export default ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '56'}
    height={height || '56'}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0251 15.5251C27.6583 16.892 27.6583 19.108 29.0251 20.4749C30.392 21.8417 32.608 21.8417 33.9749 20.4749L38.5 15.9497V49.5C38.5 51.433 40.067 53 42 53C43.933 53 45.5 51.433 45.5 49.5V15.9497L50.0251 20.4749C51.392 21.8417 53.608 21.8417 54.9749 20.4749C56.3417 19.108 56.3417 16.892 54.9749 15.5251L44.4749 5.02513C43.108 3.65829 40.892 3.65829 39.5251 5.02513L29.0251 15.5251ZM26.9749 41.4749C28.3417 40.108 28.3417 37.892 26.9749 36.5251C25.608 35.1583 23.392 35.1583 22.0251 36.5251L17.5 41.0503V7.5C17.5 5.567 15.933 4 14 4C12.067 4 10.5 5.567 10.5 7.5V41.0503L5.97487 36.5251C4.60804 35.1583 2.39196 35.1583 1.02513 36.5251C-0.341709 37.892 -0.341709 40.108 1.02513 41.4749L11.5251 51.9749C12.892 53.3417 15.108 53.3417 16.4749 51.9749L26.9749 41.4749Z"
      fill="#0067F4"
    />
  </svg>
);
