import * as React from 'react';

export default ({ stroke }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.86852C8 7.06982 8.89015 6.59343 9.5547 7.03647L15.7519 11.1679C16.3457 11.5638 16.3457 12.4362 15.7519 12.8321L9.5547 16.9635C8.89015 17.4066 8 16.9302 8 16.1315V7.86852Z"
        stroke={stroke || '#0067F4'}
        strokeWidth="2"
      />
    </svg>
  );
};
