import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle cx="8" cy="8" r="7" stroke="#1CC23A" strokeWidth="2" />
    <path
      d="M11 6L7 10L5 8"
      stroke="#1CC23A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
