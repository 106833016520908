import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 12.875v4.375A1.75 1.75 0 0 1 17.25 19H6.75A1.75 1.75 0 0 1 5 17.25v-4.375m0-5.25V6.75C5 5.784 5.784 5 6.75 5h10.5c.966 0 1.75.784 1.75 1.75v.875"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 6.75a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75V6.75zM17.25 4A2.75 2.75 0 0 1 20 6.75v10.5A2.75 2.75 0 0 1 17.25 20H6.75A2.75 2.75 0 0 1 4 17.25V6.75A2.75 2.75 0 0 1 6.75 4h10.5z"
      fill="#B5BFD2"
    />
    <path
      d="M21 9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h18z"
      fill="#fff"
    />
    <path
      d="M21 9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h18z"
      fill="#B5BFD2"
      fillOpacity=".4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11H4v2h16v-2zm2-1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-4z"
      fill="#B5BFD2"
    />
  </svg>
);
