import * as React from 'react';

export default (props) => {
  return (
    <svg width="24" height="24" viewBox="-2 -4 24 24">
      <g stroke={props.color || '#B5BFD2'} strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M3 1h4.172a2 2 0 011.414.586l1.121 1.121a1 1 0 00.707.293H16a2 2 0 012 2v8a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2z" />
        <g strokeLinecap="round">
          <path d="M6 9h7" />
          <path strokeLinejoin="round" d="M10 6l3 3-3 3" />
        </g>
      </g>
    </svg>
  );
};
