import * as React from 'react';

export default () => (
  <svg width="13" height="13" fill="none">
    <g stroke="#0067F4" strokeWidth="2" strokeLinecap="round">
      <path d="M4.357 2H1.5v10h10V9.143" strokeLinejoin="round" />
      <path d="M6.5 7l5-5" />
      <path d="M8.5 2h3v3" strokeLinejoin="round" />
    </g>
  </svg>
);
