export default {
  "connection": {
    "title": "Connections",
    "filter": {
      "title": "Filter",
      "input": "Search connections",
      "all": "All Connections"
    },
    "item": {
      "connections": "{connectionCount} Connections",
      "status": "Status",
      "owner": "Added by",
      "status_tag": { "true": "Active", "false": "Error" }
    },
    "dropdown": {
      "edit": "Edit",
      "rename": "Rename",
      "delete": "Delete"
    },
    "new": "New Connection",
    "unnamed": "Unnamed - {connectionId}",
    "rename_modal_dialog": {
      "title": "Rename {type} {name}",
      "name_input": "{type} new name",
      "submit_button": "Update",
      "warning_text": "Warning, this will change the name of the connection."
    }
  }
};
