import flatten from 'flat';

export default flatten({
  insights: {
    disclaimer: "BEEM AI insight chats aren't used to train AI models. BEEM AI can make mistakes.",
    headerImg: 'BEEM AI Insights header image',
    headerTitle: 'Welcome to BEEM AI Insights',
    headerSubtitle:
      'Discover how to uncover valuable insights from your company data by leveraging AI.',
    info1: 'None of your datasets are currently marked as searchable by BEEM AI.',
    info2: 'Follow the quick and easy steps below to start enjoying the power of AI and your data.',
    onboardingStep1Title: 'Add quality descriptions to your datasets',
    onboardingStep1Text:
      'To get the most out of your AI insights, add descriptions to your datasets that express what they contain and explain how to use the data. Navigate to a dataset, inside the editor view and add descriptions from the Documentation panel.',
    onboardingStep2Title: 'Mark your datasets as searchable',
    onboardingStep2Text:
      'In order for BEEM AI to give the most accurate answer to your questions, only marked datasets will be taken into account to generate insights. In the Documentation panel of each dataset, toggle on the searchable option.',
    onboardingStep3Title: 'Ask BEEM AI for insights about your data',
    onboardingStep3Text:
      'Uncover the best insights buried deep inside your datasets by asking the BEEM AI questions.',
    configure: 'Configure My Datasets',
    Chat: {
      placeholder: 'Ask a question about your data',
      placeholderFollowUp: 'Continue refining your question',
      inputPlaceholder: 'Type here to chat',
      copy: 'Copy',
      clear: 'Clear conversation',
      assistantName: 'Robot',
      yourName: 'You',
      send: 'Send',
      error: 'There was an error loading the chat messages.',
      disclaimer:
        'BEEM AI insights answers questions leveraging the data contained in the marked datasets of this workspace only.',
      datasetCount:
        '{datasetCount, plural, one {# Dataset} other {# Datasets}} will be used by {Icon} BEEM AI in this conversation.',
    },
    loading: {
      analyzing: 'Analyzing your questions',
      querying: 'Querying your data',
      sampling: 'Sampling data from {datasetName}',
      interpreting: 'Interpreting your results',
    },
    card: {
      questions: 'What type of questions can I ask you?',
      describe: 'Describe my datasets',
      howManyRows: 'How many rows are in my datasets',
      datasetPurpose:
        'Tell me what {datasetName} is used for and some interesting insights about it.',
    },
    showQuery: 'Show Query',
    showData: 'Show Data',
    copyData: 'Copy Data',
  },
});
