import * as React from 'react';

export default () => (
  <svg width="24" height="24">
    <defs>
      <rect id="a" x="0" y="0" width="24" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#FFF" />
        <rect
          stroke="#DADEE6"
          strokeLinejoin="square"
          fillOpacity=".4"
          fill="#F0F2F5"
          x=".5"
          y=".5"
          width="23"
          height="23"
          rx="2"
        />
      </g>
      <path
        d="M10.591 7.917l4.468 3.277a1 1 0 0 1 0 1.612l-4.468 3.277A1 1 0 0 1 9 15.277V8.723a1 1 0 0 1 1.591-.806z"
        fill="#0067F4"
      />
    </g>
  </svg>
);
