import flatten from 'flat';
import catalog from './en-US/catalog';
import source from './en-US/source';
import dataObject from './en-US/dataObject';

export default flatten({
  ...catalog,
  ...source,
  ...dataObject,
});
