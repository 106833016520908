import { graphql } from '@/utils/graphql';

export async function deleteAllSqlQueriesByDataset(
  datasetId: string,
  nextToken?: string,
): Promise<void> {
  const data = await graphql('sqlQueriesByDataset', { datasetId, nextToken }, true);
  await Promise.all(
    data.items.map(({ id, workspaceId }) => {
      if (!workspaceId) return null; // vinh408 only for old records who has no value
      return graphql('deleteDatasetSqlQuery', { input: { id } });
    }),
  );
  if (data.nextToken) await deleteAllSqlQueriesByDataset(datasetId, data.nextToken);
}
