import * as React from 'react';

export default (props) => {
  return (
    <svg
      width={props.svgWidth || 24}
      height={props.svgHeight || 22}
      viewBox={props.svgViewBox || '0 0 24 22'}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <rect
            strokeOpacity=".4"
            stroke="#B5BFD2"
            fill="#0A132B"
            x=".5"
            y=".5"
            width="23"
            height="21"
            rx="2"
          />
          <path d="M2 0h20a2 2 0 012 2v2H0V2a2 2 0 012-2z" fill="#4F576D" />
        </g>
        <g stroke="#B5BFD2" strokeLinecap="round" strokeWidth="2">
          <path strokeLinejoin="round" d="M5 8l4 4-4 4" />
          <path d="M10 16h5.833" />
        </g>
      </g>
    </svg>
  );
};
