import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      d="M3 10.1818C3 10.1818 3.66667 10.1818 4.33333 9.45455C5 8.72728 5 8 5 8V17M5 17H7M5 17H3"
      stroke={props.color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10C10 10 10 8 12 8C14 8 14.5 10.5 13.5 12C12.5 13.5 10 17 10 17H14"
      stroke={props.color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 8.99998C17 8.99998 17.5 8.00002 19 8C20.5 7.99998 21 8.99998 21 9.99998C21 11 18 13 18 13C18 13 21 13 21 15C21 16 20.5 17 19 17C17.5 17 17 16 17 16"
      stroke={props.color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
