import type * as A from '@/api';
import type { Column } from './types';
import Source from './source';
import Download from './download';
import * as DatasetCommon from './dataset.common';
import { graphql } from '@/utils/graphql';
import { logger } from '@/utils/logger';
import { addBackendSuffix } from '@/utils/namingConvention';

type DataObjectConstructorInput = A.GetDataObjectQuery['getDataObject'];

const ns = 'app.services.dataObject';

export default class DataObject {
  timestamp?: number;

  readonly id: string;

  readonly name: string;

  readonly viewName: string;

  readonly sourceId: string;

  readonly sourceWorkspaceSeq: number;

  readonly cloudResourceId: string;

  #stats?: {
    rowCount: number;
    createdDate: string;
    lastSync: string;
    columns: Column[];
    endTime: number;
    startTime: number;
  };

  get rowCount(): number | undefined {
    return this.#stats?.rowCount;
  }

  get createdDate(): string | undefined {
    return this.#stats?.createdDate;
  }

  get lastSync(): string | undefined {
    return this.#stats?.lastSync;
  }

  get columns() {
    return this.#stats?.columns;
  }

  get endTime() {
    return this.#stats?.endTime;
  }

  get startTime() {
    return this.#stats?.startTime;
  }

  get dbSchemaName() {
    if (this.cloudResourceId.startsWith('beeminternaldev.sample_db.'))
      return `ws${this.sourceWorkspaceSeq}_catalog`;
    return `${addBackendSuffix(`ws${this.sourceWorkspaceSeq}`)}_catalog`;
  }

  get dbViewName() {
    return this.viewName;
  }

  get dbViewsQualifiedName() {
    let part1 = this.dbSchemaName;
    if (/\W/.test(part1)) part1 = `"${part1}"`;
    let part2 = this.dbViewName;
    if (/\W/.test(part2)) part2 = `"${part2}"`;
    return `${part1}.${part2}`;
  }

  private constructor(input: DataObjectConstructorInput) {
    this.id = input.id;
    this.name = input.name;
    this.viewName = input.viewName;
    this.sourceId = input.sourceId;
    this.sourceWorkspaceSeq = input.source.workspace.seq;
    this.cloudResourceId = input.cloudResourceId;
    const { cloudResourceInfo } = input;
    if (cloudResourceInfo) this.#stats = JSON.parse(cloudResourceInfo);

    this.getDownloads = this.getDownloads.bind(this);
    this.getContentStart = this.getContentStart.bind(this);
  }

  async getDownloads(): Promise<Download[]> {
    return Download.getDownloadsByDataId(this.id);
  }

  getContentStart = DatasetCommon.getContentStart;

  static async getDataObjectById(id: string): Promise<DataObject> {
    logger.info(id, { label: 'app.services.dataObject.getDataObjectById.id' });
    const data = await graphql('getDataObject', { id });
    return new DataObject(data);
  }

  static async getDataObjectsBySource(
    sourceId: string,
    name?: A.ModelStringKeyConditionInput,
    filter?: A.ModelDataObjectFilterInput,
    nameContains?: string,
  ): Promise<DataObject[]> {
    logger.info({ label: `${ns}.getDataObjectsBySource.sourceId`, message: sourceId });
    let { items } = await graphql('listDataObjectsBySource', { sourceId, name, filter });
    if (nameContains) {
      const nc = nameContains.toLowerCase();
      items = items.filter((d) => d.name.toLowerCase().includes(nc));
    }
    return items.map((el) => new DataObject(el));
  }

  static async getDataObjectsByWorkspace(
    workspaceId: string,
    name?: A.ModelStringKeyConditionInput,
    filter?: A.ModelDataObjectFilterInput,
    nameContains?: string,
  ): Promise<DataObject[]> {
    logger.info({ label: `${ns}.getDataObjectsByWorkspace.workspaceId`, message: workspaceId });
    const sources = await Source.getSourcesByWorkspace(workspaceId);
    const promisesOutputs = await Promise.all(
      sources.map(async (source) =>
        DataObject.getDataObjectsBySource(source.id, name, filter, nameContains),
      ),
    );
    return promisesOutputs.reduce((prev, curr) => prev.concat(curr), []);
  }
}
