import * as React from 'react';

export default () => (
  <svg width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4a1 1 0 0 0-1 1v2a1 1 0 0 0 .293.707L11 13.414V19a1 1 0 1 0 2 0v-5.586l5.707-5.707A1 1 0 0 0 19 7V5a1 1 0 0 0-1-1H6zm1 2.586V6h10v.586l-5 5-5-5z"
      fill="#0067F4"
    />
  </svg>
);
