import type { ReduxSagaModel } from './types';
import type { FilterTypes } from '@/pages/Monitor';
import Event from '@/services/event';
import { logger } from '@/utils/logger';
import { sgcall } from '@/utils/reduxSaga';

export interface State {
  all: Event[] | null;
}

interface FetchAction {
  type: 'fetchAction';
  payload: Pick<State, 'all'>;
}

const initialState: State = { all: null };

const EventsModel: ReduxSagaModel<State, { fetchAction: FetchAction }> = {
  namespace: 'events',
  state: initialState,
  effects: {
    *fetch(
      {
        payload,
      }: {
        payload: {
          workspaceId: string;
          limit: number;
          eventFilters: FilterTypes;
          isLoadMore: boolean;
          setIsLoadingMore: (isLoadingMore: boolean) => void;
        };
      },
      { put },
    ) {
      const { workspaceId, limit, eventFilters, isLoadMore, setIsLoadingMore } = payload;
      const { startDate, endDate, objectType, status, name } = eventFilters;
      let filters = {};

      if (objectType !== 'default') filters = { ...filters, objectType };
      if (startDate && endDate)
        filters = { ...filters, dateRange: { from: startDate, to: endDate } };
      if (status !== 'default') filters = { ...filters, status };
      if (name) filters = { ...filters, name };

      const events = yield* sgcall(() => Event.getEventsByWorkspaceId(workspaceId, limit, filters));
      logger.debug({ label: 'app.models.events.fetch.events', message: events });

      yield put<FetchAction>({ type: 'fetchAction', payload: { all: events } });
      if (isLoadMore) setIsLoadingMore(false);
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    fetchAction(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default EventsModel;
