export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      workspaces {
        items {
          workspace {
            id
            name
            organizationId
          }
        }
      }
      organizations {
        items {
          id
          organizationId
        }
      }
    }
  }
`;
export const listUsersByEmail = /* GraphQL */ `
  query ListUsersByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      color
      workspaceLimit
      warehouseRetention
      timezone
      users {
        items {
          user {
            id
            email
            firstName
            lastName
            workspaces {
              items {
                workspace {
                  id
                  name
                  organizationId
                }
              }
            }
            organizations {
              items {
                id
                organizationId
              }
            }
          }
        }
        nextToken
      }
      cloudResourceId
      workspaces {
        items {
          id
          name
        }
      }
    }
  }
`;
export const listOrganizationUsersByUser = /* GraphQL */ `
  query ListOrganizationUsersByUser(
    $userId: ID
    $organizationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationUsersByUser(
      userId: $userId
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        hasWarehouseCredentials
      }
      nextToken
    }
  }
`;

export const getOrganizationFivetran = /* GraphQL */ `
  query GetOrganizationFivetran($id: ID!) {
    getOrganizationFivetran(id: $id) {
      id
      groupId
    }
  }
`;

export const getOrganizationChargebee = /* GraphQL */ `
  query GetOrganizationChargebee($id: ID!) {
    getOrganizationChargebee(id: $id) {
      id
      subscriptionId
      allowance
      billingCycleDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizationChargebees = /* GraphQL */ `
  query ListOrganizationChargebees(
    $filter: ModelOrganizationChargebeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationChargebees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subscriptionId
        allowance
        billingCycleDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getOrganizationDPU = /* GraphQL */ `
  query GetOrganizationDPU($id: ID!) {
    getOrganizationDPU(id: $id) {
      id
      organizationId
      date
      dpu
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizationDPUs = /* GraphQL */ `
  query ListOrganizationDPUs(
    $filter: ModelOrganizationDPUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationDPUs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        date
        dpu
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listOrganizationDPUsByOrganization = /* GraphQL */ `
  query ListOrganizationDPUsByOrganization(
    $organizationId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationDPUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationDPUsByOrganization(
      organizationId: $organizationId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        date
        dpu
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      name
      seq
      organization {
        id
        name
        color
        workspaceLimit
        warehouseRetention
        timezone
        cloudResourceId
      }
      users {
        items {
          user {
            id
            email
            firstName
            lastName
          }
        }
        nextToken
      }
    }
  }
`;
export const listWorkspacesByOrganization = /* GraphQL */ `
  query ListWorkspacesByOrganization(
    $organizationId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspacesByOrganization(
      organizationId: $organizationId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        seq
        organization {
          id
          name
          color
          workspaceLimit
          warehouseRetention
          timezone
          cloudResourceId
        }
      }
      nextToken
    }
  }
`;
export const listWorkspaceUsersByUser = /* GraphQL */ `
  query ListWorkspaceUsersByUser(
    $userId: ID
    $workspaceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaceUsersByUser(
      userId: $userId
      workspaceId: $workspaceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          email
          firstName
          lastName
        }
        workspace {
          id
          name
          seq
          organization {
            id
            name
            color
            workspaceLimit
            warehouseRetention
            timezone
            cloudResourceId
            workspaces {
              items {
                id
                name
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getSource = /* GraphQL */ `
  query GetSource($id: ID!) {
    getSource(id: $id) {
      id
      name
      description
      connectionId
      workspaceId
      cloudResourceId
    }
  }
`;
export const listSourcesByWorkspace = /* GraphQL */ `
  query ListSourcesByWorkspace(
    $workspaceId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSourcesByWorkspace(
      workspaceId: $workspaceId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        connectionId
        workspaceId
        cloudResourceId
      }
      nextToken
    }
  }
`;
export const listSourcesByConnection = /* GraphQL */ `
  query ListSourcesByConnection(
    $connectionId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSourcesByConnection(
      connectionId: $connectionId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const listSourcesByCloudResourceId = /* GraphQL */ `
  query ListSourcesByCloudResourceId(
    $cloudResourceId: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSourcesByCloudResourceId(
      cloudResourceId: $cloudResourceId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const listSourceWorkspaceSharesBySource = /* GraphQL */ `
  query ListSourceWorkspaceSharesBySource(
    $sourceId: ID
    $workspaceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourceWorkspaceShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSourceWorkspaceSharesBySource(
      sourceId: $sourceId
      workspaceId: $workspaceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        workspaceId
      }
      nextToken
    }
  }
`;
export const listSourceWorkspaceSharesByWorkspace = /* GraphQL */ `
  query ListSourceWorkspaceSharesByWorkspace(
    $workspaceId: ID
    $sourceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourceWorkspaceShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSourceWorkspaceSharesByWorkspace(
      workspaceId: $workspaceId
      sourceId: $sourceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        source {
          id
          name
          description
          connectionId
          workspaceId
          cloudResourceId
        }
      }
      nextToken
    }
  }
`;
export const getDataObject = /* GraphQL */ `
  query GetDataObject($id: ID!) {
    getDataObject(id: $id) {
      id
      name
      viewName
      sourceId
      source {
        workspace {
          seq
        }
      }
      cloudResourceId
      cloudResourceInfo
    }
  }
`;
export const listDataObjectsBySource = /* GraphQL */ `
  query ListDataObjectsBySource(
    $sourceId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDataObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataObjectsBySource(
      sourceId: $sourceId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        viewName
        sourceId
        source {
          workspace {
            seq
          }
        }
        cloudResourceId
        cloudResourceInfo
      }
      nextToken
    }
  }
`;
export const listDataObjectsByViewName = /* GraphQL */ `
  query ListDataObjectsByViewName(
    $viewName: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDataObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataObjectsByViewName(
      viewName: $viewName
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        viewName
        sourceId
        source {
          workspace {
            seq
          }
        }
        cloudResourceId
        cloudResourceInfo
      }
      nextToken
    }
  }
`;
export const getDataset = /* GraphQL */ `
  query GetDataset($id: ID!) {
    getDataset(id: $id) {
      id
      ownerId
      owner {
        id
        email
        firstName
        lastName
      }
      name
      description
      enableSearchByAI
      viewName
      isDraft
      folderId
      trigger
      triggerDetails
      workspaceId
      workspace {
        seq
        organizationId
      }
      cloudResourceId
      cloudResourceInfo
    }
  }
`;
export const getDatasetOnlySharedWithWorkspaceIds = /* GraphQL */ `
  query GetDatasetOnlySharedWithWorkspaceIds($id: ID!) {
    getDataset(id: $id) {
      sharedWithWorkspaceIds
    }
  }
`;
export const listDatasetsByFolder = /* GraphQL */ `
  query ListDatasetsByFolder(
    $folderId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetsByFolder(
      folderId: $folderId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        owner {
          id
          email
          firstName
          lastName
        }
        name
        description
        enableSearchByAI
        viewName
        isDraft
        folderId
        trigger
        triggerDetails
        workspaceId
        workspace {
          seq
          organizationId
        }
        cloudResourceId
        cloudResourceInfo
      }
      nextToken
    }
  }
`;
export const listDatasetsByViewName = /* GraphQL */ `
  query ListDatasetsByViewName(
    $viewName: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetsByViewName(
      viewName: $viewName
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        owner {
          id
          email
          firstName
          lastName
        }
        name
        description
        enableSearchByAI
        viewName
        isDraft
        folderId
        trigger
        triggerDetails
        workspaceId
        workspace {
          seq
          organizationId
        }
        cloudResourceId
        cloudResourceInfo
      }
      nextToken
    }
  }
`;
export const listDatasetsByWorkspace = /* GraphQL */ `
  query ListDatasetsByWorkspace(
    $workspaceId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetsByWorkspace(
      workspaceId: $workspaceId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        owner {
          id
          email
          firstName
          lastName
        }
        name
        description
        enableSearchByAI
        viewName
        isDraft
        folderId
        trigger
        triggerDetails
        workspaceId
        workspace {
          seq
          organizationId
        }
        cloudResourceId
        cloudResourceInfo
      }
      nextToken
    }
  }
`;
export const listDatasetsByWorkspaceOnlyIdAndName = /* GraphQL */ `
  query ListDatasetsByWorkspaceOnlyIdAndName(
    $workspaceId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetsByWorkspace(
      workspaceId: $workspaceId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const getDatasetTest = /* GraphQL */ `
  query GetDatasetTest($id: ID!) {
    getDatasetTest(id: $id) {
      id
      datasetId
      ownerId
      name
      isDraft
      workspaceId
      cloudResourceId
      cloudResourceInfo
    }
  }
`;
export const listDatasetTestsByDataset = /* GraphQL */ `
  query ListDatasetTestsByDataset(
    $datasetId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetTestsByDataset(
      datasetId: $datasetId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datasetId
        ownerId
        name
        isDraft
        workspaceId
        cloudResourceId
        cloudResourceInfo
      }
    }
  }
`;

export const getDatasetShareFromWorkspaceWithWorkspaceLink = /* GraphQL */ `
  query GetDatasetShareFromWorkspaceWithWorkspaceLink($id: ID!) {
    getDatasetShareFromWorkspaceWithWorkspaceLink(id: $id) {
      id
    }
  }
`;
export const listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId = /* GraphQL */ `
  query ListDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId(
    $withWorkspaceId: ID
    $fromWorkspaceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetShareFromWorkspaceWithWorkspaceLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId(
      withWorkspaceId: $withWorkspaceId
      fromWorkspaceId: $fromWorkspaceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        fromWorkspace {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listDatasetWorkspaceSharesByDataset = /* GraphQL */ `
  query ListDatasetWorkspaceSharesByDataset(
    $datasetId: ID
    $workspaceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetWorkspaceShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetWorkspaceSharesByDataset(
      datasetId: $datasetId
      workspaceId: $workspaceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceId
      }
      nextToken
    }
  }
`;
export const listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace = /* GraphQL */ `
  query ListDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace(
    $workspaceId: ID
    $originWorkspaceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetWorkspaceShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace(
      workspaceId: $workspaceId
      originWorkspaceId: $originWorkspaceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dataset {
          id
          ownerId
          owner {
            id
            email
            firstName
            lastName
          }
          name
          viewName
          isDraft
          folderId
          trigger
          triggerDetails
          workspaceId
          workspace {
            seq
            organizationId
          }
          cloudResourceId
          cloudResourceInfo
        }
      }
      nextToken
    }
  }
`;
export const getFlow = /* GraphQL */ `
  query GetFlow($id: ID!) {
    getFlow(id: $id) {
      id
      name
      enabled
      trigger
      triggerDetails
      workspaceId
      createdAt
      datasetId
      dataset {
        name
      }
      destinationId
      destination {
        integration {
          name
        }
      }
      cloudResourceId
      cloudResourceInfo
    }
  }
`;
export const listFlowsByWorkspace = /* GraphQL */ `
  query ListFlowsByWorkspace(
    $workspaceId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlowsByWorkspace(
      workspaceId: $workspaceId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        enabled
        trigger
        triggerDetails
        workspaceId
        createdAt
        datasetId
        dataset {
          name
        }
        destinationId
        destination {
          integration {
            name
          }
        }
        cloudResourceId
        cloudResourceInfo
      }
      nextToken
    }
  }
`;
export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      id
      name
      type
      category
      description
      dataSourceSupport
      flowActions
      createdAt
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations($filter: ModelIntegrationFilterInput, $limit: Int, $nextToken: String) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        category
        description
        dataSourceSupport
        flowActions
        createdAt
      }
      nextToken
    }
  }
`;
export const getConnectionOnlyNative = /* GraphQL */ `
  query GetConnectionOnlyNative($id: ID!) {
    getConnection(id: $id) {
      id
      name
      organizationId
      workspaceId
      owner {
        id
        email
        firstName
        lastName
      }
      integration {
        id
        name
        type
        category
        description
        dataSourceSupport
        flowActions
        createdAt
      }
      credentialsId
    }
  }
`;
export const getConnectionOnlyFivetran = /* GraphQL */ `
  query GetConnectionOnlyFivetran($id: ID!) {
    getConnection(id: $id) {
      id
      name
      organizationId
      workspaceId
      owner {
        id
        email
        firstName
        lastName
      }
      fivetranConnection {
        id
        name
        serviceName
        glueDatabaseName
        integrationName
        isDeployed
      }
    }
  }
`;
export const listConnectionsByWorkspaceNative = /* GraphQL */ `
  query ListConnectionsByWorkspaceNative(
    $workspaceId: ID
    $integrationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionsByWorkspaceNative(
      workspaceId: $workspaceId
      integrationId: $integrationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organizationId
        workspaceId
        owner {
          id
          email
          firstName
          lastName
        }
        integration {
          id
          name
          type
          category
          description
          dataSourceSupport
          flowActions
          createdAt
        }
        credentialsId
      }
      nextToken
    }
  }
`;
export const listConnectionsByWorkspaceFivetran = /* GraphQL */ `
  query ListConnectionsByWorkspaceFivetran(
    $workspaceId: ID
    $fivetranConnectionId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionsByWorkspaceFivetran(
      workspaceId: $workspaceId
      fivetranConnectionId: $fivetranConnectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organizationId
        workspaceId
        owner {
          id
          email
          firstName
          lastName
        }
        fivetranConnection {
          id
          name
          serviceName
          glueDatabaseName
          integrationName
          isDeployed
        }
      }
      nextToken
    }
  }
`;
export const listConnectionsByOrganizationFivetran = /* GraphQL */ `
  query ListConnectionsByOrganizationFivetran(
    $organizationId: ID
    $fivetranConnectionId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionsByOrganizationFivetran(
      organizationId: $organizationId
      fivetranConnectionId: $fivetranConnectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organizationId
        workspaceId
        owner {
          id
          email
          firstName
          lastName
        }
        fivetranConnection {
          id
          name
          serviceName
          glueDatabaseName
          integrationName
          isDeployed
        }
      }
      nextToken
    }
  }
`;
export const listConnectionsByOrganizationNative = /* GraphQL */ `
  query ListConnectionsByOrganizationNative(
    $organizationId: ID
    $integrationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionsByOrganizationNative(
      organizationId: $organizationId
      integrationId: $integrationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organizationId
        workspaceId
        owner {
          id
          email
          firstName
          lastName
        }
        integration {
          id
          name
          type
          category
          description
          dataSourceSupport
          flowActions
          createdAt
        }
        credentialsId
      }
      nextToken
    }
  }
`;
export const listConnectionsByOrganizationNativeOnlyIdAndName = /* GraphQL */ `
  query ListConnectionsByOrganizationNativeOnlyIdAndName(
    $organizationId: ID
    $integrationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionsByOrganizationNative(
      organizationId: $organizationId
      integrationId: $integrationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        integration {
          name
        }
      }
      nextToken
    }
  }
`;
export const listConnectionsByFivetranConnection = /* GraphQL */ `
  query ListConnectionsByFivetranConnection(
    $fivetranConnectionId: ID
    $organizationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionsByFivetranConnection(
      fivetranConnectionId: $fivetranConnectionId
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const getFivetranConnection = /* GraphQL */ `
  query GetFivetranConnection($id: ID!) {
    getFivetranConnection(id: $id) {
      id
      name
      serviceName
      glueDatabaseName
      integrationName
      isDeployed
    }
  }
`;
export const getCredentials = /* GraphQL */ `
  query GetCredentials($id: ID!) {
    getCredentials(id: $id) {
      id
      username
      password
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      name
      parentId
      parent {
        id
        name
      }
      subFolders {
        items {
          id
          name
        }
        nextToken
      }
      datasets {
        items {
          id
          name
        }
        nextToken
      }
    }
  }
`;
export const listFoldersByParent = /* GraphQL */ `
  query ListFoldersByParent(
    $parentId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFoldersByParent(
      parentId: $parentId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        parentId
        parent {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const getDownload = /* GraphQL */ `
  query GetDownload($id: ID!) {
    getDownload(id: $id) {
      id
      dataId
      dataTimeStamp
      requesterId
      requester {
        id
        email
        firstName
        lastName
      }
      requestTimeStamp
      signedDownloadUrl
    }
  }
`;
export const listDownloadsByData = /* GraphQL */ `
  query ListDownloadsByData(
    $dataId: ID
    $dataTimeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDownloadsByData(
      dataId: $dataId
      dataTimeStamp: $dataTimeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataId
        dataTimeStamp
        requesterId
        requester {
          id
          email
          firstName
          lastName
        }
        requestTimeStamp
        signedDownloadUrl
      }
      nextToken
    }
  }
`;
export const sqlQueriesByDataset = /* GraphQL */ `
  query SqlQueriesByDataset(
    $datasetId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetSqlQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sqlQueriesByDataset(
      datasetId: $datasetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datasetId
        workspaceId
        sqlQuery
        trigger
        triggerDetails
        deployed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pipelineResultsByRecordCloudResourceId = /* GraphQL */ `
  query PipelineResultsByRecordCloudResourceId(
    $recordCloudResourceId: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelineResultsByRecordCloudResourceId(
      recordCloudResourceId: $recordCloudResourceId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordCloudResourceId
        timestamp
        status
        info
      }
      nextToken
    }
  }
`;
export const listEventsByWorkspace = /* GraphQL */ `
  query ListEventsByWorkspace(
    $actionSourceWorkspaceId: ID
    $actionRecordedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByWorkspace(
      actionSourceWorkspaceId: $actionSourceWorkspaceId
      actionRecordedAt: $actionRecordedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        level
        userId
        actionType
        actionSourceId
        actionSourceName
        actionSourceType
        actionSourceWorkspaceId
        actionData
        actionRecordedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const beemAgentGetDataObjectContent = /* GraphQL */ `
  query BeemAgentGetDataObjectContent($query: GetDataObjectContentQueryInput) {
    beemAgentGetDataObjectContent(query: $query) {
      executionId
    }
  }
`;
export const beemAgentGetDatasetInfo = /* GraphQL */ `
  query BeemAgentGetDatasetInfo($query: GetDatasetInfoQueryInput) {
    beemAgentGetDatasetInfo(query: $query) {
      info
    }
  }
`;
export const beemAgentGetDatasetContent = /* GraphQL */ `
  query BeemAgentGetDatasetContent($query: GetDatasetContentQueryInput) {
    beemAgentGetDatasetContent(query: $query) {
      executionId
    }
  }
`;
export const beemAgentGetDatasetConsolidatedTestResults = /* GraphQL */ `
  query BeemAgentGetDatasetConsolidatedTestResults(
    $query: GetDatasetConsolidatedTestResultsQueryInput
  ) {
    beemAgentGetDatasetConsolidatedTestResults(query: $query) {
      executionId
    }
  }
`;
export const beemAgentModifyDataset = /* GraphQL */ `
  query BeemAgentModifyDataset($query: ModifyDatasetQueryInput) {
    beemAgentModifyDataset(query: $query) {
      dummy
    }
  }
`;
export const beemAgentDeleteDatasetHistoricalData = /* GraphQL */ `
  query BeemAgentDeleteDatasetHistoricalData($query: DeleteDatasetHistoricalDataQueryInput) {
    beemAgentDeleteDatasetHistoricalData(query: $query) {
      dummy
    }
  }
`;
export const beemAgentGetFlowInfo = /* GraphQL */ `
  query BeemAgentGetFlowInfo($query: GetFlowInfoQueryInput) {
    beemAgentGetFlowInfo(query: $query) {
      info
    }
  }
`;
export const beemAgentCheckRedshiftQuery = /* GraphQL */ `
  query BeemAgentCheckRedshiftQuery($query: CheckRedshiftQueryInput) {
    beemAgentCheckRedshiftQuery(query: $query) {
      status
      rows
      error
    }
  }
`;
export const beemAgentCancelRedshiftQuery = /* GraphQL */ `
  query BeemAgentCancelRedshiftQuery($query: CancelRedshiftQueryInput) {
    beemAgentCancelRedshiftQuery(query: $query) {
      status
      __typename
    }
  }
`;
export const beemAgentInsightConverse = /* GraphQL */ `
  query BeemAgentInsightConverse($query: InsightConverseInput) {
    beemAgentInsightConverse(query: $query) {
      messages
      metadata
    }
  }
`;
export const beemAgentInsightGetSampleDataStart = /* GraphQL */ `
  query BeemAgentInsightGetSampleDataStart($query: InsightGetSampleDataStartQueryInput) {
    beemAgentInsightGetSampleDataStart(query: $query) {
      executionIds
    }
  }
`;
export const beemAgentInsightGetSampleDataCheck = /* GraphQL */ `
  query BeemAgentInsightGetSampleDataCheck($query: InsightGetSampleDataCheckQueryInput) {
    beemAgentInsightGetSampleDataCheck(query: $query) {
      executions
    }
  }
`;
export const beemAgentInsightGetQueryFromBedrock = /* GraphQL */ `
  query BeemAgentInsightGetQueryFromBedrock($query: InsightGetQueryFromBedrockQueryInput) {
    beemAgentInsightGetQueryFromBedrock(query: $query) {
      query
      messages
    }
  }
`;
export const beemAgentInsightExecuteBedrockQueryStart = /* GraphQL */ `
  query BeemAgentInsightExecuteBedrockQueryStart(
    $query: InsightExecuteBedrockQueryStartQueryInput
  ) {
    beemAgentInsightExecuteBedrockQueryStart(query: $query) {
      executionId
    }
  }
`;
export const beemAgentInsightExecuteBedrockQueryCheck = /* GraphQL */ `
  query BeemAgentInsightExecuteBedrockQueryCheck(
    $query: InsightExecuteBedrockQueryCheckQueryInput
  ) {
    beemAgentInsightExecuteBedrockQueryCheck(query: $query) {
      status
    }
  }
`;
export const beemAgentInsightInterpretResult = /* GraphQL */ `
  query BeemAgentInsightInterpretResult($query: InsightInterpretResultQueryInput) {
    beemAgentInsightInterpretResult(query: $query) {
      result
      data
    }
  }
`;
export const fivetranFetchConnections = /* GraphQL */ `
  query FivetranFetchConnections($query: FivetranFetchConnectionsInput) {
    fivetranFetchConnections(query: $query) {
      response
    }
  }
`;
export const testDatasetStart = /* GraphQL */ `
  query TestDatasetStart($query: TestDatasetStartQueryInput) {
    testDatasetStart(query: $query) {
      executionId
    }
  }
`;
export const refreshDataset = /* GraphQL */ `
  query RefreshDataset($query: RefreshDatasetQueryInput) {
    refreshDataset(query: $query) {
      success
    }
  }
`;
export const validateIntegrationCredentials = /* GraphQL */ `
  query ValidateIntegrationCredentials($query: ValidateIntegrationCredentialsQueryInput) {
    validateIntegrationCredentials(query: $query)
  }
`;
// export const getFlowCode = /* GraphQL */ `
//   query GetFlowCode($query: GetFlowCodeQueryInput) {
//     getFlowCode(query: $query) {
//       commitId
//       fileContent
//     }
//   }
// `;
export const updateOrganizationSettings = /* GraphQL */ `
  query UpdateOrganizationSettings($query: UpdateOrganizationSettingsInput) {
    updateOrganizationSettings(query: $query) {
      success
    }
  }
`;
export const getWarehouseStorageInfo = /* GraphQL */ `
  query GetWarehouseStorageInfo($query: GetWarehouseStorageInfoInput) {
    getWarehouseStorageInfo(query: $query) {
      totalStorageCapacityInMegaBytes
      totalStorageUsedInMegaBytes
    }
  }
`;
export const getWarehouseConnectionInfo = /* GraphQL */ `
  query GetWarehouseConnectionInfo($query: GetWarehouseConnectionInfoInput) {
    getWarehouseConnectionInfo(query: $query) {
      host
      port
      database
    }
  }
`;
export const configureNewWorkspaceInTheWarehouse = /* GraphQL */ `
  query ConfigureNewWorkspaceInTheWarehouse($query: ConfigureNewWorkspaceInTheWarehouseInput) {
    configureNewWorkspaceInTheWarehouse(query: $query) {
      success
    }
  }
`;
export const upsertUserWarehouseCredentials = /* GraphQL */ `
  query UpsertUserWarehouseCredentials($query: UpsertUserWarehouseCredentialsInput) {
    upsertUserWarehouseCredentials(query: $query) {
      password
    }
  }
`;
export const createCognitoUser = /* GraphQL */ `
  query CreateCognitoUser($query: CreateCognitoUserInput) {
    createCognitoUser(query: $query) {
      id
    }
  }
`;
export const fivetranCreateConnectCard = /* GraphQL */ `
  query FivetranCreateConnectCard($query: FivetranCreateConnectCardInput) {
    fivetranCreateConnectCard(query: $query) {
      response
    }
  }
`;

export const fivetranRecreateConnectCard = /* GraphQL */ `
  query FivetranRecreateConnectCard($query: FivetranRecreateConnectCardInput) {
    fivetranRecreateConnectCard(query: $query) {
      response
    }
  }
`;
export const beemAgentFivetranDeployConnector = /* GraphQL */ `
  query BeemAgentFivetranDeployConnector($query: FivetranDeployConnectorQueryInput) {
    beemAgentFivetranDeployConnector(query: $query) {
      dummy
    }
  }
`;
export const beemAgentFivetranUndeployConnector = /* GraphQL */ `
  query BeemAgentFivetranUndeployConnector($query: FivetranUndeployConnectorQueryInput) {
    beemAgentFivetranUndeployConnector(query: $query) {
      dummy
    }
  }
`;
export const beemAgentAdminLogoutUser = /* GraphQL */ `
  query BeemAgentAdminLogoutUser($query: AdminLogoutUserInput) {
    beemAgentAdminLogoutUser(query: $query) {
      dummy
    }
  }
`;
export const fivetranDeleteConnector = /* GraphQL */ `
  query FivetranDeleteConnector($query: FivetranDeleteConnectorInput) {
    fivetranDeleteConnector(query: $query) {
      code
      message
      __typename
    }
  }
`;
export const getIDatasetUser = /* GraphQL */ `
  query GetIDatasetUser($id: ID!) {
    getIDatasetUser(id: $id) {
      id
      datasetId
      userId
      options
      __typename
    }
  }
`;
export const auth0CreateUser = /* GraphQL */ `
  query Auth0CreateUser($query: Auth0CreateUserInput) {
    auth0CreateUser(query: $query) {
      id
    }
  }
`;
export const auth0ChangePassword = /* GraphQL */ `
  query Auth0ChangePassword($query: Auth0ChangePasswordInput) {
    auth0ChangePassword(query: $query) {
      dummy
    }
  }
`;
export const auth0UpdateUserMetadata = /* GraphQL */ `
  query Auth0UpdateUserMetadata($query: Auth0UpdateUserMetadataInput) {
    auth0UpdateUserMetadata(query: $query) {
      dummy
    }
  }
`;
